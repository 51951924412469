import React from 'react';
import BetLockedRoundedBmx from '../BetLockedRoundedBmx/BetLockedRoundedBmx';
import { FaLock } from 'react-icons/fa';
import { TiHeartFullOutline } from "react-icons/ti";
export default function GameCard2(props) {
    const { Data, select, handleBackOpen, section1Ref, posArray, oddsDifference } = props;

    return (
        <div className="w-full">

            <div className="relative">
                {/* <div className={`even-background flex justify-center items-center w-full h-9 py-2 text-[14px] font-medium leading-4 white-text rounded cursor-pointer tracking-wide`}>
                    {select === "Red" ? <>
                        RED
                    </> : <>
                        BLACK
                    </>}

                </div> */}
                {Data.gstatus === "1" ?
                    <div className={` ${Data.nat === "LOW Card" ? 'border-[6px] rounded-none bg-transparent border-red-500' : Data.nat === "HIGH Card" ? 'border-[6px] rounded-none bg-transparent border-green-500' : 'border-none bg-[#455A46] rounded-lg'} py-1 space-y-1`}>
                        <div className="flex justify-center items-center">
                            <p className="uppercase md:text-[16px] text-[14px] px-2 text-[#AAAFB5] font-semibold">{Data && Data.rate ? (Data.rate - oddsDifference) : 0}</p>
                        </div>
                        <div onClick={() => handleBackOpen(Data, section1Ref)} className={`flex justify-center items-center w-full text-14[px] md:text-[16px] font-medium text-[#AAAFB5] rounded cursor-pointer tracking-wide`}>
                            {select === "Red" ? <>
                                {/* RED */}
                                <TiHeartFullOutline className='text-red-600 w-5 h-5' />
                            </> : <>
                                {/* BLACK */}
                                <svg className='w-5 h-5 text-black' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M11.5 12.5a3.493 3.493 0 0 1-2.684-1.254 19.92 19.92 0 0 0 1.582 2.907c.231.35-.02.847-.438.847H6.04c-.419 0-.67-.497-.438-.847a19.919 19.919 0 0 0 1.582-2.907 3.5 3.5 0 1 1-2.538-5.743 3.5 3.5 0 1 1 6.708 0A3.5 3.5 0 1 1 11.5 12.5z"></path></svg>
                            </>}
                        </div>
                        <div className={`${posArray[Data.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} flex text-14[px]  md:text-[16px] justify-center items-center font-bold`}>
                            {posArray[Data.sid] ?? 0}
                        </div>
                    </div>
                    :
                    // <BetLockedRoundedBmx />
                    <div className={`bg-[#455A46] relative ${Data.nat === "LOW Card" ? 'border-[6px] rounded-none bg-transparent border-red-500' : Data.nat === "HIGH Card" ? 'border-[6px] rounded-none bg-transparent border-green-500' : 'border-none bg-[#455A46] rounded-lg'} py-1 space-y-1`}>
                        <div className="flex justify-center items-center">
                            <p className="uppercase md:text-[16px] text-[14px] px-2 text-[#AAAFB5] font-semibold">{Data && Data.rate ? (Data.rate - oddsDifference) : 0}</p>
                        </div>
                        <div className={` flex justify-center items-center w-full md:text-[16px] text-[14px] font-medium text-[#AAAFB5] rounded cursor-pointer tracking-wide`}>
                            {select === "Red" ? <>
                                {/* RED */}
                                <TiHeartFullOutline className='text-red-600 w-5 h-5' />
                                <FaLock size={16} className="text-white absolute" />

                            </> : <>
                                {/* BLACK */}
                                <svg className='w-5 h-5 text-black' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M11.5 12.5a3.493 3.493 0 0 1-2.684-1.254 19.92 19.92 0 0 0 1.582 2.907c.231.35-.02.847-.438.847H6.04c-.419 0-.67-.497-.438-.847a19.919 19.919 0 0 0 1.582-2.907 3.5 3.5 0 1 1-2.538-5.743 3.5 3.5 0 1 1 6.708 0A3.5 3.5 0 1 1 11.5 12.5z"></path></svg>
                                <FaLock size={16} className="text-white absolute" />

                            </>}
                        </div>
                        <div className={`${posArray[Data.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} flex text-[18px] justify-center items-center font-bold`}>
                            {posArray[Data.sid] ?? 0}
                        </div>
                    </div>

                }
            </div>
            {/* <div className={`${posArray[Data.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center font-bold pt-1`}>
                {posArray[Data.sid] ?? 0}
            </div> */}
        </div>
    );
}