import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import BetLockedRoundedBmx from "../../casino_components/BetLockedRoundedBmx/BetLockedRoundedBmx";
import io from 'socket.io-client';
import PlaceBetMobileBmx from "../../casino_components/PlaceBetMobileBmx/PlaceBetMobileBmx";
import ResultModelBmx from "../../casino_components/ResultModelBmx/ResultModelBmx";
import FlipCountdown from '@rumess/react-flip-countdown';
import RoundedTabBmx from "../../casino_components/RoundedTabBmx/RoundedTabBmx";
import axios from "axios";
import PageHeaderTheme from "../../casino_components/PageHeaderTheme/PageHeaderTheme";
import moment from "moment";
import PlaceBetMobileBmxBet from "../../casino_components/PlaceBetMobileBmxBet/PlaceBetMobileBmxBet";
import MyBetTabBmx from "../../casino_components/MyBetTabBmx/MyBetTabBmx";
import BetListTableBmx from "../../casino_components/BetListTableBmx/BetListTableBmx";
import Loader from "../../components/Loader/Loader";
import { httpPost } from "../../_helpers/http";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from '../../casino_components/ErrorTost/ErrorTost';
import { BsSuitSpadeFill, BsSuitClubFill, BsSuitDiamondFill, BsFillSuitHeartFill } from "react-icons/bs";
import Card from "../../components/Card/Card";
import BetPlaceMobileLoader from "../../components/BetPlaceMobileLoader/BetPlaceMobileLoader";
import Sidebar from "../../components/Sidebar/Sidebar";
import RulesCasino from "../../components/RulesCasino/RulesCasino"
import BetPlaceMobile from '../../components/BetPlaceMobile/BetPlaceMobile';
import { FaHome } from "react-icons/fa";
import { FaCircleInfo } from "react-icons/fa6";
import BetPlaceDesktopCasinoLoader from "../../casino_components/BetPlaceDesktopCasinoLoader/BetPlaceDesktopCasinoLoader";
import BetPlaceDesktopCasino from "../../casino_components/BetPlaceDesktopCasino/BetPlaceDesktopCasino";
import BetListCasinoTableBmx from "../../casino_components/BetListCasinoTableBmx/BetListCasinoTableBmx";



class AndarBahar2 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            backBetModal: false,
            backBetModalMobile: false,
            Result: false,
            result: {},
            betSlipData: {},
            count: 0,
            time: 7,
            casinoData: [],
            showLoader: false,
            fieldsbet: {},
            errorsbet: {},
            ResultModel: false,
            casinoDetails: {},
            fetchData: "",
            socketURL: "",
            tvUrl: "",
            cacheURL: "",
            betStatus: "",
            cashinoStatus: "",
            eventId: "",
            minStake: "",
            maxStake: "",
            name: "",
            shortName: "abj",
            betList: [],
            betresponse: [],
            setting: {},
            posArray: {},
            currentRoundId: 0,
            oddsDifference: 0,
            totalProfitLoss: 0,
            socketPerm: "",
        };
        this.socket = null;
        this.section1Ref = React.createRef();
        this.scrollTimeout = null;
        this.inputRef = React.createRef();
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.users && nextProps.users.betPlaceSucess) {
            return {
                ...nextProps,
                backBetModal: false,
                backBetModalMobile: false,
                betSlipData: {},
            }
        } else {
            return {
                ...nextProps,

            }
        }
    }
    increaseCount = () => {
        const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
        this.setState({ count: parseFloat(increasedCount) });
    };

    decreaseCount = () => {
        const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
        this.setState({ count: parseFloat(decreasedCount) });
    };

    componentDidMount() {
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        let requestData = {
            eventId: this.props.match.params.eventId,
        }
        this.getDidMountData(requestData)
    }

    getDidMountData = async (requestData) => {
        this.setState({ showLoader: true })
        let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
        if (!getCasinoDetails.error) {
            let casinoDetails = getCasinoDetails.data
            this.setState({
                casinoDetails: casinoDetails,
                fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
                socketPerm: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : "",
                socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
                tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
                cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
                betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
                cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
                eventId: casinoDetails && casinoDetails.eventId ? casinoDetails.eventId : "",
                minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
                maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
                name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
                shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "abj",
                setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
                oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
            })
            if (casinoDetails.eventId) {
                this.betList(casinoDetails.eventId)
            }

            let socketPerm = ""
            let socketUrl = ""
            let cacheUrl = ""
            if (casinoDetails.fetchData) {
                socketPerm = casinoDetails.fetchData
            }
            if (casinoDetails.socketURL) {
                socketUrl = casinoDetails.socketURL
            }
            if (casinoDetails.cacheURL) {
                cacheUrl = casinoDetails.cacheURL
            }

            if (socketPerm === "socket") {
                await this.connectSocket(socketUrl, socketPerm, casinoDetails.shortName)
            } else {
                await this.callCache(cacheUrl);
            }

        } else {

        }
        this.setState({ showLoader: false })
    }

    connectSocket = async (socketUrl, socketPerm, shortName) => {
        if (!socketUrl) {
            socketUrl = this.state.socketURL
        }
        if (!socketPerm) {
            socketPerm = this.state.socketPerm
        }
        if (!shortName) {
            shortName = this.state.shortName
        }
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            return;
        }

        if (socketPerm === "socket") {
            this.socket = io.connect(socketUrl, {
                transports: ["websocket"],
                reconnection: false,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
                reconnectionAttempts: 99,
            });

            this.socket.emit('JoinRoom', shortName);
            this.socket.on(shortName, data => {
                this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
                this.setState({ casinoData: data })
                this.updatePos()
            });
        }
    }

    callCache = async (cacheUrl) => {
        try {
            this.interval = setInterval(async () => this.getMarketCacheUrl(cacheUrl), 1000);
        } catch (error) {
            console.error('Error calling cache:', error);
        }
    }

    getMarketCacheUrl = async (cacheUrl) => {
        try {
            const response = await axios.get(cacheUrl);
            this.setState({ casinoData: response?.data?.data })
        } catch (error) {
            console.error('Error fetching cache data:', error);
        }
    }



    updatePos = async () => {
        const { betList, currentRoundId } = this.state;
        if (betList && betList.length > 0) {
            const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
            let pushPos = {};
            filteredBets.forEach((bet) => {
                const posArray = bet.posArray;
                Object.entries(posArray).forEach(([key, value]) => {
                    pushPos[key] = (pushPos[key] || 0) + Number(value);
                });
            });

            this.setState({ posArray: pushPos });
        }
    }

    betList = async (eventId) => {
        let betReq = {
            "eventId": eventId,
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
        };

        let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
        let totalProfitLoss = 0
        let betList = getCasinoDetails?.data?.casinoBetData
        if (betList && betList.length > 0) {
            betList.map((bet, key) => {
                let profitLoss = "Not Declare"
                let profitLossCount = 0
                if (bet.isDeclare) {
                    profitLossCount = bet.profitLoss ? bet.profitLoss : 0
                    profitLoss = bet.profitLoss
                }
                totalProfitLoss += Number(profitLossCount)
                betList[key].profitLoss = profitLoss
            })
        }
        if (getCasinoDetails) {
            this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
        }
    }

    componentWillUnmount() {
        clearInterval(this.cacheInterval);
        clearTimeout(this.timer);
        if (this.inputRef.current) {
            this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
        }
        this.cleanupWebSocket();
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    }

    cleanupWebSocket = () => {
        if (this.socket) {
            this.socket.close();
        }
    };

    handleVisibilityChange = () => {
        if (document.visibilityState === 'visible' && !this.state.isConnected) {
            this.connectSocket()
        } else if (document.visibilityState === 'hidden') {
            this.cleanupWebSocket();
        }
    };

    updateStackOnclic = (num) => {
        let { betSlipData } = this.state;
        betSlipData.stake = num;
        this.setState({
            betSlipData: betSlipData,
        });
    };

    inputChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;

        if (value < 0 || isNaN(Number(value))) {
            value = 0;
        }

        if (value[0] === '0' && value.length > 1) {
            value = value.substring(1);
        }

        let { betSlipData } = this.state;
        betSlipData.stake = value;
        this.setState({
            betSlipData: betSlipData,
            [name]: Number(value),
        });
    };


    placeBet = async () => {
        const { casinoData } = this.state;
        this.setState({ LoadingBet: true })
        let { data } = casinoData ? casinoData : {};
        let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
        let betObject = {
            "roundId": t1.mid,
            "sid": this.state.betSlipData.sid,
            "rate": this.state.count + "",
            "amount": Number(this.state.betSlipData.stake),
            "casinoType": this.state.shortName ? this.state.shortName : "abj",
            "eventId": this.props.match.params.eventId,
            "betFor": this.state.betFor + "",
        }
        console.log(betObject, "this.state.betSlipData");

        const result = await httpPost("casino/casinoBetPlace", betObject);
        if (result) {
            if (result.error === false) {

                this.setState(
                    {
                        betresponse: result.data
                    },
                );
                toast.success(<ErrorTost message={result.message} />);
                this.betList(this.props.match.params.eventId)
                this.props.dispatch(userActions.getUserBalance());
            }
            else {
                toast.error(<ErrorTost message={result.message} />);
            }
        }
        this.handleClose();
        this.setState({ LoadingBet: false })
    };

    handleBackOpen = (data) => {
        console.log(data, "ab2/3043ab2/3043");
        this.betForSet(data?.nat)
        if (this.scrollTimeout) {
            clearInterval(this.scrollTimeout);
        }
        this.setState({ backBetModal: true, backBetModalMobile: true, betSlipData: { ...data, stake: "0" }, count: data && data.odds ? data.odds : data.b1, time: 7, });
        this.scrollTimeout = setInterval(() => {
            this.setState(prevState => ({ time: prevState.time - 1 }), () => {
                if (this.state.time < 0) {
                    this.setState({ backBetModal: false });
                }
            });
        }, 1000);
    };



    betForSet = (nat) => {
        let value = nat;
        if (value === "SA" || value === "1st Bet" || value === "2nd Bet" || value === "SB") {
            this.setState({ betFor: "andarBahar" })
        } else if (value === "Joker A" || value === "Joker 2" || value === "Joker 3" || value === "Joker 4" || value === "Joker 5" || value === "Joker 6" || value === "Joker 7" || value === "Joker 8" || value === "Joker 9" || value === "Joker 10" || value === "Joker J" || value === "Joker Q" || value === "Joker K") {
            this.setState({ betFor: "cards" })
        } else if (value === "Joker Diamond" || value === "Joker Heart" || value === "Joker Club" || value === "Joker Spade") {
            this.setState({ betFor: "color" })
        } else if (value === "Joker Odd" || "Joker Even") {
            this.setState({ betFor: "oddEven" })
        }
    }

    handleResultModel = (data) => {
        this.setState({ ResultModel: true, result: data });
    };

    handleClose = () => {
        this.setState({ backBetModal: false, backBetModalMobile: false });
    };

    handleCloseResult = () => {
        this.setState({ ResultModel: false });
    };

    updateStackOnclick = (num) => {
        let { betSlipData } = this.state;
        betSlipData.stake = num;
        this.setState({
            betSlipData: betSlipData,
        });
    };

    handleRulesCasinoModalOpen = () => {
        this.setState({ RulesCasinoModal: true });
    };

    updateStake = (event) => {
        let { value } = event.target;
        let { betSlipData } = this.state;
        if (value.startsWith('0') && value.length > 1) {
            value = value.slice(1);
        }
        betSlipData.stake = value;
        this.setState({
            betSlipData: betSlipData,
        });
    };
    inputChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;

        if (value < 0 || isNaN(Number(value))) {
            value = 0;
        }

        if (value[0] === '0' && value.length > 1) {
            value = value.substring(1);
        }

        let { betSlipData } = this.state;
        betSlipData.stake = value;
        this.setState({
            betSlipData: betSlipData,
            [name]: Number(value),
        });
    };
    handleCountdown = () => {
        if (this.state.remainingTime > 0) {
            this.setState((prevState) => ({
                remainingTime: prevState.remainingTime - 1,
            }));
        } else {
            // When the timer reaches 0, close the modal
            this.setState({ betPlaceModal: false });
        }
    };
    resetTimer = () => {
        this.setState({
            remainingTime: 10,
            timerKey: this.state.timerKey + 1,
        });
    };

    render() {
        const { casinoData, name, shortName, ResultModel, time, count, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, oddsDifference, totalProfitLoss, RulesCasinoModal, minStake, maxStake, } = this.state;
        let { data, result } = casinoData ? casinoData : {};

        let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
        // let t2 = data && data.t2 ? data.t2 : {};
        let image = t1 && t1.cards ? t1.cards.split(',') : [];
        let SA = data && data.t2 && data.t2[0] ? data.t2[0] : {};
        let SAFBet = data && data.t2 && data.t2[1] ? data.t2[1] : {};
        let SASBet = data && data.t2 && data.t2[2] ? data.t2[2] : {};
        let SB = data && data.t2 && data.t2[3] ? data.t2[3] : {};
        let SBFBet = data && data.t2 && data.t2[4] ? data.t2[4] : {};
        let SBSBet = data && data.t2 && data.t2[5] ? data.t2[5] : {};
        let CardA = data && data.t2 && data.t2[6] ? data.t2[6] : {};
        let Card2 = data && data.t2 && data.t2[7] ? data.t2[7] : {};
        let Card3 = data && data.t2 && data.t2[8] ? data.t2[8] : {};
        let Card4 = data && data.t2 && data.t2[9] ? data.t2[9] : {};
        let Card5 = data && data.t2 && data.t2[10] ? data.t2[10] : {};
        let Card6 = data && data.t2 && data.t2[11] ? data.t2[11] : {};
        let Card7 = data && data.t2 && data.t2[12] ? data.t2[12] : {};
        let Card8 = data && data.t2 && data.t2[13] ? data.t2[13] : {};
        let Card9 = data && data.t2 && data.t2[14] ? data.t2[14] : {};
        let Card10 = data && data.t2 && data.t2[15] ? data.t2[15] : {};
        let CardJ = data && data.t2 && data.t2[16] ? data.t2[16] : {};
        let CardQ = data && data.t2 && data.t2[17] ? data.t2[17] : {};
        let CardK = data && data.t2 && data.t2[18] ? data.t2[18] : {};
        let Spade = data && data.t2 && data.t2[19] ? data.t2[19] : {};
        let Club = data && data.t2 && data.t2[20] ? data.t2[20] : {};
        let Heart = data && data.t2 && data.t2[21] ? data.t2[21] : {};
        let Diamond = data && data.t2 && data.t2[22] ? data.t2[22] : {};
        let Odd = data && data.t2 && data.t2[23] ? data.t2[23] : {};
        let Even = data && data.t2 && data.t2[24] ? data.t2[24] : {};

        return (
            <>
                {showLoader ?
                    <Loader active={showLoader === true} />
                    :
                    <div className={`w-[100vw] relative h-screen page_bg overflow-y-auto text-sm aaaWraper flex`}>
                        {RulesCasinoModal === true ?
                            <RulesCasino
                                handleClose={this.handleClose}
                                casinorules="/rulesImage/ab2-rules.webpp"
                            />
                            : null}
                        {ResultModel &&
                            ResultModel === true ? (
                            <ResultModelBmx
                                handleClose={this.handleCloseResult}
                                name={name ? name : "Andar Bahar"}
                                shortName={shortName ? shortName : "abj"}
                                result={this.state.result}
                            />
                        ) : null}
                        {LoadingBet === true ?
                            <>
                                <BetPlaceMobileLoader
                                    betSlipData={this.state.betSlipData}
                                    time={time}
                                    count={count}
                                />
                            </> :
                            <>
                                {backBetModal && backBetModal === true ? (
                                    <BetPlaceMobile
                                        betSlipData={this.state.betSlipData}
                                        updateStackOnclick={this.updateStackOnclick}
                                        inputChange={this.inputChange}
                                        decreaseCount={this.decreaseCount}
                                        increaseCount={this.increaseCount}
                                        placeBet={this.placeBet}
                                        handleClose={this.handleClose}
                                        count={this.state.count}
                                        timerKey={this.state.timerKey}
                                        remainingTime={this.state.remainingTime}
                                        handleCountdown={this.handleCountdown}
                                        updateStake={this.updateStake}
                                    />
                                ) : null}
                            </>
                        }
                        <Sidebar props={this.props} />
                        <div className="lg:flex w-full h-full p-1.5 lg:space-x-1.5 space-y-4 lg:space-y-0 overflow-y-auto overflow-x-hidden">
                            <div className="lg:w-[70%] px[6px] w-full">
                                <div className="bg-[#0B0614] flex justify-between w-full relative md:text-sm text-[10px] xl:h-[615px] md:h-[350px] h-[250px]  px-2 py-1">
                                    <iframe src={tvUrl ? tvUrl : null} title=" " className='relative w-full h-full ' />
                                    <div className="bg-black/70 flex flex-col justify-center items-center absolute top-0 left-0 lg:p-3 p-1.5">
                                        <span className="text-[#E18C18] font-bold lg:text-lg text-sm uppercase">{"Andar Bahar"}</span>
                                        <span className="text-white font-semibold lg:text-sm text-xs"> Round ID: {t1 && t1.mid ? t1.mid : null}</span>
                                    </div>
                                    <div className="absolute top-[41%] left-0">
                                        <div className="py-1 px-2 bg-black/50 rounded-md flex justify-between items-center space-x-2 h-14 lg:h-16">
                                            <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="h-10 lg:h-14 w-8 lg:w-10" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-end items-end absolute top-0 right-0 lg:p-3 p-1.5 space-y-1">
                                        <div className="flex justify-center items-center space-x-2">
                                            <span className="p-1.5 lg:text-2xl text-lg rounded-full border-[2px] border-white text-white cursor-pointer"><FaHome /></span>
                                            <span onClick={this.handleRulesCasinoModalOpen} className="lg:p-1.5 p-1  text-lg lg:text-2xl rounded-full border-[2px] border-white text-white cursor-pointer"><FaCircleInfo /></span>
                                        </div>
                                    </div>
                                    <div className="flex justify-center items-center absolute bottom-2 right-2">
                                        <div className={`${t1 && t1.autotime === "0" ? "loader1" : "loader"}`}>
                                        </div>
                                        <span className="md:text-4xl text-2xl font-bold text-white absolute">{t1 && t1.autotime ? t1.autotime : null}</span>
                                    </div>
                                </div>

                                <div className="space-y-1 pt-1 !bg-[#2e3439]">
                                    <div className=" odds-bet">
                                        <div className=" text-center pt-2 w-full">
                                            <div className="flex py-0.5 md:gap-6 gap-2 justify-center items-center">
                                                <div className="md:flex block justify-between items-center md:space-x-12 space-x-0 w-5/6">
                                                    <div className="md:pb-5 pb-2">
                                                        <p className="text-md text-white font-bold">Andar</p>
                                                    </div>
                                                    <div className="flex justify-between items-center md:space-x-6 space-x-2 w-full">

                                                        <div className="w-full">
                                                            <div className="relative">
                                                                <div onClick={() => this.handleBackOpen({ data: SA, type: "Yes", odds: SA.b1, nat: SA.nat, sid: SA.sid }, this.section1Ref)}
                                                                    className="even-background 2xl:px-16 xl:px-10 px-4 white-text font-bold text-xs py-1  cursor-pointer">
                                                                    <p>{SA && SA.nat ? SA.nat : "2nd Bet"}</p>
                                                                    <p>{SA && SA.b1 ? SA.b1 : "2.00"}</p>
                                                                </div>
                                                                {SA && SA.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                                            </div>
                                                            <div className={`${posArray[SA.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[SA.sid] ?? 0.00}</div>
                                                        </div>
                                                        <div className="w-full">
                                                            <div className="relative">
                                                                <div onClick={() => this.handleBackOpen({ data: SAFBet, type: "Yes", odds: SAFBet.b1, nat: SAFBet.nat, sid: SAFBet.sid }, this.section1Ref)}
                                                                    className="even-background 2xl:px-16 xl:px-10 px-4 white-text font-bold text-xs py-1  cursor-pointer">
                                                                    <p>{SAFBet && SAFBet.nat ? SAFBet.nat : "2nd Bet"}</p>
                                                                    <p>{SAFBet && SAFBet.b1 ? SAFBet.b1 : "2.00"}</p>
                                                                </div>
                                                                {SAFBet && SAFBet.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                                            </div>
                                                            <div className={`${posArray[SAFBet.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[SAFBet.sid] ?? 0.00}</div>
                                                        </div>
                                                        <div className="w-full">
                                                            <div className="relative">
                                                                <div onClick={() => this.handleBackOpen({ data: SASBet, type: "Yes", odds: SASBet.b1, nat: SASBet.nat, sid: SASBet.sid }, this.section1Ref)}
                                                                    className="even-background 2xl:px-16 xl:px-10 px-4 white-text font-bold text-xs py-1  cursor-pointer">
                                                                    <p>{SASBet && SASBet.nat ? SASBet.nat : "2nd Bet"}</p>
                                                                    <p>{SASBet && SASBet.b1 ? SASBet.b1 : "2.00"}</p>
                                                                </div>
                                                                {SASBet && SASBet.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                                            </div>
                                                            <div className={`${posArray[SASBet.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[SASBet.sid] ?? 0.00}</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex py-0.5 md:gap-6 gap-2 justify-center items-center">
                                                <div className="md:flex block justify-between items-center md:space-x-12 space-x-0 w-5/6">
                                                    <div className="md:pb-5 pb-2">
                                                        <p className="text-md font-bold text-white">Bahar</p>
                                                    </div>
                                                    <div className="flex justify-between items-center md:space-x-6 space-x-2 w-full">

                                                        <div className="w-full">
                                                            <div className="relative">
                                                                <div onClick={() => this.handleBackOpen({ data: SB, type: "Yes", odds: SB.b1, nat: SB.nat, sid: SB.sid }, this.section1Ref)}
                                                                    className="even-background 2xl:px-16 xl:px-10 px-4 white-text font-bold text-xs py-1  cursor-pointer  ">
                                                                    <p>{SB && SB.nat ? SB.nat : "1st Bet"}</p>
                                                                    <p>{SB && SB.b1 ? SB.b1 : "2.00"}</p>
                                                                </div>
                                                                {SB && SB.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                                            </div>
                                                            <div className={`${posArray[SB.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[SB.sid] ?? 0.00}</div>
                                                        </div>
                                                        <div className="w-full">
                                                            <div className="relative">
                                                                <div onClick={() => this.handleBackOpen({ data: SBFBet, type: "Yes", odds: SBFBet.b1, nat: SBFBet.nat, sid: SBFBet.sid }, this.section1Ref)}
                                                                    className="even-background 2xl:px-16 xl:px-10 px-4 white-text font-bold text-xs py-1  cursor-pointer  ">
                                                                    <p>{SBFBet && SBFBet.nat ? SBFBet.nat : "1st Bet"}</p>
                                                                    <p>{SBFBet && SBFBet.b1 ? SBFBet.b1 : "2.00"}</p>
                                                                </div>
                                                                {SBFBet && SBFBet.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                                            </div>
                                                            <div className={`${posArray[SBFBet.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[SBFBet.sid] ?? 0.00}</div>
                                                        </div>
                                                        <div className="w-full">
                                                            <div className="relative">
                                                                <div onClick={() => this.handleBackOpen({ data: SBSBet, type: "Yes", odds: SBSBet.b1, nat: SBSBet.nat, sid: SBSBet.sid }, this.section1Ref)}
                                                                    className="even-background 2xl:px-16 xl:px-10 px-4 white-text font-bold text-xs py-1  cursor-pointer  ">
                                                                    <p>{SBSBet && SBSBet.nat ? SBSBet.nat : "1st Bet"}</p>
                                                                    <p>{SBSBet && SBSBet.b1 ? SBSBet.b1 : "2.00"}</p>
                                                                </div>
                                                                {SBSBet && SBSBet.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                                            </div>
                                                            <div className={`${posArray[SBSBet.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[SBSBet.sid] ?? 0.00}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="space-y-1">
                                    <div className=" odds-bet">
                                        <div className=" text-center !bg-[#2e3439] w-full">
                                            <div className="flex py-0.5 gap-2 justify-center items-center">
                                                <div className="md:flex block justify-between items-center md:space-x-2 space-x-0 w-11/12">
                                                    <div className="flex justify-between items-center md:space-x-2 space-x-2 w-full">

                                                        <div className="w-full">
                                                            <p className="text-[14px] font-[600] text-white">{Odd && Odd.b1 ? Odd.b1 : "2.00"}</p>
                                                            <div className="relative">
                                                                <div onClick={() => this.handleBackOpen({ data: Odd, type: "Yes", odds: Odd.b1, nat: Odd.nat, sid: Odd.sid }, this.section1Ref)}
                                                                    className="even-background white-text font-bold text-sm py-2.5  cursor-pointer uppercase ">
                                                                    <p>{Odd && Odd.nat ? Odd.nat : "ODD"}</p>
                                                                </div>
                                                                {Odd && Odd.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                                            </div>
                                                            <div className={`${posArray[Odd.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[Odd.sid] ?? 0.00}</div>
                                                        </div>
                                                        <div className="w-full">
                                                            <p className="text-[14px] font-[600] text-white">{Even && Even.b1 ? Even.b1 : "2.00"}</p>
                                                            <div className="relative">
                                                                <div onClick={() => this.handleBackOpen({ data: Even, type: "Yes", odds: Even.b1, nat: Even.nat, sid: Even.sid }, this.section1Ref)}
                                                                    className="even-background white-text font-bold text-sm py-2.5  cursor-pointer uppercase ">
                                                                    <p>{Even && Even.nat ? Even.nat : "Even"}</p>
                                                                </div>
                                                                {Even && Even.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                                            </div>
                                                            <div className={`${posArray[Odd.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[Odd.sid] ?? 0.00}</div>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-between items-center md:space-x-2 space-x-2 w-full">


                                                        <div className="w-full">
                                                            <p className="text-[14px] font-[600] text-white">{Spade && Spade.b1 ? Spade.b1 : "2.00"}</p>
                                                            <div className="relative">
                                                                <div onClick={() => this.handleBackOpen({ data: Spade, type: "Yes", odds: Spade.b1, nat: Spade.nat, sid: Spade.sid }, this.section1Ref)}
                                                                    className="even-background font-bold text-sm py-2.5  cursor-pointer uppercase flex justify-center items-center">
                                                                    <p className="text-black">   <BsSuitSpadeFill /></p>
                                                                </div>
                                                                {Spade && Spade.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                                            </div>
                                                            <div className={`${posArray[Spade.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[Spade.sid] ?? 0.00}</div>
                                                        </div>
                                                        <div className="w-full">
                                                            <p className="text-[14px] font-[600] text-white">{Club && Club.b1 ? Club.b1 : "2.00"}</p>
                                                            <div className="relative">
                                                                <div onClick={() => this.handleBackOpen({ data: Club, type: "Yes", odds: Club.b1, nat: Club.nat, sid: Club.sid }, this.section1Ref)}
                                                                    className="even-background font-bold text-sm py-2.5  cursor-pointer uppercase flex justify-center items-center ">
                                                                    <p className="text-black"> <BsSuitClubFill /></p>
                                                                </div>
                                                                {Club && Club.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                                            </div>
                                                            <div className={`${posArray[Club.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[Club.sid] ?? 0.00}</div>
                                                        </div>
                                                        <div className="w-full">
                                                            <p className="text-[14px] font-[600] text-white">{Heart && Heart.b1 ? Heart.b1 : "2.00"}</p>
                                                            <div className="relative">
                                                                <div onClick={() => this.handleBackOpen({ data: Heart, type: "Yes", odds: Heart.b1, nat: Heart.nat, sid: Heart.sid }, this.section1Ref)}
                                                                    className="even-background font-bold text-sm py-2.5  cursor-pointer uppercase flex justify-center items-center ">
                                                                    <p className="text-red-800"> <BsFillSuitHeartFill /></p>
                                                                </div>
                                                                {Heart && Heart.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                                            </div>
                                                            <div className={`${posArray[Heart.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[Heart.sid] ?? 0.00}</div>
                                                        </div>
                                                        <div className="w-full">
                                                            <p className="text-[14px] font-[600] text-white">{Diamond && Diamond.b1 ? Diamond.b1 : "2.00"}</p>
                                                            <div className="relative">
                                                                <div onClick={() => this.handleBackOpen({ data: Diamond, type: "Yes", odds: Diamond.b1, nat: Diamond.nat, sid: Diamond.sid }, this.section1Ref)}
                                                                    className="even-background font-bold text-sm py-2.5  cursor-pointer uppercase flex justify-center items-center ">
                                                                    <p className="text-red-800"> <BsSuitDiamondFill /></p>
                                                                </div>
                                                                {Diamond && Diamond.gstatus === "1" ? null : <BetLockedRoundedBmx />}
                                                            </div>
                                                            <div className={`${posArray[Diamond.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[12px] font-[600]`} > {posArray[Diamond.sid] ?? 0.00}</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="px-1 px[10px] py-4 paddingOfCards pb-0 bg-[#2e3439]">
                                    <div className="odds-bet space-y-2">
                                        <p className="flex justify-center items-center text-[#AAAFB5] text-[16px] py-2 bg-[#3C444B] font-bold mb-4">11</p>
                                        <div class="w-full row row-cols13 !flex-wrap mx-0 justify-center items-center bg-[#2e3439]">
                                            <div className="col-auto">
                                                <div className="relative">
                                                    <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                                        { data: CardA, type: "Yes", odds: CardA.b1, nat: CardA.nat, betFor: "cards" }, this.section1Ref)}>
                                                        <img src="/assets/images/1.jpg" alt="" className="w-[42px] h-auto object-contain" />
                                                    </div>
                                                    {CardA.gstatus === "0" ? <BetLockedRoundedBmx /> : null}
                                                </div>
                                                <div className={`${posArray[CardA.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[CardA.sid] ?? 0.00}</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="relative">
                                                    <div className="justify-center flex-col flex items-center"
                                                        onClick={() => this.handleBackOpen({ data: Card2, type: "Yes", odds: Card2.b1, nat: Card2.nat, betFor: "cards" }, this.section1Ref)}>
                                                        <img src="/assets/images/2.jpg" alt="" className="w-[42px] h-auto object-contain" />
                                                    </div>
                                                    {Card2.gstatus === "0" ? <BetLockedRoundedBmx /> : null}
                                                </div>
                                                <div className={`${posArray[Card2.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card2.sid] ?? 0.00}</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="relative">
                                                    <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                                        { data: Card3, type: "Yes", odds: Card3.b1, nat: Card3.nat, betFor: "cards" }, this.section1Ref)}>
                                                        <img src="/assets/images/3.jpg" alt="" className="w-[42px] h-auto object-contain" />
                                                    </div>
                                                    {Card3.gstatus === "0" ? <BetLockedRoundedBmx /> : null}
                                                </div>
                                                <div className={`${posArray[Card3.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card3.sid] ?? 0.00}</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="relative">
                                                    <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                                        { data: Card4, type: "Yes", odds: Card4.b1, nat: Card4.nat, betFor: "cards" }, this.section1Ref)}>
                                                        <img src="/assets/images/3.jpg" alt="" className="w-[42px] h-auto object-contain" />
                                                    </div>
                                                    {Card4.gstatus === "0" ? <BetLockedRoundedBmx /> : null}
                                                </div>
                                                <div className={`${posArray[Card4.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card4.sid] ?? 0.00}</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="relative">
                                                    <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                                        { data: Card5, type: "Yes", odds: Card5.b1, nat: Card5.nat, betFor: "cards" }, this.section1Ref)}>
                                                        <img src="/assets/images/5.jpg" alt="" className="w-[42px] h-auto object-contain" />
                                                    </div>
                                                    {Card5.gstatus === "0" ? <BetLockedRoundedBmx /> : null}
                                                </div>
                                                <div className={`${posArray[Card5.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card5.sid] ?? 0.00}</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="relative">
                                                    <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                                        { data: Card6, type: "Yes", odds: Card6.b1, nat: Card6.nat, betFor: "cards" }, this.section1Ref)}>
                                                        <img src="/assets/images/6.jpg" alt="" className="w-[42px] h-auto object-contain" />
                                                    </div>
                                                    {Card6.gstatus === "0" ? <BetLockedRoundedBmx /> : null}
                                                </div>
                                                <div className={`${posArray[Card6.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card6.sid] ?? 0.00}</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="relative">
                                                    <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                                        { data: Card7, type: "Yes", odds: Card7.b1, nat: Card7.nat, betFor: "cards" }, this.section1Ref)}>
                                                        <img src="/assets/images/7.jpg" alt="" className="w-[42px] h-auto object-contain" />
                                                    </div>
                                                    {Card7.gstatus === "0" ? <BetLockedRoundedBmx /> : null}
                                                </div>
                                                <div className={`${posArray[Card7.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card7.sid] ?? 0.00}</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="relative">
                                                    <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                                        { data: Card8, type: "Yes", odds: Card8.b1, nat: Card8.nat, betFor: "cards" }, this.section1Ref)}>
                                                        <img src="/assets/images/8.jpg" alt="" className="w-[42px] h-auto object-contain" />
                                                    </div>
                                                    {Card8.gstatus === "0" ? <BetLockedRoundedBmx /> : null}
                                                </div>
                                                <div className={`${posArray[Card8.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card8.sid] ?? 0.00}</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="relative">
                                                    <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                                        { data: Card9, type: "Yes", odds: Card9.b1, nat: Card9.nat, betFor: "cards" }, this.section1Ref)}>
                                                        <img src="/assets/images/9.jpg" alt="" className="w-[42px] h-auto object-contain" />
                                                    </div>
                                                    {Card9.gstatus === "0" ? <BetLockedRoundedBmx /> : null}
                                                </div>
                                                <div className={`${posArray[Card9.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card9.sid] ?? 0.00}</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="relative">
                                                    <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                                        { data: Card10, type: "Yes", odds: Card10.b1, nat: Card10.nat, betFor: "cards" }, this.section1Ref)}>
                                                        <img src="/assets/images/10.jpg" alt="" className="w-[42px] h-auto object-contain" />
                                                    </div>
                                                    {Card10.gstatus === "0" ? <BetLockedRoundedBmx /> : null}
                                                </div>
                                                <div className={`${posArray[Card10.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card10.sid] ?? 0.00}</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="relative">
                                                    <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                                        { data: CardJ, type: "Yes", odds: CardJ.b1, nat: CardJ.nat, betFor: "cards" }, this.section1Ref)}>
                                                        <img src="/assets/images/11.jpg" alt="" className="w-[42px] h-auto object-contain" />
                                                    </div>
                                                    {CardJ.gstatus === "0" ? <BetLockedRoundedBmx /> : null}
                                                </div>
                                                <div className={`${posArray[CardJ.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[CardJ.sid] ?? 0.00}</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="relative">
                                                    <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                                        { data: CardQ, type: "Yes", odds: CardQ.b1, nat: CardQ.nat, betFor: "cards" }, this.section1Ref)}>
                                                        <img src="/assets/images/12.jpg" alt="" className="w-[42px] h-auto object-contain" />
                                                    </div>
                                                    {CardQ.gstatus === "0" ? <BetLockedRoundedBmx /> : null}
                                                </div>
                                                <div className={`${posArray[CardQ.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[CardQ.sid] ?? 0.00}</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="relative">
                                                    <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                                        { data: CardK, type: "Yes", odds: CardK.b1, nat: CardK.nat, betFor: "cards" }, this.section1Ref)}>
                                                        <img src="/assets/images/13.jpg" alt="" className="w-[42px] h-auto object-contain" />
                                                    </div>
                                                    {CardK.gstatus === "0" ? <BetLockedRoundedBmx /> : null}
                                                </div>
                                                <div className={`${posArray[CardK.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[CardK.sid] ?? 0.00}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>














                                <div className="lg:hidden block">
                                    <div className="flex flex-col justify-center items-center space-y-2 mt-3 mb-5">
                                        <div className="grid grid-cols-7 gap-2">
                                            {result && result.length > 0 ? result.slice(0, 7).map((element, index) =>
                                                <span onClick={() => this.handleResultModel(element)} key={index} className={`bg-black/80 px-2 py-1 rounded font-bold cursor-pointer ${element.result === "1" ? "text-[#EF910F]" : "text-[#F74242]"}`}>
                                                    {element.result === "1" ? "A" : element.result === "2" ? "B" : "-"}</span>)
                                                : null}
                                        </div>
                                        <div className="grid grid-cols-3 gap-2">
                                            {result && result.length > 0 ? result.slice(6, 9).map((element, index) =>
                                                <span onClick={() => this.handleResultModel(element)} key={index} className={`bg-black/80 px-2 py-1 rounded font-bold cursor-pointer ${element.result === "1" ? "text-[#EF910F]" : "text-[#F74242]"}`}>
                                                    {element.result === "1" ? "A" : element.result === "2" ? "B" : "-"}</span>)
                                                : null}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="lg:w-[30%] lg:block hidden px-[6px] w-full space-y-2">

                                <div className="md:block hidden bg-black">
                                    <RoundedTabBmx
                                        PageTitle={name ? name : "20-20 Teenpatti"}
                                        ruleImage={"/rulesImage/aaaRules.webp"}
                                        eventId={this.props.match.params.eventId} />
                                    <div className="flex flex-wrap justify-center items-center py-2 gap-1 px-1">
                                        {result && result.length > 0 ? result.map((element, index) => (
                                            <div onClick={() => this.handleResultModel(element)} className={`bg-[#434343] ${element && element.result && element.result === "1" ? "text-[#FC4242]" : element && element.result && element.result === "2" ? "text-[#FDCF13]" : "text-[#03B177]"}  w-[30px] h-[30px] cursor-pointer flex justify-center items-center rounded-[4px] py-[4px] px-[20px]`} >
                                                <p className={`font-semibold text-[16px]`}>{element && element.result && element.result === "1" ? "A" : element && element.result && element.result === "2" ? "B" : "-"}</p>
                                            </div>
                                        )) : null}
                                    </div>
                                </div>

                                <div className="bg-[#3C444B] mt-3 md:block hidden">
                                    <div className="bg-[#16191C] text-[#AAAFB5] text-[16px] px-2 py-[6px] flex justify-between items-center">
                                        <span className="uppercase font-semibold tracking-wider">
                                            Place Bet
                                        </span>
                                        <div className='font-medium text-[12px]'>
                                            Range:{minStake ? minStake : "100"}-
                                            {maxStake ? maxStake : "10000"}
                                        </div>
                                    </div>
                                    <div className="px-3 text-[#AAAFB5] text-[16px] py-1.5 flex justify-between items-center ">
                                        <span>(Bet for)</span>
                                        <span>Odds</span>
                                        <span>Stake</span>
                                        <span>Profit</span>
                                    </div>
                                </div>

                                {LoadingBet === true ?
                                    <>
                                        <BetPlaceDesktopCasinoLoader
                                            betSlipData={this.state.betSlipData}
                                            time={time}
                                            count={count}
                                        />
                                    </> :
                                    <>
                                        {backBetModal && backBetModal === true ? (
                                            <BetPlaceDesktopCasino
                                                betSlipData={this.state.betSlipData}
                                                updateStackOnclick={this.updateStackOnclick}
                                                inputChange={this.inputChange}
                                                decreaseCount={this.decreaseCount}
                                                increaseCount={this.increaseCount}
                                                placeBet={this.placeBet}
                                                handleClose={this.handleClose}
                                                count={this.state.count}
                                                timerKey={this.state.timerKey}
                                                remainingTime={this.state.remainingTime}
                                                handleCountdown={this.handleCountdown}
                                                updateStake={this.updateStake}
                                            />
                                        ) : null}
                                    </>
                                }

                                <div className="bg-[#3C444B] mt-2">
                                    <div className="bg-[#000000] text-[#AAAFB5] text-[16px] px-2.5 py-[6px] flex justify-between items-center">
                                        <span className="uppercase font-semibold tracking-wider">
                                            MY BETS
                                        </span>
                                    </div>
                                </div>

                                <div className="pb-48">
                                    {betList && betList.length > 0 ?
                                        <div className="space-y-[1px] bg-gray-200 pb-1 rounded">
                                            <BetListCasinoTableBmx betList={betList} />
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>}
            </>
        );
    }
}

function mapStateToProps(state) {
    const { users } = state;
    return {
        users,
    };
}

export default connect(mapStateToProps)(AndarBahar2);