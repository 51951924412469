import { alertConstants } from '../_constants';//Raman
import { toast } from 'react-toastify';


export const alertActions = {
    success,
    error,
    clear
};

function success(message) {
    let messagetest = message.toString();
    // toast(messagetest)
    toast.success(messagetest, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
            top: '80px' // Set custom top offset
        }
    });
    return { type: alertConstants.SUCCESS, message };
}

function error(message) {
    let messagetest = message.toString();
    // toast(messagetest)
    toast.error(messagetest, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
            top: '80px' // Set custom top offset
        }
    });

    return { type: alertConstants.ERROR, message: messagetest };
}

function clear() {
    return { type: alertConstants.CLEAR };
}