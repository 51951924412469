import React from 'react';
import moment from 'moment';

export default function BetListCasinoTableBmx(props) {
    const { betList } = props;
    return (

        <div className="overflow-x-auto w-full ">
            <table className="min-w-full capitalize border-[1px] border-black">
                <tr className="w-full bg-[#3C444B] text-[#AAAFB5] text-[14px] font-semibold text-center rounded-b">
                    <th colSpan={4} className="px-3 py-[6px] whitespace-nowrap border-black  border-[1px] text-left">Team</th>
                    {/* <th className="px-3 py-[6px] whitespace-nowrap">Odds</th> */}
                    <th className="px-3 py-[6px] border-black  border-[1px] whitespace-nowrap">Mode</th>
                    <th className="px-3 py-[6px] border-black  border-[1px] whitespace-nowrap">Rate</th>
                    <th className="px-3 py-[6px] border-black  border-[1px] whitespace-nowrap">Amount</th>
                    <th className="px-3 py-[6px] border-black  border-[1px] whitespace-nowrap">Result</th>
                    <th className="px-3 py-[6px] border-black  border-[1px] whitespace-nowrap text-left">Created</th>
                </tr>
                {betList && betList.length > 0 ? betList.map((tempData, index) => (
                    <tr key={index} className={`w-full text-black text-[12px] font-medium text-center rounded-b bg-[#CDF9FF] `}>
                        {/* ${tempData.profitLoss < 0 ? 'bg-red-600' : 'bg-green-600'} */}
                        <td colSpan={4} className="px-3 py-[6px] border-black/70  border-[1px] whitespace-nowrap text-left">
                            <span className=' text-black text-[12px] text-center font-semibold p-1 uppercase '>{tempData.playerName}</span> <br />{tempData.roundId}</td>
                        {/* <td className="px-3 py-[6px] whitespace-nowrap">{tempData.amount}</td> */}
                        <td className="px-3 py-[6px] whitespace-nowrap border-black/70  border-[1px]">{tempData.betType}</td>
                        <td className="px-3 py-[6px] whitespace-nowrap border-black/70  border-[1px]">{tempData.odds}</td>
                        <td className="px-3 py-[6px] whitespace-nowrap border-black/70  border-[1px] ">{Number.parseFloat(Math.abs(tempData.amount)).toFixed(2).replace(/\.?0+$/, "")}</td>
                        <td className={` ${tempData.profitLoss < 0 ? "text-red-500" : tempData.profitLoss > 0 ? "text-green-800" : "text-black"} px-3 py-[6px] whitespace-nowrap border-black/70  border-[1px] }`}>
                            {typeof tempData.profitLoss === 'number'
                                ? Number.parseFloat(Math.abs(tempData.profitLoss)).toFixed(2).replace(/\.?0+$/, "")
                                : tempData.profitLoss
                            }

                            <br />
                            {tempData.isDeclare === 1 ?
                                <small>({tempData.showResult})</small> : null
                            }
                        </td>
                        {/* <td className={`px-3 py-[6px] whitespace-nowrap border-black/70 border-[1px]`}>
                            {tempData.isDeclare === 1 ?
                                <>{tempData.showResult}</> :
                                "Not Declare"
                            }
                        </td> */}
                        <td className="px-3 py-[6px] whitespace-nowrap border-black/70 border-[1px] text-left"> {tempData && tempData.createdAt ? moment(tempData.createdAt).utcOffset("+05:30").format("DD-MM-YYYY hh:mm:ss") : ''}</td>


                    </tr>)) : "Not found"
                }
            </table>
        </div>

    );
}