
import "./TotalLegder.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpPost } from "../../_helpers/http";
import moment from 'moment';
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";

class TotalLedger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsTransction: {},
            errors: {},
            ledgerType: "",
            userLedger: {},
            endDate: moment().format('YYYY-MM-DD'),
            startDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
            currentDate: moment().format('YYYY-MM-DD'),
            currentPage: 1,
            itemsPerPage: 10,
            errorType: "",
            showAlert: false,
            resMessage: "",
            isFetch: false,
        }
    }

    componentDidMount() {
        this.getUserLedger();
    }

    inputChangeTransction = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let fieldsTransction = this.state.fieldsTransction;
        let errors = this.state.errors;
        fieldsTransction[name] = value;
        errors[name] = "";
        this.setState({ fieldsTransction, errors });
    }

    SubmitLegdersearch = () => {
        this.getUserLedger();
    }

    inputChangeStartDate = (e) => {
        this.setState({ startDate: e.target.value });
    }

    inputChangeEndDate = (e) => {
        this.setState({ endDate: e.target.value });
    }

    getUserLedger = async () => {
        let localData = JSON.parse(localStorage.getItem('spuser'));
        let userData = localData.data;
        let reqData = {
            "downlineUserId": userData.userId,
            "fromDate": this.state.startDate,
            "toDate": this.state.endDate,
            "ledgerType": this.state.fieldsTransction.ledgerType,
            "isDeleted": 0,
            "isDeclare": 1,
        }
        this.setState({ isFetch: true })
        let userLedger = await httpPost('user/userLedger', reqData);
        if (userLedger) {
            this.setState({ userLedger: userLedger.data });
        } else {
            this.setState({ showAlert: true, errorType: 1, resMessage: "Something went wrong" });
        }
        this.setState({ isFetch: false });
        setTimeout(() => this.setState({ showAlert: false }), 2000);
    }
    handlePageChange = (page) => {
        this.setState({ currentPage: page });
    };
    handleResponseGame = (data) => {
        data.ledgerType === "diamondCasino" ?
            this.props.history.push('/app/innerstatementCasino/' + data.marketId + "/" + data.ledgerType + "/" + data.date)
            :
            this.props.history.push('/app/innerstatement/' + data.marketId)
    }


    render() {
        let { userLedger, resMessage, errorType, showAlert, isFetch } = this.state;
        let { calAmount, creditAmount, debitAmount, ledgerData } = userLedger;
        const startIndex = (this.state.currentPage - 1) * this.state.itemsPerPage;
        const endIndex = startIndex + this.state.itemsPerPage;
        const currentItems = Array.isArray(ledgerData) ? ledgerData.slice(startIndex, endIndex) : [];


        return (
            <div className="d-flex w-100">
                {isFetch === true ?
                    <Loader />
                    : null}
                {showAlert && <div className={`absolute top-[2%] right-[2%] px-5 py-3 ${errorType === 1 ? "bg-red-600" : "bg-green-600"}  rounded`}><span className='text-white font-bold'>{resMessage}</span></div>}
                <Sidebar props={this.props} />
                <div className="w-100 px-md-2 px-0">
                    <div className="cricket-icon-color flex justify-between items-center mt-md-2 mt-0">
                        <div className="w-full p-[6px] white-text font-normal text-base" >COMPANY LENA DENA</div>
                        <button className="back-btn" onClick={() => this.props.history.goBack()}>Back</button>
                    </div>
                    <div className="flex md:flex-row flex-col justify-start items-start md:space-x-5 space-x-0 md:space-y-0 space-y-2 py-2 lg:px-1 px-2">
                        <input type="date" className="px-2 py-3 h-[35px] lg:w-52 w-full text-[12px] bg-white border-[1px] border-[#007bff] focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[3px]"
                            name="Date"
                            dateformat="yyyy-mm-dd"
                            id="datepicker" required
                            value={this.state.startDate} onChange={this.inputChangeStartDate}
                        />

                        <input type="date" className="px-2 py-3 h-[35px] lg:w-52 w-full text-[12px] bg-white border-[1px] border-[#007bff]  focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[3px]"
                            name="Date"
                            dateformat="yyyy-mm-dd"
                            id="datepicker" required
                            value={this.state.endDate} onChange={this.inputChangeEndDate}
                        />
                        <select className="md:w-44 px-2 h-[35px] w-full py-1.5 text-[12px] text-gray-600 border-[1px] border-[#007bff] rounded-[3px] bg-white" aria-label="select" id="ledgerType" name="ledgerType" value={this.state.fieldsTransction && this.state.fieldsTransction["ledgerType"] ? this.state.fieldsTransction["ledgerType"] : ""} onChange={this.inputChangeTransction} >
                            <option value="">All</option>
                            <option value="Settlement">Settlement</option>
                        </select>
                        <button onClick={this.SubmitLegdersearch} className="bg-[#5CB85C] hover:bg-[#449d44] text-white lg:px-4 px-10 text-[14px] py-1.5 rounded-[3px]">Search</button>
                    </div>
                    <hr className="text-gray-400" />
                    <div className="grid md:grid-cols-3 grid-cols-1 xl:px-0 px-2 bg-[#f3f0dc] py-1 pb-3 mt-4">
                        <div className="p-2 flex justify-between items-center">
                            <span className="lena text-[20px]">Lena : </span>
                            <span className="lena-score">
                                {Number.parseFloat(Math.abs(creditAmount ? creditAmount : "0").toFixed(2).replace(/\.?0+$/, ""))}
                            </span>
                        </div>
                        <div className="p-2 flex justify-between items-center">
                            <span className="dena text-[20px]">Dena : </span>
                            <span className="dena-score">
                                {Number.parseFloat(Math.abs(debitAmount ? debitAmount : "0").toFixed(2).replace(/\.?0+$/, ""))}
                            </span>
                        </div>
                        <div className="p-2 flex justify-between items-center">
                            <span className="balance text-[20px] ">Balance : </span>
                            <span className={`bg-white ${calAmount > 0 ? "text-[#088000]" : "text-[#FF0000]"}  text-center font-bold text-lg border-2 border-[#4DC0C1] rounded-sm w-[180px] lg:w-40  px-2  whitespace-nowrap`}>
                                {calAmount > 0 ? (`${calAmount && calAmount ? Number.parseFloat(Math.abs(calAmount ? calAmount : "0").toFixed(2).replace(/\.?0+$/, "")) : 0} Lena Hai`) : (`${calAmount ? Number.parseFloat(Math.abs(calAmount ? calAmount : "0").toFixed(2).replace(/\.?0+$/, "")) : "0"} Dena Hai`)}
                            </span>
                        </div>
                    </div>
                    <div className='w-full table-responsive bg-transparent'>
                        <table className="w-full border-none text-start table table-striped !pb-8">
                            <thead className="rounded-t bg-[#DCDCDC]">
                                <tr className='text-[#252525] text-[14px] font-normal'>
                                    <th className="w-[15%] whitespace-nowrap border-[1px] border-[#b9b9b9] text-[16px]">Date</th>
                                    <th className="w-[43%] whitespace-nowrap border-[1px] border-[#b9b9b9] text-[16px]">Collection Name</th>
                                    <th className="w-[5%] whitespace-nowrap border-[1px] border-[#b9b9b9] text-[16px]">Debit</th>
                                    <th className="w-[5%] whitespace-nowrap border-[1px] border-[#b9b9b9] text-[16px]">Credit</th>
                                    <th className="w-[10%] whitespace-nowrap border-[1px] border-[#b9b9b9] text-[16px]">Balance</th>
                                    <th className="w-[12%] whitespace-nowrap border-[1px] border-[#b9b9b9] text-[16px]">Payment Type</th>
                                    <th className="w-[10%] whitespace-nowrap border-[1px] border-[#b9b9b9] text-[16px]">Remark</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {currentItems && currentItems ?
                                    <>
                                        {currentItems.map((element, key) =>
                                            <tr key={key} className="bg-transparent text-[#252525] text-[12px] ">
                                                <td className="whitespace-nowrap text-[16px] border-[1px] border-[#b9b9b9] w-[15%] ">{moment(element.createdAt, 'YYYY-MM-DD hh:mm:ss').format('DD-MMM-YYYY hh:mm A')}</td>
                                                <td className=" whitespace-nowrap text-[16px] border-[1px] border-[#b9b9b9] text-[#4083a9] hover:text-[#007BFF] hover:underline w-[43%]" onClick={() => this.handleResponseGame(element)}>{element.eventName}</td>
                                                <td className=" whitespace-nowrap text-[16px] border-[1px] border-[#b9b9b9] text-[#FF0000] w-[5%]">
                                                    {Number.parseFloat((element.amount < 0 ? element.amount : 0)).toFixed(2).replace(/\.?0+$/, "")}
                                                </td>
                                                <td className=" whitespace-nowrap text-[16px] border-[1px] border-[#b9b9b9] text-[#088000] w-[5%]">
                                                    {Number.parseFloat(Math.abs(element.amount > 0 ? element.amount : 0)).toFixed(2).replace(/\.?0+$/, "")}
                                                </td>
                                                <td className={`whitespace-nowrap text-[16px] border-[1px] border-[#b9b9b9] w-[10%] ${element.balance < 0 ? "text-[#FF0000]" : "text-[#088000]"}`} >
                                                    {Number.parseFloat(Math.abs(element.balance)).toFixed(2).replace(/\.?0+$/, "")}                                                </td>
                                                <td className=" whitespace-nowrap text-[16px] border-[1px] border-[#b9b9b9] w-[12%]">{element.ledgerType}</td>
                                                <td className=" whitespace-nowrap text-[16px] border-[1px] border-[#b9b9b9] w-[10%]">{element.remark}</td>
                                            </tr>)}
                                    </> :
                                    <>
                                        <tr className="bg-transparent text-black ">
                                            <td className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-full">No Data Found</td>
                                        </tr>
                                    </>
                                }

                            </tbody>
                        </table>
                    </div>
                    <div>
                        <Pagination
                            currentPage={this.state.currentPage}
                            totalPages={Math.ceil(ledgerData && ledgerData.length / this.state.itemsPerPage)}
                            maxVisibleButtons={1}
                            onPageChanged={this.handlePageChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { users } = state;
    return {
        users,
    };
}
export default connect(mapStateToProps)(TotalLedger);