import React from 'react';
import { FaLock } from 'react-icons/fa';

const MyComponent = ({ data, handleBackOpen, section1Ref, oddsDifference, posArray, ele }) => {
    return (
        <div className={` ${data.nat === "LOW Card" ? 'border-[6px] rounded-none bg-transparent border-red-500' : data.nat === "HIGH Card" ? 'border-[6px] rounded-none bg-transparent border-[#03B37F]' : data.nat === "Dragon" ? 'border-[2px] rounded-none bg-[#3B3636] border-[#FC4242]' : data.nat === "Tiger" ? 'border-[2px] rounded-none bg-[#3B3636] border-[#FDCF13]' : data.nat === "Tie" ? 'border-[2px] rounded-none bg-[#3B3636] border-[#72BBEF]' : 'border-none bg-[#455A46] rounded-lg'} py-1 space-y-1`}>
            <div className="flex justify-center items-center text-[#AAAFB5]">
                <p className="uppercase md:text-[16px] text-[14px] px-2 font-semibold">
                    {data && data.rate ? (data.rate - oddsDifference) : 0}
                </p>
            </div>
            <div
                className="w-full md:text-[16px] text-[14px] font-bold text-[#AAAFB5] uppercase cursor-pointer flex justify-center items-center relative"
            >
                {ele}
                <FaLock size={16} className="text-white absolute" />
            </div>
            <div className={`${posArray[data.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} md:text-[16px] text-[14px] flex justify-center items-center font-bold`}>
                {posArray[data.sid] ?? 0}
            </div>
        </div>
    );
};

export default MyComponent;