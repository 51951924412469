export const CONST = {
  // BACKEND_URL: 'http://localhost:5001/v1',

  // BACKEND_URL: 'https://api.vanky11.com/v1/',
  // BACKEND_URL: 'https://api.9pro.in/v1/',
  // BACKEND_URL: 'https://api.itchakra.in/v1/',
  // BACKEND_URL: 'https://api.pbx99.com/v1/',
  BACKEND_URL: 'https://api.tvs99.com/v1/',
    // // BACKEND_URL: 'https://api.buzz99.in/v1/',
    // BACKEND_URL: 'https://api.allexchbets.com/v1/',
};

export const betChipsData = {
  "1000": 1000,
  "2000": 2000,
  "5000": 5000,
  "10000": 10000,
  "20000": 20000,
  "50000": 50000,
  "100000": 100000,
  "250000": 250000,
};

export const DomainName = 'vanky12.net'
  ;
export const CASINO = {
  VideoUrl: 'https://stream.1ex99.com/casinoVideo/video?id=',
  CasinoDataUrl: "https://casino.1ex.in/getMarketcashino?id="
};
export const CASINODATA = {
  BACKEND_URL: "https://apipro.in/v2/api/casinoData?casinoType=",
  BASE_URL: "https://apipro.in/"
};

let user = JSON.parse(localStorage.getItem('spuser'));

export const CASINOPRICE = {
  PRICE: user?.data?.intCasinoMultiply,
  AMOUNT: "10",
};

export const UserTypeData = {
  owner: { "usertype": "owner", "priority": 9, "shortname": "OW" },
  subowner: { "usertype": "subowner", "priority": 8, "shortname": "SOW" },
  superadmin: { "usertype": "superadmin", "priority": 7, "shortname": "SU" },
  admin: { "usertype": "admin", "priority": 6, "shortname": "AD" },
  subadmin: { "usertype": "subadmin", "priority": 5, "shortname": "SUA" },
  master: { "usertype": "master", "priority": 4, "shortname": "MA" },
  superagent: { "usertype": "superagent", "priority": 3, "shortname": "SA" },
  agent: { "usertype": "agent", "priority": 2, "shortname": "A" },
  client: { "usertype": "client", "priority": 1, "shortname": "C" },
};