import React, { useState, useEffect, useRef } from 'react';
import { httpPost } from "../../_helpers/http";
import { FaTimes } from "react-icons/fa";

export default function FancySelactionKey(props) {
    const { handleClose, positionData, marketId } = props;
    const [positionDataconst, setPostionDataConst] = useState(null);
    const modalRef = useRef(null);

    useEffect(() => {
        getCasinoData();
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const getCasinoData = async () => {
        try {
            let elementPositionData = {
                "marketId": marketId,
                "selectionId": positionData.positionData.session_id
            };

            let elementPositionDataResponse = await httpPost('sports/getSessionPositionBySelectionId', elementPositionData);
            if (elementPositionDataResponse) {
                setPostionDataConst(elementPositionDataResponse && elementPositionDataResponse.data ? elementPositionDataResponse.data : {});
            }
        } catch (error) {
            console.error("Error fetching Fancy Position Data data:", error);
        }
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            handleClose();
        }
    };

    return (
        <div className='h-screen fixed top-0 left-0 z-50 w-full bg-black/50 pt-0 md:p-3 p-0'>
            <div className=" md:w-[600px] w-full mx-auto" ref={modalRef}>
                <div className="flex justify-between items-center rules-header-background px-3 py-2 cursor-pointer w-full mt-[10px]">
                    <span className="text-[14px] w-[90%] font-semibold text-[#ffffff]">{positionData.positionData.session_name}</span>
                    <span onClick={handleClose}><FaTimes className='text-[#ffffff]' size={24} /></span>
                </div>
                <div className='h-[calc(100vh-7rem)] overflow-y-auto'>
                    <table className="w-full border table-bordered text-start font-bold">
                        <thead className="rounded-t bg-[#272e41]">
                            <tr className='text-white font-normal w-full'>
                                <th className='w-1/2 text-center h-[33px] p-0'>Run</th>
                                <th className='w-1/2 text-center h-[33px] p-0'>Amount</th>
                            </tr>
                        </thead>
                        <tbody className="bg-[#ffffff] overflow-y-auto h-full">
                            {positionDataconst ?
                                <>
                                    {Object.keys(positionDataconst).length > 0 ? Object.keys(positionDataconst).map((key, index) => (
                                        <tr key={index}>
                                            <td>{key}</td>
                                            <td className={`${positionDataconst[key] > 0 ? "text-[#3C763D]" : "text-red-500"}`}>{positionDataconst[key]}</td>
                                        </tr>
                                    ))
                                        :
                                        <>
                                            {Array.from({ length: 31 }, (_, i) => (
                                                <tr key={i}>
                                                    <td>{i}</td>
                                                    <td className="text-[#3C763D]">0.00</td>
                                                </tr>
                                            ))}
                                        </>
                                    }
                                </> :
                                <>
                                    {Array.from({ length: 31 }, (_, i) => (
                                        <tr key={i}>
                                            <td>{i}</td>
                                            <td className="text-[#3C763D]">0.00</td>
                                        </tr>
                                    ))}
                                </>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}
