import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../../_actions';
import { betChipsData } from '../../_config/index';
import { FaTimes } from 'react-icons/fa';

export default function EditStakeDesktop(props) {
    const {handleClose, stakehandleClose } = props;
    const dispatch = useDispatch();
    const [keyValues1, setKeyValues1] = useState([]);
    const [betChipsObject, setBetChipsObject] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const modalRef = useRef();

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

      const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            handleClose();
        }
    }

    useEffect(() => {
        const betChipsDataItems = JSON.parse(localStorage.getItem('betChipsData'));
        let betChips = {};
        if (betChipsDataItems != null) {
            betChips = betChipsDataItems;
        } else {
            betChips = betChipsData;
        }
        const updatedKeyValues = Object.entries(betChips).map(([key, value]) => ({
            key,
            value: parseInt(value),
        }));

        setKeyValues1(updatedKeyValues);
    }, []);

    const handleInputChange = (index, key, value) => {
        const updatedKeyValues = [...keyValues1];
        if (key !== undefined) {
            updatedKeyValues[index].key = key;
        }
        if (value !== undefined) {
            updatedKeyValues[index].value = Number(value);
        }
        setKeyValues1(updatedKeyValues);
    };
    const submitValue = () => {
        const newBetChipsObject = {};
        keyValues1.forEach(item => {
            newBetChipsObject[item.key] = item.value;
        });
        setBetChipsObject(newBetChipsObject);
        const user = JSON.parse(localStorage.getItem('spuser'));
        const Id = user?.data?.userId;
        const dataValue = {
            userId: Id,
            betChipsData: newBetChipsObject,
        };
        dispatch(userActions.userUpdate(dataValue));
        localStorage.setItem('betChipsData', JSON.stringify(newBetChipsObject));
    };

    return (
        <>
            <div className='fixed h-screen z-99 w-full overflow-y-auto bg-black/40 pb-3 top-0'>
                <div ref={modalRef} className=" bg-white xl:w-[45rem]  md:w-[32rem] w-full  mx-auto rounded shadow-lg  overflow-y-auto mt-4">
                    {/*modal header*/}
                    <div className="flex items-center justify-between px-[10px] py-2 rules-header-background border-b">
                        <div className="text-[15px] font-normal white-text tracking-wide capitalize">Chip Setting</div>
                        <FaTimes onClick={handleClose || stakehandleClose} size={18} className='curser text-white' />
                    </div>

                    <form autoComplete="off" className="md:p-4 p-3 space-y-4 capitalize ">
                        <div className="grid grid-cols-2">
                            <div className="w-full px-[10px] space-y-2">
                                {keyValues1.map((item, index) => (
                                    <div key={index} className=''>
                                        <label className="block text-[14px] black-text md:text-left" for="username">Chips Name {index + 1}:</label>
                                        <div className="form-group space-y-2 ">
                                            <div className='py-1'>
                                                <input
                                                    className='w-full border-2 border-blue-500 p-1.5 text-xs md:text-sm font-medium text-gray-500 rounded'
                                                    key={index}
                                                    type="text"
                                                    name="buttonValue"
                                                    value={item.key}
                                                    placeholder='Button Value'
                                                    onChange={(e) => handleInputChange(index, e.target.value, undefined)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                            <div className="w-full px-[10px] space-y-2">
                                {keyValues1.map((item, index) => (
                                    <div key={index} className=''>
                                        <label className="block text-[14px] black-text md:text-left" for="username">Chip Value {index + 1}:</label>
                                        <div className="form-group space-y-2">
                                            <div className='py-1'>
                                                <input
                                                    className='w-full border-2 border-blue-500 p-1.5 text-xs md:text-sm font-medium text-gray-500 rounded'
                                                    key={index}
                                                    type="text"
                                                    name="buttonValue"
                                                    value={item.value}
                                                    placeholder='Button Value'
                                                    onChange={(e) => handleInputChange(index, undefined, e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </form>


                    <div className="flex py-3 justify-center">
                        <button onClick={() => submitValue() || handleClose() || stakehandleClose()}
                            className="bg-[#5cb85c] hover:bg-[#449d44] border-2 border-blue-500 white-text transition duration-150 text-[18px] font-medium px-4 py-2 rounded-[.25rem] flex items-center" type="button">
                            Update Chip Setting
                        </button>
                    </div>

                </div>
            </div>
        </>
    );
}

