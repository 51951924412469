import React from 'react';
import "./BetPlaceDesktopCasino.css";
import { betChipsData } from '../../_config';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'


export default function BetPlaceDesktopCasino(props) {
    const { updateStackOnclick, placeBet, handleClose, betSlipData, updateStake } = props;
    let betChipsDatas = JSON.parse(localStorage.getItem('betChipsData'));
    const myArray = betChipsDatas && betChipsDatas.length > 0 ? Object.keys(betChipsDatas) : Object.keys(betChipsData);

    return (
        <div className='position lg:block hidden'>
            <div className=' '>
                <div className={`bg-transparent`}>
                    <div className="grid grid-cols-4 space-x-2 gap-2">
                        <span className='flex justify-center items-center w-full white-text text-[18px]'>{betSlipData && betSlipData.nat ? betSlipData.nat : null}</span>
                        <span className='flex justify-center items-center'>
                            <input type="number" className="px-[14px] border-[2px] border-white rounded-sm py-1.5 w-full bg-[#23292E] white-text" placeholder="0" value={betSlipData && betSlipData.rate ? betSlipData.rate : betSlipData.odds ? betSlipData.odds : null} />
                        </span>
                        <span className='flex justify-center items-center'>
                            <input type="text" className="px-[14px] border-[2px] border-white rounded-sm py-1.5 w-full bg-[#23292E] white-text" placeholder="0" value={betSlipData.stake} onChange={updateStake} />
                        </span>
                    </div>

                    <div className='grid xl:grid-cols-5 grid-cols-3'>
                        {myArray && myArray.length > 0 ? myArray.map((element, index) => (
                            <div key={index} className=" p-1
                            ">
                                <div className='bg-[#205D50] text-white flex justify-center items-center rounded text-center h-10' onClick={() => updateStackOnclick(element)} key={index}>{element}</div>
                            </div>)) : null}
                        <div className='clearbtn' onClick={() => updateStackOnclick("0")}>C</div>
                    </div>
                    <div className='flex justify-between items-center px-2 pb-1'>
                        <button className="bg-[#FC4242] hover:bg-[#FC4242]/95 rounded-md white-text text-[15px] border-[2px] w-1/3 h-[40px] p-2" onClick={() => handleClose()}>Reset</button>
                        {betSlipData.stake>0?
                     <button className="bg-[#0b7d36] hover:bg-[#0b7d36]/95 rounded-md white-text text-[15px] border-[2px] w-1/3 h-[40px] p-2" onClick={() => placeBet()}>Submit</button>:
                     <button className="bg-[#0b7d36] hover:bg-[#0b7d36]/95 rounded-md white-text text-[15px] border-[2px] w-1/3 h-[40px] p-2">Submit</button>   
                    }
                    </div>
                </div>
            </div>
        </div >
    );
}

