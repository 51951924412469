import React, { Component } from "react";
import "./ProfitAndLoss.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import { httpPost } from "../../_helpers/http";
import moment from 'moment';
import Pagination from "../../components/Pagination/Pagination";
import Loader from "../../components/Loader/Loader";
import { FaFilter } from "react-icons/fa";
import ResultModel from "../../casino_components/ResultModel/ResultModel";

class ProfitLoss extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsTransction: {
        gameType: "all",
      },
      errors: {},
      BetHistoryType: "",
      userBetHistory: {},
      endDate: moment().format('YYYY-MM-DD'),
      startDate: moment().subtract(2, 'days').format('YYYY-MM-DD'),
      currentDate: moment().format('YYYY-MM-DD'),
      currentPage: 1,
      itemsPerPage: 10,
      errorType: "",
      showAlert: false,
      resMessage: "",
      isFetch: false,
      activeTab: 1,
      isCompleted: false,
      ResultModel: false,
    }
  }

  handleResultModel = (data) => {
    this.setState({ ResultModel: true, result: data });
  };
  handleCloseResult = () => {
    this.setState({ ResultModel: false, });
  };

  componentDidMount() {
    this.getUserBetHistory();
  }

  inputChangeTransction = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsTransction = this.state.fieldsTransction;
    let errors = this.state.errors;
    fieldsTransction[name] = value;
    errors[name] = "";
    this.setState({ fieldsTransction, errors });
  }

  handleSelectChange = (e) => {
    this.setState({
      isCompleted: e.target.value === "true" ? true : false,
    });
  };

  SubmitBetHistorysearch = () => {
    this.getUserBetHistory();
  }

  inputChangeStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  }

  inputChangeEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  }

  getUserBetHistory = async () => {
    let { fieldsTransction } = this.state
    let reqData = {
      "diamondBet": fieldsTransction.gameType === "casino" ? true : fieldsTransction.gameType === "all" ? true : false,
      "oddsBet": fieldsTransction.gameType === "cricket" ? true : fieldsTransction.gameType === "all" ? true : false,
      "fancyBet": fieldsTransction.gameType === "fancy" ? true : fieldsTransction.gameType === "all" ? true : false,
      "isCompleted": true,

      "fromDate": this.state.startDate,
      "toDate": this.state.endDate,
    }
    let userBetHistory = await httpPost('sports/betsList', reqData);
    if (userBetHistory) {
      this.setState({ userBetHistory: userBetHistory.data });
    } else {
      this.setState({ showAlert: true, errorType: 1, resMessage: "Something went wrong" });
    }

  }
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };


  render() {
    let { userBetHistory, currentPage, itemsPerPage, isFetch } = this.state;
    let { fancyBetData, oddsBetData, diamondBetData } = userBetHistory;
    let finalobject = [];


    fancyBetData && fancyBetData.forEach((fancyData, key) => {
      let profitLoss = ""
      if (fancyData.decisionRun) {
        if ((fancyData.type === "N" && fancyData.decisionRun > fancyData.run) || (fancyData.type === "Y" && fancyData.decisionRun < fancyData.run)) {
          profitLoss = fancyData.loss;
        } else if ((fancyData.type === "N" && fancyData.decisionRun < fancyData.run) || (fancyData.type === "Y" && fancyData.decisionRun >= fancyData.run)) {
          profitLoss = fancyData.profit;
        }
      }

      // if (fancyData.decisionRun) {
      //   if (fancyData.type === "N" && fancyData.decisionRun > fancyData.run || fancyData.type === "Y" && fancyData.decisionRun < fancyData.run) {
      //     profitLoss = fancyData.loss
      //   } else if (fancyData.type === "N" && fancyData.decisionRun < fancyData.run || fancyData.type === "Y" && fancyData.decisionRun >= fancyData.run) {
      //     profitLoss = fancyData.profit
      //   }
      // }
      fancyBetData[key].profitLoss = profitLoss

      let pushObj = {
        eventName: fancyData.sessionName,
        market: "Fancy Odds",
        profitLoss: profitLoss,
        createdAt: fancyData.createdAt,
      }
      finalobject.push(pushObj)
    })
    oddsBetData && oddsBetData.forEach((oddsData) => {

      let pushObj = {
        eventName: oddsData.teamName,
        market: "Match Odds",
        profitLoss: oddsData.positionInfo[oddsData.selectionId],
        createdAt: oddsData.createdAt,
      }
      finalobject.push(pushObj)
    })
    diamondBetData && diamondBetData.forEach((casinoData) => {

      let pushObj = {
        eventName: casinoData.playerName,
        market: casinoData.casinoType,
        profitLoss: casinoData.profitLoss,
        createdAt: casinoData.createdAt,
        roundId: casinoData.roundId,
        resultDetails: casinoData.resultDetails,
      }
      finalobject.push(pushObj)
    })




    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = Array.isArray(finalobject) ? finalobject.slice(startIndex, endIndex) : [];


    return (
      <div className="d-flex w-100">
        {this.state &&
          this.state.ResultModel === true ? (
          <ResultModel
            handleClose={this.handleCloseResult}
            resultDetails={this.state.result}

          />
        ) : null}
        {isFetch === true ?
          <Loader />
          : null}
        <Sidebar props={this.props} />
        <div className="w-100">

          <div className="cricket-icon-color flex justify-between items-center">
            <div className="flex justify-start items-center" >
              <div className="w-full p-[6px] white-text font-normal text-base" >PROFIT LOSS LISTING </div>
              <select className="p-1 rounded-none">
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
              </select>
            </div>
            <button className="back-btn me-1" onClick={() => this.props.history.goBack()}>Back</button>
          </div>


          <div className="lg:flex hidden md:flex-row flex-col justify-start items-start md:space-x-5 space-x-0 md:space-y-0 space-y-2 py-2 px-2 bg-white w-full">
            <select className="md:w-72 w-full px-2 py-2 text-[12px] text-gray-600 border-[1px] border-[#000000] rounded-[3px] bg-white cursor-pointer" aria-label="select" id="gameType" name="gameType" value={this.state.fieldsTransction && this.state.fieldsTransction["gameType"] ? this.state.fieldsTransction["gameType"] : ""} onChange={this.inputChangeTransction} >
              <option value="all">ALL </option>
              <option value="cricket">Cricket</option>
              <option value="fancy">Fancy</option>
              <option value="casino">Live Casino</option>
            </select>
            <input type="date" className="px-2 lg:py-1.5 py-2 lg:w-52 w-full text-[12px] bg-white border-[1px] border-[#000000] focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[4px] cursor-pointer"
              name="Date"
              dateformat="yyyy-mm-dd"
              id="datepicker" required
              value={this.state.startDate} onChange={this.inputChangeStartDate}
            />
            <input type="date" className="px-2 lg:py-1.5 py-2 lg:w-52 w-full text-[12px] bg-white border-[1px] border-[#000000]  focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[4px] cursor-pointer"
              name="Date"
              dateformat="yyyy-mm-dd"
              id="datepicker" required
              value={this.state.endDate} onChange={this.inputChangeEndDate}
            />
            <button onClick={this.SubmitBetHistorysearch} className="bg-[#f7c200] text-black hover:text-white px-4 text-[14px] py-1.5 rounded-[3px] flex justify-start items-center">Filter</button>
          </div>


          <div className="lg:hidden grid grid-cols-2 justify-start items-center gap-2 py-2 px-2 bgwhite w-full">
            <div className="">
              <select className="lg:w-44 w-[100%] px-2 h-[35px] py-1.5 text-[12px] text-gray-600 border-[1px] border-[#000000] rounded-[3px] bg-white cursor-pointer" aria-label="select" id="gameType" name="gameType" value={this.state.fieldsTransction && this.state.fieldsTransction["gameType"] ? this.state.fieldsTransction["gameType"] : ""} onChange={this.inputChangeTransction}>
                <option value="all">ALL </option>
                <option value="cricket">Cricket</option>
                <option value="fancy">Fancy</option>
                <option value="casino">Live Casino</option>
              </select>
            </div>
            <div className="">
              <input type="date" className="px-2 lg:py-1.5 py-2 lg:w-52 w-full text-[12px] bg-white border-[1px] border-[#000000] focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[4px]"
                name="Date"
                dateformat="yyyy-mm-dd"
                id="datepicker" required
                value={this.state.startDate} onChange={this.inputChangeStartDate}
              />
            </div>
            <div className="">
              <input type="date" className="px-2 lg:py-1.5 py-2 lg:w-52 w-full text-[12px] bg-white border-[1px] border-[#000000]  focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[4px]"
                name="Date"
                dateformat="yyyy-mm-dd"
                id="datepicker" required
                value={this.state.endDate} onChange={this.inputChangeEndDate}
              />
            </div>
            <div></div>
            <div className="" >
              <button onClick={this.SubmitBetHistorysearch} className="bg-[#f7c200] text-black px-2 text-[14px] py-1.5 rounded-[3px] flex justify-start items-center">Filter</button>
            </div>
            </div>
            <div>
          </div>

          <div className='w-full table-responsive scroller px-2 bg-white'>
            <table className="w-full border-none text-start table table-striped scroller">
              <thead className="rounded-t bg-[#dcdcdc]">
                <tr className='black-text text-[14px] font-normal'>
                  <th style={{border: '1px solid #b4afaf'}} className="w-auto whitespace-nowrap font-normal text-[16px] text-[#252525]">S.No.</th>
                  <th style={{border: '1px solid #b4afaf'}} className="w-auto whitespace-nowrap font-normal text-[16px] text-[#252525]">Event Name</th>
                  <th style={{border: '1px solid #b4afaf'}} className="w-auto whitespace-nowrap font-normal text-[16px] text-[#252525]">Market</th>
                  <th style={{border: '1px solid #b4afaf'}} className="w-auto whitespace-nowrap font-normal text-[16px] text-[#252525]">P_L</th>
                  <th style={{border: '1px solid #b4afaf'}} className="w-auto whitespace-nowrap font-normal text-[16px] text-[#252525]">Created On</th>
                </tr>
              </thead>
              <tbody className="border">
                {currentItems && currentItems ?
                  <>
                    {currentItems && currentItems.length > 0 ? currentItems.map((element, index) => (
                      <tr className="bg-transparent text-[#252525] text-start text-[12px]">
                        <td style={{border:'1px solid #c8b9b9'}} className=" w-[10%]  md:whitespace-normal text-[16px] text-[#252525] whitespace-nowrap">{index + 1} </td>
                        {element.market === "Fancy Odds" || element.market === "Match Odds" ?
                          <td style={{border:'1px solid #c8b9b9'}} className=" w-[50%]  md:whitespace-normal text-[16px] text-[#252525] whitespace-nowrap">
                            {element.eventName}
                          </td> :
                          <td style={{border:'1px solid #c8b9b9'}} className=" w-[50%]  md:whitespace-normal text-[16px] whitespace-nowrap text-blue-500 underline cursor-pointer" onClick={() => { this.handleResultModel(element.resultDetails) }}>
                            {element.eventName} ({element.roundId})
                          </td>
                        }
                        <td style={{border:'1px solid #c8b9b9'}} className=" w-[10%]  md:whitespace-normal text-[16px] text-[#252525] whitespace-nowrap">{element.market} </td>
                        <td style={{border:'1px solid #c8b9b9'}} className={` w-[10%]  md:whitespace-normal text-[16px] text-[#252525] whitespace-nowrap ${(element.profitLoss) >= 0 ? "text-[#3C763D]" : "text-red-500"}`}>
                          {Number.parseFloat(Math.abs(element.profitLoss)).toFixed(2).replace(/\.?0+$/, "")}
                        </td>
                        <td style={{border:'1px solid #c8b9b9'}} className=" w-[20%] md:whitespace-normal text-[16px] text-[#252525] whitespace-nowrap "> {moment(element.createdAt).format('ddd MMM DD hh:mm:ss [IST] YYYY')}</td>
                      </tr>)) : null}
                  </> : null}
              </tbody>
            </table>
          </div>
          <div>
            <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(finalobject && finalobject.length / itemsPerPage)}
                maxVisibleButtons={1}
                onPageChanged={this.handlePageChange}
              />
          </div>
        </div>
      </div >
    );
  }
}

export default ProfitLoss;
