import React from 'react';
import BgSkyButtonTheme2 from '../BgSkyButtonTheme2/BgSkyButtonTheme2';
import BgSkyButtonSuspend from '../BgSkyButtonSuspend/BgSkyButtonSuspend';

export default function GameCard(props) {
    const { Data, Name, handleBackOpen, section1Ref, posArray, oddsDifference } = props;

    return (
        <div className="w-full h-100">

            <div className="relative h-100">
                {/* <div className="flex justify-center items-center ">
                    <p className="uppercase text-[16px] px-2 font-semibold">{Data && Data.rate ? (Data.rate - oddsDifference) : 0}</p>
                </div> */}
                {/* <BgSkyCardTheme2 ele={Name} /> */}
                {Data.gstatus === "1" ?
                    <BgSkyButtonTheme2 ele={Name}
                        handleBackOpen={handleBackOpen}
                        data={Data}
                        posArray={posArray}
                        oddsDifference={oddsDifference}
                        section1Ref={section1Ref}
                    /> :
                    <BgSkyButtonSuspend ele={Name}
                        data={Data}
                        posArray={posArray}
                        oddsDifference={oddsDifference}
                        section1Ref={section1Ref}
                    />
                    // <BetLockedRoundedBmx />
                }
                {/* <div className={`${posArray[Data.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center font-bold pt-1`}>
                    {posArray[Data.sid] ?? 0}
                </div> */}
            </div>

        </div>
    );
}