import React from 'react';
import { connect } from 'react-redux';
import { DomainName } from '../../_config/index';
import { userActions } from '../../_actions';
import { AiOutlineStar } from 'react-icons/ai';
import { BiTime } from 'react-icons/bi';
import Sidebar from "../../components/Sidebar/Sidebar";
import SportsNavigationTab from "../../casino_components/SportsNavigationTab/SportsNavigationTab";
import "./Dashboard.css";
import Carousel from "react-bootstrap/Carousel";
import AVIATOR from "../../Assets/Images/AVIATOR BANNER.png";
import Casino from "../../Assets/Images/Casino BANNER.png";
import Cricket from "../../Assets/Images/Cricket  BANNER.png";
import MATKA from "../../Assets/Images/MATKA BANNER.png";
import RG_Casino from "../../Assets/Images/RG Casino BANNER.png";

import ronaldo from "../../Assets/Images/ronaldo.jpg";
import congrats from "../../Assets/Images/congrats.jpg";
import casino from "../../Assets/Images/casino.jpg";
import livescore from "../../Assets/Images/livescore.png";
import blackcard from "../../Assets/Images/blackcard.png";
import tv from "../../Assets/Images/tv.png";
import Matchname from "../../Assets/Images/matchname.png";
import cricket from "../../Assets/Images/cricket-icon.png";
import { FaGreaterThan } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import Rules from '../../components/Rules/Rules';
import moment from 'moment';
import { GoDotFill } from "react-icons/go";
import { httpPost } from '../../_helpers/http';


class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      notification: false,
      activeTab: 1,
    }
  }

  componentDidMount() {
    const hasSeenPopup = localStorage.getItem('hasSeenPopup');
    if (!hasSeenPopup) {
      this.setState({ notification: true });
      localStorage.setItem('hasSeenPopup', true);
    }
    this.getInplayData();

  }

  getInplayData = async () => {
    try {
      let adminMatchListData = [];
      let adminMatchList = false;
      adminMatchList = localStorage.getItem('matchListData');
      if (adminMatchList == "false") {
        let matchlistItems = await httpPost('sports/matchList');
        if (matchlistItems && matchlistItems.data && matchlistItems.data.length > 0) {
          localStorage.setItem('matchList', JSON.stringify(matchlistItems.data));
          localStorage.setItem('matchListData', true);
          this.setState({ matchlistItems: matchlistItems.data });
        } else {
          this.setState({ matchlistItems: [] });
        }
      } else {
        adminMatchListData = localStorage.getItem('matchList');
        if (adminMatchListData) {
          const parsedAdminMatchListData = JSON.parse(adminMatchListData)
          if (parsedAdminMatchListData.length > 0) {
            this.setState({ matchlistItems: parsedAdminMatchListData });
          }
        } else {
          let matchlistItems = await httpPost('sports/matchList');
          if (matchlistItems && matchlistItems.data && matchlistItems.data.length > 0) {
            localStorage.setItem('matchList', JSON.stringify(matchlistItems.data));
            this.setState({ matchlistItems: matchlistItems.data });
          } else {
            this.setState({ matchlistItems: [] });
          }
        }
      }
    } catch (error) {
      console.error('Error in getInplayData:', error);
    }
  }

  handleResponseGame = (data) => {
    this.props.history.push('/app/ViewMatchis/' + data.marketId)
  }

  handleCloseNotification = () => {
    this.setState({ notification: false });
    localStorage.setItem('hasSeenPopup', true);
  };
  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  render() {
    const { activeTab, notification, matchlistItems } = this.state;

    let sortedMatches = []
    if (matchlistItems) {
      sortedMatches = [...matchlistItems];
      sortedMatches.sort((a, b) => a.priority - b.priority);
    }

    return (

      <>
        {/* {loading === true ?
          <Loader />
          : null} */}
        <div className="d-flex">
          <Sidebar props={this.props} />
          <div className="slider-main-div">
            {/* <div className="carousalalign">
              <Carousel fade>
                <Carousel.Item>
                  <img src={AVIATOR} alt="" width={1000} className='img-height' />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={Casino} alt="" width={1000} className='img-height' />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={Cricket} alt="" width={1000} className='img-height' />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={MATKA} alt="" width={1000} className='img-height' />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={RG_Casino} alt="" width={1000} className='img-height' />
                </Carousel.Item>
              </Carousel>
            </div> */}
            <div className=''>
              <SportsNavigationTab
                activeTab={activeTab}
                handleTabClick={this.handleTabClick}
                navigation={this.navigation}
              />
              {activeTab === 1 ? (
                <div className=''>
                  <div className="cricket-icon-main cricket-icon-color ">
                    <div className="cricket-icon">
                      <img src={cricket} alt="" className='icon-size' />
                      <span className='white-text'>Cricket</span>
                    </div>
                    {sortedMatches && sortedMatches.length > 0 && sortedMatches.filter((element) => (element.sportId === 4)).length > 0 ? sortedMatches.filter((element) => (element.sportId === 4)).map((element, index) => (
                      <div key={index} className="about-matches inply-background h-auto sm:h-20">
                        <div className='flex justify-between items-start w-full py-1 h-[54px] md:h-[65px]'>
                          <div className='w-full px-2'>
                            <div className="match-name">
                              <div className='flex justify-start items-start space-x-1'>
                                <img src={Matchname} alt="" className='imagesize' />
                                <span className='matchname matchname-color text-[17px] flex justify-start items-center' onClick={() => this.handleResponseGame(element)}><div className='overflow-hidden text-ellipsis whitespace-nowrap md:w-full w-[200px] '>{element.matchName}</div>
                                  {element.status === "INPLAY" ?
                                    <div>
                                      <p className='text-[#508D0E] text-xs pt-[6px]'>&nbsp;inplay</p>
                                      {/* <GoDotFill className='text-green-800 blinking' size={24} /> */}
                                    </div> : null
                                  }
                                </span>
                              </div>
                            </div>
                            <div className=' matchdate matchdate-color flex justify-start items-center space-x-1'>
                              <BiTime className='' size={15} />&nbsp;
                              {moment(element.matchDate, 'DD-MM-YYYY hh:mm:ss A').format('DD MMM hh:mm ')}
                              <AiOutlineStar className='text-red-600 w-[11px] h-[11px] object-contain'/>
                            </div>
                          </div>
                          <div className='mr-2 md:w-[35px] w-[22px]'>
                            <img src={tv} alt="" />
                          </div>
                        </div>
                      </div>
                    )) : null}


                  </div>
                </div>) : null}
              {activeTab === 2 ? (
                <div className='pb-16 overflow-y-auto h-screen'>
                  <div className="cricket-icon-main cricket-icon-color">
                    <div className="cricket-icon">
                      <img src="/image/soccer.svg" alt="" className='icon-size' />
                      <span className='white-text'>Soccer</span>
                    </div>
                    {sortedMatches && sortedMatches.length > 0 && sortedMatches.filter((element) => (element.sportId === 2)).length > 0 ? sortedMatches.filter((element) => (element.sportId === 2)).map((element, index) => (
                      <div key={index} className="about-matches inply-backgrou
                      nd h-20">
                        <div className='flex justify-between items-start w-full py-1 h-[54px] md:h-[65px]'>
                          <div className='w-full px-2'>
                            <div className="match-name">
                              <div className='flex justify-start items-center space-x-1 pt-2'>
                                <img src={Matchname} alt="" className='imagesize' />
                                <span className='matchname matchname-color text-[16px] whitespace-nowrap flex justify-center items-center' onClick={() => this.handleResponseGame(element)}>
                                  <div className='overflow-hidden text-ellipsis whitespace-nowrap md:w-full w-[200px] '>{element.matchName}</div>
                                  {element.status === "INPLAY" ?
                                    <div>
                                      <p className='text-[#508D0E] text-xs pt-[6px]'>&nbsp;inplay</p>
                                      {/* <GoDotFill className='text-green-800 blinking' size={24} /> */}
                                    </div> : null
                                  }
                                </span>
                              </div>
                            </div>
                            <div className=' matchdate matchdate-color flex justify-start items-center space-x-1'>
                              <BiTime className='' size={15} />&nbsp;
                              {moment(element.matchDate, 'DD-MM-YYYY hh:mm:ss A').format('DD MMM hh:mm ')}
                              <AiOutlineStar className='text-red-600 w-[11px] h-[11px] object-contain'/>
                            </div>
                          </div>
                          <div className='mr-2 md:w-[35px] w-[22px]'>
                            <img src={tv} alt="" />
                          </div>
                        </div>
                      </div>
                    )) : null}
                  </div>

                </div>) : null}
              {activeTab === 3 ? (
                <div className='pb-16'>
                  <div className="cricket-icon-main cricket-icon-color">
                    <div className="cricket-icon">
                      <img src="/image/tennis.png" alt="" className='icon-size' />
                      <span className='white-text'>Tennis</span>
                    </div>
                    {sortedMatches && sortedMatches.length > 0 && sortedMatches.filter((element) => (element.sportId === 1)).length > 0 ? sortedMatches.filter((element) => (element.sportId === 1)).map((element, index) => (
                      <div key={index} className="about-matches inply-background h-20">
                        <div className='flex justify-between items-start w-full py-1 h-[54px] md:h-[65px]'>
                          <div className='w-full px-2'>
                            <div className="match-name">
                              <div className='flex justify-start items-center space-x-1 pt-2'>
                                <img src={Matchname} alt="" className='imagesize' />
                                <span className='matchname matchname-color text-[16px] whitespace-nowrap flex justify-center items-center' onClick={() => this.handleResponseGame(element)}>
                                  <div className='overflow-hidden text-ellipsis whitespace-nowrap md:w-full w-[200px] '>{element.matchName}</div>
                                  {element.status === "INPLAY" ?
                                    <div>
                                      <p className='text-[#508D0E] text-xs pt-[6px]'>&nbsp;inplay</p>
                                      {/* <GoDotFill className='text-green-800 blinking' size={24} /> */}
                                    </div> : null
                                  }
                                </span>
                              </div>
                            </div>
                            <div className=' matchdate matchdate-color flex justify-start items-center space-x-1'>
                              <BiTime className='' size={15} />&nbsp;
                              {moment(element.matchDate, 'DD-MM-YYYY hh:mm:ss A').format('DD MMM hh:mm ')}
                              <AiOutlineStar className='text-red-600 w-[11px] h-[11px] object-contain'/>
                            </div>
                          </div>
                          <div className='mr-2 md:w-[35px] w-[22px]'>
                            <img src={tv} alt="" />
                          </div>
                        </div>
                      </div>
                    )) : null}
                  </div>
                </div>) : null}
            </div>
          </div>
          <div className="rightbar-main m-hide">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header className='accordion-button-background'>Mini Games</Accordion.Header>
                <Accordion.Body></Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        {notification &&
          <Rules
            handleCloseNotification={this.handleCloseNotification}
            DomainName={DomainName}
          />}

      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(Dashboard);
