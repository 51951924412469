import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import editstate from "../../Assets/Images/editstake.png";
import inplay from "../../Assets/Images/inplay.png";
import logout from "../../Assets/Images/logout.png";
import bethistory from "../../Assets/Images/bethistory.png";
import home from "../../Assets/Images/home.png";
import "./Footer.css";
import { Link } from "react-router-dom";
import CommingSoon from '../../components/CommingSoon/CommingSoon';
import CasinoCheckModal from '../../components/CasinoCheckModal/CasinoCheckModal';
import CasinoModal from '../CasinoModal/CasinoModal';
import { useHistory } from 'react-router';
import { FaAddressBook, FaRegAddressCard, FaHome, FaGamepad } from "react-icons/fa";
import { BsBank2 } from "react-icons/bs";


const Footer = (props) => {

  const history = useHistory();

  const [userData, setUserData] = useState(null);
  const [commingSoonModal, setCommingSoonModal] = useState(false);
  const [casinoDataModal, setCasinoDataModal] = useState(false);
  const [casinoModal, setCasinoModal] = useState(false);
  const [casinoData, setCasinoData] = useState(null);

  const handleClose = () => {
    setCommingSoonModal(false);
    setCasinoDataModal(false);
    setCasinoModal(false);
  };


  const handleCasinoModalOpen = () => {
    setCasinoModal(true);
  };


  const handleModalOpen = () => {
    setCommingSoonModal(true);
  };
  const handleCasinoOpen = () => {
    setCasinoDataModal(true);
  };

  const onClickMenu = (url) => {
    props.props.history.push(url)
  }

  useEffect(() => {
    const fetchData = () => {
      let user = JSON.parse(localStorage.getItem('spuser'));
      setUserData(user);
    };
    const timerId = setTimeout(fetchData, 1000);
    return () => clearTimeout(timerId);

  }, []);

  const onClickMenu1 = (data) => {
    history.push('/app/casino')
    // window.location.reload(true);
  }

  if (casinoData !== null) {
    // Assuming you have a way to redirect, replace the placeholder URL with the actual URL
    const redirectUrl = (casinoData.url);
    window.open(redirectUrl, '_self');
  }

  return (
    <div className="mobile-main">
      {casinoDataModal && (
        <CasinoCheckModal
          handleClose={handleClose}
        />
      )}

      {casinoModal && (
        <CasinoModal handleClose={handleClose} />
      )}

      {commingSoonModal && (
        <CommingSoon
          handleClose={handleClose}
          message="You Are Not Authorized to Play Please Connect With Your Upline"
        />
      )}
      <div className="row mx-0 row-cols-5 justify-between items-center pb-[2px] mobile-menu-background">
        <div className="col">
          <div className="icon-menu white-text" onClick={() => onClickMenu("/app/accountstatement")}>
            <FaAddressBook size={22} />
            {/* <img src={inplay} alt="" /> */}
            <span>Act. Stmt.</span>
          </div>
        </div>
        <div className="col">
          <div className="icon-menu white-text" onClick={() => onClickMenu("/app/TotalLedger")}>
            {/* <img src={editstate} alt="" /> */}
            <FaRegAddressCard size={22} />
            <span>Total Ledger</span>
          </div>
        </div>
        <div className="col">
          <div className="icon-menu white-text" onClick={() => onClickMenu("/app/dashboard")}>
            <FaHome size={22} />
            {/* <img src={home} alt="" className="home-image" /> */}
            <span>Home</span>
          </div>
        </div>
        <div className="col">
          <div className="icon-menu white-text" onClick={() => onClickMenu("/app/profit-loss")}>
            <BsBank2 size={22} />
            {/* <img src={bethistory} alt="" /> */}
            <span>P&L</span>
          </div>
        </div>
        <div className="col">
        {
          userData && userData.data && userData.data.casinoStatus === true ?
            <div onClick={() => onClickMenu1()}>

              {/* onClick={handleCasinoModalOpen} */}
              <div className="icon-menu white-text">
                {/* <img src={logout} alt="" /> */}
                <FaGamepad size={24} />
                <span>Casino</span>
              </div>
            </div>
            :
            <Link to="/app/dashboard" style={{ textDecoration: "none" }} onClick={handleModalOpen}>
              <div className="icon-menu white-text">
                {/* <img src={logout} alt="" /> */}
                <FaGamepad size={24} />
                <span>Casino</span>
              </div>
            </Link>
        }
        </div>
      </div>
    </div>

  );
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}
export default connect(mapStateToProps)(Footer);