import React, { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
// import casinorules from '../../Assets/Images/casinorules.jpg'

export default function RulesCasino(props) {
    const { handleClose, casinorules } = props

    useEffect(() => {
        document.addEventListener('click', handleClose);
        return () => {
            document.removeEventListener('click', handleClose);
        };
    }, []);
    return (
        <div className='w-full z-50 fixed px-2 h-screen bg-black/50 top-0 left-0 overflow-y-auto'>
            <div className="w-full lg:w-1/4 bg-white z-50 mx-auto md:mt-8 mt-16" onClick={(e) => e.stopPropagation()}>
                <div className="w-full h-full flex justify-between bg-[#3C444B] p-[10px] items-center">
                    <h2 className="text-[#FDCD21] text-lg tracking-wide font-normal">
                        Rules
                    </h2>
                    <div className='text-center pl-2' onClick={handleClose}>
                        <FaTimes className='text-[#AAAFB5] cursor-pointer' size={20} />
                    </div>
                </div>
                <div className='overflow-y-auto  bg-[#2E3439] px-2 py-3'>
                    <img src={casinorules} alt='' className='w-full h-full' />
                </div>
            </div>
        </div>
    );
}

