
import React, { useEffect, useState } from "react";
import { Route, Switch, withRouter,useLocation } from "react-router-dom";
import Header from "../Header";
import AmarAkbarAnthony from "../../pages/Casino/AmarAkbarAnthony";
import AndarBahar from "../../pages/Casino/AndarBahar"
import Card32a from "../../pages/Card32a/Card32a"
import DragonTiger20 from "../../pages/Casino/DragonTiger20";
import Lucky7 from "../../pages/Casino/Lucky7A";
import TeenpattiOneday from "../../pages/Casino/TeenpattiOneday";
import Teenpatti20 from "../../pages/Casino/Teenpattit20";
import AccountStatement from "../../pages/AccountStatement/AccountStatement";
import BetHistory from "../../pages/BetHistory/BetHistory";
import ProfitAndLoss from "../../pages/ProfitAndLoss/ProfitAndLoss";
import Dashboard from "../../pages/Dashboard/Dashboard";
import Casino from '../../pages/Casino/Casino';
import changepassword from '../../pages/ChangePassword/changepassword';
import innerstatement from '../../pages/Innerstatement/innerstatement';
import Footer from '../../components/Footer';
import AllMarketBook from '../../pages/AllMarketBook/AllMarketBook';
import IplWinner from '../../pages/IPLWinner/IPLWinner';
import LiveBetHistory from '../../pages/LiveBetHistory/LiveBetHistory';
import MyCommission from '../../pages/MyCommission/MyCommission';
import Profile from '../../pages/Profile/Profile';
import TotalLedger from '../../pages/TotalLegder/TotalLegder';
import ViewMatch from '../../pages/ViewMatch/ViewMatch';
import exposure from '../../pages/exposure/exposure';
import Matka from '../../pages/Matka/Matka';
import IframeCasino from '../../pages/IframeCasino/IframeCasino';
import InstantWorli from '../../pages/Casino/InstantWorli';
import DragonTiger202 from '../../pages/Casino/DragonTiger202';
import innerstatementCasino from "../../pages/Innerstatement/innerstatementCasino";
import ClosedGames from "../../pages/ClosedGames/ClosedGames";

function Layout(props) {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const [openMobile, setOpenMobile] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [domainSetting, setDomainSetting] = useState(null);

  const domainSettingByDomainName = async () => {
    try {

      let checked = localStorage.getItem('domainSettingByDomainName');
      if (checked) {
        setDomainSetting(checked);
      }
    } catch (err) {
      setDomainSetting(null)
    }
  };

  useEffect(() => {
    domainSettingByDomainName()
  }, [location]);

  useEffect(() => {
    window.scrollTo(
      {
        top: 0,
        behaviour: 'smooth',
      }
    );

  }, [location.pathname]);
  
  return (
    <div className="layoutWrapper d-flex flex-col">
      <div className="col-auto">

        <Header history={props.history}
          setOpen={setOpen} open={open}
          setOpenMobile={setOpenMobile}
          openMobile={openMobile}
          openModal={openModal} setOpenModal={setOpenModal}
        />

      </div>
      <div className="col overflow-hidden h-full">
        <div className="overflow-y-auto h-full scrollBottomPadding overflow-x-hidden">
          <Switch>
            <Route path="/app/accountstatement" component={withRouter(AccountStatement)} />
            <Route path="/app/runningmarketanalysis" component={withRouter(AllMarketBook)} />
            <Route path="/app/ViewMatchis/:marketId" component={withRouter(ViewMatch)} />
            <Route path="/app/amar-akbar-anthony/:eventId?" component={withRouter(AmarAkbarAnthony)} />
            <Route path="/app/andar-bahar/:eventId?" component={withRouter(AndarBahar)} />
            <Route path="/app/card32a" component={withRouter(Card32a)} />
            <Route path="/app/dragon-tiger20/:eventId?" component={withRouter(DragonTiger20)} />
            <Route path="/app/dragon-tiger202/:eventId?" component={withRouter(DragonTiger202)} />
            <Route path="/app/worli/:eventId?" component={withRouter(InstantWorli)} />
            <Route path="/app/Lucky7/:eventId?" component={withRouter(Lucky7)} />
            <Route path="/app/teenpatti-one-day/:eventId?" component={withRouter(TeenpattiOneday)} />
            <Route path="/app/teenpatti-20/:eventId?" component={withRouter(Teenpatti20)} />
            <Route path="/app/TotalLedger" component={withRouter(TotalLedger)} />
            <Route path="/app/Profile" component={withRouter(Profile)} />
            <Route path="/app/MyCommission" component={withRouter(MyCommission)} />
            <Route path="/app/CupWinner" component={withRouter(IplWinner)} />
            <Route path="/app/LiveBetHistory" component={withRouter(LiveBetHistory)} />
            <Route path="/app/my-bets" component={withRouter(BetHistory)} />
            <Route path="/app/profit-loss" component={withRouter(ProfitAndLoss)} />
            <Route path="/app/dashboard/:gameId?/:seriesId?" component={withRouter(Dashboard)} />
            <Route path="/app/casino" component={withRouter(Casino)} />
            <Route path="/app/changepassword" component={withRouter(changepassword)} />
            <Route path="/app/innerstatement/:marketId" component={withRouter(innerstatement)} />
            <Route path="/app/innerstatementCasino/:marketId?/:ledgerType?/:date" component={withRouter(innerstatementCasino)} />
            <Route path="/app/iframe-casino/:gameId" component={withRouter(IframeCasino)} />
            <Route path="/app/exposure" component={withRouter(exposure)} />
            <Route path="/app/matka" component={withRouter(Matka)} />
            <Route path="/app/closed-games" component={withRouter(ClosedGames)} />
          </Switch>
        </div>
        <Footer props={props} />
      </div>
    </div>
  );
}

export default withRouter(Layout);
