import React from 'react';

export default function BetLockedRoundedBmx() {
  return (
    <div className="absolute top-0 bg-black/70 flex justify-center items-center w-full h-full cursor-pointer">
      <img src='/casino-images/padlock.png' alt='Lock' />
    </div>
  );
}

