import React from "react";
import { IoCheckmarkCircle, IoCloseCircle, IoCloseSharp } from 'react-icons/io5';
import { FaCheck } from "react-icons/fa";

const ErrorTost = (props) => {

    let { message, toastClose, error } = props
    return (

        <div>
            {error === false ?
                <div className='fixed w-full h-full top-0 z-40 overflow-hidden mt-0 pt-4 flex justify-center items-start overflow-y-auto bg-black/40 md:p-0 p-1 '>
                    <div className='flex lg:w-[15%] w-full px-1 justify-center items-center m-auto '>
                        <div className="w-full bg-white" onClick={() => toastClose()}>
                            {/* <div className="flex justify-end items-center">
                                <div className="p-3 text-[#363535]" ><IoCloseSharp /> </div></div> */}
                            <div className='flex flex-col justify-center items-center  pb-4 pt-10'>
                                <div onClick={() => toastClose()} className="w-[80px] h-[80px] rounded-full bg-[#4caf50] mx-auto -mt-[78px] flex justify-center items-center"><FaCheck className="text-white w-14 h-14 font-bold object-contain" /></div>
                                <div className="text-[24px] font-normal text-[#545454] mb-2 mt-4">Success!</div>
                                <div className="text-[20px] font-semibold text-[#545454]">Bet Placed Successfully</div>
                            </div>
                            {/* <div className="  text-[#28A745]  text-xl text-center font-bold pb-3">Bet Accepted :)</div> */}
                            {/* <div className='flex justify-end items-center border-t-2'>
                                <button className="capitalize p-1.5 bg-[#19A1B8] text-white px-3 m-1.5 mx-3 flex justify-center items-center space-x-1 rounded" onClick={() => toastClose()}>Close <IoCloseSharp size={18}/></button>
                            </div> */}
                        </div>
                    </div>
                </div>
                :
                <div className='fixed w-full h-full top-0 z-40 overflow-hidden mt-0 pt-4 flex justify-center items-start overflow-y-auto bg-black/40 md:p-0 p-1'>
                    <div className='flex lg:w-[15%] w-full px-1 justify-center items-center m-auto !rounded-sm'>
                        <div className="max-h-full w-full bg-white " onClick={() => toastClose()}>
                        <div className="flex justify-end items-center">
                                {/* <div className="p-3 text-[#363535]" onClick={() => toastClose()}><IoCloseSharp /> </div> */}
                                </div>
                            {/* <div className='flex justify-center items-center pt-3'>
                                <IoCloseCircle className='text-[#E04F5F] ' size={32} />
                            </div> */}
                            <div className=" text-[#E04F5F] px-2 mx-auto text-xl text-center font-bold pb-4 pt-10">
                                <div onClick={() => toastClose()} className="w-[80px] h-[80px] rounded-full bg-[#ff0000] mx-auto -mt-[78px] flex justify-center items-center"><IoCloseSharp className="text-white w-14 h-14 font-bold object-contain" /></div>
                                <div className="text-[24px] font-normal text-[#545454] mb-2 mt-4">Error!</div>
                                <div className="text-[20px] font-semibold text-[#545454]">Please Bet On Stake Between 100.0 to 200000</div>
                            </div>
                            <div className='flex justify-end items-center'>
                                {/* <button className="capitalize p-1.5 bg-[#E04F5F] text-white px-3 m-1.5 mx-3 flex justify-center items-center space-x-1 rounded" onClick={() => toastClose()}>Close <IoCloseSharp size={18}/></button> */}
                            </div>
                        </div>
                    </div>
                </div>

            }

        </div>
    )
}

export default ErrorTost;