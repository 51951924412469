import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import { httpPost } from "../../_helpers/http";
import Loader from "../../components/Loader/Loader";
import moment from 'moment';
import ResultModelCasino from '../../casino_components/ResultModel/ResultModelCasino';

class InnerStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLedgerDetails: {},
      currentPage: 1,
      itemsPerPage: 10,
      errorType: "",
      showAlert: false,
      resMessage: "",
      isFetch: false,
      betList: {},
      ResultModel: false,
      result: {},
    }
  }

  componentDidMount() {
    this.getUserLedger();
    this.betList(this.props.match.params.marketId)
  }

  getUserLedger = async () => {
    let reqData = {
      eventId: this.props.match.params.marketId + "",
      fromDate: moment(parseInt(this.props.match.params.date, 10)).format("yyyy-MM-DD"),
      toDate: moment(parseInt(this.props.match.params.date, 10)).format("yyyy-MM-DD"),
      casinoType: this.props.match.params.ledgerType + "",
    }
    this.setState({ isFetch: true })
    let userLedgerDetails = await httpPost('reports/casinoTransactionReport', reqData);
    if (userLedgerDetails) {
      this.setState({ userLedgerDetails: userLedgerDetails.data });
    } else {
      this.setState({ showAlert: true, errorType: 1, resMessage: "Something went wrong" });
    }
    this.setState({ isFetch: false });
    setTimeout(() => this.setState({ showAlert: false }), 2000);
  }

  betList = async (eventId) => {
    let betReq = {
      eventId: eventId,
      isDeleted: false,
      isDeclare: 1,
      fromDate: moment(parseInt(this.props.match.params.date, 10)).format(
        "yyyy-MM-DD",
      ),
      toDate: moment(parseInt(this.props.match.params.date, 10)).format(
        "yyyy-MM-DD",
      ),
    };

    let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)

    let betList = getCasinoDetails?.data?.casinoBetData
    if (getCasinoDetails) {
      this.setState({ betList: betList })
    }
  }

  handleResultModel = (data) => {
    this.setState({ ResultModel: true, result: data });
  };

  handleCloseResult = () => {
    this.setState({ ResultModel: false, });
  };

  onClickMenu = (url) => {
    this.props.history.push(url);
  }

  render() {
    let { userLedgerDetails, resMessage, errorType, showAlert, isFetch, betList, ResultModel, result } = this.state;;

    return (
      <>
        <div className="main-bodyTotal overflow-y-auto h-full">
          {ResultModel === true ? (
            <ResultModelCasino
              handleClose={this.handleCloseResult}
              resultDetails={result}
            />
          ) : null}
          {isFetch === true ?
            <Loader />
            : null}
          {showAlert && <div className={`absolute top-[2%] right-[2%] px-5 py-3 z-30 ${errorType === 1 ? "bg-red-600" : "bg-green-600"}  rounded`}><span className='white-text font-bold'>{resMessage}</span></div>}

          {/* <div className='flex lg:space-x-1 space-x-0 lg:p-1 p-0'> */}
          <Sidebar props={this.props} />
          <div className='w-full lg:pl-6 pl-0 pt-0 pt-md-2 overflow-y-auto h-screen'>
            <div className='w-full space-y-4 bg-[#fff]'>

              <div className='w-full bg-gradient-to-r from-[#2f5b69] to-[#47889e]  h-10 text-white text-center text-lg flex justify-center items-center font-bold'>
                Casino Bets
              </div>
              {
                (betList && betList.length > 0) ?
                  <div className='w-full overflow-x-auto scrollbar-hide'>
                    <table className="w-full text-xs text-start">
                      <thead className="rounded-t change-password-button bg-[#dcdcdc]">
                        <tr className='text-black font-semibold h-[38px]'>
                          <th className="p-1.5 border-[1px] text-[16px] text-[#252525] font-[500] border-[#DCDCDC] px-2 w-[13%]">Player</th>
                          {/* <th className="p-1.5 border-[1px] text-[16px] text-[#252525] font-[500] border-[#DCDCDC]  px-2 w-[12%]">Runs</th> */}
                          <th className="p-1.5 border-[1px] text-[16px] text-[#252525] font-[500] border-[#DCDCDC]  px-2 w-[12%]">	Rate</th>
                          <th className="p-1.5 border-[1px] text-[16px] text-[#252525] font-[500] border-[#DCDCDC]  px-2 w-[12%]">Amt.</th>
                          <th className="p-1.5 border-[1px] text-[16px] text-[#252525] font-[500] border-[#DCDCDC]  px-2 w-[17%]">Mode</th>
                          <th className="p-1.5 border-[1px] text-[16px] text-[#252525] font-[500] border-[#DCDCDC]  px-2 w-[17%]">Result</th>
                          <th className="p-1.5 border-[1px] text-[16px] text-[#252525] font-[500] border-[#DCDCDC]  px-2 w-[17%]">P&L</th>
                          <th className="p-1.5 border-[1px] text-[16px] text-[#252525] font-[500] border-[#DCDCDC]  px-2 w-[25%]">DATE</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {betList && betList ?
                          <>
                            {betList.map((element, index) =>
                              <tr key={index} className={`background-white text-[#252525]  text-[12px] font-medium h-[38px]`}>
                                <td className="lg:whitespace-normal whitespace-nowrap  text-[16px]  font-[400] border-[1px] border-[#DCDCDC] w-[13%]">{element.playerName} <br /><span className='underline text-blue-500 cursor-pointer' onClick={() => this.handleResultModel(element.resultDetails)}>({element.roundId})</span> </td>
                                {/* <td className=" whitespace-nowrap  text-[16px]  font-[400] border-[1px] border-[#DCDCDC]  w-[12%] px-2">{element.run}</td> */}
                                <td className=" whitespace-nowrap  text-[16px]  font-[400] border-[1px] border-[#DCDCDC]  w-[12%] px-2">{element.odds}</td>
                                <td className=" whitespace-nowrap  text-[16px]  font-[400] border-[1px] border-[#DCDCDC] w-[12%]">{Number.parseFloat(Math.abs(element.amount)).toFixed(2).replace(/\.?0+$/, "")}
                                </td>
                                <td className=" whitespace-nowrap  text-[16px]  font-[400] border-[1px] border-[#DCDCDC] w-[17%]">{element.type === "N" ? "NOT" : "YES"}
                                </td>
                                <td className=" whitespace-nowrap  text-[16px]  font-[400] border-[1px] border-[#DCDCDC] w-[17%]">{element.showResult}
                                </td>
                                <td className={`whitespace-nowrap  text-[16px]  font-[400] border-[1px] border-[#DCDCDC] w-[17%] ${element.profitLoss > 0 ? "text-[#5199E6]" : "text-[#DC143C]"}`} >
                                  {Number.parseFloat(Math.abs(element.profitLoss)).toFixed(2).replace(/\.?0+$/, "")}
                                </td>
                                <td className="whitespace-nowrap  text-[16px]  font-[400] border-[1px] border-[#DCDCDC] w-[17%]"> {moment(parseInt(element.createdAt, 10)).format("DD-MM-yyyy",)}</td>
                                <marquee className="w-full font-bold red-text  border-[#DCDCDC]">
                                  {element.deletedRemark}
                                </marquee>
                              </tr>)}
                          </> : null
                        }

                      </tbody>
                    </table>
                  </div>
                  : null
              }
            </div>
            {
              userLedgerDetails && userLedgerDetails.length > 0 ? userLedgerDetails.map((element, index) => (
                <>
                  {(element && element.totalAmount) > 0 ?
                    <div className='flex justify-center items-center font-bold text-lg pt-4 bg-[#fff] text-[#008000]'>
                      <span>You Won {element && element.totalAmount ? Number.parseFloat(Math.abs(element?.totalAmount)).toFixed(2).replace(/\.?0+$/, "") : 0}/- Coins.</span>
                    </div> :
                    <div className='flex justify-center items-center font-bold text-lg pt-4 bg-[#fff] text-[#FF0C00]'>
                      <span>You Lost {element && element.totalAmount ? Number.parseFloat(Math.abs(element?.totalAmount)).toFixed(2).replace(/\.?0+$/, "") : 0}/- Coins.</span>
                    </div>
                  }
                  <div className='grid grid-cols-2 pb-16'>
                    <div className='w-full bg-[#A3E672] text-[#333333]  h-10  text-center text-lg flex justify-center items-center font-bold'>
                      Casino Plus Minus
                    </div>
                    {element && element.amount > 0 ?
                      <div className='w-full bg-[#ffffff] border-t border-b border-black  h-10 text-[#5CB864] text-center text-base flex justify-center items-center font-bold'>
                        <span>You Won {element && element.amount ? Number.parseFloat(Math.abs(element?.amount)).toFixed(2).replace(/\.?0+$/, "") : 0}/- Coins.</span>
                      </div> :
                      <div className='w-full bg-[#ffffff] border-t border-b border-black  h-10 text-[#FF0C00] text-center text-base flex justify-center items-center font-bold'>
                        <span>You Lost {element && element.amount ? Number.parseFloat(Math.abs(element?.amount)).toFixed(2).replace(/\.?0+$/, "") : 0}/- Coins.</span>
                      </div>
                    }

                    <div className='w-full bg-gradient-to-r from-[#2f5b69] to-[#47889e]  h-10 text-white text-center text-lg flex justify-center items-center font-bold'>
                      Total Commission
                    </div>
                    {(element && element.totalComm) > 0 ?
                      <div className='w-full bg-[#D8D8D8] border-t border-b border-black  h-10 text-[#5CB864] text-center text-base flex justify-center items-center font-bold'>
                        <span>You Won {element && element.totalComm ? Number.parseFloat(Math.abs(element?.totalComm)).toFixed(2).replace(/\.?0+$/, "") : "0"}/- Coins.</span>
                      </div> :
                      <div className='w-full bg-[#D8D8D8] border-t border-b border-black  h-10 text-[#FF0C00] text-center text-base flex justify-center items-center font-bold'>
                        <span>You Lost {element && element.totalComm ? Number.parseFloat(Math.abs(element?.totalComm)).toFixed(2).replace(/\.?0+$/, "") : "0"}/- Coins.</span>
                      </div>
                    }
                    <div className='w-full bg-[#A3E672]  h-10 text-[#333333] text-center text-lg flex justify-center items-center font-bold'>
                      Mob. App. Charges
                    </div>
                    <div className='w-full bg-[#ffffff] border-t border-b border-black  h-10 text-[#FF0C00] text-center text-base flex justify-center items-center font-bold'>
                      You Lost 0/- Coins
                    </div>
                    <div className='w-full bg-gradient-to-r from-[#2f5b69] to-[#47889e]  h-10 text-white text-center text-lg flex justify-center items-center font-bold'>
                      Net Plus Minus
                    </div>
                    {element && element.totalAmount > 0 ?
                      <div className='w-full bg-[#D8D8D8] border-t border-b border-black  h-10 text-[#5CB864] text-center text-base flex justify-center items-center font-bold'>
                        <span>You Won {element && element.totalAmount ? Number.parseFloat(Math.abs(element?.totalAmount)).toFixed(2).replace(/\.?0+$/, "") : "0"}/- Coins.</span>
                      </div> :
                      <div className='w-full bg-[#D8D8D8] border-t border-b border-black  h-10 text-[#FF0C00] text-center text-base flex justify-center items-center font-bold'>
                        <span>You Lost {element && element.totalAmount ? Number.parseFloat(Math.abs(element?.totalAmount)).toFixed(2).replace(/\.?0+$/, "") : "0"}/- Coins.</span>
                      </div>
                    }
                  </div>

                </>


              )) : null
            }




          </div>
          {/* </div> */}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default withRouter(connect(mapStateToProps)(InnerStatement));
