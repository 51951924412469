import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import io from 'socket.io-client';
import moment from "moment";
import { userActions } from "../../_actions";
import GameCard from "../../casino_components/GameCard/GameCard";
import PlaceBetMobileBmx from "../../casino_components/PlaceBetMobileBmx/PlaceBetMobileBmx";
import ResultModelBmx from "../../casino_components/ResultModelBmx/ResultModelBmx";
import FlipCountdown from '@rumess/react-flip-countdown';
import RoundedTabBmx from "../../casino_components/RoundedTabBmx/RoundedTabBmx";
import PageHeaderTheme from "../../casino_components/PageHeaderTheme/PageHeaderTheme";
import MyBetTabBmx from "../../casino_components/MyBetTabBmx/MyBetTabBmx";
import BetListTableBmx from "../../casino_components/BetListTableBmx/BetListTableBmx";
import PlaceBetMobileBmxBet from "../../casino_components/PlaceBetMobileBmxBet/PlaceBetMobileBmxBet";
import Loader from "../../components/Loader/Loader";
import { httpPost, httpPostBet } from "../../_helpers/http";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from '../../casino_components/ErrorTost/ErrorTost';
import BetListCasinoTableBmx from "../../casino_components/BetListCasinoTableBmx/BetListCasinoTableBmx";
import { FaHome } from "react-icons/fa";
import { FaCircleInfo, FaGripLinesVertical } from 'react-icons/fa6';
import Sidebar from "../../components/Sidebar/Sidebar";
import RulesCasino from "../../components/RulesCasino/RulesCasino";
import BetPlaceMobile from '../../components/BetPlaceMobile/BetPlaceMobile';
import BetPlaceDesktopCasino from "../../casino_components/BetPlaceDesktopCasino/BetPlaceDesktopCasino";
import BetPlaceDesktopCasinoLoader from "../../casino_components/BetPlaceDesktopCasinoLoader/BetPlaceDesktopCasinoLoader";
import BetPlaceMobileLoader from "../../components/BetPlaceMobileLoader/BetPlaceMobileLoader";
import ErrorTostCasino from "../../components/ErrorTostCasino/ErrorTostCasino";

class DragonTiger202 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newCasinoModal: false,
            betOpen: false,
            myBetOpen: false,
            betSlipData: {},
            fieldsbet: {},
            errorsbet: {},
            count: 0,
            time: 7,
            backBetModal: false,
            backBetModalMobile: false,
            BetPlaceDesktop: false,
            ResultModel: false,
            casinoData: [],
            showLoader: false,
            result: {},
            casinoDetails: {},
            fetchData: "",
            socketURL: "",
            tvUrl: "",
            cacheURL: "",
            betStatus: "",
            cashinoStatus: "",
            eventId: "",
            minStake: "",
            maxStake: "",
            name: "",
            shortName: "dt202",
            betList: [],
            betresponse: [],
            setting: {},
            posArray: {},
            currentRoundId: 0,
            oddsDifference: 0,
            totalProfitLoss: 0,
            timerKey: 0,
            remainingTime: 10,
            isFatch: false,
            message: "",
            error: "",
            socketPerm: "",
        }
        this.socket = null;
        this.section1Ref = React.createRef();
        this.scrollTimeout = null;
        this.inputRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.user && nextProps.user.betPlaceSucess) {
            return {
                ...nextProps,
                backBetModal: false,
                backBetModalMobile: false,
                betSlipData: {},
            }
        } else {
            return {
                ...nextProps,

            }
        }
    }

    increaseCount = () => {
        const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
        this.setState({ count: parseFloat(increasedCount) });
    };

    decreaseCount = () => {
        const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
        this.setState({ count: parseFloat(decreasedCount) });
    };

    handleBackOpen = (data) => {
        if (this.scrollTimeout) {
            clearInterval(this.scrollTimeout);
        }
        this.setState({ backBetModal: true, backBetModalMobile: true, betSlipData: { ...data, stake: "0" }, count: data.rate, time: 7, });
        this.scrollTimeout = setInterval(() => {
            this.setState(prevState => ({ time: prevState.time - 1 }), () => {
                if (this.state.time <= 0) {
                    clearInterval(this.scrollTimeout);
                    this.setState({ backBetModal: false });
                }
            });
        }, 1000);
        this.resetTimer();

    };

    updateStackOnclic = (num) => {
        let { betSlipData } = this.state;
        betSlipData.stake = num;
        this.setState({
            betSlipData: betSlipData,
        });
    };


    placeBet = async () => {
        const { casinoData } = this.state;
        this.setState({ LoadingBet: true })
        let { data } = casinoData ? casinoData : {};
        let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
        let betObject = {
            "roundId": t1.mid,
            "sid": this.state.betSlipData.sid,
            "rate": (this.state.count - this.state.oddsDifference) + "",
            "amount": Number(this.state.betSlipData.stake),
            "casinoType": this.state.shortName ? this.state.shortName : "dt202",
            "eventId": this.props.match.params.eventId,
            "betFor": this.state.betSlipData.nat + "",
        }

        const result = await httpPostBet("casino/casinoBetPlace", betObject);
        if (result) {
            if (result.error === false) {

                this.setState(
                    {
                        betresponse: result.data
                    },
                );
                this.setState({ isFatch: true, message: result.message, error: false })
                setTimeout(() => {
                    this.setState({ isFatch: false })
                }, 3000);
                this.betList(this.props.match.params.eventId)
                this.props.dispatch(userActions.getUserBalance());
            }
            else {
                this.setState({ isFatch: true, message: result.message, error: true })
                setTimeout(() => {
                    this.setState({ isFatch: false })
                }, 3000);
            }
        }
        this.handleClose();
        this.setState({ LoadingBet: false })
    };

    toastClose = () => {
        this.setState({ isFatch: false });
    };

    componentDidMount() {
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        let requestData = {
            eventId: this.props.match.params.eventId,
        }
        this.getDidMountData(requestData)
    }

    getDidMountData = async (requestData) => {
        this.setState({ showLoader: true })
        let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
        if (!getCasinoDetails.error) {
            let casinoDetails = getCasinoDetails.data
            this.setState({
                casinoDetails: casinoDetails,
                fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
                socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
                socketPerm: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : "",
                tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
                cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
                betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
                cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
                eventId: casinoDetails && casinoDetails.eventId ? casinoDetails.eventId : "",
                minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
                maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
                name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
                shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "dt202",
                setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
                oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
            })
            if (casinoDetails.eventId) {
                this.betList(casinoDetails.eventId)
            }

            let socketPerm = ""
            let socketUrl = ""
            let cacheUrl = ""
            if (casinoDetails.fetchData) {
              socketPerm = casinoDetails.fetchData
            }
            if (casinoDetails.socketURL) {
              socketUrl = casinoDetails.socketURL
            }
            if (casinoDetails.cacheURL) {
              cacheUrl = casinoDetails.cacheURL
            }
      
            if (socketPerm === "socket") {
              await this.connectSocket(socketUrl, socketPerm, casinoDetails.shortName)
            } else {
              await this.callCache(cacheUrl);
            }
        } else {

        }
        this.setState({ showLoader: false })
    }

    connectSocket = async (socketUrl, socketPerm, shortName) => {
        if (!socketUrl) {
          socketUrl = this.state.socketURL
        }
        if (!socketPerm) {
          socketPerm = this.state.socketPerm
        }
        if (!shortName) {
          shortName = this.state.shortName
        }
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
          return;
        }
    
        if (socketPerm === "socket") {
          this.socket = io.connect(socketUrl, {
            transports: ["websocket"],
            reconnection: false,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 99,
          });
    
          this.socket.emit('JoinRoom', shortName);
          this.socket.on(shortName, data => {
            this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
            this.setState({ casinoData: data })
            this.updatePos()
          });
        }
      }
    
      callCache = async (cacheUrl) => {
        try {
          this.interval = setInterval(async () => this.getMarketCacheUrl(cacheUrl), 1000);
        } catch (error) {
          console.error('Error calling cache:', error);
        }
      }
    
      getMarketCacheUrl = async (cacheUrl) => {
        try {
          const response = await axios.get(cacheUrl);
          this.setState({ casinoData: response?.data?.data })
        } catch (error) {
          console.error('Error fetching cache data:', error);
        }
      }
          


    updatePos = async () => {
        const { betList, currentRoundId } = this.state;
        if (betList && betList.length > 0) {
            const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
            let pushPos = {};
            filteredBets.forEach((bet) => {
                const posArray = bet.posArray;
                Object.entries(posArray).forEach(([key, value]) => {
                    pushPos[key] = (pushPos[key] || 0) + Number(value);
                });
            });

            this.setState({ posArray: pushPos });
        }
    }


    betList = async (eventId) => {
        let betReq = {
            "eventId": eventId,
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
        };

        let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
        let totalProfitLoss = 0
        let betList = getCasinoDetails?.data?.casinoBetData

        if (betList && betList.length > 0) {
            betList.map((bet, key) => {
                let posArray = bet.posArray
                let profitLoss = "Not Declare"
                let profitLossCount = 0
                if (bet.isDeclare && bet.posArray) {
                    profitLoss = posArray[bet.result]
                    profitLossCount = posArray[bet.result]
                }
                totalProfitLoss += Number(profitLossCount)
                betList[key].profitLoss = profitLoss
            })
        }
        if (getCasinoDetails) {
            this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
        }
    }

    componentWillUnmount() {
        clearInterval(this.cacheInterval);
        clearTimeout(this.timer);
        if (this.inputRef.current) {
          this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
        }
        this.cleanupWebSocket();
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
      }
    
      cleanupWebSocket = () => {
        if (this.socket) {
          this.socket.close();
        }
      };
    
      handleVisibilityChange = () => {
        if (document.visibilityState === 'visible' && !this.state.isConnected) {
          this.connectSocket()
        } else if (document.visibilityState === 'hidden') {
          this.cleanupWebSocket();
        }
      };

    handleResultModel = (data) => {
        this.setState({ ResultModel: true, result: data });
    };
    handleCloseResult = () => {
        this.setState({ ResultModel: false, });
    };

    handleClose = () => {
        this.setState({ backBetModal: false, backBetModalMobile: false, RulesCasinoModal: false, });
    };

    handleRulesCasinoModalOpen = () => {
        this.setState({ RulesCasinoModal: true });
    };

    updateStackOnclick = (num) => {
        let { betSlipData } = this.state;
        betSlipData.stake = num;
        this.setState({
            betSlipData: betSlipData,
        });
    };

    updateStake = (event) => {
        let { value } = event.target;
        let { betSlipData } = this.state;
        if (value.startsWith('0') && value.length > 1) {
            value = value.slice(1);
        }
        betSlipData.stake = value;
        this.setState({
            betSlipData: betSlipData,
        });
    };
    inputChange = (e) => {
        e.preventDefault();
        let { name, value } = e.target;

        if (value < 0 || isNaN(Number(value))) {
            value = 0;
        }

        if (value[0] === '0' && value.length > 1) {
            value = value.substring(1);
        }

        let { betSlipData } = this.state;
        betSlipData.stake = value;
        this.setState({
            betSlipData: betSlipData,
            [name]: Number(value),
        });
    };
    handleCountdown = () => {
        if (this.state.remainingTime > 0) {
            this.setState((prevState) => ({
                remainingTime: prevState.remainingTime - 1,
            }));
        } else {
            // When the timer reaches 0, close the modal
            this.setState({ betPlaceModal: false });
        }
    };
    resetTimer = () => {
        this.setState({
            remainingTime: 10,
            timerKey: this.state.timerKey + 1,
        });
    };
    handleResponseGame = () => {
        this.props.history.push('/app/casino')
    }

    render() {

        const { casinoData, name, shortName, ResultModel, time, count, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, oddsDifference, totalProfitLoss, minStake, maxStake, RulesCasinoModal, isFatch, message, error } = this.state;
        let { data, result } = casinoData ? casinoData : {};
        let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
        let Dragon = data && data.t2 && data.t2[0] ? data.t2[0] : {};
        let Tiger = data && data.t2 && data.t2[2] ? data.t2[1] : {};
        let Tie = data && data.t2 && data.t2[2] ? data.t2[2] : {};
        return (
            <>
                {showLoader ?
                    <Loader active={showLoader === true} />
                    :
                    <div className={`w-[100vw] relative h-100 page_bg overflow-y-auto text-sm flex`}>
                        {isFatch ?
                            <ErrorTostCasino message={message} toastClose={this.toastClose} error={error} />
                            : null}
                        {RulesCasinoModal === true ?
                            <RulesCasino
                                handleClose={this.handleClose}
                                casinorules="/rulesImage/dragon-tiger-20-rules.webp"
                            />
                            : null}
                        {ResultModel && ResultModel === true ? (
                            <ResultModelBmx
                                handleClose={this.handleCloseResult}
                                name={name ? name : "Dragon Tiger 2"}
                                shortName={shortName ? shortName : "dt202"}
                                result={this.state.result}
                            />
                        ) : null}
                        {LoadingBet === true ?
                            <>
                                <BetPlaceMobileLoader
                                    betSlipData={this.state.betSlipData}
                                    time={time}
                                    count={count}
                                />
                            </> :
                            <>
                                {backBetModal && backBetModal === true ? (
                                    <BetPlaceMobile
                                        betSlipData={this.state.betSlipData}
                                        updateStackOnclick={this.updateStackOnclick}
                                        inputChange={this.inputChange}
                                        decreaseCount={this.decreaseCount}
                                        increaseCount={this.increaseCount}
                                        placeBet={this.placeBet}
                                        handleClose={this.handleClose}
                                        count={this.state.count}
                                        timerKey={this.state.timerKey}
                                        remainingTime={this.state.remainingTime}
                                        handleCountdown={this.handleCountdown}
                                        updateStake={this.updateStake}
                                    />
                                ) : null}
                            </>
                        }
                        <Sidebar props={this.props} />
                        <div className="lg:flex block w-full p-1.5 space-x-1.5 md:pt-2 overflow-x-hidden pb-2">
                            <div className="lg:w-[70%] px-[6px] md:w-full mx-auto">
                                <div>
                                    <div className="bg-[#0B0614] flex justify-between w-full relative md:text-sm text-[10px] xl:h-[610px] md:h-[300px] h-[200px]">
                                        <iframe src={tvUrl ? tvUrl : null} title=" " className='relative w-full  ' />
                                        <div className="bg-black/70 flex flex-col justify-center items-center absolute top-0 left-0 lg:p-3 p-1.5">
                                            <span className="text-[#E18C18] font-bold lg:text-lg text-sm uppercase">{name ? name : "Dragon Tiger 2"}</span>
                                            <span className="text-white font-semibold lg:text-sm text-xs">Round ID: {t1 && t1.mid ? t1.mid : null}</span>
                                        </div>

                                        <div className="flex flex-col justify-end items-end absolute top-0 right-0 lg:p-3 p-1.5 space-y-1">
                                            <div className="flex justify-center items-center space-x-2">
                                                <span className="p-1.5 lg:text-2xl text-lg rounded-full border-[2px] border-white text-white cursor-pointer" onClick={() => this.handleResponseGame()}><FaHome /></span>
                                                <span onClick={this.handleRulesCasinoModalOpen} className="lg:p-1.5 p-1  text-lg lg:text-2xl rounded-full border-[2px] border-white text-white cursor-pointer"><FaCircleInfo /></span>
                                            </div>
                                        </div>

                                        <div className="absolute lg:top-[41%] top-[39%] left-0">
                                            <div className="py-1 w-full px-2 space-x-2 bg-black/50 rounded-r-md flex justify-between items-center h-14 lg:h-16">
                                                <div className="flex space-x-2 justify-start">
                                                    <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="h-10 lg:h-14 w-7 lg:w-10" />
                                                    <img src={`/cards/${t1 && t1.C2 ? t1.C2 : 1}.png`} alt="card" className="h-10 lg:h-14 w-7 lg:w-10" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex justify-center items-center absolute bottom-2 right-2">
                                            <div className={`${t1 && t1.autotime === "0" ? "loader1" : "loader"}`}>
                                            </div>
                                            <span className="md:text-4xl text-2xl font-bold text-white absolute">{t1 && t1.autotime ? t1.autotime : null}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="grey_background">
                                    <div className="grey_background ">
                                        <div className="flex justify-end items-center" >
                                            <span className='font-bold text-[12px] text-[#EEEEEE] px-[10px] py-1 tracking-wide  '>
                                                MIN: {minStake ? minStake : "100"} &nbsp;
                                                MAX: {maxStake ? maxStake : "10000"}
                                            </span>
                                        </div>

                                        <div className="odds-bet w-full p-[10px] ">
                                            <div className="flex space-x-2">
                                                <GameCard
                                                    handleBackOpen={this.handleBackOpen}
                                                    Data={Dragon}
                                                    Name={Dragon.nat ? Dragon.nat : "Dragon"}
                                                    section1Ref={this.section1Ref}
                                                    posArray={posArray}
                                                    oddsDifference={oddsDifference}
                                                />
                                                <GameCard
                                                    handleBackOpen={this.handleBackOpen}
                                                    Data={Tie}
                                                    Name={Tie.nat ? Tie.nat : "Tie"}
                                                    section1Ref={this.section1Ref}
                                                    posArray={posArray}
                                                    oddsDifference={oddsDifference}
                                                />
                                                <GameCard
                                                    handleBackOpen={this.handleBackOpen}
                                                    Data={Tiger}
                                                    Name={Tiger.nat ? Tiger.nat : "Tiger"}
                                                    section1Ref={this.section1Ref}
                                                    posArray={posArray}
                                                    oddsDifference={oddsDifference}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="md:hidden block py-2">
                                    <div className="flex flex-col justify-center items-center space-y-2">
                                        <div className="grid grid-cols-7 gap-2">
                                            {result && result.length > 0 ? result.slice(0, 7).map((element, index) => (
                                                <div onClick={() =>
                                                    this.handleResultModel(element)} className={`bg-[#000000] ${element && element.result && element.result === "1" ? "text-[#FC4242]" : element && element.result && element.result === "2" ? "text-[#FDCF13]" : element && element.result && element.result === "3" ? "text-red-600" : "-"}  w-[30px] h-[30px] cursor-pointer flex justify-center items-center rounded-[4px] py-[4px] px-[20px]`}>
                                                    <p className=" font-semibold text-[16px]">{element && element.result && element.result === "1" ? "D" : element && element.result && element.result === "2" ? "T" : element && element.result === "3" ? "C" : "-"}</p>
                                                </div>
                                            )) : null}
                                        </div>
                                        <div className="grid grid-cols-3 gap-2">
                                            {result && result.length > 0 ? result.slice(6, 9).map((element, index) => (
                                                <div onClick={() =>
                                                    this.handleResultModel(element)} className={`bg-[#000000] ${element && element.result && element.result === "1" ? "text-[#FC4242]" : element && element.result && element.result === "2" ? "text-[#FDCF13]" : element && element.result && element.result === "3" ? "text-red-600" : "-"}  w-[30px] h-[30px] cursor-pointer flex justify-center items-center rounded-[4px] py-[4px] px-[20px]`}>
                                                    <p className=" font-semibold text-[16px]">{element && element.result && element.result === "1" ? "D" : element && element.result && element.result === "2" ? "T" : element && element.result === "3" ? "C" : "-"}</p>
                                                </div>
                                            )) : null}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="lg:w-[30%] pr-[8px] w-full space-y-4">

                                <div className="md:block hidden bg-black">
                                    <RoundedTabBmx
                                        PageTitle={name ? name : "Dragon Tiger"}
                                        ruleImage={"/rulesImage/dragon-tiger-20-rules.webp"}
                                        eventId={this.props.match.params.eventId} />
                                    <div className="flex flex-wrap justify-center items-center py-2 gap-1">
                                        {result && result.length > 0 ? result.map((element, index) => (
                                            <div onClick={() =>
                                                this.handleResultModel(element)} className={`bg-[#434343] ${element && element.result && element.result === "1" ? "text-[#FC4242]" : element && element.result && element.result === "2" ? "text-[#FDCF13]" : element && element.result && element.result === "3" ? "text-[#2CD62C]" : "-"}  w-[30px] h-[30px] cursor-pointer flex justify-center items-center rounded-[4px] py-[4px] px-[20px]`}>
                                                <p className=" font-semibold text-[16px]">{element && element.result && element.result === "1" ? "D" : element && element.result && element.result === "2" ? "T" : element && element.result === "3" ? "C" : "-"}</p>
                                            </div>
                                        )) : null}
                                    </div>
                                </div>

                                <div className="bg-[#3C444B] mt-3 md:block hidden">
                                    <div className="bg-[#16191C] text-[#AAAFB5] text-[16px] px-2 py-[6px] flex justify-between items-center">
                                        <span className="uppercase font-semibold tracking-wider">
                                            Place Bet
                                        </span>
                                        <div className='font-medium text-[12px]'>
                                            Range:{minStake ? minStake : "100"}-
                                            {maxStake ? maxStake : "10000"}
                                        </div>
                                    </div>
                                    <div className="px-3 text-[#AAAFB5] text-[16px] py-1.5 flex justify-between items-center ">
                                        <span>(Bet for)</span>
                                        <span>Odds</span>
                                        <span>Stake</span>
                                        <span>Profit</span>
                                    </div>
                                </div>

                                {LoadingBet === true ?
                                    <>
                                        <BetPlaceDesktopCasinoLoader
                                            betSlipData={this.state.betSlipData}
                                            time={time}
                                            count={count}
                                        />
                                    </> :
                                    <>
                                        {backBetModal && backBetModal === true ? (
                                            <BetPlaceDesktopCasino
                                                betSlipData={this.state.betSlipData}
                                                updateStackOnclick={this.updateStackOnclick}
                                                inputChange={this.inputChange}
                                                decreaseCount={this.decreaseCount}
                                                increaseCount={this.increaseCount}
                                                placeBet={this.placeBet}
                                                handleClose={this.handleClose}
                                                count={this.state.count}
                                                timerKey={this.state.timerKey}
                                                remainingTime={this.state.remainingTime}
                                                handleCountdown={this.handleCountdown}
                                                updateStake={this.updateStake}
                                            />
                                        ) : null}
                                    </>
                                }

                                <div className="bg-[#3C444B] mt-2">
                                    <div className="bg-[#000000] text-[#AAAFB5] text-[16px] px-2.5 py-[6px] flex justify-between items-center">
                                        <span className="uppercase font-semibold tracking-wider">
                                            MY BETS
                                        </span>
                                    </div>
                                    {/* <div className="px-4 text-[#AAAFB5] text-[16px] py-1 flex justify-between items-center ">
                    <span>Team</span>
                    <span>Mode</span>
                    <span>Rate</span>
                    <span>Amount</span>
                    <span>Result</span>
                    <span>Created</span>
                  </div> */}
                                </div>

                                <div className="">
                                    {betList && betList.length > 0 ?
                                        <div className="space-y-[1px] bg-gray-200 pb-1 rounded">
                                            {/* <MyBetTabBmx totalProfitLoss={totalProfitLoss} /> */}
                                            <BetListCasinoTableBmx betList={betList} />
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>}
            </>
        );
    }
}

function mapStateToProps(state) {
    const { users } = state;
    return {
        users,
    };
}

export default connect(mapStateToProps)(DragonTiger202);
