import React, { } from 'react';
import { IoMdClose } from 'react-icons/io';

export default function CommingSoon(props) {
    const { handleClose, message } = props;

    return (
        <>
            <div className='fixed w-full h-full top-0 left-0 z-40 overflow-hidden mt-0 pt-4 flex justify-center items-start overflow-y-auto bg-black/40 md:p-0 p-1'>
                <div className=" bg-white xl:w-[41rem]  md:w-[20rem] w-full mx-2 md:mx-auto rounded shadow-lg  overflow-y-auto lg:mt-44 mt-28 top-2">
                    {/*modal header*/}
                    <div>
                        <div className="flex items-center justify-end p-6 py-2  border-b">
                            {/* <div className="text-[16px] font-medium  text-white uppercase">Game Playlist</div> */}
                            <span className='h-8 w-8 rounded-full flex items-center justify-center bg-[#045f5f]'>
                            <IoMdClose onClick={handleClose} size={30} className='curser text-red-600' />
                            </span>
                        </div>

                        <div className='text-center space-y-2 lg:py-5 pb-5 '>
                          
                            <div className='md:text-5xl text-3xl flex justify-center'>
                            <img src='/images/oops.jpg'  alt='oops' className='md:w-52 w-36 md:h-52 h-36'/>
                            </div>
                            <div className='md:text-2xl text-lg px-2 font-bold text-red-600'>
                                {message}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

