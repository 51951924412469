import React, { Component } from 'react';
import { connect } from 'react-redux';
import "./ViewMatch.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import { AiOutlineStar, AiFillCaretDown } from "react-icons/ai";
import axios from "axios";
import BetPlaceDesktop from "../../components/BetPlaceDesktop/BetPlaceDesktop";
import EditStakeDesktop from '../../components/EditStakeDesktop/EditStakeDesktop';
import { FaInfoCircle } from 'react-icons/fa';
import moment from 'moment';
import { userActions } from "../../_actions";
import FancySelactionKey from '../../components/FancySelactionKey/FancySelactionKey';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import { httpPost, httpPostBet } from '../../_helpers/http';
import BetPlaceMatch from '../../components/BetPlaceMatch/BetPlaceMatch';
import BetPlaceDesktopLoader from '../../components/BetPlaceDesktopLoader/BetPlaceDesktopLoader';
import BetPlaceMatchLoader from '../../components/BetPlaceMatchLoader/BetPlaceMatchLoader';
import ErrorTost from '../../components/ErrorTost/ErrorTost';
import BetLoader from '../../components/BetLoader/BetLoader';

class ViewDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            betPlaceModal: false,
            matchDetails: {},
            matchDetailList: {},
            socketPerm: true,
            socketUrl: "",
            scoreIframe: "",
            tvUrl: "",
            tvId: "",
            matchDetailsForSocket: {},
            scoreHeight: "130",
            inplayMatchList: [],
            scoreScreen: false,
            isTvScreen: false,
            minMaxOddsModal: false,
            minMaxFancyModal: false,
            FancypositionModal: false,
            minMaxExtraFancyModal: false,
            cacheUrl: "",
            matchScoreDetails: {},
            matchList: {},
            matchDetailsModal: false,
            marketId: "",
            filterMatchList: {},
            matchPosition: {},
            isFetch: false,
            oddsBetData: [],
            showAlert: false,
            getMatchDetails: {},
            loading: true,
            currentIndex: "",
            currentExtraIndex: "",
            positionData: "",
            timerKey: 0,
            remainingTime: 10,
            fullScoreIframe: false,
            closeModalTimeoutId: null,
            betSlipData: {
                stake: '',
            },
            betplace: false,
            inCompleteFancy: [],
            sessionPlusMinus: "",
            showCompletedFancy: [],
            maximum_session_bet: "",
            minimum_session_bet: "",
            maximum_match_bet: "",
            minimum_match_bet: "",
            notification: "",
            matchMarketId: "",
            loadingBet: false,
            position: [],
            clientNotification: true,
            isBookmaker: false,
            isFancy: false,
            isScore: false,
            isTv: false,
            returnDataObject: {},
            returnDataFancyObject: {},
            activeTab: "1",
            matchName: "",
            EditStakeModal: false,
            isFatch: false,
            message: "",
            error: false,
            maximum_match_bet: "",
            maximum_bookmaker_coins: "",
            maximum_toss_coins: "",
            isBookmaker: "",
            isToss: "",
            isTv: "",
            isScore: "",
            isFancy: ""
        }
        this.socket = null
        this.section1Ref = React.createRef();
        this.scrollTimeout = null;
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        try {
            const marketId = {
                "marketId": this.props.match.params.marketId
            };
            this.getMarketCacheUrl(`https://oddsapi.winx777.com/v2/api/oddsData?market_id=${this.props.match.params.marketId}`)
            this.setupAsyncActions(marketId);
        } catch (error) {
            console.error("Error in componentDidMount:", error);
        }
    }

    async setupAsyncActions(marketId) {
        try {
            await this.getDidMountData(marketId);
            await this.localStoragedata();
            await this.fetchBetLists();
            await this.matchDetails()
            await this.userPositionByMarketId(marketId);
        } catch (error) {
            console.error("Error in setupAsyncActions:", error);
        }
    }

    getDidMountData = async (marketId) => {
        try {
            marketId = marketId.marketId
            let getMatchDetails = await httpPost(`sports/sportByMarketId`, { marketId: marketId })
            if (!getMatchDetails.error) {
                let matchDetails = getMatchDetails.data
                this.setState({
                    matchDetails: matchDetails,
                    socketPerm: matchDetails && matchDetails.socketPerm ? matchDetails.socketPerm : {},
                    socketUrl: matchDetails && matchDetails.socketUrl ? matchDetails.socketUrl : {},
                    tvUrl: matchDetails && matchDetails.tvUrl ? matchDetails.tvUrl : "",
                    cacheUrl: matchDetails && matchDetails.cacheUrl ? matchDetails.cacheUrl : {},
                    scoreIframe: matchDetails && matchDetails.scoreIframe ? matchDetails.scoreIframe : "",
                    notification: matchDetails && matchDetails.notification ? matchDetails.notification : "",
                    matchMarketId: matchDetails && matchDetails.marketId ? matchDetails.marketId : "",
                    isBookmaker: matchDetails && matchDetails.isBookmaker ? matchDetails.isBookmaker : false,
                    isCompletedOdds: matchDetails && matchDetails.isCompletedOdds ? matchDetails.isCompletedOdds : false,
                    isFancy: matchDetails && matchDetails.isFancy ? matchDetails.isFancy : false,
                    isMatchOdds: matchDetails && matchDetails.isMatchOdds ? matchDetails.isMatchOdds : false,
                    isScore: matchDetails && matchDetails.isScore ? matchDetails.isScore : false,
                    isTieOdds: matchDetails && matchDetails.isTieOdds ? matchDetails.isTieOdds : false,
                    isToss: matchDetails && matchDetails.isToss ? matchDetails.isToss : false,
                    isTv: matchDetails && matchDetails.isTv ? matchDetails.isTv : false,
                    matchName: matchDetails && matchDetails.matchName ? matchDetails.matchName : "",
                })

                let socketPerm = false
                let socketUrl = ""
                let cacheUrl = ""
                if (matchDetails.socketPerm) {
                    socketPerm = matchDetails.socketPerm
                }
                if (matchDetails.socketUrl) {
                    socketUrl = matchDetails.socketUrl
                }
                if (matchDetails.cacheUrl) {
                    cacheUrl = matchDetails.cacheUrl
                }
                if (socketPerm == true) {
                    await this.connectSocket(socketUrl)
                } else {
                    await this.callCache(cacheUrl);
                }

                const minMaxData = JSON.parse(matchDetails?.maxMinCoins || "100");
                if (minMaxData) {
                    this.setState({
                        maximum_match_bet: minMaxData.maximum_match_bet,
                        maximum_session_bet: minMaxData.maximum_session_bet,
                        minimum_match_bet: minMaxData.minimum_match_bet,
                        minimum_session_bet: minMaxData.minimum_session_bet,
                        maximum_bookmaker_coins: minMaxData.maximum_bookmaker_coins,
                        maximum_toss_coins: minMaxData.maximum_toss_coins,
                    });
                }

            } else {

            }
        } catch (error) {
            console.error('Error in getDidMountData:', error);
        }
    }

    localStoragedata = async () => {
        try {
            let adminMatchList = [];
            adminMatchList = localStorage.getItem('matchList');
            if (adminMatchList) {
                const parsedAdminMatchListData = JSON.parse(adminMatchList)
                if (parsedAdminMatchListData.length > 0) {
                    this.setState({ inplayMatchList: parsedAdminMatchListData });
                }
            } else {
                let matchlistItems = await httpPost('sports/matchList');
                if (matchlistItems && matchlistItems.data && matchlistItems.data.length > 0) {
                    localStorage.setItem('matchList', JSON.stringify(matchlistItems.data));
                    this.setState({ inplayMatchList: matchlistItems.data });
                } else {
                    this.setState({ inplayMatchList: [] });
                }
            }
        } catch (error) {
            console.error('Error in getInplayData:', error);
        }
    }

    matchDetails = async () => {
        try {
            let matchlistItems = await httpPost('sports/matchList');
            if (matchlistItems && matchlistItems.data && matchlistItems.data.length > 0) {
                this.setState({ inplayMatchList: matchlistItems.data });
                localStorage.setItem('matchList', JSON.stringify(matchlistItems.data));
            } else {
                this.setState({ inplayMatchList: [] });
            }
        } catch (error) {
            console.error('Error in getInplayData:', error);
        }
    }

    fetchBetLists = async () => {
        try {
            const BetListData = {
                fancyBet: true,
                isDeclare: false,
                isDeleted: 0,
                oddsBet: true,
                marketId: this.props.match.params.marketId,
            };

            const userBetHistory = await httpPost('sports/betsList', BetListData);

            if (userBetHistory && userBetHistory.data) {
                const { fancyBetData, oddsBetData } = userBetHistory.data;
                const sortedOddsBetData = oddsBetData ? oddsBetData.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
                const filteredFancyBetData = fancyBetData ? fancyBetData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
                let completeFancy = filteredFancyBetData && filteredFancyBetData.length > 0 ? filteredFancyBetData.filter(element => element.isDeclare === 1) : []
                let inCompleteFancy = filteredFancyBetData && filteredFancyBetData.length > 0 ? filteredFancyBetData.filter(element => element.isDeclare === 0) : []

                this.setState({ oddsBetData: sortedOddsBetData, inCompleteFancy: inCompleteFancy, showCompletedFancy: completeFancy });
            }
        } catch (error) {
            console.error('Error fetching bet lists:', error);
        }
    };

    userPositionByMarketId = async (marketId) => {
        try {
            const userPositionData = await httpPost('sports/userPositionByMarketId', marketId);
            if (userPositionData) {
                const getUserPositionItem = userPositionData.data;
                let oddsPos = [];
                let sessPos = [];
                let returnDataObject = {}
                let returnDataFancyObject = {}
                if (getUserPositionItem?.oddsPosition) {
                    oddsPos = getUserPositionItem.oddsPosition;
                    oddsPos.forEach(data => {
                        const hasKey = returnDataObject.hasOwnProperty(data._id);
                        if (!hasKey) {
                            returnDataObject[data._id] = data.totalPosition.toFixed(2);
                            this.setState({ returnDataObject });
                        }
                    });
                }

                if (getUserPositionItem?.sessionPosition) {
                    sessPos = getUserPositionItem.sessionPosition;
                    sessPos.forEach(data => {
                        const hasKey = returnDataObject.hasOwnProperty(data._id);
                        if (!hasKey) {
                            returnDataFancyObject[data._id] = data.position;
                            this.setState({ returnDataFancyObject });
                        }
                    });
                }
            }
        } catch (error) {
            console.error('Error fetching user position:', error);
        }
    }

    connectSocket = async (socketUrl, socketPerm) => {
        if (!socketUrl) {
            socketUrl = this.state.socketUrl
        }
        if (!socketPerm) {
            socketPerm = this.state.socketPerm
        }
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            return;
        }
        if (socketPerm) {
            this.socket = io.connect(socketUrl, {
                transports: ["websocket"],
                reconnection: false,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
                reconnectionAttempts: 99,
            });

            this.socket.emit('JoinRoom', this.props.match.params.marketId);
            this.socket.on(this.props.match.params.marketId, data => {
                data = JSON.parse(data);
                let matchScoreDetails = data?.result
                this.setState({ matchScoreDetails: matchScoreDetails })
                // this.finalDataForSocketCache(matchScoreDetails)
            });
        }
    }

    callCache = async (cacheUrl) => {
        try {
            this.interval = setInterval(async () => this.getMarketCacheUrl(cacheUrl), 1000);
        } catch (error) {
            console.error('Error calling cache:', error);
        }
    }

    getMarketCacheUrl = async (cacheUrl) => {
        try {
            const response = await axios.get(cacheUrl);
            let matchScoreDetails = response?.data?.result
            this.setState({ matchScoreDetails: matchScoreDetails })
            // this.finalDataForSocketCache(matchScoreDetails)
        } catch (error) {
            console.error('Error fetching cache data:', error);
        }
    }

    inputChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            betSlipData: {
                ...this.state.betSlipData,
                [name]: Number(value),
            },
        });
    };

    componentWillUnmount() {
        clearInterval(this.interval);
        if (this.inputRef.current) {
            this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
        }
        this.cleanupWebSocket();
        document.removeEventListener("visibilitychange", this.handleVisibilityChange);
    }

    cleanupWebSocket = () => {
        if (this.socket) {
            this.socket.close();
        }
    };

    handleVisibilityChange = () => {
        if (document.visibilityState === 'visible' && !this.state.isConnected) {
            this.connectSocket()
        } else if (document.visibilityState === 'hidden') {
            this.cleanupWebSocket();
        }
    };

    handleIncomingData = (matchData) => {
        this.setState(() => ({
            matchScoreDetails: matchData?.data.result,
        }));
    };

    handleTvScreen = () => {
        this.setState({ isTvScreen: !this.state.isTvScreen });
    }

    handleTabClick = (tabIndex) => {
        this.setState({ activeTab: tabIndex, betListShow: true });
    };

    handleBetList = () => {
        this.setState({ betListShow: !this.state.betListShow });
    }

    handleCompletedFancyModal = () => {
        this.setState({ showCompletedFancy: !this.state.showCompletedFancy });
    }

    handleFancyDetailsModal = () => {
        this.setState({ fancyDetailsModal: !this.state.fancyDetailsModal });
    }

    handleScoreIframe = () => {
        this.setState({ fullScoreIframe: !this.state.fullScoreIframe });
    }

    handleMatchDetailsModal = () => {
        this.setState({ matchDetailsModal: !this.state.matchDetailsModal });
    }
    handleMinMaxOddsModal = () => {
        this.setState({ minMaxOddsModal: !this.state.minMaxOddsModal });
        // this.setState({ minMaxOddsModal: true });
    }
    handleMinMaxFancyModal = (index) => {
        this.setState({ currentIndex: index });
        this.setState({ minMaxFancyModal: !this.state.minMaxFancyModal });
    }
    handleFancyPositionModal = (positionData) => {
        this.setState({ FancypositionModal: !this.state.FancypositionModal, positionData: positionData });
    }
    handleMinMaxExtraFancyModal = (index) => {
        this.setState({ currentExtraIndex: index });
        this.setState({ minMaxExtraFancyModal: !this.state.minMaxExtraFancyModal });
    }
    handleResponseGame = (data) => {
        this.props.history.push('/app/ViewMatchis/' + data.marketId)
    }

    handleCountdown = () => {
        if (this.state.remainingTime > 0) {
            this.setState((prevState) => ({
                remainingTime: prevState.remainingTime - 1,
            }));
        } else {
            // When the timer reaches 0, close the modal
            this.setState({ betPlaceModal: false });
        }
    };


    updateStake = (event) => {
        let { value } = event.target;
        let { betSlipData } = this.state;
        if (value.startsWith('0') && value.length > 1) {
            value = value.slice(1);
        }
        betSlipData.stake = value;
        this.setState({
            betSlipData: betSlipData,
        });
    };
    resetTimer = () => {
        this.setState({
            remainingTime: 10,
            timerKey: this.state.timerKey + 1,
        });
    };

    handleBackOpen = (data) => {

        if (this.state.closeModalTimeoutId) {
            clearTimeout(this.state.closeModalTimeoutId);
        }
        this.setState(
            {
                betPlaceModal: true,
                betSlipData: { ...data, stake: '' },
                count: data.odds,
                teamname: data.name,
            },
            () => {
                // Set a new timeout for 10 seconds and reset the timer
                this.setState({
                    closeModalTimeoutId: setTimeout(() => {
                        this.setState({ betPlaceModal: false });
                    }, 10000),
                });
                this.resetTimer();
            }
        );
    };


    handleStakeOpen = () => {
        this.setState({ EditStakeModal: true });

    };

    handlechangepage = () => {
        this.props.history.push('/app/innerstatement/' + this.props.match.params.marketId)
    }

    handleClose = () => {
        this.setState({ betPlaceModal: false, matchDetailsModal: false, EditStakeModal: false});
    };
    handleCloseOutside = () => {
        this.setState({ FancypositionModal: false, minMaxFancyModal: false, minMaxOddsModal: false });
    };


    updateStackOnclick = (num) => {
        let { betSlipData } = this.state;
        betSlipData.stake = num;
        this.setState({
            betSlipData: betSlipData,
        });
    };

    placeBet = async () => {
        try {
            const { matchDetails, betSlipData } = this.state;
            const betObject = {
                "odds": this.state.count + "",
                "amount": this.state.betSlipData.stake,
                "selectionId": this.state.betSlipData.selectionId + "",
                "marketId": matchDetails.marketId + "",
                "eventId": matchDetails.eventId,
                "betFor": this.state.betSlipData.betFor + "",
                "run": this.state.betSlipData.run ? this.state.betSlipData.run + "" : "0",
                "oddsType": this.state.betSlipData.oddsType === "Match Odds" ? "matchOdds" : this.state.betSlipData.oddsType === "Tied Match" ? "tiedMatch" : this.state.betSlipData.oddsType + "",
                "type": this.state.betSlipData.betType + "",
            };
            this.setState({ loadingBet: true, betPlaceModal: false })
            if (betSlipData.oddsType === "fancy") {
                if (this.state.minimum_session_bet <= this.state.betSlipData.stake) {
                    let saveBetOdds = await httpPostBet("sports/sessionBetPlaced", betObject);
                    this.setState({ loadingBet: false })
                    if (!saveBetOdds.error) {
                        this.setState({ isFatch: true, message: saveBetOdds.message, error: false })
                        setTimeout(() => {
                            this.setState({ isFatch: false })
                        }, 3000);
                        this.props.dispatch(userActions.getUserBalance());
                        await this.userPositionByMarketId({ marketId: matchDetails.marketId })
                        await this.fetchBetLists()
                    } else {
                        this.setState({ isFatch: true, message: saveBetOdds.message, error: true })
                        setTimeout(() => {
                            this.setState({ isFatch: false })
                        }, 3000);
                    }
                } else {
                    this.setState({ isFatch: true, message: `Please Enter Minimun Amount ${this.state.minimum_session_bet}`, error: true, loadingBet: false })
                    setTimeout(() => {
                        this.setState({ isFatch: false })
                    }, 3000);
                }
            } else {
                if (this.state.minimum_match_bet <= this.state.betSlipData.stake) {
                    let saveBetOdds = await httpPostBet("sports/oddBetPlaced", betObject);
                    this.setState({ loadingBet: false })
                    if (!saveBetOdds.error) {
                        this.setState({ isFatch: true, message: saveBetOdds.message, error: false })
                        setTimeout(() => {
                            this.setState({ isFatch: false })
                        }, 3000);
                        this.props.dispatch(userActions.getUserBalance());
                        await this.userPositionByMarketId({ marketId: matchDetails.marketId })
                        await this.fetchBetLists()
                    } else {
                        this.setState({ isFatch: true, message: saveBetOdds.message, error: true })
                        setTimeout(() => {
                            this.setState({ isFatch: false })
                        }, 3000);
                    }
                }
                else {
                    this.setState({ loadingBet: false, })
                    this.setState({ isFatch: true, message: `Please Enter Minimun Amount ${this.state.minimum_match_bet}`, error: true, loadingBet: false })
                    setTimeout(() => {
                        this.setState({ isFatch: false })
                    }, 3000);
                }
            }

        } catch (error) {
            console.error('Error placing bet:', error);
            toast.error('Failed to place bet. Please try again later.');
        }
    };

    toastClose = () => {
        this.setState({ isFatch: false });
    };

    render() {
        let { scoreIframe, tvUrl, matchScoreDetails, isTvScreen, inCompleteFancy, oddsBetData, showCompletedFancy, maximum_match_bet, maximum_session_bet, minimum_match_bet, minimum_session_bet, notification, inplayMatchList, matchMarketId, loadingBet, positionData, fullScoreIframe, time, betPlaceModal, isBookmaker, isFancy, isScore, isToss, isTv, returnDataObject, returnDataFancyObject, FancypositionModal, minMaxOddsModal, minMaxFancyModal, currentIndex, activeTab, matchName, EditStakeModal, isFatch, message, error } = this.state
        let { commission_fancy_data, no_commission_fancy_data, team_data, toss_data } = matchScoreDetails ? matchScoreDetails : {};

        let Team1Toss = toss_data && toss_data[0] ? toss_data[0] : {};
        let Team2Toss = toss_data && toss_data[1] ? toss_data[1] : {};

        return (
            <div className='overflow-y-auto h-full'>
                <div className='main-body overflow-y-auto'>
                    {
                        EditStakeModal === true ?
                            <EditStakeDesktop
                                handleClose={this.handleClose}
                                stakehandleClose={this.handleClose}
                            />
                            : null
                    }
                    {
                        FancypositionModal === true ?
                            <FancySelactionKey
                                handleClose={this.handleCloseOutside}
                                positionData={positionData}
                                marketId={matchMarketId} />
                            : null
                    }
                    {loadingBet && loadingBet == true ?
                        <>
                            <BetPlaceMatchLoader
                                betSlipData={this.state.betSlipData}
                                updateStackOnclick={this.updateStackOnclick}
                                inputChange={this.inputChange}
                                decreaseCount={this.decreaseCount}
                                increaseCount={this.increaseCount}
                                placeBet={this.placeBet}
                                handleClose={this.handleClose}
                                count={this.state.count}
                                timerKey={this.state.timerKey}
                                remainingTime={this.state.remainingTime}
                                handleCountdown={this.handleCountdown}
                                updateStake={this.updateStake}
                            />
                        </> :
                        <>
                            {betPlaceModal === true ?
                                <BetPlaceMatch
                                    betSlipData={this.state.betSlipData}
                                    updateStackOnclick={this.updateStackOnclick}
                                    inputChange={this.inputChange}
                                    decreaseCount={this.decreaseCount}
                                    increaseCount={this.increaseCount}
                                    placeBet={this.placeBet}
                                    handleClose={this.handleClose}
                                    count={this.state.count}
                                    timerKey={this.state.timerKey}
                                    remainingTime={this.state.remainingTime}
                                    handleCountdown={this.handleCountdown}
                                    updateStake={this.updateStake}
                                />
                                : null}
                        </>}
                    {isFatch ?
                        <ErrorTost message={message} toastClose={this.toastClose} error={error} />
                        : null}


                    <Sidebar props={this.props} />
                    <div className="flex flexcol flex-1 sm:px-2 px-0 ">
                        <main className="relative lg:flex flex-col lg:flex-row w-full md:px-1 gap-2 px-0 left-0 md:top-2 top[56px] h-full" onClick={this.handleCloseOutside}>
                            <section className="lg:w-[60%] w-full rounded-md  hfull relative" >
                                {notification && notification !== "" ?
                                    <marquee className="w-full text-lg border-black text-black font-bold bg-white h-5">
                                        {notification && notification ? notification : ""}
                                    </marquee>
                                    : null}
                                <div className="md:flex row mx-0 justify-between !border-t !border-white !border" onClick={(e) => e.stopPropagation()}>
                                    <div className="col-8 col12 px-0">
                                        <div className='px-1 py-1 h-full cricket-icon-color md:text-[14px] text-[16px] text-white text-left w-full flex justify-start items-center space-x-1'> <span><AiOutlineStar className='w-[15px] h-[17px] object-contain' /></span>
                                            <span className='text-[16px] w-[80%] truncate'>{matchName ? matchName : ""}</span>
                                        </div>
                                    </div>
                                    <div className="col-4 col12 px-0">
                                        <div className='row mx-0 h-full items-center border-t !border-white'>
                                            {/* <div className="col-4 px-0 h-full">
                                                <div onClick={this.handleScoreIframe} className=' h-full text-sm text-white font-semibold flex justify-center items-center bg-[#780909] whitespace-nowrap cursor-pointer uppercase py-2'>
                                                    {fullScoreIframe ? " Half Score" : " Full Score"}
                                                </div>
                                            </div> */}
                                            <div className="col-6 px-0 h-full">
                                                <div onClick={this.handleTvScreen} className='  h-full text-[16px] black-text font-semibold flex justify-center items-center bg-[#FFDF1A] whitespace-nowrap cursor-pointer uppercase'>Live TV</div>
                                            </div>
                                            <div className="col-6 px-0 h-full">
                                                <div className=' h-full text-sm white-text font-semibold flex justify-center items-center bg-[#8B0000] whitespace-nowrap w-full cursor-pointer' onClick={this.handleMatchDetailsModal}>Matches</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="details">
                                    {isScore ?
                                        <div className={`w-full md:text-sm text-[10px] bg-[#000000c6] overflow-y-auto border-2 border-[#780909] h-[175px]`} >
                                            {scoreIframe && scoreIframe === null ?
                                                <div className='bg-[#000000c6] h-[175px]'>

                                                </div> :
                                                <div>
                                                    <iframe src={scoreIframe && scoreIframe ? scoreIframe : ""} title=" " loading='lazy' className={`w-[100%] h-[230px]`} />
                                                </div>
                                            }

                                        </div> : null
                                    }
                                </div>
                                {isTv ?
                                    <div>
                                        {isTvScreen ?
                                            <div className="details">
                                                <div className={`w-full relative md:text-sm text-[10px] bg-[#000000c6] h-[225px]`}>
                                                    <iframe src={tvUrl ? tvUrl : ""} title=" " loading='lazy' className="w-[100%] h-[225px]" />
                                                </div>
                                            </div> : null}
                                    </div> : null
                                }
                                <div className='divide-y divide-black space-y-2'>
                                    {isBookmaker ?
                                        <>
                                            {team_data && team_data.length > 0 ?
                                                <div className="min-w-full border-[1px] border-black ">
                                                    <div className='grid grid-cols-6  text-white border-b-[1px] border-black divide-x-[1px] divide-black'>
                                                        <div className="col-span-4 flex justify-between items-center yellow-head-color p-1 ">
                                                            <span className='font-bold black-text'>Match Odds</span>
                                                            <span onClick={(e) => e.stopPropagation()} className='relative cursor-pointer'>
                                                                <FaInfoCircle className='text-black' size={18} onClick={()=>this.handleMinMaxOddsModal ()} />
                                                                {minMaxOddsModal ?
                                                                    <div className='absolute flex justify-center  text-black w-full top-8 z-10'>
                                                                        <div className='w-60 relative text-center py-1.5 px-1 bg-white shadow-lg shadow-black rounded-md whitespace-nowrap h-8'>
                                                                            Min stake: {minimum_match_bet ? minimum_match_bet : "100"} | Max stake: {maximum_match_bet ? maximum_match_bet : "100000"}
                                                                            <div className="absolute -top-2 inset-x-[6.5rem] bg-white rotate-45 w-4 h-4"></div>
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                            </span>
                                                        </div>
                                                        <div className="head-background text-center text-[16px] text-black font-[500] p-[5px]">LAGAI</div>
                                                        <div className="head-background text-center text-[16px] text-black font-[500] p-[5px]">KHAI</div>
                                                    </div>

                                                    {team_data && team_data.length > 0 ? team_data.map((element, index) => (
                                                        <div key={index} className="grid grid-cols-6 bg-[#ffffff] text-black border-b-[1px] border-black divide-x-[1px] divide-black">
                                                            <div className="col-span-4 flex justify-between items-center p-[6px] md:text-[14px] text-[12px]">
                                                                <span className='font-semibold truncate text-[15px]'>{element.team_name}</span>
                                                                <span key={index} className={`${returnDataObject[element.selectionid] > 0 ? 'text-green-800' : returnDataObject[element.selectionid] < 0 ? 'text-red-500' : 'text-black'} font-semibold text-base text-center p-[6px]  `} >
                                                                    {returnDataObject[element.selectionid] !== undefined && returnDataObject[element.selectionid] !== null && returnDataObject[element.selectionid] !== "" ? returnDataObject[element.selectionid] : "0"}
                                                                </span>

                                                            </div>
                                                            <div className='col-span-2' onClick={(e) => e.stopPropagation()}>
                                                                {element.lgaai === "0.00" ?
                                                                    <div className='flex items-center relative h-full'>
                                                                        <div className="bg-[#7CC4F7] w-full h-full font-bold text-xl text-[#252525] text-center p-[6px]"></div>
                                                                        <div className="bg-[#FCA4B7] w-full h-full font-bold text-xl text-[#252525] text-center p-[6px]"></div>
                                                                        <div className='w-full h-full bg-black/30 absolute top-0 left-0 flex justify-center items-center'>
                                                                            <span className='white-text uppercase'>Suspended</span>
                                                                        </div>
                                                                    </div> :
                                                                    <div className='w-full flex items-center divide-x-[1px] divide-black'>
                                                                        <div className="cursor-pointer bg-[#7CC4F7] w-full font-bold text-xl text-[#252525] text-center p-[10px]"
                                                                            onClick={() => this.handleBackOpen({ data: element, type: "Yes", odds: element.lgaai, name: element.team_name, betFor: "odds", oddsType: "bookmaker", betType: "L", selectionId: element.selectionid })}>{element.lgaai}</div>
                                                                        <div className="cursor-pointer bg-[#FCA4B7] w-full font-bold text-xl text-[#252525] text-center p-[10px]"
                                                                            onClick={() => this.handleBackOpen({ data: element, type: "No", odds: element.khaai, name: element.team_name, betFor: "odds", oddsType: "bookmaker", betType: "k", selectionId: element.selectionid })}>{element.khaai}</div>
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    )) : null}

                                                </div>
                                                : null}
                                        </> : null
                                    }
                                    {isToss ?
                                        <>
                                            {Team1Toss && Team1Toss.is_toss === true ?
                                                <div className="min-w-full border-[1px] border-black">
                                                    <div className='grid grid-cols-6 text-white border-b-[1px] border-black divide-x-[1px] divide-black'>
                                                        <div className="col-span-4 flex justify-between items-center yellow-head-color p-1">
                                                            <span className='font-bold black-text'>Toss Book</span>
                                                        </div>
                                                        <div className="head-background text-center text-xs text-black font-bold p-[6px] truncate">{Team1Toss && Team1Toss.team_name ? Team1Toss.team_name : "Team Toss 1"}</div>
                                                        <div className="head-background text-center text-xs text-black font-bold p-[6px] truncate">{Team2Toss && Team2Toss.team_name ? Team2Toss.team_name : "Team Toss 2"}</div>
                                                    </div>
                                                    <div className="grid grid-cols-6 text-black border-b-[1px] border-black divide-x-[1px] divide-black">
                                                        <div className="col-span-4 p-[6px]">
                                                            <span className='font-semibold text-sm'>WHO WILL WIN THE TOSS</span>
                                                        </div>
                                                        <div className='col-span-2' onClick={(e) => e.stopPropagation()}>
                                                            {Team1Toss.lgaai === "0.00" ?
                                                                <div className='flex items-center relative h-full'>
                                                                    <div className="bg-[#7CC4F7] w-full h-full font-bold text-xl text-black text-center p-[6px]"></div>

                                                                    <div className='w-full h-full bg-black/30 absolute top-0 left-0 flex justify-center items-center'>
                                                                        <span className='text-white uppercase'>Suspended</span>
                                                                    </div>
                                                                </div> :
                                                                <div className='w-full flex items-center divide-x divide-black '>
                                                                    <div className="bg-[#7CC4F7] cursor-pointer w-full h-full font-bold text-xl text-black text-center p-[6px]" onClick={() => this.handleBackOpen({ data: Team1Toss, type: "Yes", odds: Team1Toss.lgaai, name: Team1Toss.team_name, betFor: "toss", oddsType: "toss", betType: "L", selectionId: Team1Toss.selectionid })}>
                                                                        <div className='text-lg font-bold'>{Team1Toss.lgaai}</div>
                                                                        <p className={`${returnDataObject[Team1Toss.selectionid] > 0 ? 'text-green-800' : returnDataObject[Team1Toss.selectionid] < 0 ? 'text-red-500' : 'text-black'} font-semibold text-xs text-center `} >
                                                                            {returnDataObject[Team1Toss.selectionid] !== undefined && returnDataObject[Team1Toss.selectionid] !== null && returnDataObject[Team1Toss.selectionid] !== "" ? returnDataObject[Team1Toss.selectionid] : "0"}
                                                                        </p>
                                                                    </div>
                                                                    <div className="bg-[#7CC4F7] cursor-pointer w-full h-full font-bold text-xl text-black text-center p-[6px]" onClick={() => this.handleBackOpen({ data: Team2Toss, type: "Yes", odds: Team2Toss.lgaai, name: Team2Toss.team_name, betFor: "toss", oddsType: "toss", betType: "L", selectionId: Team2Toss.selectionid })}>
                                                                        <div className='text-lg font-bold'>{Team2Toss.lgaai}</div>
                                                                        <p className={`${returnDataObject[Team2Toss.selectionid] > 0 ? 'text-green-800' : returnDataObject[Team2Toss.selectionid] < 0 ? 'text-red-500' : 'text-black'} font-semibold text-xs text-center `} >
                                                                            {returnDataObject[Team2Toss.selectionid] !== undefined && returnDataObject[Team2Toss.selectionid] !== null && returnDataObject[Team2Toss.selectionid] !== "" ? returnDataObject[Team2Toss.selectionid] : "0"}
                                                                        </p>
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                        </> : null}

                                    {isFancy ?
                                        <>
                                            {commission_fancy_data && commission_fancy_data.length > 0 ?
                                                <div className="min-w-full border-[1px] border-red-600" >
                                                    <div className='grid grid-cols-6 black-text w-full border-b-[1px] border-black divide-x-[1px] divide-black'>
                                                        <div className="col-span-4 yellow-head-color font-bold p-[5px]">Session</div>
                                                        <div className="head-background text-center font-bold p-[5px]">No</div>
                                                        <div className="head-background text-center font-bold p-[5px]">Yes</div>
                                                    </div>
                                                    {commission_fancy_data && commission_fancy_data.length > 0 ? (
                                                        commission_fancy_data.map((element, index) => (
                                                            <div key={index} className="grid grid-cols-6 bg-[#ffffff] text-black border-b-[1px] border-black divide-x-[1px] divide-black " >
                                                                <div className="col-span-4 flex justify-between items-center p-[6px] md:text-[13px] text-[12px] font-bold">
                                                                    <span>{element.session_name}</span>
                                                                    <span className='flex items-center space-x-2 text-gray-800 font-bold text-sm' onClick={(e) => e.stopPropagation()}>
                                                                        <div className='cursor-pointer' onClick={() => this.handleFancyPositionModal({ positionData: element })} >
                                                                            <img src="/images/ladder.svg" alt='lodder' className='w-[17px] h-26px] md:h-[46px] md:w-[30px]' />
                                                                        </div>
                                                                        <div className='cursor-pointer relative' >
                                                                            <FaInfoCircle size={18} onClick={() => this.handleMinMaxFancyModal(index)} key={index} />
                                                                            {minMaxFancyModal ?
                                                                                <>
                                                                                    {currentIndex === index ?
                                                                                        <div className='absolute  text-black top-7 z-10 -left-[6.5rem]'>
                                                                                            <div className='lg:w-32 w-60 relative text-center py-1.5 bg-[#FFF] shadow-lg shadow-black rounded-md px-2 font-light'>
                                                                                                Min stake: {minimum_session_bet ? minimum_session_bet : "100"} | Max stake: {element && element.max ? element.max : maximum_session_bet ? maximum_session_bet : "100000"}
                                                                                                <div className="absolute -top-2 inset-x-[6.5rem] shadow-lg shadow-black bg-[#FFF] rotate-45   w-4 h-4"></div>
                                                                                            </div>
                                                                                        </div> : null}
                                                                                </> : null}
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <div className='col-span-2 flex items-center relative divide-x-[1px] divide-black' onClick={(e) => e.stopPropagation()}>
                                                                    <div className=
                                                                        {`${element.runsNo === 0 || element.oddsNo === 0 || element.runsYes === 0 || element.oddsYes === 0 ? "text-[#FCA4B7]" : "text-[#252525]"} w-full cursor-pointer bg-[#FCA4B7] font-bold text-center p-[6px] h-14`}
                                                                        onClick={() => this.handleBackOpen({ data: element, type: "No", odds: element.oddsNo, name: element.session_name, betFor: "fancy", oddsType: "fancy", betType: "N", run: element.runsNo, selectionId: element.session_id })}>
                                                                        <div className='text-lg '> {element.runsNo}</div>
                                                                        <div className='text-[11px]  font-normal'> {element.oddsNo}</div>
                                                                    </div>
                                                                    <div className={`${element.runsNo === 0 || element.oddsNo === 0 || element.runsYes === 0 || element.oddsYes === 0 ? "text-[#7CC4F7] " : "text-[#252525]"} w-full cursor-pointer bg-[#7CC4F7]  font-bold text-center h-14 p-[6px]`}
                                                                        onClick={() => this.handleBackOpen({ data: element, type: "Yes", odds: element.oddsYes, name: element.session_name, betFor: "fancy", oddsType: "fancy", betType: "Y", run: element.runsYes, selectionId: element.session_id })}>
                                                                        <div className='text-lg '> {element.runsYes}</div>
                                                                        <div className='text-[11px] font-normal'> {element.oddsYes}</div>
                                                                    </div>
                                                                    {element.runsNo === 0 || element.oddsNo === 0 || element.runsYes === 0 || element.oddsYes === 0 ?
                                                                        <div className='w-full h-full bg-black/30 absolute top-0 left-0 flex justify-center items-center'>
                                                                            <span className='text-white uppercase'>Suspended</span>
                                                                        </div> : null}
                                                                </div>
                                                                <marquee className="w-full col-span-6 border-t  border-black">
                                                                    {element.remark}
                                                                </marquee>
                                                            </div>
                                                        ))) : null}
                                                </div>
                                                : null}
                                        </> : null}

                                    {no_commission_fancy_data && no_commission_fancy_data.length > 0 ?
                                        <div className="min-w-full border-[1px] border-red-600">
                                            <div className='grid grid-cols-6 black-text w-full border-b-[1px] border-black divide-x-[1px] divide-black'>
                                                <div className="col-span-4 yellow-head-color font-bold p-[6px]">Extra Fancy(No Commission)</div>
                                                <div className="head-background text-center text-black font-bold p-[6px]">No</div>
                                                <div className="head-background text-center text-black font-bold p-[6px]">Yes</div>
                                            </div>
                                            {no_commission_fancy_data && no_commission_fancy_data.length > 0 ? (
                                                no_commission_fancy_data.map((element, index) => (
                                                    <div key={index} className="grid grid-cols-6 text-black border-b-[1px] border-black divide-x-[1px] divide-black">
                                                        <div className="col-span-4 flex justify-between items-center p-[6px] md:text-[13px] text-[12px] font-bold">
                                                            <span>{element.session_name}</span>
                                                            <span className='flex items-center space-x-2 text-gray-800 font-bold text-sm'>
                                                                <div className='cursor-pointer' onClick={() => this.handleFancyPositionModal({ positionData: element })} >
                                                                    <img src="/images/ladder.svg" alt='lodder' className='w-[17px] h-26px] md:h-[46px] md:w-[30px]' />
                                                                </div>
                                                                <div className='cursor-pointer relative'>
                                                                    <FaInfoCircle size={18} onClick={() => this.handleMinMaxFancyModal(index)} key={index} />
                                                                    {minMaxFancyModal ?
                                                                        <>
                                                                            {currentIndex === index ?
                                                                                <div className='absolute  text-black top-7 z-10 -left-[6.5rem]'>
                                                                                    <div className='lg:w-32 w-60 relative text-center py-1.5 bg-[#FFF] shadow-lg shadow-black rounded-md px-2 font-light'>
                                                                                        Min stake: {minimum_session_bet ? minimum_session_bet : "100"} | Max stake: {element && element.max ? element.max : maximum_session_bet ? maximum_session_bet : "100000"}
                                                                                        <div className="absolute -top-2 inset-x-[6.5rem] shadow-lg shadow-black bg-[#FFF] rotate-45   w-4 h-4"></div>
                                                                                    </div>
                                                                                </div> : null}
                                                                        </> : null}
                                                                </div>
                                                            </span>
                                                        </div>
                                                        <div className='col-span-2 flex items-center relative divide-x-[1px] divide-black' onClick={(e) => e.stopPropagation()}>
                                                            <div className={`${element.runsNo === 0 || element.oddsNo === 0 || element.runsYes === 0 || element.oddsYes === 0 ? "text-[#FCA4B7]" : "text-black"} w-full cursor-pointer bg-[#FCA4B7] font-bold text-center h-full p-[6px]`}
                                                                onClick={() => this.handleBackOpen({ data: element, type: "No", odds: element.oddsNo, name: element.session_name, betFor: "fancy", oddsType: "fancy", betType: "N", run: element.runsNo, selectionId: element.session_id })}>
                                                                <div className='text-lg '> {element.runsNo}</div>
                                                                <div className='text-[11px] font-normal'> {element.oddsNo}</div>
                                                            </div>
                                                            <div className={`${element.runsNo === 0 || element.oddsNo === 0 || element.runsYes === 0 || element.oddsYes === 0 ? "text-[#7CC4F7] " : "text-black"} w-full cursor-pointer bg-[#7CC4F7] h-full font-bold text-center p-[6px]`} onClick={() => this.handleBackOpen({ data: element, type: "Yes", odds: element.oddsYes, name: element.session_name, betFor: "fancy", oddsType: "fancy", betType: "Y", run: element.runsYes, selectionId: element.session_id })}>
                                                                <div className='text-lg'> {element.runsYes}</div>
                                                                <div className='text-[11px]  font-normal'> {element.oddsYes}</div>
                                                            </div>
                                                            {element.runsNo === 0 || element.oddsNo === 0 || element.runsYes === 0 || element.oddsYes === 0 ?
                                                                <div className='w-full h-full bg-black/30 absolute top-0 left-0 flex justify-center items-center'>
                                                                    <span className='text-white uppercase'>Suspended</span>
                                                                </div> : null}
                                                        </div>
                                                        <marquee className="w-full col-span-6 border-t  border-black">
                                                            {element.remark}
                                                        </marquee>
                                                    </div>
                                                ))) : null}
                                        </div>
                                        : null}

                                </div>

                                {this.state.matchDetailsModal ?
                                    <ul className='absolute text-white text-sm uppercase top-[1.2rem] pt-3 right-2 '>
                                        {inplayMatchList && inplayMatchList.length > 0 ? inplayMatchList.map(
                                            (elementinner, index) => (
                                                <div key={index}>
                                                    {matchMarketId !== elementinner.marketId ? (
                                                        <li className='bg-[#045f5f] hover:bg-[#e6e6e6] px-2 hover:text-black cursor-pointer py-1 border-[1px] border-[#55b569] rounded-sm w-40 text-[13px]  truncate whitespace-nowrap' onClick={() => this.handleResponseGame(elementinner)}>{elementinner.matchName}
                                                        </li>
                                                    ) : null}  </div>
                                            )
                                        ) :
                                            <li className='bg-[#045f5f] hover:bg-white px-2 hover:text-black cursor-pointer py-1 border-[1px] border-[#55b569] rounded-sm w-40 truncate whitespace-nowrap'>
                                                No Matches
                                            </li>
                                        }
                                    </ul>
                                    : null
                                }
                            </section>

                            <div className='lg:w-[40%] w-full md:pb-0  mt-3 mt-lg-0'>

                                <div className='flex justify-between text-[15px] items-center text-white pb-1 item-center stakeBtns'>
                                    <div className="bg-[#417E92] text-center w-full h-10 rounded-tl-md flex justify-center items-center text-md" >Bet Slip</div>
                                    <div className="bg-[#232323] text-center w-full h-10 rounded-tr-md flex justify-center items-center text-md hover:underline cursor-pointer" onClick={() => this.handleStakeOpen()}>Edit Stake</div>
                                </div>
                                {loadingBet && loadingBet == true ?
                                    <>
                                        <BetPlaceDesktopLoader
                                            betSlipData={this.state.betSlipData}
                                            updateStackOnclick={this.updateStackOnclick}
                                            inputChange={this.inputChange}
                                            decreaseCount={this.decreaseCount}
                                            increaseCount={this.increaseCount}
                                            placeBet={this.placeBet}
                                            handleClose={this.handleClose}
                                            count={this.state.count}
                                            timerKey={this.state.timerKey}
                                            remainingTime={this.state.remainingTime}
                                            handleCountdown={this.handleCountdown}
                                            updateStake={this.updateStake}
                                        />
                                    </> :
                                    <>
                                        {betPlaceModal === true ?
                                            <BetPlaceDesktop
                                                betSlipData={this.state.betSlipData}
                                                updateStackOnclick={this.updateStackOnclick}
                                                inputChange={this.inputChange}
                                                decreaseCount={this.decreaseCount}
                                                increaseCount={this.increaseCount}
                                                placeBet={this.placeBet}
                                                handleClose={this.handleClose}
                                                count={this.state.count}
                                                timerKey={this.state.timerKey}
                                                remainingTime={this.state.remainingTime}
                                                handleCountdown={this.handleCountdown}
                                                updateStake={this.updateStake}
                                            />
                                            : null}
                                    </>}


                                <div className='flex justify-between items-center bg-[#F7C200]'>
                                    <div className="text-[13px] flex items-center text-white">
                                        <button className={`${activeTab === 1 ? "bg-[#0B7D36]" : "bg-[#0B7D36]"} md:px-2.5 px-3.5 py-[9px] text-center rounded-0`} onClick={() => this.handleTabClick(1)}>Matched Bet ({oddsBetData && oddsBetData.length})</button>
                                        <button className={`${activeTab === 2 ? "bg-[#417E92]" : "bg-[#417E92]"} md:px-2.5 px-3.5 py-[9px] text-center rounded-0`} onClick={() => this.handleTabClick(2)}>Fancy Bet ({inCompleteFancy && inCompleteFancy.length})</button>
                                        <button className="bg-[#8B0000] md:px-2.5 px-3.5 py-[9px] text-center rounded-0" onClick={() => this.handlechangepage()}>Completed Fancy</button>
                                    </div>
                                    <div className='flex items-center space-x-2'>
                                        <AiFillCaretDown className="text-black hover:border cursor-pointer" onClick={this.handleBetList} size={20} />
                                    </div>
                                </div>
                                {this.state.betListShow ?
                                    <>
                                        {activeTab === 1 ?
                                            <div className='w-full overflow-x-auto scrollbar-hide'>
                                                <table className="w-full text-start text-[12px] border-separate !border-[1px] border-[#dcdcdc]">
                                                    <thead className="rounded-t bg-[#DCDCDC] font-normal">
                                                        <tr className='text-[#252525]'>
                                                            <th className="">No.</th>
                                                            <th className="">Runner</th>
                                                            <th className="">odds</th>
                                                            <th className="">Stack</th>
                                                            <th className="">Bet Type</th>
                                                            <th className="">Place Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="">
                                                        {oddsBetData && oddsBetData.length > 0 ? oddsBetData.map((element, index) => (
                                                            <tr key={index} className={`${element.type === "L" ? "bg-[#7CC4F7]" : "bg-[#FCA4B7]"} text-[#252525] `}>
                                                                <td className="">{index + 1} </td>
                                                                <td className="">{element.teamName} ({element.oddsType})</td>
                                                                <td className=" ">{element.amount}</td>
                                                                <td className="">{element.odds}</td>
                                                                <td className="">{element && element.type === "L" ? "LAGAI" : "KHAI"}</td>
                                                                <td className="">{moment(element.createdAt, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD hh:mm:ss A')}</td>

                                                            </tr>
                                                        )) : <tr className="bg-[#F9F9F9] text-[#252525] text-center ">
                                                            <td colSpan={7}>No placed bet found ! </td>
                                                        </tr>
                                                        }


                                                    </tbody>
                                                </table>
                                            </div> : null}
                                        {activeTab === 2 ?
                                            <div className='w-full overflow-x-auto scrollbar-hide'>
                                                <table className="w-full text-start text-[12px] border-separate !border-[1px] border-[#dcdcdc]">
                                                    <thead className="rounded-t bg-[#DCDCDC] font-normal">
                                                        <tr className='text-[#252525] text-center'>
                                                            <th className="">No.</th>
                                                            <th className="text-left">Runner</th>
                                                            <th className="">odds</th>
                                                            <th className="text-center">Stack</th>
                                                            <th className="">Type</th>
                                                            <th className="text-left">Place Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="">
                                                        {inCompleteFancy && inCompleteFancy.length > 0 ? inCompleteFancy.map((element, index) => (
                                                            <tr key={index} className={`text-center ${element.type === "N" ? "bg-[#FCA4B7]" : "bg-[#7CC4F7]"} text-[#252525] `}>
                                                                <td className="">{index + 1} </td>
                                                                <td className="text-left">{element.sessionName}</td>
                                                                <td className="">{element.run}</td>
                                                                <td className="">{element.amount}</td>
                                                                <td className="">{element && element.type === "N" ? "NO" : "YES"}</td>
                                                                <td className="text-left"> {element && element.createdAt ? moment(element.createdAt).utcOffset("+05:30").format("DD-MM-YYYY hh:mm:ss") : ''}</td>
                                                            </tr>
                                                        )) : <tr className="bg-[#F9F9F9] text-[#252525] text-center ">
                                                            <td colSpan={7}>No placed bet found ! </td>
                                                        </tr>
                                                        }


                                                    </tbody>
                                                </table>
                                            </div> : null}
                                        {activeTab === 3 ? <></> : null}
                                    </> : null}
                            </div>
                            {/* <BetLoader/> */}
                        </main>

                    </div >
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { users, sport } = state;
    return {
        users,
        sport,
    };
}
export default connect(mapStateToProps)(ViewDetails);

