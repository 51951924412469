import React, { useRef, useEffect, useState } from 'react';
import "./BetPlaceDesktop.css";
import { betChipsData } from '../../_config';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'


export default function BetPlaceDesktop(props) {
    const { updateStackOnclick, placeBet, handleClose, betSlipData, timerKey, handleCountdown, remainingTime, updateStake } = props;
    const [stake, setStack] = useState(0);
    const [activeButtonIndex, setActiveButtonIndex] = useState(null);
    const [placeButton, setPlaceButton] = useState(false);
    let betChipsDatas = JSON.parse(localStorage.getItem('betChipsData'));
    const myArray = betChipsDatas && betChipsDatas.length > 0 ? Object.keys(betChipsDatas) : Object.keys(betChipsData);



    const arrayData = (element, index) => {
        setActiveButtonIndex(index);
        if (element >= 0) {
            updateFinalBalance(element);
        }
    };

    const updateInputValue = (event) => {
        const newValue = parseFloat(event.target.value);
        setStack(() => {
            const newStack = !isNaN(newValue) ? (newValue >= 0 ? newValue : 0) : 0;
            betSlipData.stake = newStack;

            return newStack;
        });
    };

    const updateFinalBalance = (amount) => {
        let newStack = 0;
        if (amount === 0) {
            newStack = 0;
        } else {
            newStack = stake + Number(amount);
        }

        betSlipData.stake = newStack;

        if (newStack > 0) {
            setPlaceButton(true);
        } else {
            setPlaceButton(false);
        }
        setStack(newStack);
    };



    return (
        <div className='position lg:block hidden'>
            <div className='borderye '>
                <div className={`${betSlipData.type === "Yes" ? "bg-white" : "bg-white"} p-2`}>
                    <div className="state-name">{betSlipData && betSlipData.name ? betSlipData.name : null}</div>
                    <div className="state-profit-loss">
                        <span>
                            <span>Yet (Bet For)</span> <br />
                            <span >{betSlipData && betSlipData.betType === "N" ? "Not" : betSlipData.betType === "Y" ? "Yes" : betSlipData.betType === "L" ? "Lagai" : betSlipData.betType === "K" ? "Khai" : null}</span>
                        </span>
                        <span>
                            <span>Profit</span>
                            <span className='text-[#087C34]'>0</span>
                        </span>
                        <span>
                            <span>Loss</span>
                            <span className='text-[#FF0000]'>0</span>
                        </span>
                        <CountdownCircleTimer
                            key={timerKey}
                            isPlaying
                            duration={remainingTime}
                            colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                            colorsTime={[10, 6, 3, 0]}
                            size={50}
                            onComplete={() => handleCountdown()}
                        >
                            {({ remainingTime }) => remainingTime}
                        </CountdownCircleTimer>
                    </div>

                    {betSlipData.betFor === "fancy" ?
                        <>
                            <div className="flex">
                                <div className='text-left w-full font-bold'>{`${betSlipData.betFor === "fancy" ? "fancy" : "odds"}`}</div>
                                <div className='text-left w-full font-bold'>Run</div>
                                <div className='text-left w-full font-bold'>Stake</div>
                            </div>
                            <div className="flex">
                                <input type="text" className="odd-input" placeholder="0" value={parseFloat(betSlipData.odds * 100).toFixed(2)} />
                                <input type="text" className="odd-input" placeholder="0" value={betSlipData.run} />
                                <input type="text" className="even-input" placeholder="0" value={betSlipData.stake} onChange={updateInputValue} />
                            </div>
                        </>
                        :
                        <>
                            <div className="flex">
                                <div className='text-left w-full font-bold'>{`${betSlipData.betFor === "fancy" ? "fancy" : "odds"}`}</div>
                                <div className='text-left w-full font-bold'>Stake</div>
                            </div>
                            <div className="choice-betting">
                                <input type="text" className="odd-input" placeholder="0" value={betSlipData.odds} />
                                <input type="text" className="even-input" placeholder="0" value={betSlipData.stake} onChange={updateInputValue} />
                            </div>
                        </>}



                    <div className='grid xl:grid-cols-5 grid-cols-3'>
                        {myArray && myArray.length > 0 ? myArray.map((element, index) => (
                            <div key={index} className="
                            betprice-box">
                                <div className='text-center h-10 w-full' onClick={() => arrayData(element, index)} key={index}>{element}</div>
                            </div>)) : null}
                        <div className='clearbtn' onClick={() => arrayData(0)}>C</div>
                    </div>
                    <div className='spacing'>
                        <button className="clear-all-btn" onClick={() => handleClose()}>Clear All</button>
                        {betSlipData.stake > 0 ?
                            <button className="place-bet-btn" onClick={() => placeBet()}>Place Bet</button>
                            :
                            <button className="place-bet-btn">Place Bet</button>
                        }
                    </div>
                </div>
            </div>
        </div >
    );
}

