import React, { useRef, useEffect, useState } from 'react';
import "./Navbar.css";
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GiHamburgerMenu } from 'react-icons/gi'
import { AiOutlinePlus } from 'react-icons/ai';
import { FaUserSecret } from 'react-icons/fa6'
import bethistory from "../../Assets/Images/bethistory.png";
import { AiFillInfoCircle } from "react-icons/ai";
import { FaUserCircle, FaBook, FaGamepad, FaTimes } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { BsPlayFill } from "react-icons/bs";
import { VscGraph } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { betChipsData } from '../../_config/index';
import EditStakeDesktop from '../EditStakeDesktop/EditStakeDesktop';
import RulesNotification from '../RulesNotification/RulesNotification'
import { headerConstent } from './HeaderConstent'
import Dropdown from "react-bootstrap/Dropdown";
import { userActions } from "../../_actions";
import bellicon from "../../Assets/Images/bell-icon.png";
import { HiCurrencyRupee } from "react-icons/hi";
import CasinoCheckModal from '../../components/CasinoCheckModal/CasinoCheckModal';
import { httpPost } from '../../_helpers/http';
import rupee from '../../Assets/Images/rupeeImg.png'

const Header = (props) => {

  // const [cart, setCart] = useState({});

  // updateCoin => {
  //   async function init() {
  //     const loginData = await localStorage.getItem('spuser');
  //     const afterApiData = await localStorage.getItem('finalBalance');
  //     setCart(JSON.parse(...loginData, ...afterApiData));
  //   }
  // }
  // React.useEffect(() => {
  //   window.addEventListener('storage', () => {
  //     // When local storage changes, dump the list to
  // }, [cart])

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShow = () => setShow(true);
  const [stake, setStake] = useState(false);
  // const [clientNotification, setClientNotification] = useState([]);
  // header Dropdown state
  const [showReportDropdown, setShowReportDropdown] = useState(false);
  const [showLiveGamesDropdown, setShowLiveGamesDropdown] = useState(false);
  // end header Dropdown
  const stakehandleClose = () => setStake(false);
  const stakehandleShow = () => setStake(true);
  const [casinoDataModal, setCasinoDataModal] = useState(false);
  const sidebarRef = useRef(null);
  const handleClose = () => {
    setCasinoDataModal(false);
  };
  const handleCasinoOpen = () => {
    setCasinoDataModal(true);
  };

  const onClickMenu = (url) => {
    props.history.push(url)
  }

  const onClickMenutwo = (url) => {
    localStorage.removeItem('spuser');
    localStorage.clear();
    sessionStorage.clear();
    // window.location.href(url)
    props.history.push(url)
  }
  // For edit stak model
  const dispatch = useDispatch();
  const [keyValues1, setKeyValues1] = useState([]);

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('spuser'));
    if (props.history.location.pathname !== "/app/changepassword" && user.data.isPasswordChanged === false) {
      props.history.push("/app/changepassword")
      window.location.reload();
    }
    const betChipsDataItems = JSON.parse(localStorage.getItem('betChipsData'));
    let betChips = {};
    if (betChipsDataItems != null) {
      betChips = betChipsDataItems;
    } else {
      betChips = betChipsData;
    }
    const updatedKeyValues = Object.entries(betChips).map(([key, value]) => ({
      key,
      value: parseInt(value),
    }));

    setKeyValues1(updatedKeyValues);
    window.addEventListener('storage', () => {
    })
    // getNotificationData()
    const intervalIdNotification = setInterval(() => {
      dispatch(userActions.domainSettingByDomainName());
      // getNotificationData();
    }, 300000);

    // try {
    //   let notification = JSON.parse(localStorage.getItem('notification'));
    //   if (!notification || !Array.isArray(notification)) {
    //     console.error("Error fetching notification data:");
    //   }
    //   let clientNotifications = notification && notification.length > 0 ? notification.filter(element => element.for === "cleint" || element.for === "client") : []
    //   setClientNotification(clientNotifications);
    // } catch (error) {
    //   console.error("Error fetching notification data:");
    // }

    return () => clearInterval(intervalIdNotification);


  }, [props.history.location.pathname]);

  useEffect(() => {
    try {
      dispatch(userActions.getUserBalance());
      const intervalId = setInterval(() => {
        dispatch(userActions.getUserBalance());
      }, 5000);
      return () => {
        clearInterval(intervalId);
      };
    } catch (error) {
      console.error("getUserBalance error in header");
    }
  }, [])

  let domainSettingByDomainName = JSON.parse(localStorage.getItem('domainSettingByDomainName'))
  let user = JSON.parse(localStorage.getItem('spuser'));
  let Balance = JSON.parse(localStorage.getItem('Balance'));
  let exposure = JSON.parse(localStorage.getItem('exposure'));

  let clientNotification = domainSettingByDomainName && domainSettingByDomainName.clientNotification ? domainSettingByDomainName.clientNotification : ''


  const toggleReportDropdown = () => {
    setShowReportDropdown(!showReportDropdown);
  };
  const toggleLiveGamesDropdown = () => {
    setShowLiveGamesDropdown(!showLiveGamesDropdown);
  };

  const closeDropdown = () => {
    setShowLiveGamesDropdown(false);
    setShowReportDropdown(false)
  };

  // const getNotificationData = async () => {
  //   let dataFor = {
  //   }

  //   let notificationListResponse = await httpPost('user/notificationList', dataFor);
  //   localStorage.setItem('notification', JSON.stringify(notificationListResponse?.data));
  // };

  console.log(clientNotification, "clientNotification");

  return (

    <>
      {casinoDataModal && (
        <CasinoCheckModal
          handleClose={handleClose}
        />
      )}
      <div className={`${clientNotification && clientNotification ? "headerWrapper" : 'headerWrapperSecond'}`}>
      {/* <div className="headerWrapperSecond"> */}


        <div className="row rules-header-background headerInnerRow h100 mx-0">
          <div className="col px-0 d-flex justify-content-start align-items-center">
            <div className="row mx-0">
              <div className="col-12 d-flex align-items-center gap-2 pe-0">
                <div className='hamburgerWrapper'>
                  <button className="hamburger-menu w-8 h-8" onClick={() => setOpen(!open)}>
                    {open ? (
                      <div className='w-[252px] h-12  marque-background -ml-2 -mt-2 '>
                        <div className=' flex justify-between items-center p-2'>
                          <div className='flex justify-center items-center space-x-1 '>
                            <span><FaUserSecret size={28} className='green-text' /></span>
                            <span className='black-text text-[15px] font-bold'> {user && user.data && user.data.username ? user.data.username : "user"} ({`${(user && user.data && user.data.name ? user.data.name : "Clint")}`})
                            </span>
                          </div>
                          <span> <FaTimes className='black-text' size={20} /></span>
                        </div>
                      </div>
                    ) : (
                      <GiHamburgerMenu className='white-text' size={32} />
                    )}
                  </button>
                </div>
                <div>
                  <Link className='headerMenuPadding' to="/">
                    <img src="/images/vanky-header-logo.png" alt="logo" className="w-[140px] h-[60px]" />
                  </Link>
                </div>
                <div>
                  <div className='position-relative yellowMarequeeWrapper' >

                    {clientNotification && clientNotification !== "" ? (
                      <marquee className="nav-marquee yellowMarquee d-flex align-items-center casino-header-button-background black-text">
                        {React.createElement('div', { dangerouslySetInnerHTML: { __html: clientNotification } })}
                      </marquee>
                    ) : null}

                    {/* {clientNotification && clientNotification.length > 0 && (
                      <marquee className="nav-marquee yellowMarquee d-flex align-items-center casino-header-button-background black-text">
                        {React.createElement('div', { dangerouslySetInnerHTML: { __html: clientNotification && clientNotification[0] && clientNotification[0].text ? clientNotification[0].text : "" } })}
                      </marquee>)} */}
                    <span className="nav-bell-icon bellIcon white-text">
                      <img className="bell-icon" src={bellicon} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto d-flex justify-center items-center">
            <div className="navbar-right">
              <div>
                <span>
                  <img className='rupee-logo white-text invert' src={rupee} alt="" srcset="" />
                  {/* <img src='https://vanky12.com/userfiles/images/wallet-icons.png' alt='' style={{ backgroundColor: 'white' }} /> */}
                  {/* <HiCurrencyRupee size={40} className="rupee-logo white-text" /> */}
                </span>
              </div>
              <div className='md:block hidden'>
                <div className='text-sm flex white-text' style={{ flexDirection: "column" }}>
                  <span className='white-text'>{headerConstent.Main}
                    {Balance && Balance ? Number.parseFloat(Math.abs(Balance)).toFixed(2).replace(/\.?0+$/, "") : 0}
                  </span>
                  <span className='white-text'>{headerConstent.Exposure}
                    <span className='header-red-text'>{" "}{exposure ? Number.parseFloat(Math.abs(exposure)).toFixed(2).replace(/\.?0+$/, "") : "0.00"}
                    </span>
                  </span>
                </div>
              </div>
              <div className='md:hidden block font-extrabold text-[15px]'>
                <div className='flex white-text' style={{ flexDirection: "column" }}>
                  <span className='white-text'>BAL:{" "}
                    {Balance && Balance ? Number.parseFloat(Math.abs(Balance)).toFixed(2).replace(/\.?0+$/, "") : 0}
                    {/* {Balance ? parseInt(Balance).toFixed(2) : 0} */}


                  </span>
                  <span className='header-red-text'>EXPO.:
                    <span className='header-red-text'>{" "}{exposure ? Number.parseFloat(Math.abs(exposure)).toFixed(2).replace(/\.?0+$/, "") : "0.00"}
                    </span>
                  </span>
                </div>
              </div>
              <div style={{ flexDirection: "column" }} className='md:mr-[0.5px] mr-0 flex'>
                <div className='flex justify-end items-end px-2'>
                  <img src={bethistory} alt="user" style={{ width: "30px" }} />
                </div>
                <span className='white-text' style={{ height: "20px" }}>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" className='text-[14px]'>
                      {user && user.data && user.data.username ? user.data.username : "user"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='md:w-64 w-44 sky-blur-colour py-0 space-y-1 md:mt-3 mt-0 '>
                      <Dropdown.Item onClick={() => onClickMenu("/app/changepassword")} className='white-background md:py-2.5 py-0.5 '>{headerConstent.Change_Password}</Dropdown.Item>
                      <Dropdown.Item onClick={() => onClickMenutwo("/login")} className='white-background md:py-2.5 py-0.5'>{headerConstent.Log_out}</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 px-0">

            {clientNotification && clientNotification !== "" ? (
              <marquee attribute_name="welcome" className="mobile-nav-marquee header-red-background uppercase text-[13px] font-bold text-black" >
                {React.createElement('div', { dangerouslySetInnerHTML: { __html: clientNotification } })}
              </marquee >
            ) : null}
            <div className="nav-menu text-[0.775rem]">
              <ul className='black-background white-text'>
                <li> <Link to="/" className="link-css white-text"> <FaHome size={15} /> <span className='flex items-center'>{headerConstent.Dashoard}</span>  </Link> </li>
                <li> <Link to="/" className="link-css white-text"> <BsPlayFill size={20} /> <span className='flex items-center'>{headerConstent.In_play}</span> </Link></li>
                <li><Link to="/app/runningmarketanalysis" className="link-css white-text"> <VscGraph size={15} /> <span className='flex items-center'>{headerConstent.All_Market_Book}</span></Link> </li>
                <li><Link to="/app/Profile" className="link-css white-text"><FaUserCircle size={16} /><span className='flex items-center'>{headerConstent.Profile}</span></Link></li>
                <li><Link className="link-css white-text" to="/app/changepassword"><FaLock size={10} /><span className='flex items-center'>{headerConstent.Password}</span></Link></li>
                <li onClick={handleShow} className="link-css white-text"><AiFillInfoCircle size={16} /><span className='flex items-center'>{headerConstent.Rules}</span></li>
                <li><Link to="/app/MyCommission" className="link-css white-text"><FaBook size={12} /><span className='flex items-center'> {headerConstent.My_Commision}</span></Link></li>
                <li>
                  <div className="dropdown">
                    <div className="dropbtn flex">
                      <FaBook size={14} />
                      <p>{headerConstent.Report}</p>
                    </div>
                    <div className="dropdown-content text-sm border-t-2 border-[#FFC228] whitespace-nowrap">
                      <Link to="/app/accountstatement">{headerConstent.Account_Statement}</Link>
                      <Link to="/app/TotalLedger">{headerConstent.Total_Ledger}</Link>
                      <Link to="/app/profit-loss">{headerConstent.Profit_Loss}</Link>
                      <Link to="/app/my-bets">{headerConstent.Bet_History}</Link>
                      <Link to="/app/LiveBetHistory">{headerConstent.Live_Bet_History}</Link>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="dropdown">
                    <div className="dropbtn"><FaGamepad size={18} /> {headerConstent.Live_Games}
                    </div>
                    <div className="dropdown-content text-sm border-t-2 border-[#FFC228] whitespace-nowrap">
                      <a href='#/app/teenpatti-20/3030'>{headerConstent.TeenPatti_T20}</a>
                      <a href='#/app/teenpatti-one-day/3031'>{headerConstent.TeenPatti_1Day}</a>
                      <a href='#/app/dragon-tiger20/3035'>{headerConstent.Dragon_Tiger}</a>
                      <a href='#/app/amar-akbar-anthony/3056'> {headerConstent.Amar_Akbar_Anthony}</a>
                      <a href='#/app/Lucky7/3032'>{headerConstent.Lucky_7}</a>
                      <a href='#/app/andar-bahar/3043'>{headerConstent.Ander_Bahar}</a>
                      <a href='#/app/dragon-tiger202/3059'>{headerConstent.Dragon_Tiger_2}</a>
                      <a href='#/app/worli/3054'>{headerConstent.Instant_Worli}</a>
                    </div>
                  </div>
                </li>
                <li onClick={stakehandleShow} className="link-css white-text"><FaBook size={12} /><span>{headerConstent.Edit_Stake}</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {open ?
        <div className="nav-menu-mobile dark-grey-light-button h-full !overflow-hidden" ref={sidebarRef}>
          <ul className='linehight mobilescrollView h-full overflow-y-auto pb-[50px] pt[10px]'>
            <li onClick={() => setOpen(false)} className=''> <Link to="/" className="link-css white-text items-center text-[14px] font-semibold leading-normal">  <FaHome size={15} /> <span >{headerConstent.Dashoard}</span>  </Link> </li>
            <li onClick={() => setOpen(false)} className=''> <Link to="/" className="link-css white-text items-center text-[14px] font-semibold leading-normal"> <BsPlayFill size={20} /> <span >{headerConstent.In_play}</span> </Link></li>
            <li onClick={() => setOpen(false)} className=''><Link to="/app/runningmarketanalysis" className="link-css white-text items-center text-[14px] font-semibold leading-normal "> <VscGraph size={15} /> <span >{headerConstent.All_Market_Book}</span></Link> </li>
            <li onClick={() => setOpen(false)} className=''><Link to="/app/Profile" className="link-css white-text items-center text-[14px] font-semibold leading-normal "><FaUserCircle size={16} />{headerConstent.Profile}</Link></li>
            <li onClick={() => setOpen(false)} className=''><Link className="link-css white-text items-center text-[14px] font-semibold leading-normal " to="/app/changepassword"><FaLock size={10} />{headerConstent.Password}</Link></li>
            <li onClick={() => setOpen(false) || setShow(true)} className="link-css white-text items-center text-[14px] font-semibold leading-normal "><AiFillInfoCircle size={16} /><span>{headerConstent.Rules}</span></li>
            <li onClick={() => setOpen(false)} className=''><Link to="/app/MyCommission" className="link-css white-text items-center text-[14px] font-semibold leading-normal"><FaBook size={12} /><span> {headerConstent.My_Commision}</span></Link></li>
            <li className='ml-0.5'>
              <ul className="space-y-2 font-medium">
                <li className={showReportDropdown ? 'active' : ''}>
                  <div onClick={toggleReportDropdown} className={`flex items-center leading-normal text-[14px] ${showReportDropdown ? 'activesss' : ''}`}>
                    <FaBook size={14} style={{ height: "37px", marginLeft: "4px" }} />
                    <span className="flex-1 ml-1 text-left whitespace-nowrap white-text">{headerConstent.Report}</span>
                    <span className={`plus-sign ${showReportDropdown ? 'plus-icon plus-icon-color' : ''}`}><AiOutlinePlus size={14} /></span>
                  </div>
                  {showReportDropdown && (
                    <ul className="nolinehight plus-icon-color">
                      <li onClick={() => setOpen(false)} className=' divide-y divide-slate-200'>
                        <Link onClick={closeDropdown} to="/app/accountstatement" className="flex items-center w-full p-2 white-text transition duration-75  hover:bg-red-200 dark:white-text dark:hover-bg-gray-700 px-4">{headerConstent.Account_Statement}</Link>
                        <Link onClick={closeDropdown} to="/app/TotalLedger" className="flex items-center w-full p-2 white-text transition duration-75 group hover:bg-gray-100 dark:white-text dark:hover-bg-gray-700 px-4">{headerConstent.Total_Ledger}</Link>
                        <Link onClick={closeDropdown} to="/app/profit-loss" className="flex items-center w-full p-2 white-text transition duration-75 group hover:bg-gray-100 dark:white-text dark:hover-bg-gray-700 px-4">{headerConstent.Profit_Loss}</Link>
                        <Link onClick={closeDropdown} to="/app/my-bets" className="flex items-center w-full p-2 white-text transition duration-75 group hover:bg-gray-100 dark:white-text dark:hover-bg-gray-700 px-4">{headerConstent.Bet_History}</Link>
                        <Link onClick={closeDropdown} to="/app/LiveBetHistory" className="flex items-center w-full p-2 white-text transition duration-75 group hover:bg-gray-100 dark:white-text dark:hover-bg-gray-700 px-4">{headerConstent.Live_Bet_History}</Link>
                      </li>

                    </ul>
                  )}
                </li> </ul>
            </li>

            <li className='ml-0.5'>
              <ul className="space-y-2 font-medium">
                <li className={showLiveGamesDropdown ? 'active' : ''}>
                  <div onClick={toggleLiveGamesDropdown} className={`flex items-center leading-normal text-[14px] ${showLiveGamesDropdown ? 'activesss' : ''}`}>
                    <FaGamepad size={18} style={{ height: "37px", marginLeft: "4px" }} />
                    <span className="flex-1 ml-1 text-left whitespace-nowrap white-text">{headerConstent.Live_Games}</span>
                    <span className={`plus-sign ${showLiveGamesDropdown ? 'plus-icon plus-icon-color' : ''}`}><AiOutlinePlus size={14} /></span>
                  </div>
                  {showLiveGamesDropdown && (
                    <ul className="nolinehight plus-icon-color">
                      <li onClick={() => setOpen(false)} className=' divide-y divide-slate-200'>
                        <a onClick={closeDropdown} href='#/app/teenpatti-20/3030' className="flex items-center w-full p-2 white-text transition duration-75  pl-11  hover:bg-red-200 dark:white-text dark:hover-bg-gray-700">{headerConstent.TeenPatti_T20}</a>
                        <a onClick={closeDropdown} href='#/app/teenpatti-one-day/3031' className="flex items-center w-full p-2 white-text transition duration-75  pl-11  hover:bg-red-200 dark:white-text dark:hover-bg-gray-700">{headerConstent.TeenPatti_1Day} </a>
                        <a onClick={closeDropdown} href='#/app/dragon-tiger20/3035' className="flex items-center w-full p-2 white-text transition duration-75  pl-11  hover:bg-red-200 dark:white-text dark:hover-bg-gray-700">{headerConstent.Dragon_Tiger}</a>
                        <a onClick={closeDropdown} href='#/app/amar-akbar-anthony/3056' className="flex items-center w-full p-2 white-text transition duration-75  pl-11  hover:bg-red-200 dark:white-text dark:hover-bg-gray-700">{headerConstent.Amar_Akbar_Anthony}</a>
                        <a onClick={closeDropdown} href='#/app/Lucky7/3032' className="flex items-center w-full p-2 white-text transition duration-75  pl-11  hover:bg-red-200 dark:white-text dark:hover-bg-gray-700">Lucky 7</a>
                        <a onClick={closeDropdown} className='flex items-center w-full p-2 white-text transition duration-75  pl-11  hover:bg-red-200 dark:white-text dark:hover-bg-gray-700' href='#/app/andar-bahar/3043'>{headerConstent.Ander_Bahar}</a>
                        <a onClick={closeDropdown} className='flex items-center w-full p-2 white-text transition duration-75  pl-11  hover:bg-red-200 dark:white-text dark:hover-bg-gray-700' href='#/app/dragon-tiger202/3059'>{headerConstent.Dragon_Tiger_2}</a>
                        <a onClick={closeDropdown} className='flex items-center w-full p-2 white-text transition duration-75  pl-11  hover:bg-red-200 dark:white-text dark:hover-bg-gray-700' href='#/app/worli/3054'>{headerConstent.Instant_Worli}</a>
                      </li>

                    </ul>
                  )}
                </li>
              </ul>
            </li>
            <li onClick={() => setOpen(false) || setStake(true)} className="link-css white-text items-center text-[14px] font-semibold leading-normal"><FaBook size={12} /><span>{headerConstent.Edit_Stake}</span></li>
            {/* <li onClick={() => setOpen(false)}><Link to="/app/CupWinner" className="link-css white-text"><VscGraph size={25} /><span> {headerConstent.IPL_Winner}</span></Link></li> */}
          </ul>
        </div>
        : ""}
      {show ? <RulesNotification handleClose={handleCloseModal} /> : null}
      {stake ? <EditStakeDesktop stakehandleClose={stakehandleClose} handleClose={stakehandleClose} /> : null}
    </>
  );


}


function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}
export default connect(mapStateToProps)(Header);