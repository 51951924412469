import React from 'react';
import { connect } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import "./Casino.css";
import andarbahar from "../..//Assets/Images/anderbahar.jpg";
import anthony from "../../Assets/Images/anthony.jpg";
import teen from "../../Assets/Images/teen.jpg";
import teen20 from "../../Assets/Images/teen20.jpg";
import card32 from "../../Assets/Images/card32.jpg";
import lucky7 from "../../Assets/Images/lucky7.jpg";
import dt20 from "../../Assets/Images/dt20.jpg";
import { Link } from "react-router-dom";
import instaWorli from "../../Assets/Images/instant-worli.png";
import dragontiger2 from "../../Assets/Images/dragon-tiger-2.png"

import Card from "react-bootstrap/Card";
class Casino extends React.Component {

    render() {
        return (
            <div className=''>
                <Sidebar />
                <div className=''>
                    {/* <div className="live-casino-nav">
                        <div className="live-casino">Live Casino</div>
                        <div>
                            <Link to="/app/dashboard">
                                <button className="live-casino-btn">Back</button>
                            </Link>
                        </div>
                    </div> */}
                    <div className="d-flex flex-col px-lg-2 px-0">
                        <div className="row mx-0 w-full">
                            <div className="col-12 px-0">
                                <div className="live-casino-nav m-0 w-full px-2">
                                    <div className="live-casino">Live Casino</div>
                                    <div>
                                        <Link to="/app/dashboard">
                                            <button className="live-casino-btn">Back</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mx-0 mt-1">
                            {/* <div className="col-sm-3 col-6 px-0">
                                <Link to="/app/andar-bahar/3043">
                                    <Card style={{ }}>
                                        <Card.Img variant="top" src={andarbahar} />
                                        <Card.Body>
                                            <Card.Title>Ander Bahar</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div> */}
                            <div className="col-sm-3 col-6 px-0">
                                <Link to="/app/teenpatti-one-day/3031">
                                    <Card style={{ }}>
                                        <Card.Img variant="top" src={teen} />
                                        <Card.Body>
                                            <Card.Title>Teen Patti 1Day</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                            <div className="col-sm-3 col-6 px-0">
                                <Link to="/app/teenpatti-20/3030">
                                    <Card style={{ }}>
                                        <Card.Img variant="top" src={teen20} />
                                        <Card.Body>
                                            <Card.Title>Teen Patti T20</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                            <div className="col-sm-3 col-6 px-0">
                                <Link to="/app/Lucky7/3032">
                                    <Card style={{ }}>
                                        <Card.Img variant="top" src={lucky7} />
                                        <Card.Body>
                                            <Card.Title>Lucky 7</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                            <div className="col-sm-3 col-6 px-0">
                                <Link to="/app/dragon-tiger20/3035">
                                    <Card style={{ }}>
                                        <Card.Img variant="top" src={dt20} />
                                        <Card.Body>
                                            <Card.Title>Dragon Tiger T20</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                            <div className="col-sm-3 col-6 px-0">
                                <Link to="/app/dragon-tiger202/3059">
                                    <Card style={{ }}>
                                        <Card.Img variant="top" src={dragontiger2} />
                                        <Card.Body>
                                            <Card.Title>Dragon Tiger 2</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                            <div className="col-sm-3 col-6 px-0">
                                <Link to="/app/amar-akbar-anthony/3056">
                                    <Card style={{ }}>
                                        <Card.Img variant="top" src={anthony} />
                                        <Card.Body>
                                            <Card.Title>Amar Akbar Anthony</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                            <div className="col-sm-3 col-6 px-0">
                                <Link to="/app/worli/3054">
                                    <Card style={{ }}>
                                        <Card.Img variant="top" src={instaWorli} />
                                        <Card.Body>
                                            <Card.Title>Instant Worli</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                        </div>
                        {/* <Link to="/app/card32a">
                            <Card style={{ }}>
                                <Card.Img variant="top" src={card32} />
                                <Card.Body>
                                    <Card.Title>32Card A</Card.Title>
                                </Card.Body>
                            </Card>
                        </Link> */}
                    </div>
                </div>
            </div>


        );
    }
}

function mapStateToProps(state) {
    const { users } = state;
    return {
        users
    };
}

export default connect(mapStateToProps)(Casino);