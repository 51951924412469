
import "./AccountStartment.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpPost } from "../../_helpers/http";
import moment from 'moment';
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";

class AccountStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsTransction: {},
      errors: {},
      AccountType: "",
      userAccountDetails: {},
      endDate: moment().format('YYYY-MM-DD'),
      startDate: moment().subtract(2, 'days').format('YYYY-MM-DD'),
      currentDate: moment().format('YYYY-MM-DD'),
      currentPage: 1,
      currentPagePl: 1,
      currentPageAccount: 1,
      itemsPerPage: 10,
      errorType: "",
      showAlert: false,
      resMessage: "",
      isFetch: false,
      activeTab: 1,
    }
  }

  componentDidMount() {
    this.getUserAccountDetails();
  }

  inputChangeTransction = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsTransction = this.state.fieldsTransction;
    let errors = this.state.errors;
    fieldsTransction[name] = value;
    errors[name] = "";
    this.setState({ fieldsTransction, errors });
  }


  SubmitAccountDetailssearch = () => {
    this.getUserAccountDetails();
  }

  inputChangeStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  }

  inputChangeEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  }

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  getUserAccountDetails = async () => {
    let localData = JSON.parse(localStorage.getItem('spuser'));
    let userData = localData.data;
    let reqData = {
      "downlineUserId": userData.userId,
      "fromDate": this.state.startDate,
      "toDate": this.state.endDate,
      "AccountType": this.state.fieldsTransction.AccountType
    }
    this.setState({ isFetch: true })
    let userAccountResponse = await httpPost('user/userStatement', reqData);
    this.setState({ userAccountDetails: userAccountResponse && userAccountResponse.data ? userAccountResponse.data : {} });
    if (userAccountResponse) {
      // this.setState({ showAlert: true, errorType: userAccountDetails.code, resMessage: userAccountDetails.message, userAccountDetails: userAccountDetails.data });
    } else {
      this.setState({ showAlert: true, errorType: 1, resMessage: "Something went wrong" });
    }
    this.setState({ isFetch: false });
    setTimeout(() => this.setState({ showAlert: false }), 2000);
  }
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };
  handlePageChangeProfitLoss = (page) => {
    this.setState({ currentPagePl: page });
  };
  handlePageChangeAccount = (page) => {
    this.setState({ currentPageAccount: page });
  };
  handleRefreshClick = () => {
    window.location.reload();
  };

  render() {
    let { userAccountDetails, resMessage, errorType, showAlert, isFetch, activeTab } = this.state;

    const { currentPage, itemsPerPage, currentPagePl, currentPageAccount } = this.state;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const startIndexPl = (currentPagePl - 1) * itemsPerPage;
    const endIndexPl = startIndexPl + itemsPerPage;
    const startIndexAccount = (currentPageAccount - 1) * itemsPerPage;
    const endIndexAccount = startIndexAccount + itemsPerPage;
    const userAccountDetailsArray = Object.values(userAccountDetails);

    // AccountStatement filter
    let filteredData = userAccountDetailsArray.filter(item => item.statementFor === 'ACCOUNT_STATEMENT');
    let totalAmount = 0;
    filteredData.forEach((data, key) => {
      totalAmount += data.amount
    })
    let balance = 0;
    let showAmount = 0;

    let finalData = filteredData.map((data, key) => {
      balance = totalAmount - showAmount;
      showAmount += data.amount;
      const pushObj = {
        amount: data.amount,
        balance: balance,
        gameType: data.gameType,
        remark: data.remark,
        userRemark: data.userRemark,
        statementFor: data.statementFor,
        isComm: data.isComm,
        marketId: data.marketId,
        createdAt: data.createdAt,
      };
      return pushObj;
    });
    let displayedData = finalData.slice(startIndexAccount, endIndexAccount);
    const totalPages = Math.ceil((finalData && finalData.length) / itemsPerPage);


    // P&L filter
    let filteredDataProfitLoss = userAccountDetailsArray.filter(item => item.statementFor !== 'ACCOUNT_STATEMENT');
    filteredDataProfitLoss.forEach((data, key) => {
      totalAmount += data.amount
    })

    let finalDataProfitLoss = filteredDataProfitLoss.map((data, key) => {
      balance = totalAmount - showAmount;
      showAmount += data.amount;
      const pushObj = {
        amount: data.amount,
        balance: balance,
        gameType: data.gameType,
        remark: data.remark,
        userRemark: data.userRemark,
        statementFor: data.statementFor,
        isComm: data.isComm,
        marketId: data.marketId,
        createdAt: data.createdAt,
      };
      return pushObj;
    });
    let displayedDataProfitLoss = finalDataProfitLoss.slice(startIndexPl, endIndexPl);
    const totalPagesProfitLoss = Math.ceil((finalDataProfitLoss && finalDataProfitLoss.length) / itemsPerPage);

    // AllData filter
    let filteredDataAll = userAccountDetailsArray.filter(item => item.statementFor !== 'ALL');
    filteredDataAll.forEach((data, key) => {
      totalAmount += data.amount
    })

    let finalDataAll = filteredDataAll.map((data, key) => {
      balance = totalAmount - showAmount;
      showAmount += data.amount;
      const pushObj = {
        amount: data.amount,
        balance: balance,
        gameType: data.gameType,
        remark: data.remark,
        userRemark: data.userRemark,
        statementFor: data.statementFor,
        isComm: data.isComm,
        marketId: data.marketId,
        createdAt: data.createdAt,
      };
      return pushObj;
    });
    let displayedDataAll = finalDataAll.slice(startIndex, endIndex);
    const totalPagesAll = Math.ceil((finalDataAll && finalDataAll.length) / itemsPerPage);

    return (
      <div className="d-flex w-100">
        {isFetch === true ?
          <Loader />
          : null}
        {showAlert && <div className={`absolute top-[2%] right-[2%] px-5 py-3 ${errorType === 1 ? "bg-red-600" : "bg-green-600"}  rounded`}><span className='white-text font-bold'>{resMessage}</span></div>}
        <Sidebar props={this.props} />
        <div className="w-100">
          <div className="cricket-icon-color flex justify-between items-center">
            <div className=" w-full p-[4px] white-text font-normal text-base" >ACCOUNT STATEMENT</div>
            <button className="back-btn" onClick={() => this.props.history.goBack()}>Back</button>
          </div>
          <div className="flex lg:flex-row flex-col justify-start items-start lg:space-x-5 space-x-0 lg:space-y-0 space-y-2 py-2 lg:px-1 px-3">
            <input type="date" className="px-2 lg:py-1.5 py-2 lg:w-52 w-full text-[12px] white-background border-[1px] border-[#007bff] focus:outline-none text-grey placeholder:text-gray-500 rounded-[4px]"
              name="Date"
              dateformat="yyyy-mm-dd"
              id="datepicker" required
              value={this.state.startDate} onChange={this.inputChangeStartDate}
            />

            <input type="date" className="px-2 lg:py-1.5 py-2 lg:w-52 w-full text-[12px] white-background border-[1px] border-[#007bff]  focus:outline-none text-grey placeholder:text-gray-500 rounded-[4px]"
              name="Date"
              dateformat="yyyy-mm-dd"
              id="datepicker" required
              value={this.state.endDate} onChange={this.inputChangeEndDate}
            />
            <div className="grid xl:grid-cols-6 grid-cols-3 gap-[3px]">
              <button onClick={this.SubmitAccountDetailssearch || this.handleTabClick(1)} className="bg-[#5CB85C] hover:bg-[#449d44] text-white lg:px-6 px-10 text-[14px] py-1.5 rounded-[4px]">Search</button>
              <button onClick={this.handleRefreshClick} className="bg-[#d43f3a] hover:bg-[#c9302c] text-white lg:px-6 px-10 text-[14px] py-1.5 rounded-[4px]">Reset</button>
              <button onClick={() => this.handleTabClick(1)} className="bg-[#e2a521] hover:bg-[#337ab7] text-black hover:text-white lg:px-6 px-10 text-[14px] py-1.5 rounded-[4px]">ALL</button>
              <button onClick={() => this.handleTabClick(2)} className="bg-[#5CB85C] hover:bg-[#449d44] text-white lg:px-6 px-10 text-[14px] py-1.5 rounded-[4px]">P&L</button>
              <button onClick={this.handleRefreshClick} className="bg-[#d43f3a] hover:bg-[#c9302c] text-white lg:px-6 px-8 text-[14px] py-1.5 rounded-[4px]">PDC</button>
              {/* <button className="pdc-btn">PDC</button> */}
              <button onClick={() => this.handleTabClick(3)} className="bg-[#EFEFEF] text-black lg:px-6 px-10 text-[14px] py-1.5 rounded-[4px]">Account</button>
            </div>
          </div>
          <hr className="text-gray-400" />
          <div className="px-2">
            {activeTab === 1 ? (<>  
              <div className='w-full table-responsive pt-3 bg-transparent'>
                <table className="w-full border-none text-start table table-striped">
                  <thead className="rounded-t bg-[#DCDCDC]">
                    <tr className='text-[#252525] text-[14px] font-normal'>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%]">Date</th>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[52%]">Description</th>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%]">Bet Type</th>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[6%]">CR</th>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[6%]">DR</th>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%]">Balance</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {displayedDataAll && displayedDataAll.length > 0 ? displayedDataAll.map((element, index) => (
                      <tr key={index} className="bg-transparent text-[#252525] text-[13px] font-normal">
                        <td className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%] text-[16px]">{moment(element.createdAt).utcOffset("+05:30").format("DD MMM YYYY h:mm a")} </td>
                        <td className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[52%] text-[16px]">{element.remark}</td>
                        <td className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%] text-[16px]">{element.statementFor}</td>
                        <td className='whitespace-nowrap border-[1px] border-[#b9b9b9] w-[6%] text-[16px]'>  {element && element.amount > 0 ?
                          <div className="text-green-600">
                            {Number.parseFloat(Math.abs(element.amount)).toFixed(2).replace(/\.?0+$/, "")}
                          </div>
                          : 0
                        }</td>
                        <td className="border-[1px] border-[#b9b9b9] w-[6%] text-[16px]">{element && element.amount < 0 ?
                          <div className="text-red-500">
                            {Number.parseFloat(Math.abs(element.amount)).toFixed(2).replace(/\.?0+$/, "")}
                          </div>
                          : 0
                        }</td>
                        <td className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%] text-[16px]">
                          {Number.parseFloat(Math.abs(element.balance)).toFixed(2).replace(/\.?0+$/, "")}
                        </td>
                      </tr>
                    )) : <tr className="bg-transparent text-[#252525] text-[12px] font-normal">
                      <td className="whitespace-nowrap flex justify-start items-center w-full border-[1px] border-[#b9b9b9]" colSpan={6}>No Record Found</td>
                    </tr>
                    }

                  </tbody>
                </table>
              </div>
              <div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPagesAll}
                    maxVisibleButtons={1}
                    onPageChanged={this.handlePageChange}
                  />
              </div>
            </>) : null}
            {activeTab === 2 ? (<>
              <div className='w-full table-responsive py-3  bg-transparent'>
                <table className="w-full border-none text-start table table-striped">
                  <thead className="rounded-t bg-[#DCDCDC]">
                    <tr className='text-[#252525] text-[12px] font-normal'>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%]">Date</th>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[52%]">Description</th>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%]">Bet Type</th>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[6%]">CR</th>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[6%]">DR</th>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%]">Balance</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {displayedDataProfitLoss && displayedDataProfitLoss.length > 0 ? displayedDataProfitLoss.map((element, index) => (
                      <tr key={index} className="bg-transparent text-[#252525] text-[13px] font-normal">
                        <td className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%]">{moment(element.createdAt).utcOffset("+05:30").format("DD MMM YYYY h:mm a")} </td>
                        <td className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[52%]">{element.remark}</td>
                        <td className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%]">{element.statementFor}</td>
                        <td className='whitespace-nowrap border-[1px] border-[#b9b9b9] w-[6%]'>  {element && element.amount > 0 ?
                          <div className="text-green-600">
                            {Number.parseFloat(Math.abs(element.amount)).toFixed(2).replace(/\.?0+$/, "")}
                          </div>
                          : 0
                        }</td>
                        <td className="border-[1px] border-[#b9b9b9] w-[6%]">{element && element.amount < 0 ?
                          <div className="text-red-500">
                            {Number.parseFloat(Math.abs(element.amount)).toFixed(2).replace(/\.?0+$/, "")}
                          </div>
                          : 0
                        }</td>
                        <td className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%]">
                          {Number.parseFloat(Math.abs(element.balance)).toFixed(2).replace(/\.?0+$/, "")}
                        </td>
                      </tr>
                    )) : <tr className="bg-transparent text-[#252525] text-[12px] font-normal">
                      <td className="whitespace-nowrap flex justify-start items-center w-full border-[1px] border-[#b9b9b9]" colSpan={6}>No Record Found</td>
                    </tr>
                    }

                  </tbody>
                </table>
              </div>
              <div>
                <Pagination
                    currentPage={currentPagePl}
                    totalPages={totalPagesProfitLoss}
                    maxVisibleButtons={1}
                    onPageChanged={this.handlePageChangeProfitLoss}
                  />
              </div>

            </>) : null}
            {activeTab === 3 ? (<>
              <div className='w-full table-responsive py-3  bg-transparent'>
                <table className="w-full border-none text-start table table-striped">
                  <thead className="rounded-t bg-[#DCDCDC]">
                    <tr className='text-[#252525] text-[12px] font-normal'>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%]">Date</th>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[52%]">Description</th>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%]">Bet Type</th>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[6%]">CR</th>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[6%]">DR</th>
                      <th className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%]">Balance</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {displayedData && displayedData.length > 0 ? displayedData.map((element, index) => (
                      <tr key={index} className="bg-transparent text-[#252525] text-[13px] font-normal">
                        <td className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%]">{moment(element.createdAt).utcOffset("+05:30").format("DD MMM YYYY h:mm a")} </td>
                        <td className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[52%]">{element.remark}</td>
                        <td className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%]">{element.statementFor}</td>
                        <td className='whitespace-nowrap border-[1px] border-[#b9b9b9] w-[6%]'>  {element && element.amount > 0 ?
                          <div className="text-green-600">
                            {Number.parseFloat(Math.abs(element.amount)).toFixed(2).replace(/\.?0+$/, "")}
                          </div>
                          : 0
                        }</td>
                        <td className="border-[1px] border-[#b9b9b9] w-[6%]">{element && element.amount < 0 ?
                          <div className="text-red-500">
                            {Number.parseFloat(Math.abs(element.amount)).toFixed(2).replace(/\.?0+$/, "")}
                          </div>
                          : 0
                        }</td>
                        <td className="whitespace-nowrap border-[1px] border-[#b9b9b9] w-[12%]">
                          {Number.parseFloat(Math.abs(element.balance)).toFixed(2).replace(/\.?0+$/, "")}
                        </td>
                      </tr>
                    )) : <tr className="bg-transparent text-[#252525] text-[12px] font-normal">
                      <td className="whitespace-nowrap flex justify-start items-center w-full border-[1px] border-[#b9b9b9]" colSpan={6}>No Record Found</td>
                    </tr>
                    }

                  </tbody>
                </table>
                <div>
                  <Pagination
                    currentPage={currentPageAccount}
                    totalPages={totalPages}
                    maxVisibleButtons={1}
                    onPageChanged={this.handlePageChangeAccount}
                  />
                </div>
              </div>

            </>) : null}
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}
export default connect(mapStateToProps)(AccountStatement);