export const headerConstent = {
    WELCOME_TO: 'WELCOME TO THE VANKY.COM',
    Main: 'Main: ',
    Exposure : 'Exposure :',
    Change_Password: 'Change Password',
    Log_out: 'Log out',
    Dashoard: 'Dashoard',
    In_play: 'In-play',
    All_Market_Book: 'All Market Book',
    Profile: 'Profile',
    Password: 'Password',
    Rules: 'Rules',
    My_Commision: 'My Commision',
    Report: 'Report',
    Account_Statement: 'Account Statement',
    Total_Ledger:'Total Ledger',
    Profit_Loss: 'Profit & Loss',
    Bet_History:'Bet History',
    Live_Bet_History:'Casino Bet History',
    Live_Games:'Live Games',
    TeenPatti_T20: 'TeenPatti T20',
    TeenPatti_1Day: 'TeenPatti 1Day',
    AndarBahar: 'Andar Bahar',
    Dragon_Tiger: 'Dragon Tiger',
    Cards_32: '32 Cards',
    Amar_Akbar_Anthony: 'Amar Akbar Anthony',
    Search_Result: 'Search Result',
    Edit_Stake: 'Edit Stake',
    IPL_Winner: 'IPL Winner',
    Lucky_7: 'Lucky 7',
    Ander_Bahar: 'Ander Bahar',
    Dragon_Tiger_2: 'Dragon tiger 2',
    Instant_Worli: 'Instant Worli',
}