import React, { useState } from 'react';
import { CASINOPRICE } from '../../_config';
import { httpPost } from "../../_helpers/http";
import CommingSoon from '../CommingSoon/CommingSoon';
import Loader from "../../components/Loader/Loader";
import { useHistory } from 'react-router-dom';


export default function CasinoCheckModal(props) {
    const { handleClose } = props;
    const [loading, setLoading] = useState(false);
    const [casinoData, setCasinoData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [comming, setComming] = useState(false);
    const history = useHistory();

    // const getCasinoData = async () => {
    //     setLoading(true);

    //     try {
    //         let casinoLogin = {
    //             // "gameId": "90000000",
    //             "gameId": "900000",
    //             "platformId": "mobile",
    //             "redirectUrl": "localhost:3000/dashboard"
    //         };

    //         let casinoLoginResponse = await httpPost('user/casinoLoginUrl', casinoLogin);
    //         if (casinoLoginResponse.status !== "OP_GENERAL_ERROR") {
    //             setCasinoData(casinoLoginResponse && casinoLoginResponse.data ? casinoLoginResponse.data : {});
    //         } else {
    //             alert("Something Went Wrong")
    //             setComming(true)
    //         }
    //     } catch (error) {
    //         console.error("Error fetching casino data:", error);
    //         // Handle error, show a user-friendly message, etc.
    //         setShowModal(true); // Set showModal to true in case of an error
    //     } finally {
    //         setLoading(false);
    //         handleClose();
    //     }

    // };

    const onClickMenu = (data) => {
        history.push('/app/iframe-casino/' + data)
        // window.location.reload(true);
    }

    const closeModal = () => {
        setShowModal(false);
    };

    // Check if casinoData is not null, redirect if true, otherwise show the modal
    if (casinoData !== null) {
        // Assuming you have a way to redirect, replace the placeholder URL with the actual URL
        const redirectUrl = (casinoData.url);
        window.open(redirectUrl, '_balnk');
    }
    return (
        <>
            {comming && <CommingSoon handleClose={closeModal}
                message="You Are Not Authorized for the Play Casino Games" />}
            <div className=' fixed w-full h-full top-0 z-40 overflow-hidden mt-0 pt-4 flex justify-center items-start overflow-y-auto bg-black/40 md:p-0 p-1 right-0'>
                {loading === true ?
                    <Loader />
                    : null}
                <div className=" bg-white xl:w-[45rem]  md:w-[32rem] w-full  mx-auto rounded shadow-lg  overflow-y-auto xl:mt-24 mt-20 top-2">
                    {/*modal header*/}
                    <div className=''>
                        <div className="flex items-center justify-center p-6 py-2 ">
                            <img src='/images/casino-imgage.png' alt='casino-image' className="md:h-44 h-32" />
                        </div>

                        <div className='text-center md:space-y-2 space-y-0.5'>
                            <div className='text-base  text-[#0088CC] font-bold '>
                                Please Note
                            </div>
                            <div className='md:text-2xl text-base text-black '>
                                (1 Points = ₹{CASINOPRICE.PRICE})
                            </div>
                            <div className=' md:text-lg text-sm text-black px-4 '>
                                <span className='text-[#0088CC]'>For Example:</span> If you place ₹{CASINOPRICE.AMOUNT} your bet will be ₹{CASINOPRICE.AMOUNT * CASINOPRICE.PRICE} Win or Loss according to the above calculation. If your balance is ₹{CASINOPRICE.AMOUNT * CASINOPRICE.PRICE} then it will show ₹{CASINOPRICE.AMOUNT} in the casino and your bet of ₹{CASINOPRICE.AMOUNT} rupee in the casino will be considered as ₹{CASINOPRICE.AMOUNT * CASINOPRICE.PRICE} rupees.<br />
                                यदि आप ₹{CASINOPRICE.AMOUNT} लगाते हैं तो उपरोक्त गणना के अनुसार आपकी शर्त जीत या हार ₹ {CASINOPRICE.AMOUNT * CASINOPRICE.PRICE} होगी। यदि आपका बैलेंस ₹{CASINOPRICE.AMOUNT * CASINOPRICE.PRICE} है तो कैसीनो में यह ₹{CASINOPRICE.AMOUNT} दिखाएगा और कैसीनो में आपकी ₹{CASINOPRICE.AMOUNT} रुपये की शर्त ₹{CASINOPRICE.AMOUNT * CASINOPRICE.PRICE} रुपये मानी जाएगी।
                            </div>
                        </div>
                        <div className='flex justify-center items-center space-x-2 p-3'>
                            <button onClick={() => {onClickMenu(900000); handleClose()}} disabled={loading} className="w-full text-white px-3 py-1.5 flex justify-center items-center cursor-pointer  bg-[#183F45] font-semibold rounded-none md:text-base text-sm" type="submit">
                                <span> Ok I Agree</span>
                            </button>
                            <button onClick={handleClose} className="w-full text-white px-3 py-1.5 flex justify-center items-center cursor-pointer  bg-[#951D1B] font-semibold rounded-none md:text-base text-sm" type="submit">
                                <span>No, I Don't Agree</span>
                            </button>
                            {showModal && <CommingSoon handleClose={closeModal}
                                message="You Are Not Authorized for the Play Casino Games" />}
                        </div>
                    </div>

                </div>
            </div >
        </>
    );
}

