import {
  userConstants
} from '../_constants';

export function users(state = {}, action) {

  switch (action.type) {

    case userConstants.USER_BALANCE_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case userConstants.USER_BALANCE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        getUserBalanceItem: action.users.getUserBalance,
      };
    case userConstants.USER_BALANCE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.USER_ACCOUNT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        userAccountDetailsItems: action.users.userAccountDetails,
      };
    case userConstants.USER_ACCOUNT_DETAILS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.USER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_UPDATE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
      };
    case userConstants.USER_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case userConstants.GET_MATCH_LIST_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case userConstants.GET_MATCH_LIST_SUCCESS:
      return {
        ...state,
        // loading: false,
        getMatchDetails: action.users.getMatchList.data,
        oddsBetData: Array.isArray(action.users.oddsBetData) ? action.users.oddsBetData : [],
        fancyBetData: Array.isArray(action.users.fancyBetData) ? action.users.fancyBetData : [],
        inplayMatchList: Array.isArray(action.users.inplayMatchList) ? action.users.inplayMatchList : [],
        ScoreIframe: action.users.ScoreIframe,
        tvUrl: action.users.tvUrl,
        matchPosition: action.users.matchPosition,
      };

    case userConstants.GET_MATCH_LIST_ADD_SUCCESS:
      return {
        ...state,
        // loading: false,
        oddsBetData: action.users.oddsBetData,
      };
    case userConstants.GET_MATCH_LIST_ADD_FANCY_SUCCESS:
      return {
        ...state,
        loading: false,
        fancyBetData: action.users.fancyBetData,
      };
    case userConstants.GET_MATCH_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.MATCH_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.MATCH_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        matchlistItems: action.users.MatchList
        // userParkingTotal: action.users.getUserParkingList.total
      };
    case userConstants.MATCH_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.CASINO_GAME_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CASINO_GAME_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        casinogamelistItems: action.users.casinoGameList
        // userParkingTotal: action.users.getUserParkingList.total
      };
    case userConstants.CASINO_GAME_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_MATCH_LIST_ADD_CASINO_SUCCESS:
      return {
        ...state,
        loading: false,
        casinogamelistItems: action.users.casinoGameList
      };

    case userConstants.CASINO_LOGIN_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case userConstants.CASINO_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        casinoLoginData: action.users.casinoLogin,
      };
    case userConstants.CASINO_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.DOMAIN_SETTING_BY_DOMAIN_NAME_REQUEST:
      return {
        ...state,
      };
    case userConstants.DOMAIN_SETTING_BY_DOMAIN_NAME_SUCCESS:
      return {
        ...state,
        domainSettingByDomainNameData: action.users.domainSettingByDomainName,
      };
    case userConstants.DOMAIN_SETTING_BY_DOMAIN_NAME_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.SAVE_BET_CASINO_REQUEST:
      return {
        ...state,
        loading: true,
        // betStatus: false,
      };
    case userConstants.SAVE_BET_CASINO_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: true,
        // betStatus: true,
        // errorData: action.users.errorData,
        savebetItems: action.users.saveBetCasino,
        // responseDataItem: [action.users.responseData],
        //responseDataItem: action.users.responseData,

      };
    case userConstants.SAVE_BET_CASINO_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        // betStatus: false,
      };

    case userConstants.SAVE_BET_REQUEST:
      return {
        ...state,
        loading: true,
        betStatus: false,
      };
    case userConstants.SAVE_BET_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: true,
        betStatus: true,
        errorData: action.users.errorData,
        savebetItems: action.users.save_bet,
        responseDataItem: [action.users.responseData],

      };
    case userConstants.SAVE_BET_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        betStatus: false,
      };

    case userConstants.SAVE_BET_FANCY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SAVE_BET_FANCY_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: true,
        errorDataFancy: action.users.errorDataFancy,
        savebetItemsFancy: action.users.save_betFancy,
        responseDataItemFancy: [action.users.responseDataFancy],
      };
    case userConstants.SAVE_BET_FANCY_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case userConstants.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        addUserSuccess: false
      };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state
  }
}