import React, { useEffect, useState } from 'react';
import "./SportsNavigationTab.css";
import { Link } from "react-router-dom";
import CommingSoon from '../../components/CommingSoon/CommingSoon';
import CasinoCheckModal from '../../components/CasinoCheckModal/CasinoCheckModal';
import AviatorCheckModal from '../../components/AviatorCheckModal/AviatorCheckModal';
import CasinoModal from '../../components/CasinoModal/CasinoModal';
import { useHistory } from 'react-router';


export default function SportsNavigationTab(props) {

    const history = useHistory()
    const [userData, setUserData] = useState(null);
    const [commingSoonModal, setCommingSoonModal] = useState(false);
    const [casinoDataModal, setCasinoDataModal] = useState(false);
    const [casinoModal, setCasinoModal] = useState(false);
    const [aviatorDataModal, setAviatorDataModal] = useState(false);
    const [casinoData, setCasinoData] = useState(null);


    const handleClose = () => {
        setCommingSoonModal(false);
        setCasinoDataModal(false);
        setAviatorDataModal(false);
        setCasinoModal(false);
    };

    const handleModalOpen = () => {
        setCommingSoonModal(true);
    };
    const handleCasinoOpen = () => {
        setCasinoDataModal(true);
    };
    const handleCasinoModalOpen = () => {
        setCasinoModal(true);
    };

    const handleAviatorOpen = () => {
        setAviatorDataModal(true);
    };

    // const dispatch = useDispatch();
    useEffect(() => {
        // getCasinoData();
        const fetchData = () => {
            let user = JSON.parse(localStorage.getItem('spuser'));
            setUserData(user);
        };
        const timerId = setTimeout(fetchData, 500);
        return () => clearTimeout(timerId);
        // dispatch(userActions.getUserBalance());
        // const timerId = setTimeout(() => {
        //     getCasinoData();
        // }, 5000);

        // return () => clearTimeout(timerId);
        // const intervalId = setInterval(() => {
        //     getCasinoData();
        // }, 2000);
        // return () => clearInterval(intervalId);
    }, []);
    // const getCasinoData = async () => {
    //     let casinoLogin = {
    //         "gameId": "900000",
    //         "platformId": "mobile",
    //         "redirectUrl": "localhost:3000\/dashboard"
    //     }

    //     let casinoLoginResponse = await httpPost('user/casinoLoginUrl', casinoLogin);
    //     // setCasinoData(casinoLoginResponse && casinoLoginResponse.data ? casinoLoginResponse.data : {});
    // };


    const onClickMenu1 = (data) => {
        history.push('/app/casino')
        // window.location.reload(true);
    }

    if (casinoData !== null) {
        // Assuming you have a way to redirect, replace the placeholder URL with the actual URL
        const redirectUrl = (casinoData.url);
        window.open(redirectUrl, '_self');
    }

    const { activeTab, handleTabClick } = props;
    return (
        <div className=" pb-1">
            {casinoDataModal && (
                <CasinoCheckModal
                    handleClose={handleClose}
                />
            )}
            {aviatorDataModal && (
                <AviatorCheckModal
                    handleClose={handleClose}
                />
            )}
            {commingSoonModal && (
                <CommingSoon
                    handleClose={handleClose}
                    // message="You Are Not Authorized to Play Please Connect With Your Upline"
                    message="You Are Not Authorized to Play Please Connect With Your Upline"

                />
            )}

            {casinoModal && (
                <CasinoModal handleClose={handleClose} />
            )}
            <div className=" flex md:bg-white  bg-[#1e1e1e] pt-1.5 ps-1 ps-md-0 overflow-x-auto whitespace-wrap w-full cursor-pointer border-b-0 md:border-b-4 border-[#FFC21C] h-[56px] mt-3 mt-md-0">

                <div className={`${activeTab === 1 ? "cricket-game green-button white-text lg:ml-0 ml-0 " : "soccer-game dark-green-button  white-text "} flex justify-center items-center space-x-1 border-r-[4px] border-black`} onClick={() => handleTabClick(1)}>
                    {/* <img className=' ' src='/images/cricket-ball.png' alt='Cricket-logo' width={25} /> */}
                    <span className='svgcricket'></span>
                    <span className='flex justify-center items-center text-[16px] tracking-wide leading-[1px]'>Cricket</span>
                </div>

                <div className={`${activeTab === 2 ? "cricket-game green-button white-text lg:ml-0 ml-0" : "soccer-game dark-green-button white-text"} flex justify-center items-center space-x-1 border-r-[4px] border-black`} onClick={() => handleTabClick(2)}>
                    {/* <img className='' src='/image/soccer.svg' alt='Soccer-logo' width={25} /> */}
                    <span className='svgsoccer'></span>
                    <span className='flex justify-center items-center text-[16px] tracking-wide leading-[1px]'>Soccer</span>
                </div>
                {/* <div className={`${activeTab === 2 ? "cricket-game green-button white-text " : "soccer-game dark-green-button white-text"}`} onClick={() => handleTabClick(2)}>Soccer</div> */}
                

                <div className={`${activeTab === 3 ? "cricket-game green-button white-text" : "soccer-game dark-green-button white-text"} flex justify-center items-center space-x-1 border-r-[4px] border-black`} onClick={() => handleTabClick(3)}>
                    <span className='svgtennis'></span>
                    <span className='flex justify-center items-center text-[16px] tracking-wide leading-[1px]'>Tennis</span>
                </div>

                {userData && userData.data && userData.data.casinoStatus === true ?
                    // onClick={() => onClickMenu1(3031) || handleClose()}
                    <div onClick={() => onClickMenu1()} className='casino-game dark-green-button animationdd text-white flex justify-center items-center space-x-1 border-r-[4px] border-[#3b5160]'>
                        {/* <img className='' src='/images/footer-menu-casino.svg' alt='Casino-logo' width={25} /> */}
                        <span className='svgcasino'></span>
                        <span className='flex justify-center items-center text-[16px] tracking-wide leading-[1px] blink'>Casino</span>
                    </div>
                    :
                    <div onClick={handleModalOpen} className='casino-game dark-green-button animationdd text-white flex justify-center items-center space-x-1 border-r-[4px] border-black'>
                        {/* <img className='' src='/images/footer-menu-casino.svg' alt='Casino-logo' width={25} /> */}
                        <span className='svgcasino'></span>
                        <span className='flex justify-center items-center text-[16px] tracking-wide leading-[1px]'>Casino</span>
                    </div>
                }
                {/* {
                    userData && userData.data && userData.data.intCasinoStatus === true ?
                        <div className="casino-game dark-green-button">
                            <div onClick={handleCasinoOpen}>
                                <span className="animationdd text-white gap-2"> <img className='w-[18px] m-auto' src='/images/RGCasino.webp' alt='avitor-logo' width={40}/> 
                                <span className=''>RG Casino</span></span>
                            </div>
                        </div>
                        :
                        <div className="casino-game dark-green-button">
                            <div onClick={handleModalOpen}>
                                <span className="animationdd text-white gap-2"><img className='w-[18px] m-auto' src='/images/RGCasino.webp' alt='avitor-logo' width={40} />
                                <span className=''>RG Casino</span></span>
                            </div>
                        </div>
                } */}
                {/* {
                    userData && userData.data && userData.data.intCasinoStatus === true ?
                        <div className="casino-game dark-green-button">
                            <div onClick={handleAviatorOpen}>

                                <span className="animationdd gap-2 text-white"> <img className='md:w-[32px] w-[36px] m-auto' src='/images/aviator.png' alt='avitor-logo' /> Avitor
                                </span>
                            </div>
                        </div>
                        :
                        <div className="casino-game dark-green-button">
                            <div onClick={handleModalOpen}>
                                <span className="animationdd text-white gap-2"> <img className='md:w-[32px] w-[36px] m-auto' src='/images/aviator.png' alt='avitor-logo' /> Avitor</span>
                            </div>
                        </div>
                } */}
                {/* <div className="animationdd md:px-0 px-4 whitespace-nowrap casino-game flex justify-center items-center white-text dark-green-button lg:mr-0 ">
                    <Link to="/app/matka" style={{ textDecoration: "none" }} className='flex justify-center items-center space-x-1'>
                        <img className='' src='/images/matka11.png' alt='Matka-logo' width={30} />
                        <span className='flex justify-center items-center text-[16px] tracking-wide leading-[1px]'>Matka</span>
                    </Link>
                </div> */}
            </div>
        </div>
    );
}
