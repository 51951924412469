import React from "react";
import "./Sidebar.css";
import { RiArrowRightDoubleLine } from "react-icons/ri";
import { AiFillCaretDown } from "react-icons/ai";
import { Link } from "react-router-dom";

const Sidebar = (props) => {
  const handleResponseGame = (data) => {
    props.props.history.push('/app/ViewMatchis/' + data.marketId)
  }
  let MatchList = JSON.parse(localStorage.getItem('MatchList'));
  return (
    <div className="left-side-bar02 h-screen overflow-hidden">
      <div className="left-side-bar h-full overflow-y-auto mt-2">
        <div className="sport-left sport-left-color">Sports</div>
        <Link to="/app/dashboard" className="play-left">In-play</Link>

        <div className="cricket-left" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
          Cricket
          <span><AiFillCaretDown /></span>
        </div>
        <div className="collapse" id="collapseExample">
          {MatchList && MatchList.length > 0 && MatchList.filter((element) => (element.sportId === 4)).length > 0 ? MatchList.map((element, index) => (
            <ul key={index} className="white-background">
              <li className="d-flex text-[12px] py-[5px]" onClick={() => handleResponseGame(element)}> <RiArrowRightDoubleLine className="" size={16} />{element.matchName}</li>
            </ul>
          )) : <ul className="white-background">
            <li className="d-flex text-[12px] py-[5px]"> <RiArrowRightDoubleLine className="" size={16} />No Data Found</li>
          </ul>}
        </div>
        <div className="cricket-left" data-bs-toggle="collapse" href="#collapseExampleTennis" role="button" aria-expanded="false" aria-controls="collapseExampleTennis">
          Tennis
          <span><AiFillCaretDown /></span>
        </div>
        <div className="collapse" id="collapseExampleTennis">
          {MatchList && MatchList.length > 0 && MatchList.filter((element) => (element.sportId === 2)).length > 0 ? MatchList.map((element, index) => (
            <ul key={index} className="white-background">
              <li className="d-flex text-[12px] py-[5px]"> <RiArrowRightDoubleLine className="" size={16} />{element.matchName}</li>
            </ul>
          )) : <ul className="white-background">
            <li className="d-flex text-[12px] py-[5px]"> <RiArrowRightDoubleLine className="" size={16} />No Data Found</li>
          </ul>}
        </div>

        <div className="cricket-left" data-bs-toggle="collapse" href="#collapseExampleSoccer" role="button" aria-expanded="false" aria-controls="collapseExampleSoccer">
          Soccer
          <span><AiFillCaretDown /></span>
        </div>
        <div className="collapse" id="collapseExampleSoccer">
          {MatchList && MatchList.length > 0 && MatchList.filter((element) => (element.sportId === 1)).length > 0 ? MatchList.map((element, index) => (
            <ul key={index} className="white-background">
              <li className="d-flex text-[12px] py-[5px]"> <RiArrowRightDoubleLine className="" size={16} />{element.matchName}</li>
            </ul>
          )) : <ul className="white-background">
            <li className="d-flex text-[12px] py-[5px]"> <RiArrowRightDoubleLine className="" size={16} />No Data Found</li>
          </ul>}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;