import React from 'react';
// import "./BetPlaceDesktopCasino.css";
import { betChipsData } from '../../_config';


export default function BetPlaceDesktopCasino(props) {
    const { updateStackOnclick, placeBet, handleClose, betSlipData } = props;
    const myArray = Object.values(betChipsData);


    return (
        <div className='lg:block hidden'>
            <div className=' '>
                <div className="bg-transparent p-2">
                    <div className='flex justify-between items-center'>
                        <div className="text-lg w-full">{betSlipData && betSlipData.name ? betSlipData.name : null}</div>
                        <div className="flex justify-center items-center w-full">
                            <input type="text" className="text-white bg-transparent text-center text-lg border border-gray-50 w-1/2 rounded-sm" placeholder="0" value={betSlipData.odds} />
                        </div>
                        <div className="flex justify-center items-center w-full">
                            <input type="text" className="text-white bg-transparent text-center text-lg border border-gray-50 w-1/2 rounded-0" placeholder="0" value={betSlipData.stake} />
                        </div>
                        <div className="w-full">

                        </div>
                    </div>

                    <div className='row'>
                        {myArray && myArray.length > 0 ? myArray.map((element, index) => (
                            <div key={index} className="col-3 p-2 px-2 justify-center items-center">
                                <button className='bg-[#205D50] w-full px-4 p-2' onClick={() => updateStackOnclick(element)} key={index}>{element}</button>
                            </div>
                        )) : null}
                    </div>
                    <div className='flex justify-between items-center'>
                        <button className="bg-[#FC4242] p-2 px-5 text-lg" onClick={() => handleClose()}>Reset</button>
                        <button className="bg-[#03B37F] p-2 px-5 text-lg" onClick={() => placeBet()}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

