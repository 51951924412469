import React, { Component } from "react";
import "./AllMarketBook.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import { httpPost } from "../../_helpers/http";

class AllMarketBook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userExpoDetails: {}
        }
    }

    componentDidMount() {
        this.handleUserExposerModal();
    }


    handleUserExposerModal = async (data) => {
        let expoReqBody = {
            "userId": this.props.match.params.userId,
            "userType": "client",
        }
        let userExpoDetails = await httpPost('reports/exposerListForAllexch', expoReqBody);
        if (userExpoDetails) {
            this.setState({ userExpoDetails: userExpoDetails.data ? userExpoDetails.data : {} })
        }
    }


    render() {
        const { props } = this.props;
        let { userExpoDetails } = this.state;


        return (
            <div className="main-bodyTotal">
                <Sidebar props={props} />

                <div className="h-screen overflow-y-auto w-full md:pl-[18px] pe-lg-2 pe-0">
                    <div className="cricket-icon-color w-full">
                        <div className="w-full p-[6px] white-text font-medium text-base">MY MARKET</div>
                    </div>
                    <div className="w-full overflow-x-auto">
                        <table className="w-full border-[#c5c5c5] matchTableWrapper">
                            <thead>
                                <tr className="bg-[#dcdcdc] matchTr border-b border-[#c5c5c5]">
                                    <th className="text-sm font-normal text-black border-l border-[#c5c5c5]">S.No.</th>
                                    <th className="text-sm font-normal text-black border-l border-[#c5c5c5]">Match Name</th>
                                    <th className="text-sm font-normal text-black border-l border-[#c5c5c5]">Sport Name</th>
                                    <th className="text-sm font-normal text-black border-l border-[#c5c5c5]">Match Type</th>
                                    <th className="text-sm font-normal text-black border-l border-[#c5c5c5]">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userExpoDetails && userExpoDetails.length >= 0 ? userExpoDetails.map((element, index) =>
                                    <tr className="bg-white matchTr border-b border-[#c5c5c5]">
                                        <td className="text-sm font-normal text-black border-l border-[#c5c5c5]">{index + 1}</td>
                                        <td className="text-sm font-normal text-black border-l border-[#c5c5c5]">{element._id}</td>
                                        <td className="text-sm font-normal text-black border-l border-[#c5c5c5]">{element.gameName}</td>
                                        <td className="text-sm font-normal text-black border-l border-[#c5c5c5]">{element.gameType}</td>
                                        <td className="text-sm font-normal text-black border-l border-[#c5c5c5]">{element.userAmount}</td>
                                    </tr>
                                ) : <tr className="bg-white matchTr border-b border-[#c5c5c5]">
                                    <td colSpan={5} className="text-sm font-normal text-black border border-[#c5c5c5]">
                                        No record found
                                    </td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        );
    }
}

export default AllMarketBook;
