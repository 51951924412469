import React, { useState } from "react";
import "./Profile.css";
import Table from "react-bootstrap/Table";
import Sidebar from "../../components/Sidebar/Sidebar";
import { httpPatch } from "../../_helpers/http"
import ErrorTostCasino from "../../components/ErrorTostCasino/ErrorTostCasino";

const Profile = (props) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [isFetch, setIsFetch] = useState(false);
    const [messages, setMessage] = useState("");
    const [error, setError] = useState("");

    const handleDropdownChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const getResultByApi = async () => {

        let resultBody = {
            "rate": Number(selectedOption)
        }
        let resultData = await httpPatch('user/updateRateReffrenece', resultBody);

        if (resultData) {
            localStorage.setItem('rateDiff', JSON.stringify(resultData.data.rateReffrenec))
            setIsFetch(true)
            setMessage(resultData.message)
            setError(false)
            setTimeout(() => {
                setIsFetch(false)
            }, 1000);

        } else {
            setIsFetch(true)
            setMessage(resultData.message)
            setError(true)
            setTimeout(() => {
                setIsFetch(false)
            }, 1000);
        }

    };

    // }
    const handleButtonClick = () => {
        getResultByApi()
    };


    const toastClose = () => {
        setIsFetch(false);
    };

    let user = JSON.parse(localStorage.getItem('spuser'));
    let rateDiffrence = JSON.parse(localStorage.getItem('rateDiff'));

    return (
        <>
            {isFetch ?
                <ErrorTostCasino message={messages} toastClose={toastClose} error={error} isProfile={true} />
                : null}
            <Sidebar props={props} />
            <div className="mtop flex px-2">
                <div className="w-full border-[3px] border-[#289ab1] rounded-[10px]">
                    <div className="rate-info rules-header-background rules-header-color tracking-wide">RATE INFORMATION</div>
                    <table className="w-full white-background border-[1px] border-[#98abe8]">
                        <tbody className="rate-main white-background">
                            <tr>
                                <td className="rate-differ black-text">Rate Difference.</td>
                                <td>
                                    <select onChange={handleDropdownChange} value={selectedOption} className="py-[5px] px-2 font-bold">
                                        <option style={{ display: 'none' }} selected value={rateDiffrence}>{rateDiffrence ? (rateDiffrence * 100) : 1}</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </td>
                                <td>
                                    <button onClick={handleButtonClick} className="upt-btn upt-btn-color rules-header-color">Update</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="per-info rules-header-background rules-header-color tracking-wide">PERSONAL INFORMATION</div>

                    <Table bordered hover>
                        <tbody className="table-name white-background text-start black-text">
                            <tr>
                                <td>Client Name</td>
                                <td>{user && user.data && user.data.name ? user.data.name : "userName"}</td>
                            </tr>
                            <tr>
                                <td>Client Code</td>
                                <td>{user && user.data && user.data.username ? user.data.username : "user"}</td>
                            </tr>
                            <tr>
                                <td>Chips</td>
                                <td>{user && user.data && user.data.balance ? Number.parseFloat(Math.abs(user.data.balance)).toFixed(2).replace(/\.?0+$/, "") : "0.00"}</td>                            </tr>
                            <tr>
                                <td>Contact No.</td>
                                <td>0</td>
                            </tr>
                            <tr>
                                <td>Date Of Joining</td>
                                <td>Thu Jan 13 22:56:18 IST 2022</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>INDIA</td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="com-info rules-header-background rules-header-color tracking-wide">COMPANY INFORMATION</div>
                    <Table bordered hover className="mb-0">
                        <tbody className="company-contact white-background text-start">
                            <tr>
                                <td className="matchname-color">Help Line No.</td>
                                <td className="matchname-color">+91 1234567890</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <ErrorTostCasino />
            </div>
        </>
    );
};

export default Profile;
