import React, { useRef, useEffect, useState } from 'react';
import { betChipsData } from '../../_config';
import { FaTimes } from 'react-icons/fa';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import BetLoader from '../BetLoader/BetLoader';



export default function BetPlaceMatchLoader(props) {
    const { updateStackOnclick, placeBet, handleClose, betSlipData, timerKey, handleCountdown, remainingTime, updateStake, minStake, maxStake } = props;
    let betChipsDatas = JSON.parse(localStorage.getItem('betChipsData'));
    const myArray = betChipsDatas && betChipsDatas.length > 0 ? Object.keys(betChipsDatas) : Object.keys(betChipsData);


    return (
        <div className='h-full md:hscreen m-auto absolute top-0 flex justify-center items-start left-0 z-50 w-full bg-black/60 pt-0 lg:hidden'>
            <div className='borderye borderyeMobile relative'>
                <div className={`${betSlipData.type === "Yes" ? "bg-white" : "bg-white"} p2`}>
                    <div className="state-name">{betSlipData && betSlipData.name ? betSlipData.name : null}</div>
                    <div className="state-profit-loss">
                        <span>
                            <span>Yet (Bet For)</span> <br />
                            <span className='text-[#000000]'>{betSlipData && betSlipData.betType === "N" ? "Not" : betSlipData.betType === "Y" ? "Yes" : betSlipData.betType === "L" ? "Lagai" : betSlipData.betType === "K" ? "Khai" : null}</span>
                        </span>
                        <span>
                            <span>Profit</span>
                            <span className='text-[#087C34]'>0</span>
                        </span>
                        <span>
                            <span>Loss</span>
                            <span className='text-[#FF0000]'>0</span>
                        </span>
                        <CountdownCircleTimer
                            key={timerKey}
                            isPlaying
                            duration={remainingTime}
                            colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                            colorsTime={[10, 6, 3, 0]}
                            size={50}
                            onComplete={() => handleCountdown()}
                        >
                            {({ remainingTime }) => remainingTime}
                        </CountdownCircleTimer>
                    </div>

                    {betSlipData.betFor === "fancy" ?
                        <>
                            <div className="flex">
                                <div className='text-left w-full font-bold'>{`${betSlipData.betFor === "fancy" ? "fancy" : "odds"}`}</div>
                                <div className='text-left w-full font-bold'>Run</div>
                                <div className='text-left w-full font-bold'>Stake</div>
                            </div>
                            <div className="flex">
                                <input type="text" className="odd-input" placeholder="0" value={parseFloat(betSlipData.odds * 100).toFixed(2)} />
                                <input type="text" className="odd-input" placeholder="0" value={betSlipData.run} />
                                <input type="text" className="even-input" placeholder="0" value={betSlipData.stake} />
                            </div>
                        </>
                        :
                        <>
                            <div className="flex">
                                <div className='text-left w-full font-bold'>{`${betSlipData.betFor === "fancy" ? "fancy" : "odds"}`}</div>
                                <div className='text-left w-full font-bold'>Stake</div>
                            </div>
                            <div className="choice-betting">
                                <input type="text" className="odd-input" placeholder="0" value={betSlipData.odds} />
                                <input type="text" className="even-input" placeholder="0" value={betSlipData.stake} />
                            </div>
                        </>}



                    <div className='grid xl:grid-cols-5 grid-cols-3'>
                        {myArray && myArray.length > 0 ? myArray.map((element, index) => (
                            <div key={index} className="
                        betprice-box">
                                <div className='textcenter numberBox h-10' key={index}>{element}</div>
                            </div>)) : null}
                        <div className='clearbtn' >C</div>
                    </div>
                    <div className='spacing'>
                        <button className="clear-all-btn">Clear All</button>
                        {betSlipData.stake > 0 ?
                            <button className="place-bet-btn">Place Bet</button>
                            :
                            <button className="place-bet-btn">Place Bet</button>
                        }
                    </div>
                </div>
                <BetLoader />
            </div>

        </div>

    );
}

