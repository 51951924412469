import React, { useRef, useEffect, useState } from 'react';
import "./BetPlaceDesktopLoader.css";
import { betChipsData } from '../../_config';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import BetLoader from '../BetLoader/BetLoader';


export default function BetPlaceDesktopLoader(props) {
    const { updateStackOnclick, placeBet, handleClose, betSlipData, timerKey, handleCountdown, remainingTime, updateStake } = props;
    let betChipsDatas = JSON.parse(localStorage.getItem('betChipsData'));
    const myArray = betChipsDatas && betChipsDatas.length > 0 ? Object.keys(betChipsDatas) : Object.keys(betChipsData);


    return (
        <div className='position lg:block hidden'>
            <div className='borderye relative'>
                <div className={`${betSlipData.type === "Yes" ? "bg-white" : "bg-white"} p-2`}>
                    <div className="state-name">{betSlipData && betSlipData.name ? betSlipData.name : null}</div>
                    <div className="state-profit-loss">
                        <span>
                            <span>Yet (Bet For)</span> <br />
                            <span >{betSlipData && betSlipData.betType === "N" ? "Not" : betSlipData.betType === "Y" ? "Yes" : betSlipData.betType === "L" ? "Lagai" : betSlipData.betType === "K" ? "Khai" : null}</span>
                        </span>
                        <span>
                            <span>Profit</span>
                            <span className='text-[#087C34]'>0</span>
                        </span>
                        <span>
                            <span>Loss</span>
                            <span className='text-[#FF0000]'>0</span>
                        </span>
                        <CountdownCircleTimer
                            key={timerKey}
                            isPlaying
                            duration={remainingTime}
                            colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                            colorsTime={[10, 6, 3, 0]}
                            size={50}
                            onComplete={() => handleCountdown()}
                        >
                            {({ remainingTime }) => remainingTime}
                        </CountdownCircleTimer>
                    </div>

                    {betSlipData.betFor === "fancy" ?
                        <>
                            <div className="flex">
                                <div className='text-left w-full font-bold'>{`${betSlipData.betFor === "fancy" ? "fancy" : "odds"}`}</div>
                                <div className='text-left w-full font-bold'>Run</div>
                                <div className='text-left w-full font-bold'>Stake</div>
                            </div>
                            <div className="flex">
                                <input type="text" className="odd-input" placeholder="0" value={parseFloat(betSlipData.odds * 100).toFixed(2)} />
                                <input type="text" className="odd-input" placeholder="0" value={betSlipData.run} />
                                <input type="text" className="even-input" placeholder="0" value={betSlipData.stake} />
                            </div>
                        </>
                        :
                        <>
                            <div className="flex">
                                <div className='text-left w-full font-bold'>{`${betSlipData.betFor === "fancy" ? "fancy" : "odds"}`}</div>
                                <div className='text-left w-full font-bold'>Stake</div>
                            </div>
                            <div className="choice-betting">
                                <input type="text" className="odd-input" placeholder="0" value={betSlipData.odds} />
                                <input type="text" className="even-input" placeholder="0" value={betSlipData.stake} />
                            </div>
                        </>}



                    <div className='grid xl:grid-cols-5 grid-cols-3'>
                        {myArray && myArray.length > 0 ? myArray.map((element, index) => (
                            <div key={index} className="
                            betprice-box">
                                <div className='text-center h-10 w-full'>{element}</div>
                            </div>)) : null}
                        <div className='clearbtn'>C</div>
                    </div>
                    <div className='spacing'>
                        <button className="clear-all-btn" >Clear All</button>
                        {/* <div className=""> */}
                            <button className='place-bet-btn flex justify-center items-center space-x-2'>
                                <div>Place Bet</div>
                                <div className="px-4 white-text h-2 flex justify-center items-center cursor-pointer border-[#d4d3e8] rounded-none font-extrabold text-sm" type="submit">
                                    <div className=" flex items-center justify-center absolute bg-transparent">
                                        <div className="flex items-center justify-center bg-transparent w-full">
                                            <div className="w-5 h-5 rounded-full animate-spin 
                        border-[5px] border-solid border-[#ffffff] border-t-transparent"></div>
                                        </div>
                                    </div>
                                </div>

                            </button>
                        {/* </div> */}
                    </div>
                </div>
                <BetLoader />
            </div>
        </div >
    );
}

