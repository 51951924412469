
import Sidebar from "../../components/Sidebar/Sidebar";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpPost } from "../../_helpers/http";
import moment from 'moment';
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";

class MyCommission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsTransction: {},
            errors: {},
            userCommission: {},
            endDate: moment().format('YYYY-MM-DD'),
            startDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
            currentDate: moment().format('YYYY-MM-DD'),
            currentPage: 1,
            itemsPerPage: 10,
            errorType: "",
            showAlert: false,
            resMessage: "",
            isFetch: false,
        }
    }

    componentDidMount() {
        this.getUserCommission();
    }

    inputChangeTransction = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let fieldsTransction = this.state.fieldsTransction;
        let errors = this.state.errors;
        fieldsTransction[name] = value;
        errors[name] = "";
        this.setState({ fieldsTransction, errors });
    }

    SubmitLegdersearch = () => {
        this.getUserCommission();
    }

    inputChangeStartDate = (e) => {
        this.setState({ startDate: e.target.value });
    }

    inputChangeEndDate = (e) => {
        this.setState({ endDate: e.target.value });
    }

    getUserCommission = async () => {
        let reqData = {
            "fromDate": this.state.startDate,
            "toDate": this.state.endDate,
        }
        this.setState({ isFetch: true })
        let userCommission = await httpPost('decision/commissionReport', reqData);
        if (userCommission) {
            this.setState({ userCommission: userCommission.data })

        } else {
            this.setState({ showAlert: true, errorType: 1, resMessage: "Something went wrong" });
        }
        this.setState({ isFetch: false });
        setTimeout(() => this.setState({ showAlert: false }), 2000);
    }
    handlePageChange = (page) => {
        this.setState({ currentPage: page });
    };


    render() {
        let { userCommission, resMessage, errorType, showAlert, isFetch } = this.state;
        const startIndex = (this.state.currentPage - 1) * this.state.itemsPerPage;
        const endIndex = startIndex + this.state.itemsPerPage;
        const currentItems = Array.isArray(userCommission) ? userCommission.slice(startIndex, endIndex) : [];

        let totalMatchComm = 0;
        let totalSessionComm = 0;
        let totalCassinoComm = 0;

        currentItems.forEach((data) => {
            totalMatchComm += !data.isCasino ? data.oddsComm : 0
            totalSessionComm += data.sessionComm
            totalCassinoComm += data.isCasino ? data.oddsComm : 0
        })

        console.log("rrrrrrrrrr");

        return (
            <div className="mainbodyTotal hfull d-flex">
                {isFetch === true ?
                    <Loader />
                    : null}
                {showAlert && <div className={`absolute top-[2%] right-[2%] px-5 py-3 ${errorType === 1 ? "bg-red-600" : "bg-green-600"}  rounded`}><span className='text-white font-bold'>{resMessage}</span></div>}
                <Sidebar props={this.props} />
                <div className="w-full lg:pl-6 pl-0">
                    <div className="cricket-icon-color">
                        <div className=" w-full p-[6px] white-text font-normal text-base" >MY COMMISSION</div>
                    </div>
                    <div className="flex md:flex-row flex-col justify-start items-start md:space-x-5 space-x-0 md:space-y-0 space-y-2 py-2 lg:px-1 px-3">
                        <input type="date" className="px-2 md:py-1.5 py-2 md:w-60 w-full text-[12px] white-background border-[1px] border-[#007bff] focus:outline-none text-grey placeholder:text-gray-500 rounded-[4px]"
                            name="Date"
                            dateformat="yyyy-mm-dd"
                            id="datepicker" required
                            value={this.state.startDate} onChange={this.inputChangeStartDate}
                        />

                        <input type="date" className="px-2 md:py-1.5 py-2 md:w-60 w-full text-[12px] white-background border-[1px] border-[#007bff]  focus:outline-none text-grey placeholder:text-gray-500 rounded-[4px]"
                            name="Date"
                            dateformat="yyyy-mm-dd"
                            id="datepicker" required
                            value={this.state.endDate} onChange={this.inputChangeEndDate}
                        />

                        <button onClick={this.SubmitLegdersearch} className="rule-green-button font-medium white-text md:px-8 px-10 text-[14px] py-1.5 mt-1 rounded-[4px]">Search</button>
                    </div>
                    <div className='w-full table-responsive px-2'>
                        <table className="w-full border-none text-start table table-striped ">
                            <thead className="rounded-t table-header-color">
                                <tr className='black-text font-normal '>
                                    <th className="w-[20%] whitespace-nowrap border-[1px] border-[#b9b9b9] font-normal">Date</th>
                                    <th className="w-[10%] whitespace-nowrap border-[1px] border-[#b9b9b9] font-normal">M. Comm.</th>
                                    <th className="w-[10%] whitespace-nowrap border-[1px] border-[#b9b9b9] font-normal">S. Comm.</th>
                                    <th className="w-[10%] whitespace-nowrap border-[1px] border-[#b9b9b9] font-normal">C. Comm.</th>
                                    <th className="w-[10%] whitespace-nowrap border-[1px] border-[#b9b9b9] font-normal">T. Comm.</th>
                                    <th className="w-[40%] whitespace-nowrap border-[1px] border-[#b9b9b9] font-normal">Name</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {currentItems && currentItems ?
                                    <>
                                        {currentItems.map((element, index) =>
                                            <tr key={index} className="bg-transparent table-text-color text-[15px] font-semibold ">
                                                <td className="whitespace-nowrap w-[20%] border-[1px] border-[#b9b9b9]">
                                                    {moment(element.date).format('YYYY-MM-DD hh:mm:ss')}
                                                </td>
                                                <td className={`${element.oddsComm <= 0 ? "green-text-number" : "header-red-text"} whitespace-nowrap w-[10%] border-[1px] border-[#b9b9b9]`}>
                                                    {element.isCasino !== 1 ?
                                                        <>
                                                            {element.oddsComm}
                                                        </> : 0
                                                    }
                                                </td>
                                                <td className={`${element.sessionComm <= 0 ? "green-text-number" : "header-red-text"} whitespace-nowrap w-[10%] border-[1px] border-[#b9b9b9]`}>{element.sessionComm}</td>
                                                <td className={`${element.oddsComm <= 0 ? "green-text-number" : "header-red-text"} whitespace-nowrap w-[10%] border-[1px] border-[#b9b9b9]`}>
                                                    {element.isCasino === 1 ?
                                                        <>
                                                            {element.oddsComm}
                                                        </> : 0
                                                    }
                                                </td>
                                                <td className={`${(element.sessionComm + element.oddsComm) <= 0 ? "green-text-number" : "header-red-text"} whitespace-nowrap w-[10%] border-[1px] border-[#b9b9b9]`}>{element.sessionComm + element.oddsComm}</td>
                                                <td className=" whitespace-nowrap w-[40%] border-[1px] border-[#b9b9b9]">{element.eventName}</td>
                                            </tr>)}
                                    </> :
                                    <>
                                        <tr className="bg-transparent black-text ">
                                            <td className="whitespace-nowrap w-full border-[1px] border-[#b9b9b9]" colSpan={6}>No Data Found</td>
                                        </tr>
                                    </>
                                }
                                <tr className="black-text font-semibold text-[15px]">
                                    <td className="w-[20%] border-[1px] border-[#b9b9b9]">Total</td>
                                    <td className={`${totalMatchComm <= 0 ? "green-text-number" : "header-red-text"} w-[10%] border-[1px] border-[#b9b9b9]`}>{totalMatchComm}</td>
                                    <td className={`${totalSessionComm <= 0 ? "green-text-number" : "header-red-text"} w-[10%] border-[1px] border-[#b9b9b9]`}>{totalSessionComm}</td>
                                    <td className={`${totalCassinoComm <= 0 ? "green-text-number" : "header-red-text"} w-[10%] border-[1px] border-[#b9b9b9]`}>{totalCassinoComm}</td>
                                    <td className={`${(totalMatchComm + totalSessionComm + totalCassinoComm) <= 0 ? "green-text-number" : "header-red-text"} w-[10%] border-[1px] border-[#b9b9b9]`}>{totalMatchComm + totalSessionComm + totalCassinoComm}</td>
                                    <td className="w-[40%] border-[1px] border-[#b9b9b9]">{ }</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="paginationWrapper">
                        <Pagination
                            currentPage={this.state.currentPage}
                            totalPages={Math.ceil(userCommission && userCommission.length / this.state.itemsPerPage)}
                            maxVisibleButtons={1}
                            onPageChanged={this.handlePageChange}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { users } = state;
    return {
        users,
    };
}
export default connect(mapStateToProps)(MyCommission);