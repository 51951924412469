import React, { Component } from "react";
import "./BetHistory.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import { httpPost } from "../../_helpers/http";
import moment from 'moment';
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";
import { FaFilter } from "react-icons/fa";

class BetHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsTransction: {},
      errors: {},
      BetHistoryType: "",
      userBetHistory: {},
      endDate: moment().format('YYYY-MM-DD'),
      startDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
      currentDate: moment().format('YYYY-MM-DD'),
      currentPage: 1,
      currentPageCricket: 1,
      currentPageFancy: 1,
      itemsPerPage: 10,
      errorType: "",
      showAlert: false,
      resMessage: "",
      isFetch: false,
      activeTab: 1,
      isCompleted: false,
    }
  }
  componentDidMount() {
    this.getUserBetHistory();
  }

  inputChangeTransction = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldsTransction = this.state.fieldsTransction;
    let errors = this.state.errors;
    fieldsTransction[name] = value;
    errors[name] = "";
    this.setState({ fieldsTransction, errors });
  }

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  handleSelectChange = (e) => {
    this.setState({
      isCompleted: e.target.value === "true" ? true : false,
    });
  };

  SubmitBetHistorysearch = () => {
    this.getUserBetHistory();
  }

  inputChangeStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  }

  inputChangeEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  }

  getUserBetHistory = async () => {

    let reqData = {

      "fancyBet": true,  // optional
      "oddsBet": true,  // optional
      "isCompleted": this.state.fieldsTransction.ledgerType === "Settlement" ? true : false, // optionals
      "fromDate": this.state.startDate,
      "toDate": this.state.endDate,
      // "status": this.state.fieldsTransction.ledgerType,
    }
    this.setState({ isFetch: true })
    let userBetHistory = await httpPost('sports/betsList', reqData);
    if (userBetHistory) {
      this.setState({ userBetHistory: userBetHistory.data });
    } else {
      this.setState({ showAlert: true, errorType: 1, resMessage: "Something went wrong" });
    }
    this.setState({ isFetch: false });
    setTimeout(() => this.setState({ showAlert: false }), 2000);
  }
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };
  handlePageChangeCricket = (page) => {
    this.setState({ currentPageCricket: page });
  };
  handlePageChangeFancy = (page) => {
    this.setState({ currentPageFancy: page });
  };


  render() {
    let { userBetHistory, activeTab, currentPage, itemsPerPage, isFetch, currentPageCricket, currentPageFancy } = this.state;
    let { fancyBetData, oddsBetData } = userBetHistory;
    let finalobject = [];

    fancyBetData && fancyBetData.forEach((fancyData, key) => {

      let profitLoss = ""
      if (fancyData.decisionRun) {
        if ((fancyData.type === "N" && fancyData.decisionRun > fancyData.run) || (fancyData.type === "Y" && fancyData.decisionRun < fancyData.run)) {
          profitLoss = fancyData.loss;
        } else if ((fancyData.type === "N" && fancyData.decisionRun < fancyData.run) || (fancyData.type === "Y" && fancyData.decisionRun >= fancyData.run)) {
          profitLoss = fancyData.profit;
        }
      }
      fancyBetData[key].profitLoss = profitLoss
    })

    fancyBetData && fancyBetData.forEach((fancyData) => {

      let pushObj = {
        userInfo: fancyData.userInfo,
        description: fancyData.sessionName,
        selection: "Fancy",
        stack: fancyData.amount,
        odds: fancyData.odds,
        run: fancyData.run,
        Type: "Fancy Odds",
        createdAt: fancyData.createdAt,
        profit: fancyData.profit,
        type: fancyData.type,
        isDeclare: fancyData.isDeclare,
        ip: fancyData.ip,
        profitLoss: fancyData.profitLoss
      }
      finalobject.push(pushObj)
    })


    oddsBetData && oddsBetData.forEach((oddsData) => {
      let pushObj = {
        userInfo: oddsData.userInfo,
        description: oddsData.teamName,
        selection: oddsData.oddsType,
        stack: oddsData.amount,
        odds: oddsData.odds,
        Type: "Match Odds",
        createdAt: oddsData.createdAt,
        profit: oddsData.profit,
        type: oddsData.type,
        isDeclare: oddsData.isDeclare,
        ip: oddsData.ip,
        run: "",
        profitLoss: oddsData.positionInfo[oddsData.selectionId]
      }
      finalobject.push(pushObj)
    })

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const startIndexCricket = (currentPageCricket - 1) * itemsPerPage;
    const endIndexCricket = startIndexCricket + itemsPerPage;
    const startIndexFancy = (currentPageFancy - 1) * itemsPerPage;
    const endIndexFancy = startIndexFancy + itemsPerPage;
    const currentItems = Array.isArray(finalobject) ? finalobject.slice(startIndex, endIndex) : [];
    const currentItemsodds = Array.isArray(oddsBetData) ? oddsBetData.slice(startIndexCricket, endIndexCricket) : [];
    const currentItemsfancy = Array.isArray(fancyBetData) ? fancyBetData.slice(startIndexFancy, endIndexFancy) : [];

    return (
      <div className="d-flex">
        {isFetch === true ?
          <Loader />
          : null}
        <Sidebar props={this.props} />
        <div className="w-full h-full lg:pl-6 pl-0 pt-2">
          <div className="cricket-icon-color flex justify-between items-center">
            <div className="flex justify-start items-center" >
              <div className="p-[6px] white-text font-normal text-base" >BET HISTORY</div>
              <select className="p-1 rounded-none">
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
              </select>
            </div>
            {/* <button className="back-btn text-[14px]" onClick={() => this.props.history.goBack()}>Back</button> */}
          </div>
          <div className="lg:flex hidden md:flex-row flex-col justify-start items-start md:space-x-5 space-x-0 md:space-y-0 space-y-2 py-2 px-2 bg-white w-full">
            <input type="date" className="px-2 lg:py-1.5 py-2 lg:w-52 w-full text-[12px] bg-white border-[1px] border-[#007bff] focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[4px] cursor-pointer"
              name="Date"
              dateformat="yyyy-mm-dd"
              id="datepicker" required
              value={this.state.startDate} onChange={this.inputChangeStartDate}
            />
            <input type="date" className="px-2 lg:py-1.5 py-2 lg:w-52 w-full text-[12px] bg-white border-[1px] border-[#007bff]  focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[4px] cursor-pointer"
              name="Date"
              dateformat="yyyy-mm-dd"
              id="datepicker" required
              value={this.state.endDate} onChange={this.inputChangeEndDate}
            />
            <input type="search" className="px-2 lg:py-1.5 py-2 lg:w-28 w-full text-[12px] bg-white border-[1px] border-[#007bff]  focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[4px] cursor-pointer"
              name="search"
              placeholder="Search"
              id="search"

            />
            <select className="md:w-44 w-full px-2 h-[35px] py-1.5 text-[12px] text-gray-600 border-[1px] border-[#007bff] rounded-[3px] bg-white cursor-pointer" aria-label="select" id="ledgerType" name="ledgerType" value={this.state.fieldsTransction && this.state.fieldsTransction["ledgerType"] ? this.state.fieldsTransction["ledgerType"] : ""} onChange={this.inputChangeTransction} >
              <option value="Open">Open</option>
              <option value="Settlement">Settlement</option>
            </select>
            <button onClick={this.SubmitBetHistorysearch} className="bg-[#f7c200] text-black px-4 text-[14px] py-1.5 rounded-[3px] flex justify-start items-center"><FaFilter size={12} />Filter</button>
          </div>

          <div className="lg:hidden flex md:flex-row flex-col justify-start items-start md:space-x-5 space-x-0 md:space-y-0 space-y-2 py-2 px-2 bg-white w-full">
            <div className="flex justify-between items-center space-x-2 w-full">
              <input type="date" className="px-2 lg:py-1.5 py-2 lg:w-52 w-full text-[12px] bg-white border-[1px] border-[#007bff] focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[4px]"
                name="Date"
                dateformat="yyyy-mm-dd"
                id="datepicker" required
                value={this.state.startDate} onChange={this.inputChangeStartDate}
              />

              <input type="date" className="px-2 lg:py-1.5 py-2 lg:w-52 w-full text-[12px] bg-white border-[1px] border-[#007bff]  focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[4px]"
                name="Date"
                dateformat="yyyy-mm-dd"
                id="datepicker" required
                value={this.state.endDate} onChange={this.inputChangeEndDate}
              />
            </div>
            <div className="flex justify-between items-center space-x-2 w-full">
              <input type="search" className="px-2 lg:py-1.5 py-2 lg:w-28 w-full text-[12px] bg-white border-[1px] border-[#007bff]  focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[4px]"
                name="search"
                placeholder="Search"
                id="search"
              />
              <select className="md:w-44 w-full px-2 h-[35px] py-1.5 text-[12px] text-gray-600 border-[1px] border-[#007bff] rounded-[3px] bg-white" aria-label="select" id="ledgerType" name="ledgerType" value={this.state.fieldsTransction && this.state.fieldsTransction["ledgerType"] ? this.state.fieldsTransction["ledgerType"] : ""} onChange={this.inputChangeTransction} >
                <option value="Settlement">Settlement</option>
                <option value="Open">Open</option>
              </select>
            </div>
            <button onClick={this.SubmitBetHistorysearch} className="bg-[#f7c200] text-black px-4 text-[14px] py-1.5 rounded-[3px] flex justify-start items-center"><FaFilter size={12} />Filter</button>

          </div>


          <div className="all-games divide-x-[1px] divide-[#222224] cursor-pointer mx-lg-2 mx-0">
            <div onClick={() => this.handleTabClick(1)} className="!font-[400] text-black text-[12px]">All</div>
            <div onClick={() => this.handleTabClick(2)} className="!font-[400] text-black text-[12px]">Cricket</div>
            <div onClick={() => this.handleTabClick(3)} className="!font-[400] text-black text-[12px]">Tennis</div>
            <div onClick={() => this.handleTabClick(4)} className="!font-[400] text-black text-[12px]">Soccer</div>
            <div onClick={() => this.handleTabClick(5)} className="!font-[400] text-black text-[12px]">Fancy</div>
          </div>
          <div className="">
            {activeTab === 1 ? (<>
              <div className='w-full table-responsive bg-white px-lg-2 px-0'>
                <table className="w-full border-none text-start table table-striped text-[12px]">
                  <thead className="rounded-t bg-[#dcdcdc]">
                    <tr className='text-[#252525] font-normal text-[12px]'>
                      <th style={{border: '1px solid #c3bdbd'}} className="w-auto whitespace-nowrap text-[16px] font-normal">S.No.</th>
                      <th style={{border: '1px solid #c3bdbd'}} className="w-auto whitespace-nowrap text-[16px] font-normal">Client</th>
                      <th style={{border: '1px solid #c3bdbd'}} className="w-auto whitespace-nowrap text-[16px] font-normal">Description</th>
                      <th style={{border: '1px solid #c3bdbd'}} className="w-auto whitespace-nowrap text-[16px] font-normal">Selection</th>
                      <th style={{border: '1px solid #c3bdbd'}} className="w-auto whitespace-nowrap text-[16px] font-normal">Type</th>
                      <th style={{border: '1px solid #c3bdbd'}} className="w-auto whitespace-nowrap text-[16px] font-normal">Odds</th>
                      <th style={{border: '1px solid #c3bdbd'}} className="w-auto whitespace-nowrap text-[16px] font-normal">Stack</th>
                      <th style={{border: '1px solid #c3bdbd'}} className="w-auto whitespace-nowrap text-[16px] font-normal">Date</th>
                      <th style={{border: '1px solid #c3bdbd'}} className="w-auto whitespace-nowrap text-[16px] font-normal">P_L</th>
                      <th style={{border: '1px solid #c3bdbd'}} className="w-auto whitespace-nowrap text-[16px] font-normal">Profit </th>
                      <th style={{border: '1px solid #c3bdbd'}} className="w-auto whitespace-nowrap text-[16px] font-normal">Liability</th>
                      <th style={{border: '1px solid #c3bdbd'}} className="w-auto whitespace-nowrap text-[16px] font-normal">Bet type </th>
                      <th style={{border: '1px solid #c3bdbd'}} className="w-auto whitespace-nowrap text-[16px] font-normal">Status</th>
                      <th style={{border: '1px solid #c3bdbd'}} className="w-auto whitespace-nowrap text-[16px] font-normal">IP</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {currentItems && currentItems ?
                      <>
                        {currentItems && currentItems.length > 0 ? currentItems.map((element, index) => (
                          <tr key={index} className="bg-transparent text-[#252525] text-start ">
                            <td style={{border: '1px solid #c3bdbd'}} className=" w-[3%]  md:whitespace-normal whitespace-nowrap text-[16px] font-normal">{index + 1} </td>
                            <td style={{border: '1px solid #c3bdbd'}} className=" w-[5%]  md:whitespace-normal whitespace-nowrap text-[16px] font-normal">{element.userInfo.clientName}</td>
                            <td style={{border: '1px solid #c3bdbd'}} className=" w-[11%] md:whitespace-normal whitespace-nowrap text-[16px] font-normal ">{element.description}</td>
                            <td style={{border: '1px solid #c3bdbd'}} className=" w-[16%] md:whitespace-normal whitespace-nowrap text-[16px] font-normal ">{element.selection}</td>
                            <td style={{border: '1px solid #c3bdbd'}} className=" w-[5%]  md:whitespace-normal whitespace-nowrap text-[16px] font-normal">{element.Type}</td>
                            <td style={{border: '1px solid #c3bdbd'}} className=" w-[5%]  md:whitespace-normal whitespace-nowrap text-[16px] font-normal">{element.run}<br></br> ({element.odds})</td>
                            <td style={{border: '1px solid #c3bdbd'}} className=" w-[5%]  md:whitespace-normal whitespace-nowrap text-[16px] font-normal">{element.stack} </td>
                            <td style={{border: '1px solid #c3bdbd'}} className=" w-[11%] md:whitespace-normal whitespace-nowrap text-[16px] font-normal ">{moment(element.createdAt, 'YYYY-MM-DD hh:mm:ss').format('ddd MMM DD hh:mm:ss [IST] YYYY')}</td>
                            <td style={{border: '1px solid #c3bdbd'}} className=" w-[5%]  md:whitespace-normal whitespace-nowrap text-[16px] font-normal">
                              {Number.parseFloat(Math.abs(element.profitLoss)).toFixed(2).replace(/\.?0+$/, "")}
                            </td>
                            <td style={{border: '1px solid #c3bdbd'}}  className=" w-[5%]  md:whitespace-normal whitespace-nowrap text-[16px] font-normal ">
                              {Number.parseFloat(Math.abs(element.profit)).toFixed(2).replace(/\.?0+$/, "")}
                            </td>
                            <td style={{border: '1px solid #c3bdbd'}}  className=" w-[8%]  md:whitespace-normal whitespace-nowrap text-[16px] font-normal ">
                              {element.profitLoss > 0 ? `-${Number.parseFloat(Math.abs(element.profitLoss)).toFixed(2).replace(/\.?0+$/, "")}` : Number.parseFloat(Math.abs(element.profitLoss)).toFixed(2).replace(/\.?0+$/, "")}
                            </td>
                            <td  style={{border: '1px solid #c3bdbd'}} className=" w-[5%]  md:whitespace-normal whitespace-nowrap text-[16px] font-normal ">{element && (element.type === "L" ? "LAGAI" : element.type === "K" ? "KHAI" : element.type === "Y" ? "Yes" : "Not")}</td>
                            <td style={{border: '1px solid #c3bdbd'}}  className=" w-[5%]  md:whitespace-normal whitespace-nowrap text-[16px] font-normal ">{element && element.isDeclare === 1 ? "CLOSE" : "OPEN"}</td>
                            <td style={{border: '1px solid #c3bdbd'}}  className=" w-[11%] md:whitespace-normal whitespace-nowrap  text-[16px] font-normal truncate">{element.ip}</td>
                          </tr>)) : null}
                      </> : null}
                  </tbody>
                </table>
              </div>
              <div>
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(finalobject && finalobject.length / itemsPerPage)}
                  maxVisibleButtons={1}
                  onPageChanged={this.handlePageChange}
                />
              </div>
              </>) : null}
            {activeTab === 2 ? (<>
              <div className='w-full table-responsive bg-white '>
                <table className="w-full border-none text-start table table-striped text-[12px]">
                  <thead className="rounded-t bg-[#DCDCDC]">
                    <tr className='text-[#252525] font-normal text-[12px]'>
                      <th className="w-[3%]">S.No.</th>
                      <th className="w-[5%]">Client</th>
                      <th className="w-[16%]">Description</th>
                      <th className="w-[11%]">Selection</th>
                      <th className="w-[5%]">Type</th>
                      <th className="w-[5%]">Odds</th>
                      <th className="w-[5%]">Stack</th>
                      <th className="w-[11%]">Date</th>
                      <th className="w-[5%]">P_L</th>
                      <th className="w-[5%]">Profit </th>
                      <th className="w-[8%]">Liability</th>
                      <th className="w-[5%]">Bet type </th>
                      <th className="w-[5%]">Status</th>
                      <th className="w-[11%]">IP</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {currentItemsodds && currentItemsodds ?
                      <>
                        {currentItemsodds && currentItemsodds.length > 0 ? currentItemsodds.map((element, index) => (
                          <tr key={index} className="bg-transparent text-[#252525] text-start ">
                            <td className=" w-[3%]  md:whitespace-normal whitespace-nowrap">{index + 1} </td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">{element.userInfo.clientName}</td>
                            <td className=" w-[11%] md:whitespace-normal whitespace-nowrap ">{element.teamName}</td>
                            <td className=" w-[16%] md:whitespace-normal whitespace-nowrap ">{element.oddsType}</td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">Match odds</td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">{element.odds}</td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">{element.amount} </td>
                            <td className=" w-[11%] md:whitespace-normal whitespace-nowrap ">{moment(element.createdAt, 'YYYY-MM-DD hh:mm:ss').format('ddd MMM DD hh:mm:ss [IST] YYYY')}</td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">{element.decisionSelectionId ? (element.positionInfo[element.decisionSelectionId]) : ""}</td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">{Number.parseFloat(Math.abs(element.positionInfo[element.selectionId])).toFixed(2).replace(/\.?0+$/, "")}</td>
                            <td className="w-[8%] md:whitespace-normal whitespace-nowrap">
                              {element.decisionSelectionId ? (
                                element.positionInfo[element.decisionSelectionId] > 0 ?
                                  `-${Number.parseFloat(Math.abs(element.positionInfo[element.decisionSelectionId])).toFixed(2).replace(/\.?0+$/, "")}` :
                                  Number.parseFloat(Math.abs(element.positionInfo[element.decisionSelectionId])).toFixed(2).replace(/\.?0+$/, "")
                              ) : ""}
                            </td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">{element && element.type === "L" ? "LAGAI" : "KHAI"}</td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">{element && element.isDeclare === 1 ? "CLOSE" : "OPEN"}</td>
                            <td className=" w-[11%] md:whitespace-normal whitespace-nowrap truncate">{element.ip}</td>
                          </tr>)) : null}
                      </> : null}
                  </tbody>
                </table>
              </div>
              <div>
                <Pagination
                  currentPage={currentPageCricket}
                  totalPages={Math.ceil(oddsBetData && oddsBetData.length / itemsPerPage)}
                  maxVisibleButtons={1}
                  onPageChanged={this.handlePageChangeCricket}
                />
              </div>
              </>) : null}
            {activeTab === 3 ? (<>
              <div className='w-full table-responsive bg-white'>
                <table className="w-full border-none text-start table table-striped text-[12px]">
                  <thead className="rounded-t bg-[#DCDCDC]">
                    <tr className='text-[#252525] font-normal text-[12px]'>
                      <th className="w-[3%]">S.No.</th>
                      <th className="w-[5%]">Client</th>
                      <th className="w-[16%]">Description</th>
                      <th className="w-[11%]">Selection</th>
                      <th className="w-[5%]">Type</th>
                      <th className="w-[5%]">Odds</th>
                      <th className="w-[5%]">Stack</th>
                      <th className="w-[11%]">Date</th>
                      <th className="w-[5%]">P_L</th>
                      <th className="w-[5%]">Profit </th>
                      <th className="w-[8%]">Liability</th>
                      <th className="w-[5%]">Bet type </th>
                      <th className="w-[5%]">Status</th>
                      <th className="w-[11%]">IP</th>
                    </tr>
                  </thead>

                </table>
              </div>
              <div>
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(fancyBetData && fancyBetData.length / itemsPerPage)}
                  maxVisibleButtons={1}
                  onPageChanged={this.handlePageChange}
                />
              </div>
              </>) : null}
            {activeTab === 4 ? (<>
              <div className='w-full table-responsive bg-white'>
                <table className="w-full border-none text-start table table-striped text-[12px]">
                  <thead className="rounded-t bg-[#DCDCDC]">
                    <tr className='text-[#252525] font-normal text-[12px]'>
                      <th className="w-[3%]">S.No.</th>
                      <th className="w-[5%]">Client</th>
                      <th className="w-[16%]">Description</th>
                      <th className="w-[11%]">Selection</th>
                      <th className="w-[5%]">Type</th>
                      <th className="w-[5%]">Odds</th>
                      <th className="w-[5%]">Stack</th>
                      <th className="w-[11%]">Date</th>
                      <th className="w-[5%]">P_L</th>
                      <th className="w-[5%]">Profit </th>
                      <th className="w-[8%]">Liability</th>
                      <th className="w-[5%]">Bet type </th>
                      <th className="w-[5%]">Status</th>
                      <th className="w-[11%]">IP</th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div>
                <Pagination
                  currentPage={currentPage}
                  totalPages={Math.ceil(fancyBetData && fancyBetData.length / itemsPerPage)}
                  maxVisibleButtons={1}
                  onPageChanged={this.handlePageChange}
                />
              </div>
              </>) : null}
            {activeTab === 5 ? (<>
              <div className='w-full table-responsive bg-white '>
                <table className="w-full border-none text-start table table-striped text-[12px]">
                  <thead className="rounded-t bg-[#DCDCDC]">
                    <tr className='text-[#252525] font-normal text-[12px]'>
                      <th className="w-[3%]">S.No.</th>
                      <th className="w-[5%]">Client</th>
                      <th className="w-[16%]">Description</th>
                      <th className="w-[11%]">Selection</th>
                      <th className="w-[5%]">Type</th>
                      <th className="w-[5%]">Odds</th>
                      <th className="w-[5%]">Run</th>
                      <th className="w-[5%]">Stack</th>
                      <th className="w-[11%]">Date</th>
                      <th className="w-[5%]">P_L</th>
                      <th className="w-[5%]">Profit </th>
                      <th className="w-[8%]">Liability</th>
                      <th className="w-[5%]">Bet type </th>
                      <th className="w-[5%]">Status</th>
                      <th className="w-[11%]">IP</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {currentItemsfancy && currentItemsfancy ?
                      <>
                        {currentItemsfancy && currentItemsfancy.length > 0 ? currentItemsfancy.map((element, index) => (
                          <tr key={index} className="bg-transparent text-[#252525] text-start ">
                            <td className=" w-[3%]  md:whitespace-normal whitespace-nowrap">{index + 1} </td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">{element.userInfo.clientName}</td>
                            <td className=" w-[16%] md:whitespace-normal whitespace-nowrap ">{element.sessionName}</td>
                            <td className=" w-[11%] md:whitespace-normal whitespace-nowrap ">Fancy</td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">Fancy odds</td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">{element.odds}</td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">{element.run}</td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">{element.amount} </td>
                            <td className=" w-[11%] md:whitespace-normal whitespace-nowrap ">{moment(element.createdAt, 'YYYY-MM-DD hh:mm:ss').format('ddd MMM DD hh:mm:ss [IST] YYYY')}</td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">{element.profitLoss}</td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">{element.profit}</td>
                            <td className=" w-[8%]  md:whitespace-normal whitespace-nowrap">{element.profitLoss > 0 ? `-${Math.abs(element.profitLoss)}` : Math.abs(element.profitLoss)}</td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">{element && element.type === "N" ? "NO" : "Yes"}</td>
                            <td className=" w-[5%]  md:whitespace-normal whitespace-nowrap">{element && element.isDeclare === 1 ? "CLOSE" : "OPEN"}</td>
                            <td className=" w-[11%] md:whitespace-normal whitespace-nowrap truncate">{element.ip}</td>
                          </tr>)) : null}
                      </> : null}

                  </tbody>
                </table>
              </div>
              <div>
                <Pagination
                      currentPage={currentPageFancy}
                      totalPages={Math.ceil(fancyBetData && fancyBetData.length / itemsPerPage)}
                      maxVisibleButtons={1}
                      onPageChanged={this.handlePageChangeFancy}
                    />
              </div>
              </>) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default BetHistory;
