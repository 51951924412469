export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    USER_BALANCE_REQUEST: 'USER_BALANCE_REQUEST',
    USER_BALANCE_SUCCESS: 'USER_BALANCE_SUCCESS',
    USER_BALANCE_FAILURE: 'USER_BALANCE_FAILURE',

    USER_ACCOUNT_DETAILS_REQUEST: 'USER_ACCOUNT_DETAILS_REQUEST',
    USER_ACCOUNT_DETAILS_SUCCESS: 'USER_ACCOUNT_DETAILS_SUCCESS',
    USER_ACCOUNT_DETAILS_FAILURE: 'USER_ACCOUNT_DETAILS_FAILURE',

    MATCH_LIST_REQUEST: 'MATCH_LIST_REQUEST',
    MATCH_LIST_SUCCESS: 'MATCH_LIST_SUCCESS',
    MATCH_LIST_FAILURE: 'MATCH_LIST_FAILURE',

    USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
    USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE',

    GET_MATCH_LIST_REQUEST: 'GET_MATCH_LIST_REQUEST',
    GET_MATCH_LIST_SUCCESS: 'GET_MATCH_LIST_SUCCESS',
    GET_MATCH_LIST_FAILURE: 'GET_MATCH_LIST_FAILURE',

    GET_MATCH_LIST_ADD_SUCCESS: 'GET_MATCH_LIST_ADD_SUCCESS',

    GET_MATCH_LIST_ADD_CASINO_SUCCESS: 'GET_MATCH_LIST_ADD_CASINO_SUCCESS',

    GET_MATCH_LIST_ADD_FANCY_SUCCESS: 'GET_MATCH_LIST_ADD_FANCY_SUCCESS',

    CASINO_GAME_LIST_REQUEST: 'CASINO_GAME_LIST_REQUEST',
    CASINO_GAME_LIST_SUCCESS: 'CASINO_GAME_LIST_SUCCESS',
    CASINO_GAME_LIST_FAILURE: 'CASINO_GAME_LIST_FAILURE',

    CASINO_LOGIN_REQUEST: 'CASINO_LOGIN_REQUEST',
    CASINO_LOGIN_SUCCESS: 'CASINO_LOGIN_SUCCESS',
    CASINO_LOGIN_FAILURE: 'CASINO_LOGIN_FAILURE',

    DOMAIN_SETTING_BY_DOMAIN_NAME_REQUEST: 'DOMAIN_SETTING_BY_DOMAIN_NAME_REQUEST',
    DOMAIN_SETTING_BY_DOMAIN_NAME_SUCCESS: 'DOMAIN_SETTING_BY_DOMAIN_NAME_SUCCESS',
    DOMAIN_SETTING_BY_DOMAIN_NAME_FAILURE: 'DOMAIN_SETTING_BY_DOMAIN_NAME_FAILURE',

    LOGIN_FIRST_REQUEST: 'USERS_FIRST_LOGIN_REQUEST',
    LOGIN_FIRST_SUCCESS: 'USERS_FIRST_LOGIN_SUCCESS',
    LOGIN_FIRST_FAILURE: 'USERS_FIRST_LOGIN_FAILURE',

    SAVE_BET_REQUEST: 'SAVE_BET_REQUEST',
    SAVE_BET_SUCCESS: 'SAVE_BET_SUCCESS',
    SAVE_BET_FAILURE: 'SAVE_BET_FAILURE',

    SAVE_BET_CASINO_REQUEST: 'SAVE_BET_CASINO_REQUEST',
    SAVE_BET_CASINO_SUCCESS: 'SAVE_BET_CASINO_SUCCESS',
    SAVE_BET_CASINO_FAILURE: 'SAVE_BET_CASINO_FAILURE',

    SAVE_BET_FANCY_REQUEST: 'SAVE_BET_FANCY_REQUEST',
    SAVE_BET_FANCY_SUCCESS: 'SAVE_BET_FANCY_SUCCESS',
    SAVE_BET_FANCY_FAILURE: 'SAVE_BET_FANCY_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',

    ADMIN_CODE: "AD",
    SUPER_STOCKIST_CODE: "SSKT",
    STOCKIST_CODE: "SKT",
    AGENT_CODE: "AGT",
    USER_CODE: "USER",

};
