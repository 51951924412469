import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { BsSuitSpadeFill, BsSuitClubFill } from "react-icons/bs";
import { ImDiamonds } from "react-icons/im";
import { AiFillHeart } from "react-icons/ai";
import io from 'socket.io-client';
import PlaceBetMobileBmx from "../../casino_components/PlaceBetMobileBmx/PlaceBetMobileBmx";
import ResultModelBmx from "../../casino_components/ResultModelBmx/ResultModelBmx";
import BetLockedRoundedBmx from "../../casino_components/BetLockedRoundedBmx/BetLockedRoundedBmx";
import FlipCountdown from '@rumess/react-flip-countdown';
import RoundedTabBmx from "../../casino_components/RoundedTabBmx/RoundedTabBmx";
import axios from "axios";
import PageHeaderTheme from "../../casino_components/PageHeaderTheme/PageHeaderTheme";
import Loader from "../../components/Loader/Loader";
import MyBetTabBmx from "../../casino_components/MyBetTabBmx/MyBetTabBmx";
import BetListCasinoTableBmx from "../../casino_components/BetListCasinoTableBmx/BetListCasinoTableBmx";
import BetListTableBmx from "../../casino_components/BetListTableBmx/BetListTableBmx";
import PlaceBetMobileBmxBet from "../../casino_components/PlaceBetMobileBmxBet/PlaceBetMobileBmxBet";
import moment from "moment";
import { httpPost, httpPostBet } from "../../_helpers/http";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from '../../casino_components/ErrorTost/ErrorTost';
import { FaHome } from "react-icons/fa";
import { FaCircleInfo, FaGripLinesVertical } from 'react-icons/fa6';
import Sidebar from "../../components/Sidebar/Sidebar";
import RulesCasino from "../../components/RulesCasino/RulesCasino";
import BetPlaceDesktopCasino from "../../casino_components/BetPlaceDesktopCasino/BetPlaceDesktopCasino";
import BetPlaceMobile from '../../components/BetPlaceMobile/BetPlaceMobile';
import BetPlaceDesktopCasinoLoader from "../../casino_components/BetPlaceDesktopCasinoLoader/BetPlaceDesktopCasinoLoader";
import BetPlaceMobileLoader from "../../components/BetPlaceMobileLoader/BetPlaceMobileLoader";
import ErrorTostCasino from "../../components/ErrorTostCasino/ErrorTostCasino";


class AmarAkbarAnthony extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backBetModal: false,
      offset: 0,
      betSlipData: {},
      fieldsbet: {},
      errorsbet: {},
      count: 0,
      time: 7,
      casinoData: [],
      ResultModel: false,
      Result: false,
      backBetModalMobile: false,
      showLoader: false,
      result: {},
      casinoDetails: {},
      fetchData: "",
      socketURL: "",
      tvUrl: "",
      cacheURL: "",
      betStatus: "",
      cashinoStatus: "",
      eventId: "",
      minStake: "",
      maxStake: "",
      name: "",
      shortName: "aaa",
      betList: [],
      completeBetList: [],
      inCompleteBetList: [],
      betresponse: [],
      newCasinoModal: false,
      BetPlaceDesktop: false,
      betFor: null,
      betOpen: false,
      myBetOpen: false,
      setting: {},
      posArray: {},
      currentRoundId: 0,
      oddsDifference: 0,
      totalProfitLoss: 0,
      clicked: true,
      RulesCasinoModal: false,
      timerKey: 0,
      remainingTime: 10,
      isFatch: false,
      message: "",
      error: "",
      socketPerm: "",
    };
    this.socket = null;
    this.section1Ref = React.createRef();
    this.scrollTimeout = null;
    this.inputRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user && nextProps.user.betPlaceSucess) {
      return {
        ...nextProps,
        backBetModal: false,
        backBetModalMobile: false,
        betSlipData: {},
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  increaseCount = () => {
    const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
    this.setState({ count: parseFloat(increasedCount) });
  };

  decreaseCount = () => {
    const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
    this.setState({ count: parseFloat(decreasedCount) });
  };

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    if (value < 0 || isNaN(Number(value))) {
      value = 0;
    }

    if (value[0] === '0' && value.length > 1) {
      value = value.substring(1);
    }

    let { betSlipData } = this.state;
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
      [name]: Number(value),
    });
  };

  handleBackOpen = (data) => {
    if (this.scrollTimeout) {
      clearInterval(this.scrollTimeout);
    }
    this.setState({ backBetModal: true, backBetModalMobile: true, betSlipData: { ...data, stake: "0" }, count: data.odds, time: 7, });
    this.scrollTimeout = setInterval(() => {
      this.setState(prevState => ({ time: prevState.time - 1 }), () => {
        if (this.state.time <= 0) {
          clearInterval(this.scrollTimeout);
          this.setState({ backBetModal: false });
        }
      });
    }, 1000);
    this.resetTimer();

  };

  handleResponseGame = () => {
    this.props.history.push('/app/casino')
  }


  handleResultModel = (data) => {
    this.setState({ ResultModel: true, result: data });
  };

  updateStackOnclic = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  placeBet = async () => {
    const { casinoData } = this.state;
    this.setState({ LoadingBet: true })
    let { data } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let betObject = {
      "roundId": t1.mid,
      "sid": this.state.betSlipData.data.sid,
      "rate": this.state.count + "",
      "amount": Number(this.state.betSlipData.stake),
      "casinoType": this.state.shortName ? this.state.shortName : "aaa",
      "eventId": this.props.match.params.eventId,
      "betFor": this.state.betSlipData.betFor + "",
      "isLay": this.state.betSlipData.type === "No" ? true : false,
    }

    const result = await httpPostBet("casino/casinoBetPlace", betObject);
    if (result) {
      if (result.error === false) {

        this.setState(
          {
            betresponse: result.data
          },
        );
        this.setState({ isFatch: true, message: result.message, error: false })
        setTimeout(() => {
          this.setState({ isFatch: false })
        }, 3000);
        this.betList(this.props.match.params.eventId)
        this.props.dispatch(userActions.getUserBalance());
      }
      else {
        this.setState({ isFatch: true, message: result.message, error: true })
        setTimeout(() => {
          this.setState({ isFatch: false })
        }, 3000);
      }
    }
    this.handleClose();
    this.setState({ LoadingBet: false })
  };


  toastClose = () => {
    this.setState({ isFatch: false });
  };


  componentDidMount() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    let requestData = {
      eventId: this.props.match.params.eventId,
    }
    this.getDidMountData(requestData)
  }

  getDidMountData = async (requestData) => {
    this.setState({ showLoader: true })
    let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
    if (!getCasinoDetails.error) {
      let casinoDetails = getCasinoDetails.data
      this.setState({
        casinoDetails: casinoDetails,
        fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
        socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
        socketPerm: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : "",
        tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
        cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
        betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
        cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
        eventId: casinoDetails && casinoDetails.eventId ? casinoDetails.eventId : "",
        minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
        maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
        name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
        shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "aaa",
        setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
        oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
      })
      if (casinoDetails.eventId) {
        this.betList(casinoDetails.eventId)
      }

      let socketPerm = ""
      let socketUrl = ""
      let cacheUrl = ""
      if (casinoDetails.fetchData) {
        socketPerm = casinoDetails.fetchData
      }
      if (casinoDetails.socketURL) {
        socketUrl = casinoDetails.socketURL
      }
      if (casinoDetails.cacheURL) {
        cacheUrl = casinoDetails.cacheURL
      }

      if (socketPerm === "socket") {
        await this.connectSocket(socketUrl, socketPerm, casinoDetails.shortName)
      } else {
        await this.callCache(cacheUrl);
      }

    } else {

    }
    this.setState({ showLoader: false })
  }

  connectSocket = async (socketUrl, socketPerm, shortName) => {
    if (!socketUrl) {
      socketUrl = this.state.socketURL
    }
    if (!socketPerm) {
      socketPerm = this.state.socketPerm
    }
    if (!shortName) {
      shortName = this.state.shortName
    }
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      return;
    }

    if (socketPerm === "socket") {
      this.socket = io.connect(socketUrl, {
        transports: ["websocket"],
        reconnection: false,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 99,
      });

      this.socket.emit('JoinRoom', shortName);
      this.socket.on(shortName, data => {
        this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
        this.setState({ casinoData: data })
        this.updatePos()
      });
    }
  }

  callCache = async (cacheUrl) => {
    try {
      this.interval = setInterval(async () => this.getMarketCacheUrl(cacheUrl), 1000);
    } catch (error) {
      console.error('Error calling cache:', error);
    }
  }

  getMarketCacheUrl = async (cacheUrl) => {
    try {
      const response = await axios.get(cacheUrl);
      this.setState({ casinoData: response?.data?.data })
    } catch (error) {
      console.error('Error fetching cache data:', error);
    }
  }

  updatePos = async () => {
    const { betList, currentRoundId } = this.state;
    if (betList && betList.length > 0) {
      const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
      let pushPos = {};
      filteredBets.forEach((bet) => {
        const posArray = bet.posArray;
        Object.entries(posArray).forEach(([key, value]) => {
          pushPos[key] = (pushPos[key] || 0) + Number(value);
        });
      });

      this.setState({ posArray: pushPos });
    }
  }


  betList = async (eventId) => {
    let betReq = {
      "eventId": eventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    };

    let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
    let totalProfitLoss = 0
    let betList = getCasinoDetails?.data?.casinoBetData

    if (betList && betList.length > 0) {
      betList.map((bet, key) => {
        let posArray = bet.posArray
        let profitLoss = "Not Declare"
        let profitLossCount = 0
        if (bet.isDeclare && bet.posArray) {
          profitLoss = posArray[bet.result]
          profitLossCount = posArray[bet.result]
        }
        totalProfitLoss += Number(profitLossCount)
        betList[key].profitLoss = profitLoss
      })
    }
    if (getCasinoDetails) {
      this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
    }
  }

  updateStackOnclick = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  handleRulesCasinoModalOpen = () => {
    this.setState({ RulesCasinoModal: true });
  };

  updateStake = (event) => {
    let { value } = event.target;
    let { betSlipData } = this.state;
    if (value.startsWith('0') && value.length > 1) {
      value = value.slice(1);
    }
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
    });
  };
  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    if (value < 0 || isNaN(Number(value))) {
      value = 0;
    }

    if (value[0] === '0' && value.length > 1) {
      value = value.substring(1);
    }

    let { betSlipData } = this.state;
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
      [name]: Number(value),
    });
  };
  handleCountdown = () => {
    if (this.state.remainingTime > 0) {
      this.setState((prevState) => ({
        remainingTime: prevState.remainingTime - 1,
      }));
    } else {
      // When the timer reaches 0, close the modal
      this.setState({ betPlaceModal: false });
    }
  };
  resetTimer = () => {
    this.setState({
      remainingTime: 10,
      timerKey: this.state.timerKey + 1,
    });
  };

  componentWillUnmount() {
    clearInterval(this.cacheInterval);
    clearTimeout(this.timer);
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
    }
    this.cleanupWebSocket();
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  }

  cleanupWebSocket = () => {
    if (this.socket) {
      this.socket.close();
    }
  };

  handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && !this.state.isConnected) {
      this.connectSocket()
    } else if (document.visibilityState === 'hidden') {
      this.cleanupWebSocket();
    }
  };

  handleClose = () => {
    this.setState({ backBetModal: false, backBetModalMobile: false, RulesCasinoModal: false, });
  };

  handleCloseResult = () => {
    this.setState({ ResultModel: false, });
  };

  render() {
    const { casinoData, name, shortName, ResultModel, time, count, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, oddsDifference, totalProfitLoss, minStake, maxStake, RulesCasinoModal, isFatch, message, error } = this.state;
    let { data, result } = casinoData ? casinoData : {};
    let Amar = data && data.t2 && data.t2[0] ? data.t2[0] : {};
    let Akbar = data && data.t2 && data.t2[1] ? data.t2[1] : {};
    let Anthony = data && data.t2 && data.t2[2] ? data.t2[2] : {};
    let Even = data && data.t2 && data.t2[3] ? data.t2[3] : {};
    let Odd = data && data.t2 && data.t2[4] ? data.t2[4] : {};
    let Red = data && data.t2 && data.t2[5] ? data.t2[5] : {};
    let Black = data && data.t2 && data.t2[6] ? data.t2[6] : {};
    let CardA = data && data.t2 && data.t2[7] ? data.t2[7] : {};
    let Card2 = data && data.t2 && data.t2[8] ? data.t2[8] : {};
    let Card3 = data && data.t2 && data.t2[9] ? data.t2[9] : {};
    let Card4 = data && data.t2 && data.t2[10] ? data.t2[10] : {};
    let Card5 = data && data.t2 && data.t2[11] ? data.t2[11] : {};
    let Card6 = data && data.t2 && data.t2[12] ? data.t2[12] : {};
    let Card7 = data && data.t2 && data.t2[13] ? data.t2[13] : {};
    let Card8 = data && data.t2 && data.t2[14] ? data.t2[14] : {};
    let Card9 = data && data.t2 && data.t2[15] ? data.t2[15] : {};
    let Card10 = data && data.t2 && data.t2[16] ? data.t2[16] : {};
    let CardJ = data && data.t2 && data.t2[17] ? data.t2[17] : {};
    let CardQ = data && data.t2 && data.t2[18] ? data.t2[18] : {};
    let CardK = data && data.t2 && data.t2[19] ? data.t2[19] : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    return (
      <>
        {showLoader ?
          <Loader active={showLoader === true} />
          :
          <div className={`w-[100vw] relative h-100 page_bg text-sm aaaWraper flex`}>
            {isFatch ?
              <ErrorTostCasino message={message} toastClose={this.toastClose} error={error} />
              : null}
            {RulesCasinoModal === true ?
              <RulesCasino
                handleClose={this.handleClose}
                casinorules="/rulesImage/aaaRules.webp"
              />
              : null}
            {ResultModel &&
              ResultModel === true ? (
              <ResultModelBmx
                handleClose={this.handleCloseResult}
                name={name ? name : "Amar Akbar Anthony"}
                shortName={shortName ? shortName : "aaa"}
                result={this.state.result}
              />
            ) : null}
            {LoadingBet === true ?
              <>
                <BetPlaceMobileLoader
                  betSlipData={this.state.betSlipData}
                  time={time}
                  count={count}
                />
              </> :
              <>
                {backBetModal && backBetModal === true ? (
                  <BetPlaceMobile
                    betSlipData={this.state.betSlipData}
                    updateStackOnclick={this.updateStackOnclick}
                    inputChange={this.inputChange}
                    decreaseCount={this.decreaseCount}
                    increaseCount={this.increaseCount}
                    placeBet={this.placeBet}
                    handleClose={this.handleClose}
                    count={this.state.count}
                    timerKey={this.state.timerKey}
                    remainingTime={this.state.remainingTime}
                    handleCountdown={this.handleCountdown}
                    updateStake={this.updateStake}
                  />
                ) : null}
              </>
            }
            <Sidebar props={this.props} />
            <div className="lg:flex block w-full h-full p-1.5 space-x-1.5 md:pt-2 overflow-y-auto overflow-x-hidden pb-2">
              <div className="lg:w-[70%] px-sm-2 px-0 w-full space-y-2 hfull">
                <div>
                  <div className="bg-[#0B0614] flex justify-between w-full relative xl:h-[600px] md:h-[350px] h-[250px] px-2 py-1">
                    <iframe src={tvUrl ? tvUrl : null} title=" " className='relative w-full  ' />
                    <div className="bg-black/70 flex flex-col justify-center items-center absolute top-0 left-0 lg:p-3 p-1.5">
                      <span className="text-[#E18C18] font-bold lg:text-lg text-sm uppercase"> {name ? name : "AMAR AKBAR ANTHONY"}</span>
                      <span className="text-white font-semibold lg:text-sm text-xs">Round ID: {t1 && t1.mid ? t1.mid : null}</span>
                    </div>

                    <div className="flex flex-col justify-end items-end absolute top-0 right-0 lg:p-3 p-1.5 space-y-1">
                      <div className="flex justify-center items-center space-x-2">
                        <span className="p-1.5 lg:text-2xl text-lg rounded-full border-[2px] border-white text-white cursor-pointer" onClick={() => this.handleResponseGame()}><FaHome /></span>
                        <span onClick={this.handleRulesCasinoModalOpen} className="lg:p-1.5 p-1  text-lg lg:text-2xl rounded-full border-[2px] border-white text-white cursor-pointer"><FaCircleInfo /></span>
                      </div>
                    </div>

                    <div className="absolute top-[41%] left-0">
                      <div className="py-1 px-2 bg-black/50 rounded-md flex justify-between items-center space-x-2 h-14 lg:h-16">

                        <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="h-10 lg:h-14 w-8 lg:w-10" />
                      </div>
                    </div>
                    <div className="flex justify-center items-center absolute bottom-2 right-2">
                      <div className={`${t1 && t1.autotime === "0" ? "loader1" : "loader"}`}>
                      </div>
                      <span className="md:text-4xl text-2xl font-bold text-white absolute">{t1 && t1.autotime ? t1.autotime : null}</span>
                    </div>
                  </div>

                </div>

                <div className="space-y-1 bg-[#2E3439] h-auto">

                  <div className="py-1 px-[10px]">
                    <div className="odds-bet ">
                      <div className="space-y-3 ">
                        <div className="grid grid-cols-2 pt-2 space-x-2">
                          <div className="flex justify-between items-center bg-[#3C444B] px-2 ">
                            <p className="text-[14px] font-semibold text-[#EEEEEE] uppercase" >
                              <span>A.</span> {Amar && Amar.nat && Amar.nat ? Amar.nat : "Amar"}</p>
                            <div className={`${posArray[Amar.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[Amar.sid] ?? 0.00}</div>
                          </div>
                          <div className="flex justify-center items-center text-[#000000] border-2 border-[#72BBEF] bg-[#72bBEF40] divide-x-2 divide-[#72BBEF]">
                            <div className="match_bg_blue_index-0 w-full text-center py-2 relative cursor-pointer" onClick={() => this.handleBackOpen({ data: Amar, type: "Yes", odds: Amar.b1, nat: Amar.nat, betFor: "aaa" }, this.section1Ref)}>
                              <div className="text-[16px] font-medium  leading-4">{Amar && Amar.b1 && Amar.b1 ? Amar.b1 : "0.00"}</div>
                              {Amar.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                            </div>
                            <div onClick={() => this.handleBackOpen({ data: Amar, type: "No", odds: Amar.l1, nat: Amar.nat, betFor: "aaa" }, this.section1Ref)} className="match_bg_pink_index-0 w-full text-center py-2 cursor-pointer relative">
                              <div className="text-[16px] font-medium leading-4">{Amar && Amar.l1 && Amar.l1 ? Amar.l1 : "0.00"}</div>
                              {Amar.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 space-x-2">
                          <div className="flex justify-between items-center bg-[#3C444B] px-2 ">
                            <p className="text-[14px] font-semibold text-[#EEEEEE] uppercase">
                              <span>B.</span> {Akbar && Akbar.nat && Akbar.nat ? Akbar.nat : "Akbar"}</p>
                            <div className={`${posArray[Akbar.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[Akbar.sid] ?? 0.00}</div>
                          </div>
                          <div className="flex justify-center items-center text-[#000000] border-2 border-[#72BBEF] bg-[#72bBEF40] divide-x-2 divide-[#72BBEF]">
                            <div className="match_bg_blue_index-0 w-full text-center py-2 relative cursor-pointer"
                              onClick={() => this.handleBackOpen({ data: Akbar, type: "Yes", odds: Akbar.b1, nat: Akbar.nat, betFor: "aaa" }, this.section1Ref)}>
                              <div className="text-[16px] font-medium leading-4">{Akbar && Akbar.b1 && Akbar.b1 ? Akbar.b1 : "0.00"}</div>
                              {Akbar.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                            </div>
                            <div className="match_bg_pink_index-0 w-full text-center py-2 cursor-pointer relative"
                              onClick={() => this.handleBackOpen({ data: Akbar, type: "No", odds: Akbar.l1, nat: Akbar.nat, betFor: "aaa" }, this.section1Ref)}>
                              <div className="text-[16px] font-medium leading-4">{Akbar && Akbar.l1 && Akbar.l1 ? Akbar.l1 : "0.00"}</div>
                              {Akbar.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-2 space-x-2">
                          <div className="flex justify-between items-center bg-[#3C444B] px-2 ">
                            <p className="text-[14px] font-semibold text-[#EEEEEE] uppercase">
                              <span>C.</span>{Anthony && Anthony.nat && Anthony.nat ? Anthony.nat : "Anthony"}</p>
                            <div className={`${posArray[Anthony.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[Anthony.sid] ?? 0.00}</div>
                          </div>
                          <div className="flex justify-center items-center text-[#000000] border-2 border-[#72BBEF] bg-[#72bBEF40] divide-x-2 divide-[#72BBEF]">
                            <div className=" match_bg_blue_index-0 w-full text-center py-2 cursor-pointer relative" onClick={() => this.handleBackOpen(
                              { data: Anthony, type: "Yes", odds: Anthony.b1, nat: Anthony.nat, betFor: "aaa" }, this.section1Ref)}>
                              <div className="text-[16px] font-medium leading-4">{Anthony && Anthony.b1 && Anthony.b1 ? Anthony.b1 : "0.00"}</div>
                              {Anthony.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                            </div>
                            <div className=" match_bg_pink_index-0 w-full text-center py-2 cursor-pointer relative" onClick={() => this.handleBackOpen(
                              { data: Anthony, type: "No", odds: Anthony.l1, nat: Anthony.nat, betFor: "aaa" }, this.section1Ref)}>
                              <div className="text-[16px] font-medium leading-4">{Anthony && Anthony.l1 && Anthony.l1 ? Anthony.l1 : "0.00"}</div>
                              {Anthony.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 py-2 odds-bet px-[10px]">

                    <div className="pb-2 pt-1">
                      <div className="flex justify-center items-center">
                        <p className="text-[16px] font-medium text-[#AAAFB5]">{Even && Even.b1 ? Even.b1 : "0.00"}</p>
                      </div>
                      <div className="relative pb-2 border-2 border-[#72BBEF] bg-[#72bBEF40] rounded-sm   h-14">
                        <div className="w-full text-center py-1.5" onClick={() => this.handleBackOpen({ data: Even, type: "Yes", odds: Even.b1, nat: Even.nat, betFor: "oddEven" }, this.section1Ref)}>
                          <div className="text-[20px] font-bold leading-4 tracking-wider text-[#EEEEEE]">{Even && Even.nat ? Even.nat : "Even"}</div>
                        </div>
                        <div className={`${posArray[Even.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[Even.sid] ?? 0.00}</div>
                        {Even && Even.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                      </div>
                    </div>

                    <div className="pb-2 pt-1">
                      <div className="flex justify-center items-center ">
                        <p className="text-[16px] font-medium text-[#AAAFB5]">{Odd && Odd.b1 ? Odd.b1 : "0.00"}</p>
                      </div>
                      <div className="relative pb-2 border-2 border-[#72BBEF] bg-[#72bBEF40] rounded-sm   h-14">
                        <div className="w-full text-center py-1.5"
                          onClick={() => this.handleBackOpen({ data: Odd, type: "Yes", odds: Odd.b1, nat: Odd.nat, betFor: "oddEven" }, this.section1Ref)}>
                          <div className="text-[20px] font-bold leading-4 tracking-wider text-[#EEEEEE]">{Odd && Odd.nat ? Odd.nat : "Odd"}</div>
                        </div>
                        <div className={`${posArray[Odd.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[Odd.sid] ?? 0.00}</div>
                        {Odd && Odd.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                      </div>

                    </div>

                    <div className="pb-2 pt-1">
                      <div className="flex justify-center items-center ">
                        <p className="text-[16px] font-medium text-[#AAAFB5]">{Red && Red.b1 ? Red.b1 : "0.00"}</p>
                      </div>
                      <div className="relative pb-2 border-2 border-[#72BBEF] bg-[#72bBEF40] rounded-sm   h-14">
                        <div class=" py-1.5 flex justify-center items-center cursor-pointer space-x-0.5"
                          onClick={() => this.handleBackOpen({ data: Red, type: "Yes", odds: Red.b1, nat: Red.nat, betFor: "color" }, this.section1Ref)}>
                          <span className="text-[#FE0000]">
                            <AiFillHeart size={20} />
                          </span>
                          <span className="text-[#FE0000]">
                            <ImDiamonds size={20} />
                          </span>
                        </div>
                        <div className={`${posArray[Red.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[Red.sid] ?? 0.00}</div>
                        {Red && Red.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                      </div>

                    </div>

                    <div className="pb-2 pt-1">
                      <div className="flex justify-center items-center ">
                        <p className="text-[16px] font-medium text-[#AAAFB5]">{Black && Black.b1 ? Black.b1 : "0.00"}</p>
                      </div>
                      <div className="relative pb-2 border-2 border-[#72BBEF] bg-[#72bBEF40] rounded-sm   h-14">
                        <div class=" py-1.5 flex  justify-center items-center cursor-pointer space-x-0.5"
                          onClick={() => this.handleBackOpen({ data: Black, type: "Yes", odds: Black.b1, nat: Black.nat, betFor: "color" }, this.section1Ref)}>
                          <span className="text-[#000000]">
                            <BsSuitSpadeFill size={20} />
                          </span>
                          <span className="text-[#000000]">
                            <BsSuitClubFill size={20} />
                          </span>
                        </div>
                        <div className={`${posArray[Black.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[Black.sid] ?? 0.00}</div>
                        {Black && Black.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                      </div>

                    </div>
                  </div>

                  <div className="py-1 px-[10px]">
                    <div className="odds-bet space-y-2">
                      <p className="flex justify-center items-center text-[#AAAFB5] text-[16px] py-2 bg-[#3C444B] font-bold">11</p>
                      <div class="w-full row row-cols13 !flex-wrap mx-0 justify-center items-center pb-lg-5 pb-0">
                        <div className="col-auto">
                          <div className="relative">
                            <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                              { data: CardA, type: "Yes", odds: CardA.b1, nat: CardA.nat, betFor: "cards" }, this.section1Ref)}>
                              <img src="/assets/images/1.jpg" alt="" className="w-[42px] h-auto object-contain" />
                            </div>
                            {CardA.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                          <div className={`${posArray[CardA.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[CardA.sid] ?? 0.00}</div>
                        </div>
                        <div className="col-auto">
                          <div className="relative">
                            <div className="justify-center flex-col flex items-center"
                              onClick={() => this.handleBackOpen({ data: Card2, type: "Yes", odds: Card2.b1, nat: Card2.nat, betFor: "cards" }, this.section1Ref)}>
                              <img src="/assets/images/2.jpg" alt="" className="w-[42px] h-auto object-contain" />
                            </div>
                            {Card2.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                          <div className={`${posArray[Card2.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card2.sid] ?? 0.00}</div>
                        </div>
                        <div className="col-auto">
                          <div className="relative">
                            <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                              { data: Card3, type: "Yes", odds: Card3.b1, nat: Card3.nat, betFor: "cards" }, this.section1Ref)}>
                              <img src="/assets/images/3.jpg" alt="" className="w-[42px] h-auto object-contain" />
                            </div>
                            {Card3.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                          <div className={`${posArray[Card3.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card3.sid] ?? 0.00}</div>
                        </div>
                        <div className="col-auto">
                          <div className="relative">
                            <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                              { data: Card4, type: "Yes", odds: Card4.b1, nat: Card4.nat, betFor: "cards" }, this.section1Ref)}>
                              <img src="/assets/images/3.jpg" alt="" className="w-[42px] h-auto object-contain" />
                            </div>
                            {Card4.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                          <div className={`${posArray[Card4.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card4.sid] ?? 0.00}</div>
                        </div>
                        <div className="col-auto">
                          <div className="relative">
                            <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                              { data: Card5, type: "Yes", odds: Card5.b1, nat: Card5.nat, betFor: "cards" }, this.section1Ref)}>
                              <img src="/assets/images/5.jpg" alt="" className="w-[42px] h-auto object-contain" />
                            </div>
                            {Card5.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                          <div className={`${posArray[Card5.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card5.sid] ?? 0.00}</div>
                        </div>
                        <div className="col-auto">
                          <div className="relative">
                            <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                              { data: Card6, type: "Yes", odds: Card6.b1, nat: Card6.nat, betFor: "cards" }, this.section1Ref)}>
                              <img src="/assets/images/6.jpg" alt="" className="w-[42px] h-auto object-contain" />
                            </div>
                            {Card6.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                          <div className={`${posArray[Card6.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card6.sid] ?? 0.00}</div>
                        </div>
                        <div className="col-auto">
                          <div className="relative">
                            <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                              { data: Card7, type: "Yes", odds: Card7.b1, nat: Card7.nat, betFor: "cards" }, this.section1Ref)}>
                              <img src="/assets/images/7.jpg" alt="" className="w-[42px] h-auto object-contain" />
                            </div>
                            {Card7.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                          <div className={`${posArray[Card7.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card7.sid] ?? 0.00}</div>
                        </div>
                        <div className="col-auto">
                          <div className="relative">
                            <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                              { data: Card8, type: "Yes", odds: Card8.b1, nat: Card8.nat, betFor: "cards" }, this.section1Ref)}>
                              <img src="/assets/images/8.jpg" alt="" className="w-[42px] h-auto object-contain" />
                            </div>
                            {Card8.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                          <div className={`${posArray[Card8.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card8.sid] ?? 0.00}</div>
                        </div>
                        <div className="col-auto">
                          <div className="relative">
                            <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                              { data: Card9, type: "Yes", odds: Card9.b1, nat: Card9.nat, betFor: "cards" }, this.section1Ref)}>
                              <img src="/assets/images/9.jpg" alt="" className="w-[42px] h-auto object-contain" />
                            </div>
                            {Card9.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                          <div className={`${posArray[Card9.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card9.sid] ?? 0.00}</div>
                        </div>
                        <div className="col-auto">
                          <div className="relative">
                            <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                              { data: Card10, type: "Yes", odds: Card10.b1, nat: Card10.nat, betFor: "cards" }, this.section1Ref)}>
                              <img src="/assets/images/10.jpg" alt="" className="w-[42px] h-auto object-contain" />
                            </div>
                            {Card10.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                          <div className={`${posArray[Card10.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Card10.sid] ?? 0.00}</div>
                        </div>
                        <div className="col-auto">
                          <div className="relative">
                            <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                              { data: CardJ, type: "Yes", odds: CardJ.b1, nat: CardJ.nat, betFor: "cards" }, this.section1Ref)}>
                              <img src="/assets/images/11.jpg" alt="" className="w-[42px] h-auto object-contain" />
                            </div>
                            {CardJ.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                          <div className={`${posArray[CardJ.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[CardJ.sid] ?? 0.00}</div>
                        </div>
                        <div className="col-auto">
                          <div className="relative">
                            <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                              { data: CardQ, type: "Yes", odds: CardQ.b1, nat: CardQ.nat, betFor: "cards" }, this.section1Ref)}>
                              <img src="/assets/images/12.jpg" alt="" className="w-[42px] h-auto object-contain" />
                            </div>
                            {CardQ.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                          <div className={`${posArray[CardQ.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[CardQ.sid] ?? 0.00}</div>
                        </div>
                        <div className="col-auto">
                          <div className="relative">
                            <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                              { data: CardK, type: "Yes", odds: CardK.b1, nat: CardK.nat, betFor: "cards" }, this.section1Ref)}>
                              <img src="/assets/images/13.jpg" alt="" className="w-[42px] h-auto object-contain" />
                            </div>
                            {CardK.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                          </div>
                          <div className={`${posArray[CardK.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[CardK.sid] ?? 0.00}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="md:hidden flex items-center justify-center py-2 !flex-wrap">
                    <div className="flex flexcol justify-center gap-2 items-center">
                      <div className="flex justify-center gap-2 flex-wrap px-2">
                        {result && result.length > 0 ? result.map((element, index) => (
                          <div onClick={() => this.handleResultModel(element)} className={`bg-[#000000] ${element && element.result && element.result === "1" ? "text-[#FC4242]" : element && element.result && element.result === "2" ? "text-[#FDCF13]" : "text-[#03B177]"}  w-[30px] h-[30px] cursor-pointer flex justify-center items-center rounded-[4px] py-[4px] px-[20px]`} >
                            <p className={`font-semibold text-[16px]`}>{element && element.result && element.result === "1" ? "A" : element && element.result && element.result === "2" ? "B" : element && element.result && element.result === "3" ? "C" : "-"}</p>
                          </div>
                        )) : null}
                      </div>
                      {/* <div className="grid grid-cols-3 gap-2 flex-wrap">
                        {result && result.length > 0 ? result.slice(6, 9).map((element, index) => (
                          <div onClick={() => this.handleResultModel(element)} className={`bg-[#000000] ${element && element.result && element.result === "1" ? "text-[#FC4242]" : element && element.result && element.result === "2" ? "text-[#FDCF13]" : "text-[#03B177]"}  w-[30px] h-[30px] cursor-pointer flex justify-center items-center rounded-[4px] py-[4px] px-[20px]`} >
                            <p className={`font-semibold text-[16px]`}>{element && element.result && element.result === "1" ? "A" : element && element.result && element.result === "2" ? "B" : element && element.result && element.result === "3" ? "C" : "-"}</p>
                          </div>
                        )) : null}
                      </div> */}
                    </div>
                  </div>

                </div>

              </div>



              <div className="lg:w-[30%] lg:px-[6px] px-0 w-full lg:space-y-2 space-y-0 mt4 lg:mt0 mx-0">

                <div className="md:block hidden bg-black">
                  <RoundedTabBmx
                    PageTitle={name ? name : "Amar Akbar Anthoney"}
                    ruleImage={"/rulesImage/aaaRules.webp"}
                    eventId={this.props.match.params.eventId} />
                  <div className="flex flex-wrap lg:justify-start justify-center p-1 gap-2 items-center">
                    {result && result.length > 0 ? result.map((element, index) => (
                      <div onClick={() => this.handleResultModel(element)} className={`bg-[#434343] ${element && element.result && element.result === "1" ? "text-[#FC4242]" : element && element.result && element.result === "2" ? "text-[#FDCF13]" : "text-[#03B177]"} w-[39px] h-[30px] cursor-pointer flex justify-center items-center rounded-[4px] py-[4px] px-1`} >
                        <p className={`font-semibold text-[16px]`}>{element && element.result && element.result === "1" ? "A" : element && element.result && element.result === "2" ? "B" : element && element.result && element.result === "3" ? "C" : "-"}</p>
                      </div>
                    )) : null}
                  </div>
                </div>

                <div className="bg-[#3C444B] mt-3 md:block hidden">
                  <div className="bg-[#16191C] text-[#AAAFB5] text-[16px] px-2 py-[6px] flex justify-between items-center">
                    <span className="uppercase font-semibold tracking-wider">
                      Place Bet
                    </span>
                    <div className='font-medium text-[12px]'>
                      Range:{minStake ? minStake : "100"}-
                      {maxStake ? maxStake : "10000"}
                    </div>
                  </div>
                  <div className="px-3 text-[#AAAFB5] text-[16px] py-1.5 flex justify-between items-center ">
                    <span>(Bet for)</span>
                    <span>Odds</span>
                    <span>Stake</span>
                    <span>Profit</span>
                  </div>
                </div>

                {LoadingBet === true ?
                  <>
                    <BetPlaceDesktopCasinoLoader
                      betSlipData={this.state.betSlipData}
                      time={time}
                      count={count}
                    />
                  </> :
                  <>
                    {backBetModal && backBetModal === true ? (
                      <BetPlaceDesktopCasino
                        betSlipData={this.state.betSlipData}
                        updateStackOnclick={this.updateStackOnclick}
                        inputChange={this.inputChange}
                        decreaseCount={this.decreaseCount}
                        increaseCount={this.increaseCount}
                        placeBet={this.placeBet}
                        handleClose={this.handleClose}
                        count={this.state.count}
                        timerKey={this.state.timerKey}
                        remainingTime={this.state.remainingTime}
                        handleCountdown={this.handleCountdown}
                        updateStake={this.updateStake}
                      />
                    ) : null}
                  </>
                }

                <div className="bg-[#3C444B] mt-2">
                  <div className="bg-[#000000] text-[#AAAFB5] text-[16px] px-2.5 py-[6px] flex justify-between items-center">
                    <span className="uppercase font-semibold tracking-wider">
                      MY BETS
                    </span>
                  </div>
                  {/* <div className="px-4 text-[#AAAFB5] text-[16px] py-1 flex justify-between items-center ">
                    <span>Team</span>
                    <span>Mode</span>
                    <span>Rate</span>
                    <span>Amount</span>
                    <span>Result</span>
                    <span>Created</span>
                  </div> */}
                </div>

                <div className="">
                  {betList && betList.length > 0 ?
                    <div className="space-y-[1px] bg-gray-200 pb-1 rounded">
                      {/* <MyBetTabBmx totalProfitLoss={totalProfitLoss} /> */}
                      <BetListCasinoTableBmx betList={betList} />
                    </div>
                    : null}
                </div>
              </div>
            </div>
          </div>}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(AmarAkbarAnthony);
