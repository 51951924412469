import React from "react";

const ErrorTost = (props) => {
    let {message} = props
    return (
        
        <div className="">
            <p className="">
                {message}
            </p>
        </div>
    )
} 

export default ErrorTost;