import React from "react";
import { IoCheckmarkCircle, IoCloseCircle, IoCloseSharp } from 'react-icons/io5';
import { FaCheck } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";

const ErrorTostCasino = (props) => {

    let { message, toastClose, error, isProfile } = props
    return (

        <div>
            {error === false ?
                <div className='fixed w-full h-full top-0 z-40 overflow-x-hidden mt-0 pt-4  overflow-y-auto bgblack/40 md:p-0 p-1 ' onClick={() => toastClose()}>
                    <div className='flex w-[200px] md:w-[250px] px-1 justify-start items-start m-auto '>
                        <div className="w-full bg-white" onClick={() => toastClose()}>
                            <div className=" bg-[#03b37f] text-white px-2 mx-auto text-xl flex justify-start items-start font-bold py-2 leading-5">
                                <div className="md:text-[16px] text-[14px] font-normal text-[white] text-left flex items-center"><FaCheckCircle className="md:w-4 w-4 md:h-4 h-4 me-2 object-contain" /> {isProfile ? message : "Bet Accepted"}</div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='fixed w-full h-full top-0 z-40 overflow-x-hidden mt-0 pt-4  overflow-y-auto bgblack/40 md:p-0 p-1' onClick={() => toastClose()}>
                    <div className='flex w-[200px] md:w-[250px] px-1 justify-start items-start m-auto '>
                        <div className="max-h-full w-full bg-white !rounded-sm " onClick={() => toastClose()}>
                            <div className=" bg-[#fc4242] text-[#E04F5F] px-2 mx-auto text-xl text-center font-bold py-2 leading-5">
                                <div className="md:text-[16px] text-[14px] font-normal text-[white] text-left flex items-start"><IoMdCloseCircle className="md:w-6 w-8 md:h-6 h-8 me-2 object-contain" /> {isProfile ? message : "Minimum stake you can place bet on is 100"}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default ErrorTostCasino;