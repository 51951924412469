import React from "react";
import "./ClosedGames.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Button } from "react-bootstrap";

const ClosedGames = (props) => {
    return (
        <div className="main-bodyTotal h-full">
            <Sidebar props={props} />

            <div className="mtop w-full pt-3 md:pl-[18px] pe-lg-2 pe-0">
                <div className="cricket-icon-color w-full mb-3">
                    <div className="flex justify-between items-center">
                        <div className=" w-full p-[6px] white-text font-medium text-base" >CLOSED GAMES</div>
                        <button class="back-btn text-[14px]">Back</button>
                    </div>
                </div>
                <div>
                    <input className="w-[200px] h-[30px] border border-[#c5c5c5] text-[12px] font-semibold ps-2 rounded-sm mb-2" placeholder="Search" type="text" name="" id="" />
                </div>
                <div>
                    <table className="w-full border-[#c5c5c5] matchTableWrapper">
                        <thead className="text-start">
                            <tr className="bg-[#dcdcdc] matchTr  border-b border-black">
                                <th className="text-sm font-bold text-black border-l border-[#c5c5c5]">Round</th>
                                <th className="text-sm font-bold text-black border-l border-[#c5c5c5]">Result Time</th>
                                <th className="text-sm font-bold text-black border-l border-[#c5c5c5]">P/L</th>
                                <th className="text-sm font-bold text-black border-l border-[#c5c5c5]">Commission</th>
                                <th className="text-sm font-bold text-black border-l border-[#c5c5c5]">Total PL</th>
                                <th className="text-sm font-bold text-black border-l border-[#c5c5c5]">Action</th>
                            </tr>
                        </thead>
                        <tbody className="border text-start border-[#c5c5c5]">
                            <tr className="bg-white matchTr border-b border-[#c5c5c5]">
                                <td className="text-sm font-normal text-black border-l border-[#c5c5c5]">1</td>
                                <td className="text-sm font-normal text-black border-l border-[#c5c5c5]">Match Name</td>
                                <td className="text-sm text-[#5cb85c] font-semibold border-l border-[#c5c5c5]">Sport Name</td>
                                <td className="text-sm text-[#5cb85c] font-semibold border-l border-[#c5c5c5]">Match Status</td>
                                <td className="text-sm text-[#5cb85c] font-semibold border-l border-[#c5c5c5] ">India Women</td>
                                <td className="font-normal text-white border-l border-[#c5c5c5] "><Button className="bg-[#5cb85c] w-[100px] h-[28px] border-0 rounded-sm text-[14px]">Show Bets</Button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className=" w-full p-1 py-1.5 black-black font-bold border-[0.5px] border-gray-600" >
                    No record found
                </div>
            </div>
        </div >
    );
};

export default ClosedGames;