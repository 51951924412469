import React from 'react';
import { connect } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import { httpPost } from "../../_helpers/http";
import { Link } from "react-router-dom";
import CommingSoon from '../../components/CommingSoon/CommingSoon';
import Loader from "../../components/Loader/Loader";

class Matka extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      matkaList: {},
      isFetch: false,
      errorType: "",
      resMessage: "",
      showAlert: false
    }
  }

  componentDidMount() {
    this.getMatkaList();
  }
  getMatkaList = async () => {
    this.setState({ isFetch: true })
    let matkaList = await httpPost('sports/getMatkaList');
    if (matkaList) {
      this.setState({ matkaList: matkaList.data ? matkaList.data : {} })
    } else {
      this.setState({ showAlert: true, errorType: 1, resMessage: "Something went wrong" });
    }
    this.setState({ isFetch: false })
    setTimeout(() => this.setState({ showAlert: false }), 3000);
  }
  handleClose = () => {
    this.setState({ betPlaceModal: false, CommingSoonModal: false });
  };
  handleStakeOpen = () => {
    this.setState({ CommingSoonModal: true });

  };

  render() {
    let { matkaList, errorType, resMessage, showAlert } = this.state;

    return (
      <div className="main-bodyAccount">
        {this.state.isFetch === true ?
          <Loader />
          : null}
        {showAlert && <div className={`absolute top-[2%] right-[2%] px-5 py-3 ${errorType === 1 ? "bg-red-600" : "bg-green-600"}  rounded`}><span className='white-text font-bold'>{resMessage}</span></div>}
        <div className="border border-gray-600"></div>
        {this.state.CommingSoonModal === true ?
          <CommingSoon
            handleClose={this.handleClose}
            message="These Games are Comming Soon"
          />
          : null}
        <Sidebar props={this.props} />
        <div className='w-full matka-background lg:pb-16 pb-32 lg:ml-4 mt-[4rem] matkaPadding mt-lg-0 ml-0 lg:mx-0 mx-1'>
          <div className="live-casino-nav casino-header-background">
            <div className="live-casino white-text">Matka Game List</div>
            <div>
              <Link to="/app/dashboard">
                <button className="live-casino-btn casino-header-button-background black-text" onClick={() => this.props.history.goBack()}>Back</button>
              </Link>
            </div>
          </div>
          <div className='px-2 !h-screen overflow-y-auto'>
            <h2 className='md:text-5xl text-4xl white-text text-center md:py-5 py-3 font-normal'>
              RECENT RESULT
            </h2>
            <div className='space-y-4 mb-72'>
              {matkaList && matkaList.length > 0 ? matkaList.map((element, index) =>
                <div key={index}>
                  <div className='flex justify-between md:text-base text-xs items-center white-text matka-tab-background md:p-3 p-2'>
                    <div>
                      OPEN {" "}{element.open_time}
                    </div>
                    <div className='md:text-xl text-lg px-2'>
                      {element.matka_name}
                    </div>
                    <div>CLOSE {element.close_time}</div>
                  </div>
                  <div className='flex justify-between items-center black-text white-background p-3 font-semibold'>
                    <div className='md:text-4xl text-2xl'>
                      <span>{element.result_array.patti_close_result}</span> -
                      <span className='md:text-5xl text-3xl text-grey font-bold'>{element.result_array.patti_open_result}</span>{" "}
                      <span className='md:text-5xl text-3xl text-grey font-bold'>{element.result_array.single_close_result}</span> -
                      <span>{element.result_array.single_open_result}</span>
                    </div>
                    <div>
                      {element && element.play_status === 1 ?
                        <button className='play-button-background py-2 md:px-4 px-2 uppercase md:text-xl text-sm yellow-color border-[#E1BB3A] border-2 rounded-none' onClick={() => this.handleStakeOpen()}>
                          Play Now
                        </button> :
                        <button className='play-button-background py-2 md:px-4 px-2 uppercase md:text-xl text-sm yellow-color border-[#E1BB3A] border-2 rounded-none'>
                          BET closed
                        </button>
                      }
                    </div>
                  </div>
                </div>) : <div className='flex justify-center md:text-base text-lg items-center white-text matka-tab-background md:p-3 p-2'>
                No Records Found
              </div>}
            </div>
          </div>
        </div>
      </div>


    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(Matka);
