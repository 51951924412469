import React from 'react';
import BetLocked from "../BetLocked/BetLocked";

export default function Card(props) {
    const { Data, num, handleBackOpen, posArray } = props;
    return (
        <div className="col-auto">
            <div className="relative">
                <div onClick={() => handleBackOpen(Data)} className="justify-center flex-col flex items-center">
                    <img src={`/casino-images/${num}.jpg`} alt="aaaaaa"  className="w-[42px] h-auto object-contain"  />
                </div>
                {Data.gstatus === "0" ? <BetLocked /> : null}
            </div>
            <div className={`${posArray[Data.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} font-semibold text-center`} > {posArray[Data.sid] ?? 0.00}</div>
        </div>
    );
}