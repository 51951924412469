import React from 'react';
import './style.css';


const BetLoader = () => {
  return (
    <>
        <div class="betloader absolute top-0 left-0"><img className='w-full h-full object-cover' src="/images/loader.gif" alt="" /></div>
    </>
  )
}

export default BetLoader