import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import { useHistory } from 'react-router-dom';
import treeexchcom from "../../Assets/Images/treeexchcom.png";
import Loader from "../../components/Loader/Loader";
// import "./changepassword.css";

const ChangePassword = (props) => {

  const [fieldsChangePassword, setFieldsChangePassword] = useState({});
  const [errorsChangePassword, setErrorsChangePassword] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsChangePassword((prevState) => ({ ...prevState, [name]: value }));
    setErrorsChangePassword((prevState) => ({ ...prevState, [name]: '' }));
  };

  const history = useHistory();

  const changePassword = () => {
    setIsFetching(true);
    if (handleValidationChangePassword()) {
      const changePasswordData = {
        oldPassword: fieldsChangePassword.oldPassword,
        password: fieldsChangePassword.password,
        transactionPassword: fieldsChangePassword.oldPassword,
      };
      props.dispatch(userActions.changePassword(changePasswordData, history));
    }
    const timeoutId = setTimeout(() => {
      setIsFetching(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  };

  const handleValidationChangePassword = () => {
    let errorsChangePassword = {};
    let formIsValid = true;

    if (!fieldsChangePassword.oldPassword || fieldsChangePassword.oldPassword === '') {
      formIsValid = false;
      errorsChangePassword.oldPassword = 'Old Password Cannot Be Blank.';
    }

    if (!fieldsChangePassword.password || fieldsChangePassword.password === '') {
      formIsValid = false;
      errorsChangePassword.password = 'New Password Cannot Be Blank.';
    }
    // confirmPassword
    if (!fieldsChangePassword["confirmPassword"] || fieldsChangePassword["confirmPassword"] === "") {
      formIsValid = false;
      errorsChangePassword["confirmPassword"] = "Confirm Password Cannot Be Blank";
    } else if (fieldsChangePassword["password"] !== fieldsChangePassword["confirmPassword"]) {
      formIsValid = false;
      errorsChangePassword["confirmPassword"] = "Passwords and Confirm Password do not match";
    } else {
      errorsChangePassword["confirmPassword"] = "";
    }
    console.log("errorsChangePassworderrorsChangePassworderrorsChangePassworderrorsChangePassword:::", errorsChangePassword);
    setErrorsChangePassword(errorsChangePassword);
    return formIsValid;
  };



  return (
    <>
      {isFetching ? <Loader /> : null}
      <div className=''>
        <Sidebar open={true} props={props} showSport={true} />
        <div className='flex justify-center'>
          <div className='w-full'>
            <div className='w-full justify-center flex md:pt-[5rem] pt-[1.5rem] rounded-[4px] px-3'>
              <div className='border-[3px] border-[#007bff] rounded-lg lg:w-[500px] md:w-[600px] password-item w-full'>
                <div className='flex justify-center items-center passLogo'>
                  <img src="/images/vanky-login-logo.png" alt="" className="md:w-[140px] w-[100px] md:h-[60px] h-[45px]" />
                </div>
                <div className='px-3 space-y-4'>
                  <div className="form-group">
                    <input id="oldpassword"
                      className='w-full border-2 border-[#007bff]  p-1.5 focus:outline-none text-xs md:text-sm font-medium text-grey rounded'
                      type="password"
                      name="oldPassword"
                      placeholder='Old Password'
                      value={fieldsChangePassword.oldPassword}
                      onChange={inputChange}
                    />
                  </div>
                  {errorsChangePassword && errorsChangePassword["oldPassword"] ?
                    <div className="flex justify-center">
                      <div className="header-red-text w-full ">
                        {errorsChangePassword["oldPassword"]}
                      </div>
                    </div>
                    : null}

                  <div className="form-group">
                    <input className='w-full border-2 border-[#007bff]  p-1.5 focus:outline-none md:text-sm text-xs font-medium text-grey rounded'
                      type="password"
                      name="password"
                      id="password"
                      placeholder='New Password'
                      value={fieldsChangePassword.password}
                      onChange={inputChange}
                    />
                  </div>
                  {errorsChangePassword && errorsChangePassword["password"] ?
                    <div className="flex justify-center">
                      <div className="header-red-text w-full ">
                        {errorsChangePassword["password"]}
                      </div>
                    </div>
                    : null}

                  <div className="form-group ">
                    <input className='w-full border-2 border-[#007bff]  p-1.5 focus:outline-none text-xs md:text-sm font-medium text-grey rounded'
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      placeholder='Retype Password'
                      value={fieldsChangePassword.confirmPassword}
                      onChange={inputChange}
                    />
                  </div>
                  {errorsChangePassword && errorsChangePassword["confirmPassword"] ?
                    <div className="flex justify-center">
                      <div className="header-red-text w-full ">
                        {errorsChangePassword["confirmPassword"]}
                      </div>
                    </div>
                    : null}
                  <div className="form-group space-x-2 pt-1 py-1">
                    <button onClick={changePassword} type="button" className="bg-[#5cb85c] w-full border-2 border-[#007bff] white-text text-lg uppercase font-bold px-4 py-1 rounded tracking-widest">Submit</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className='lg:col-span-4 col-span-0'></div>
        </div>
      </div>












      {/* <div className=' w-full min-h-screen mainclass '>
        <div className='flex space-x-1 p-1'>
          <Sidebar
            open={true}
            props={props}
            showSport={true}
          />
          <div className='grid grid-cols-12 pt-20'>
            <div className='lg:col-span-4 col-span-0 '></div>
            <div className='w-full lg:col-span-4 col-span-12'>
              <div className='w-full p-4 pt-8 rounded-[4px]'>
                <div className='border-2 border-[#007bff] rounded-lg lg:w-[450px] md:w-[550px] w-[300px]'>
                  <div className='flex justify-center items-center'>
                    <img src={treeexchcom} alt="" className="w-48" />
                  </div>
                  <div className='px-4 space-y-2'>
                    <div className="form-group">
                      <input id="oldpassword"
                        className='w-full border-2 border-[#007bff]  p-1.5 focus:outline-none text-xs md:text-sm font-medium text-grey rounded'
                        type="password"
                        name="oldPassword"
                        placeholder='Old Password'
                        value={fieldsChangePassword.oldPassword}
                        onChange={inputChange}
                      />
                    </div>
                    {errorsChangePassword && errorsChangePassword["oldPassword"] ?
                      <div className="flex justify-center">
                        <div className="header-red-text w-full ">
                          {errorsChangePassword["oldPassword"]}
                        </div>
                      </div>
                      : null}

                    <div className="form-group">
                      <input className='w-full border-2 border-[#007bff]  p-1.5 focus:outline-none md:text-sm text-xs font-medium text-grey rounded'
                        type="password"
                        name="password"
                        id="password"
                        placeholder='New Password'
                        value={fieldsChangePassword.password}
                        onChange={inputChange}
                      />
                    </div>
                    {errorsChangePassword && errorsChangePassword["password"] ?
                      <div className="flex justify-center">
                        <div className="header-red-text w-full ">
                          {errorsChangePassword["password"]}
                        </div>
                      </div>
                      : null}

                    <div className="form-group ">
                      <input className='w-full border-2 border-[#007bff]  p-1.5 focus:outline-none text-xs md:text-sm font-medium text-grey rounded'
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder='Retype Password'
                        value={fieldsChangePassword.confirmPassword}
                        onChange={inputChange}
                      />
                    </div>
                    {errorsChangePassword && errorsChangePassword["confirmPassword"] ?
                      <div className="flex justify-center">
                        <div className="header-red-text w-full ">
                          {errorsChangePassword["confirmPassword"]}
                        </div>
                      </div>
                      : null}
                    <div className="form-group space-x-2 py-3">
                      <button onClick={changePassword} type="button" className="bg-[#5CB85C] w-full border-2 border-[#007bff] text-white text-lg uppercase font-bold px-4 py-1 rounded tracking-widest">Submit</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className='lg:col-span-4 col-span-0'></div>
          </div>
        </div>
      </div> */}


    </>
  );
};

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default withRouter(connect(mapStateToProps)(ChangePassword));