import React, { useState, useEffect } from "react";
import { httpPost } from "../../_helpers/http";
import Loader from "../../components/Loader/Loader";

import { useHistory } from 'react-router-dom';



const IframeCasino = (props) => {
 
    let gameId = props.match.params.gameId
    const [casinoData, setCasinoData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [errorType, setErrorType] = useState(0);
    const [resMessage, setResMessage] = useState("");
    const history = useHistory();
    useEffect(() => {
        getCasinoData();
        if (showAlert) {
            const timeout = setTimeout(() => {
                setShowAlert(false);
                history.push('/app/dashboard');
            }, 2000);

            // Cleanup function to clear timeout when component unmounts or showAlert changes
            return () => clearTimeout(timeout);
        }

    }, [showAlert]);

    const handleIframeLoad = () => {
        setLoading(false);
    };

    const getCasinoData = async () => {

        try {

            let casinoLogin = {
                "gameId": gameId,
                "platformId": "mobile",
                "redirectUrl": "https://vanky12.net/#/app/dashboard",
                "providerName": gameId === "3031" ? "diamond" : "",
            };

            let casinoLoginResponse = await httpPost('user/casinoLoginUrl', casinoLogin);
            if (casinoLoginResponse) {
                setLoading(true);
                setCasinoData(casinoLoginResponse && casinoLoginResponse.data ? casinoLoginResponse.data : {});
                setLoading(false);
            } else {
                // this.setState({ showAlert: true, errorType: 1, resMessage: "Something went wrong" });
                // history.push('/app/dashboard');
                setShowAlert(true);
                setErrorType(1);
                setResMessage("Something went wrong");
            }

        } catch (error) {
            console.error("Error fetching casino data:", error);
            setShowAlert(true);
            setErrorType(1);
            setResMessage("Something went wrong");

            // console.error("Error fetching casino data:", error);
            // Handle error, show a user-friendly message, etc.
            // Set showModal to true in case of an error
        }

    };

    let { clientNotification } =  "";
    return (
        <div className={`min-h-screen ${clientNotification ? "md:pt-[105px] pt-[100px]" : "md:pt-[0px] pt-[3.5rem]"}`}>
             {showAlert && <div className={`absolute top-[2%] right-[2%] px-5 py-3 z-30 ${errorType === 1 ? "bg-red-600 mt-12" : "bg-green-600 mt-12"}  rounded`}><span className='white-text font-bold'>{resMessage}</span></div>}
            {loading === true ?
                <Loader />
                : null}
            {casinoData ? (
                <>
                    {loading === true ?
                        <Loader />
                        : null}
                    <div className="overflow-y-auto h-screen ">
                        <iframe src={casinoData.url} title=" " loading='lazy' className=" mx-auto w-[100%] h-full md-pb-8 md:mb-0 mb-16" onLoad={handleIframeLoad} />
                    </div>
                </>
            ) : <Loader />
            }

        </div>
    );
};

export default IframeCasino;
