import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import { httpPost } from "../../_helpers/http";
import Loader from "../../components/Loader/Loader";

class InnerStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLedgerDetails: {},
      currentPage: 1,
      itemsPerPage: 10,
      errorType: "",
      showAlert: false,
      resMessage: "",
      isFetch: false,
    }
  }

  componentDidMount() {
    this.getUserLedger();
  }

  getUserLedger = async () => {
    let reqData = {
      marketId: this.props.match.params.marketId + ""
    }
    this.setState({ isFetch: true })
    let userLedgerDetails = await httpPost('user/completeLedgerDetails', reqData);
    if (userLedgerDetails) {
      this.setState({ userLedgerDetails: userLedgerDetails.data });
    } else {
      this.setState({ showAlert: true, errorType: 1, resMessage: "Something went wrong" });
    }
    this.setState({ isFetch: false });
    setTimeout(() => this.setState({ showAlert: false }), 2000);
  }


  render() {
    let { userLedgerDetails, resMessage, errorType, showAlert, isFetch } = this.state;
    let { completeData, oddsBetsData, sessionBetsData } = userLedgerDetails;
    let { clientOddsAmount, clientOddsComm, clientSessionAmount, clientSessionComm, isDeclare } = completeData ? completeData : {};

    return (
      <>
        <div className="main-bodyTotal overflow-y-auto h-full">
          {isFetch === true ?
            <Loader />
            : null}
          {showAlert && <div className={`absolute top-[2%] right-[2%] px-5 py-3 z-30 ${errorType === 1 ? "bg-red-600" : "bg-green-600"}  rounded`}><span className='white-text font-bold'>{resMessage}</span></div>}

          {/* <div className='flex lg:space-x-1 space-x-0 lg:p-1 p-0'> */}
          <Sidebar props={this.props} />
          <div className='w-full lg:pl-6 pl-0 overflow-y-auto h-screen'>
            <div className='w-full space-y-4 mt-2 bg-[#fff]'>
              <div className='w-full bg-gradient-to-r from-[#2f5b69] to-[#47889e]  h-10 text-white text-center text-lg flex justify-center items-center font-bold'>
                Match Toss Bets
              </div>
              <div className='w-full bg-gradient-to-r from-[#2f5b69] to-[#47889e]  h-10 text-white text-center text-lg flex justify-center items-center font-bold'>
                Match Winner Bets
              </div>
              {
                (oddsBetsData && oddsBetsData.length > 0) && isDeclare ?
                  <div className='w-full overflow-x-auto scrollbar-hide'>
                    <table className="w-full text-xs text-start">
                      <thead className="rounded-t bg-[#dcdcdc]">
                        <tr className='white-black font-semibold '>
                          <th className="p-1.5 font-semibold border-[1px] text-[16px] text-[#252525] border-[#DCDCDC] px-2 w-[12%]">Rate</th>
                          <th className="p-1.5 font-semibold border-[1px] text-[16px] text-[#252525] border-[#DCDCDC]  px-2 w-[12%]">Amt.</th>
                          <th className="p-1.5 font-semibold border-[1px] text-[16px] text-[#252525] border-[#DCDCDC]  px-2 w-[19%]">Mode</th>
                          <th className="p-1.5 font-semibold border-[1px] text-[16px] text-[#252525] border-[#DCDCDC]  px-2 w-[19%]">Team</th>
                          <th className="p-1.5 font-semibold border-[1px] text-[16px] text-[#252525] border-[#DCDCDC]  px-2 w-[19%]">Result</th>
                          {/* <th className="p-1.5 font-semibold border-[1px] text-[16px] text-[#252525] border-[#DCDCDC]  px-2 w-[25%]">DATE</th> */}
                          <th className="p-1.5 font-semibold border-[1px] text-[16px] text-[#252525] border-[#DCDCDC]  px-2 w-[19%]">P&L</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {oddsBetsData && oddsBetsData ?
                          <>
                            {oddsBetsData.map((element, index) =>
                              <tr key={index} className=" background-white text-[10px] text-[#49494A]">
                                <td className=" whitespace-nowrap border-[1px] text-[16px] border-[#DCDCDC]  w-[12%] px-2">{element.odds}</td>
                                <td className=" whitespace-nowrap border-[1px] text-[16px] border-[#DCDCDC] w-[12%]"> {Number.parseFloat(Math.abs(element.amount)).toFixed(2).replace(/\.?0+$/, "")}
                                </td>
                                <td className=" whitespace-nowrap border-[1px] text-[16px] border-[#DCDCDC] w-[19%]">{element.type === "L" ? "LAGAI" : "KHAI"}
                                </td>
                                <td className=" whitespace-nowrap border-[1px] text-[16px] border-[#DCDCDC] text-start w-[19%]">{element.teamName}</td>
                                {/* <td className="truncate text-start border-[1px] border-[#DCDCDC]  w-[25%]"> {moment(element.createdAt, 'DD-MM-YYYY hh:mm:ss A').format('DD-MM-YYYY hh:mm A')}</td> */}
                                <td className=" whitespace-nowrap border-[1px] text-[16px] border-[#DCDCDC] w-[19%]">Result
                                </td>
                                <td className={`whitespace-nowrap border-[1px] text-[16px] border-[#DCDCDC] w-[19%] ${element.profitLoss > 0 ? "text-[#5199E6]" : "text-[#DC143C]"}`} >
                                {Number.parseFloat(Math.abs(element.profitLoss )).toFixed(2).replace(/\.?0+$/, "")}
                                </td>
                                <marquee className="w-full font-bold red-text  border-[#DCDCDC]">
                                  {element.deletedRemark}
                                </marquee>
                              </tr>)}
                          </> : null
                        }

                      </tbody>
                    </table>
                  </div>
                  : null
              }
              <div className='w-full bg-gradient-to-r from-[#2f5b69] to-[#47889e]  h-10 text-white text-center text-lg flex justify-center items-center font-bold'>
                Fancy Bets
              </div>
              {
                (sessionBetsData && sessionBetsData.length > 0) && isDeclare ?
                  <div className='w-full overflow-x-auto scrollbar-hide'>
                    <table className="w-full text-xs text-start">
                      <thead className="rounded-t bg-[#DCDCDC]">
                        <tr className='white-text font-semibold '>
                          <th className="p-1.5 border-[1px] font-semibold text-[16px] text-[#252525] border-[#DCDCDC] px-2 w-[13%]">Fancy</th>
                          <th className="p-1.5 border-[1px] font-semibold text-[16px] text-[#252525] border-[#DCDCDC]  px-2 w-[12%]">Runs</th>
                          <th className="p-1.5 border-[1px] font-semibold text-[16px] text-[#252525] border-[#DCDCDC]  px-2 w-[12%]">	Rate</th>
                          <th className="p-1.5 border-[1px] font-semibold text-[16px] text-[#252525] border-[#DCDCDC]  px-2 w-[12%]">Amt.</th>
                          <th className="p-1.5 border-[1px] font-semibold text-[16px] text-[#252525] border-[#DCDCDC]  px-2 w-[17%]">Mode</th>
                          {/* <th className="p-1.5 border-[1px] font-semibold text-[16px] text-[#252525] border-[#DCDCDC]  px-2 w-[25%]">DATE</th> */}
                          <th className="p-1.5 border-[1px] font-semibold text-[16px] text-[#252525] border-[#DCDCDC]  px-2 w-[17%]">Result</th>
                          <th className="p-1.5 border-[1px] font-semibold text-[16px] text-[#252525] border-[#DCDCDC]  px-2 w-[17%]">P&L</th>
                        </tr>
                      </thead>
                      <tbody className="">
                        {sessionBetsData && sessionBetsData ?
                          <>
                            {sessionBetsData.map((element, index) =>
                              <tr key={index} className={`${element.isDeleted === 0 ? "background-white text-[#252525]" : "change-password-button text-white"}  text-[12px] font-medium `}>
                                <td className="lg:whitespace-normal whitespace-nowrap border-[1px] text-[16px] font-normal border-[#DCDCDC] w-[13%]">{element.sessionName}</td>
                                {/* <td className="truncate text-start border-[1px] border-[#3d8282]  w-[26%]"> {moment(element.createdAt, 'DD-MM-YYYY hh:mm:ss A').format('DD-MM-YYYY hh:mm A')}</td> */}
                                <td className=" whitespace-nowrap border-[1px] text-[16px] font-normal border-[#DCDCDC]  w-[12%] px-2">{element.run}</td>
                                <td className=" whitespace-nowrap border-[1px] text-[16px] font-normal border-[#DCDCDC]  w-[12%] px-2">{element.odds}</td>
                                <td className=" whitespace-nowrap border-[1px] text-[16px] font-normal border-[#DCDCDC] w-[12%]">{Number.parseFloat(Math.abs(element.amount)).toFixed(2).replace(/\.?0+$/, "")}
                                </td>
                                <td className=" whitespace-nowrap border-[1px] text-[16px] font-normal border-[#DCDCDC] w-[17%]">{element.type === "N" ? "NOT" : "YES"}
                                </td>
                                <td className=" whitespace-nowrap border-[1px] text-[16px] font-normal border-[#DCDCDC] w-[17%]">{element.decisionRun}
                                </td>
                                <td className={`whitespace-nowrap border-[1px] text-[16px] font-normal border-[#DCDCDC] w-[17%] ${element.profitLoss > 0 ? "text-[#5199E6]" : "text-[#DC143C]"}`} >
                                  {Number.parseFloat(Math.abs(element.profitLoss )).toFixed(2).replace(/\.?0+$/, "")}
                                </td>
                                <marquee className="w-full font-bold red-text  border-[#DCDCDC]">
                                  {element.deletedRemark}
                                </marquee>
                              </tr>)}
                          </> : null
                        }

                      </tbody>
                    </table>
                  </div>
                  : null
              }
            </div>
            {(clientOddsComm + clientSessionComm + clientSessionAmount + clientOddsAmount) > 0 ?
              <div className='flex justify-center items-center font-bold text-lg pt-4 bg-[#fff] text-[#008000]'>
                <span>You Won {((clientOddsComm ?? 0) + (clientSessionComm ?? 0) + (clientSessionAmount ?? 0) + (clientOddsAmount ?? 0))}/- Coins.</span>
              </div> :
              <div className='flex justify-center items-center font-bold text-lg pt-4 bg-[#fff] text-[#FF0C00]'>
                <span>You Lost {((clientOddsComm ?? 0) + (clientSessionComm ?? 0) + (clientSessionAmount ?? 0) + (clientOddsAmount ?? 0))}/- Coins.</span>
              </div>
            }
            <div className='grid grid-cols-2 pb-16'>
              <div className='w-full bg-gradient-to-r from-[#2f5b69] to-[#47889e]  h-10 text-white text-center text-lg flex justify-center items-center font-bold'>
                Match Plus Minus
              </div>
              {clientOddsAmount > 0 ?
                <div className='w-full bg-[#D8D8D8] border-t border-b border-black  h-10 text-[#5CB864] text-center text-base flex justify-center items-center font-bold'>
                  <span>You Won {clientOddsAmount ? clientOddsAmount : 0}/- Coins.</span>
                </div> :
                <div className='w-full bg-[#D8D8D8] border-t border-b border-black  h-10 text-[#FF0C00] text-center text-base flex justify-center items-center font-bold'>
                  <span>You Lost {clientOddsAmount ? clientOddsAmount : 0}/- Coins.</span>
                </div>
              }
              <div className='w-full bg-[#A3E672]  h-10 text-[#333333] text-center text-lg flex justify-center items-center font-bold'>
                Fancy Plus Minus
              </div>
              {clientSessionAmount > 0 ?
                <div className='w-full bg-[#ffffff] border-t border-b border-black  h-10 text-[#5CB864] text-center text-base flex justify-center items-center font-bold'>
                  <span>You Won {clientSessionAmount ? clientSessionAmount : 0}/- Coins.</span>
                </div> :
                <div className='w-full bg-[#ffffff] border-t border-b border-black  h-10 text-[#FF0C00] text-center text-base flex justify-center items-center font-bold'>
                  <span>You Lost {clientSessionAmount ? clientSessionAmount : 0}/- Coins.</span>
                </div>
              }
              <div className='w-full bg-gradient-to-r from-[#2f5b69] to-[#47889e]  h-10 text-white text-center text-lg flex justify-center items-center font-bold'>
                Total Commission
              </div>
              {(clientOddsComm + clientSessionComm) > 0 ?
                <div className='w-full bg-[#D8D8D8] border-t border-b border-black  h-10 text-[#5CB864] text-center text-base flex justify-center items-center font-bold'>
                  <span>You Won {(clientOddsComm ?? 0) + (clientSessionComm ?? 0)}/- Coins.</span>
                </div> :
                <div className='w-full bg-[#D8D8D8] border-t border-b border-black  h-10 text-[#FF0C00] text-center text-base flex justify-center items-center font-bold'>
                  <span>You Lost {(clientOddsComm ?? 0) + (clientSessionComm ?? 0)}/- Coins.</span>
                </div>
              }
              <div className='w-full bg-[#A3E672]  h-10 text-[#333333] text-center text-lg flex justify-center items-center font-bold'>
                Mob. App. Charges
              </div>
              <div className='w-full bg-[#ffffff] border-t border-b border-black  h-10 text-[#FF0C00] text-center text-base flex justify-center items-center font-bold'>
                You Lost 0/- Coins
              </div>
              <div className='w-full bg-gradient-to-r from-[#2f5b69] to-[#47889e]  h-10 text-white text-center text-lg flex justify-center items-center font-bold'>
                Net Plus Minus
              </div>
              {(clientOddsComm + clientSessionComm + clientSessionAmount + clientOddsAmount) > 0 ?
                <div className='w-full bg-[#D8D8D8] border-t border-b border-black  h-10 text-[#5CB864] text-center text-base flex justify-center items-center font-bold'>
                  <span>You Won {((clientOddsComm ?? 0) + (clientSessionComm ?? 0) + (clientSessionAmount ?? 0) + (clientOddsAmount ?? 0))}/- Coins.</span>
                </div> :
                <div className='w-full bg-[#D8D8D8] border-t border-b border-black  h-10 text-[#FF0C00] text-center text-base flex justify-center items-center font-bold'>
                  <span>You Lost {((clientOddsComm ?? 0) + (clientSessionComm ?? 0) + (clientSessionAmount ?? 0) + (clientOddsAmount ?? 0))}/- Coins.</span>
                </div>
              }
            </div>
          </div>
          {/* </div> */}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default withRouter(connect(mapStateToProps)(InnerStatement));
