import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { FaLock, FaUser } from "react-icons/fa";
// import "./Login.css";
import { HiChevronRight } from "react-icons/hi";
import Loader from "../../components/Loader/Loader";

class Login extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      showMe: false,
      passtype: "password",
    };
  }


  componentDidMount() {
    let userData = JSON.parse(localStorage.getItem('spuser'));
    if (userData) {
      this.props.history.push(`app/dashboard`)
    }
  }

  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    this.setState({ fieldslogin, errorslogin });
  }

  loginSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let loginData = {
        username: this.state.fieldslogin.username.toUpperCase().trim(),
        password: this.state.fieldslogin.password.trim(),
        isClient: true,
        host: window.location.host,
      };
      this.props.dispatch(userActions.login(loginData, this.props));
    }

  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    });
    this.hideErrorMessage();
  };

  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //User Name
    if (!fieldslogin["username"]) {
      formIsValid = false;
      errorslogin["username"] = "User Name Cannot Be Blank.";
    }
    //password
    if (!fieldslogin["password"]) {
      formIsValid = false;
      errorslogin["password"] = "Password Cannot Be Blank.";
    }

    console.log("errorsloginerrorsloginerrorsloginerrorslogin:::", errorslogin);

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  };

  show = () => {
    this.setState({ showMe: true, passtype: "text" });
  };
  hide = () => {
    this.setState({ showMe: false, passtype: "password" });
  };
  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.loginSubmit(e);
    }
  };

  render() {
    let { loading } = this.props;
    return (
      <>
        {loading === true ?
          <Loader />
          : null}
        <div className="bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url("/images/bg.webp")` }} >
          <div className="bg-black/40 flex items-center justify-center min-h-screen min-w-screen" >
            <div className="w-[360px] h-[425px] overflow-hidden bg-white/80 shadow-[-0px_0px_0px_2px_rgb(120,117,181)] rounded-3xl pl-[40px] pr-[30px] ">
              <div className="space-y-10 h-full">
                <div className="flex justify-center items-center pt-2 w-full">
                  <img src="/images/vanky-login-logo.png" alt="" />
                </div>
                <div className="w-full">
                  <div className="flex justify-start items-start">
                    <div className="relative">
                      <FaUser className="absolute login-icon-color h-4 w-4 pt-1" />
                      <input
                        className={`py-2.5 px-8 pl-8 text-xl black-text font-bold placeholder:text-gray-500 capitalize ${this.state.errorslogin && this.state.errorslogin["username"] ? "border-red-500 border" : "border-b-2 border-gray-300 focus:border-[#5c5696]"} focus:outline-none bg-transparent w-52 rounded-none`}
                        placeholder="Enter User Id..."
                        onChange={this.inputChange}
                        type="text"
                        name="username"
                        id="username"
                        value={this.state.fieldslogin.username}
                        onKeyDown={this.handleKeyDown}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full ">
                  <span className="flex justify-start items-start">
                    <div className="relative">
                      <FaLock className="absolute login-icon-color h-4 w-4 pt-1" />
                      <input
                        className={`py-2.5 px-8 pl-8 text-xl black-text font-bold placeholder:text-gray-500 ${this.state.errorslogin && this.state.errorslogin["password"] ? "border-red-500 border" : "border-b-2 border-gray-300 focus:border-[#5c5696]"} focus:outline-none bg-transparent w-52 rounded-none`}
                        placeholder="Password"
                        onChange={this.inputChange}
                        type="password"
                        name="password"
                        id="password"
                        value={this.state.fieldslogin.password}
                        onKeyDown={this.handleKeyDown}
                        required
                      />
                    </div>
                  </span>
                </div>
                <div className=" pt-3 px-1">
                  <button onClick={this.loginSubmit} className="w-64 white-text px-1 py-2 flex justify-between items-center uppercase cursor-pointer border-[#d4d3e8] black-background font-semibold rounded-[10px] text-sm shadow-md border-b shadow-[#5c5696] tracking-wider" type="submit">
                    <span className="pl-4">log in now</span>
                    <HiChevronRight className="login-icon-color h-10 w-10" />
                  </button>
                  <div className="font-bold text-xs black-text pt-2 tracking-tight">
                    Note - This Website is not for Indian Territory.
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div >
      </>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, loading } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    user,
    users,
    loading,
  };
}
export default connect(mapStateToProps)(Login);
