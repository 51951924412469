
import React, { useState } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from 'react-router';
import Layout from "./Layout";
import Error from "../pages/error";
import Login from "../pages/login";
import NetworkDetector from '../components/Hoc/NetworkDetector';

function App({ component: Component, ...rest }) {
  // useEffect(() => {
  //   const detectDevTools = () => {
  //     const devToolsListener = new ResizeObserver(() => {
  //       if (performance.now() > 1) {
  //         console.log('Developer tools detected. Be cautious while making changes.');
  //       }
  //     });

  //     devToolsListener.observe(document.documentElement);

  //     const devToolsCheck = setInterval(() => {
  //       if (window.outerWidth - window.innerWidth > 100 || window.outerHeight - window.innerHeight > 100) {
  //         console.log('Developer tools detected. Be cautious while making changes.');
  //         window.location.href = "/login"

  //       }
  //     }, 1000);

  //     return () => {
  //       devToolsListener.disconnect();
  //       clearInterval(devToolsCheck);
  //     };
  //   };

  //   detectDevTools();
  // }, []);
  // global
  //var { isAuthenticated } = useUserState();
  let user = JSON.parse(localStorage.getItem('spuser'));
  const tokenExpiration = localStorage.getItem(user && user.token ? user.token : "");
  const currentTimestamp = Date.now() / 1000; // Convert milliseconds to seconds

  // Check if the token is expired (assuming tokenExpiration is in seconds)
  const isTokenExpired = tokenExpiration && currentTimestamp > parseInt(tokenExpiration, 10);

  

  if (isTokenExpired) {
    // Token expired, redirect to login
    return <Redirect to="/login" />;
  }
 

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="app/dashboard" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="app/dashboard" />}
        />
        <PrivateRoute path="/app" component={withRouter(Layout)} />
        <PublicRoute path="/login" component={withRouter(Login)} />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          localStorage.getItem('spuser') ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          false ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
export default App;