
import { authHeader, history } from '../_helpers';
import { CONST } from '../_config';

export const
    userService = {

        login,
        logout,
        notification,
        getUserBalance,
        casinoLogin,
        MatchList,
        casinoGameList,
        save_bet,
        saveBetCasino,
        save_bet_fancy,
        getMatchList,
        userUpdate,
        changePassword,
        userAccountDetails,
        domainSettingByDomainName,
    };

function logout() {
    // localStorage.removeItem('spuser');
    localStorage.removeItem('hasSeenPopup');
    localStorage.removeItem('MatchList');
    localStorage.removeItem('finalBalance');
    localStorage.removeItem('userData');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('betChipsData');
    localStorage.removeItem('rateDiff');
    localStorage.removeItem('position');
    localStorage.removeItem('Balance');
    localStorage.removeItem('exposure');
    // localStorage.removeItem('domainSettingByDomainName');

}

// function logoutTeamp() {
//     // localStorage.removeItem('spuser');
//     history.push(`#/app/dashboard`);
//     window.location.reload()
// }

// function onerrorlogout() {

//     // localStorage.removeItem('user');
//     history.push(`#/app/dashboard`);
//     window.location.reload();
// }
function login(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `user/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user,
                betChipsData: user && user.data && user.data.betChipsData
            }
            if (user) {
                localStorage.setItem('spuser', JSON.stringify(user));
                localStorage.setItem('Balance', JSON.stringify(user.data.balance));
                localStorage.setItem('rateDiff', JSON.stringify(user.data.rateReffrence));
            }
            if (user.data.betChipsData) {
                localStorage.setItem('betChipsData', JSON.stringify(user.data.betChipsData));
            }

            return userObj;
        });
}

function notification() {
    let data = {
        // for: "client"
    }

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `user/notificationList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                notification: data.data
            }
            if (data.data) {
                localStorage.setItem('notification', JSON.stringify(data.data));
            }
            return userObj;
        });
}

function getUserBalance(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `user/userBalance`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getUserBalance: data.data
            }
            if (data) {
                localStorage.setItem('Balance', JSON.stringify(data.data.coins));
                localStorage.setItem('exposure', JSON.stringify(data.data.exposure));
            }
            return userObj;
        });
}

function userAccountDetails(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `user/userAccountDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                userAccountDetails: data.data
            }
            return userObj;
        });
}

function userUpdate(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "PATCH",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `user/userUpdate`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                userUpdate: data
            }
            return userObj;
        });
}


function changePassword(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "PATCH",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `user/updateUserPassword`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                changePassword: data.message
            }
            return userObj;
        });
}


function getMatchList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `sports/betMatchDetails`, requestOptions)
        // return fetch(`https://alb.1ex.in/getMatchList?id=${data.marketId}`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                getMatchList: data,
                oddsBetData: data.data.oddsBetData,
                fancyBetData: data.data.fancyBetData,
                inplayMatchList: data.data.inplayMatchList,
                ScoreIframe: data.data.matchDetails.scoreIframe,
                tvUrl: data.data.matchDetails.tvUrl,
                matchPosition: data.data.matchDetails.matchPosition,
            }
            if (data) {
                localStorage.setItem('position', JSON.stringify(data.data.matchPosition));
            }
            return userObj;
        });
}


function MatchList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    // return fetch(`http://owner.sixpro.in/api_inplay`, requestOptions)
    return fetch(CONST.BACKEND_URL + `sports/matchList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                MatchList: data
            }
            if (data) {
                localStorage.setItem('MatchList', JSON.stringify(data.data));
            }
            return userObj;
        });
}

// let userObj = {
//     userinfo: user,
//     betChipsData: user && user.data && user.data.betChipsData
// }
// if (user) {
//     localStorage.setItem('spuser', JSON.stringify(user));
// }
// if (user.data.betChipsData) {
//     localStorage.setItem('betChipsData', JSON.stringify(user.data.betChipsData));
// }

function casinoGameList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `sports/casinoBetList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                casinoGameList: data.data
            }
            return userObj;
        });
}



function saveBetCasino(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `casino/casinoBetPlaced`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                errorData: data,
                saveBetCasino: data,
                // responseData: JSON.parse(requestOptions.body)
            }
            // if (data) {
            //     let finalBalance = {
            //         mainBlance: data.data.totalCoins,
            //         exposureBalance: data.data.exposure
            //     }
            //     localStorage.setItem('finalBalance', JSON.stringify(finalBalance));
            // }
            return userObj;
        });
}

function save_bet(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `sports/oddBetPlaced`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                errorData: data,
                save_bet: data.data,
                responseData: JSON.parse(requestOptions.body)
            }
            if (data) {
                // let finalBalance = {
                //     mainBlance: data.data.totalCoins,
                //     exposureBalance: data.data.exposure
                // }
                localStorage.setItem('Balance', JSON.stringify(data.data.totalCoins));
                localStorage.setItem('exposure', JSON.stringify(data.data.exposure));
                localStorage.setItem('position', JSON.stringify(data.data.matchPosition));
            }
            return userObj;
        });
}

function save_bet_fancy(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `sports/sessionBetPlaced`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                errorDataFancy: data,
                save_betFancy: data.data,
                responseDataFancy: JSON.parse(requestOptions.body)
            }
            if (data) {
                // let finalBalance = {
                //     mainBlance: data.data.totalCoins,
                //     exposureBalance: data.data.exposure
                // }
                // localStorage.setItem('finalBalance', JSON.stringify(finalBalance));
                localStorage.setItem('Balance', JSON.stringify(data.data.totalCoins));
                localStorage.setItem('exposure', JSON.stringify(data.data.exposure));
            }
            return userObj;
        });
}

function casinoLogin(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `user/casinoLoginUrl`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                casinoLogin: data.data
            }

            return userObj;
        });
}

function domainSettingByDomainName(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify({ domainUrl: window.location.origin })
    }
    return fetch(CONST.BACKEND_URL + `website/domainSettingByDomainName`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                domainSettingByDomainName: data.data
            }
            if (data) {
                localStorage.setItem('domainSettingByDomainName', JSON.stringify(data.data));
            }
            return userObj;
        });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('spuser');
                history.push(`#/login`);
                window.location.reload()
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (!response.ok) {
            // console.log("response.status", response.status);
            if (response.status === 400) {
                localStorage.removeItem('spuser');
                history.push(`#/login`);
                window.location.reload()
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {

                localStorage.removeItem('spuser');
                history.push(`#/login`);
                window.location.reload();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}