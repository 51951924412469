import axios from "axios";
import { authHeader } from "./auth-header";
import { CONST } from '../_config';

axios.interceptors.response.use((response) => {
    if (response.config.parse) {
        //perform the manipulation here and change the response object
    }
    return response;
}, (error) => {
    // if (error.response.status === 401) {
    //     localStorage.clear();
    //     sessionStorage.clear();
    //     localStorage.removeItem("user-data");
    //     localStorage.removeItem("access-token");

    //     window.location.href = "/login";
    //     return false
    // }

    // return Promise.reject(error.message);
});


// axios.interceptors.response.use((response) => {
//     if (response.status === 200) {
//         // alert("You are not authorized");
//     }
//     return response;
// }, (error) => {
//     if (error.response && error.response.data) {
//         return Promise.reject(error.response.data);
//     }
//     return Promise.reject(error.message);
// });

const httpGet = async (url, params, isNotify) => {
    try {
        let headers = {
            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        };
        // params = { ...params };
        const result = await axios({
            method: "POST",
            url: CONST.BACKEND_URL + url,
            data: { ...params },
            headers: headers,
        });

        invalidToken(result.code);

        return result;
    } catch (err) {
        console.error(err);
    }
};


const httpPost = async (url, params, isNotify) => {
    try {
        let headers = {
            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        };
        const result = await axios({
            method: "POST",
            url: CONST.BACKEND_URL + url,
            data: { ...params },
            headers: headers,
        });

        if (result.data.error && isNotify) {
            // error(result.message)
            //alert(result.data.message)
        }
        else if (isNotify && !result.data.error) {
            // success(result.message)
            //alert(result.data.message)
        }
        invalidToken(result.code);

        return result.data
    } catch (err) {
        console.error(err);
    }
};

const httpPostBet = async (url, params) => {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(params)
    }
    return fetch(CONST.BACKEND_URL + url, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });

};

const httpPatch = async (url, params, isNotify) => {
    try {
        let headers = {
            'Content-Type': 'application/json',
            "Authorization": authHeader().Authorization
        };
        const result = await axios({
            method: "PATCH",
            url: CONST.BACKEND_URL + url,
            data: { ...params },
            headers: headers,
        });

        if (result.data.error && isNotify) {
            // error(result.message)
            //alert(result.data.message)
        }
        else if (isNotify && !result.data.error) {
            // success(result.message)
            //alert(result.data.message)
        }
        invalidToken(result.code);
        return result.data
    } catch (err) {
        console.error(err);
    }
};

const invalidToken = (code) => {
    if (code === 3) {
        localStorage.clear();
        sessionStorage.clear();
        localStorage.removeItem("user-data");
        localStorage.removeItem("access-token");

        window.location.href = "/login";
        return false
    }

};

const logoutTeamp = () => {
    sessionStorage.clear();
    localStorage.removeItem("clientuser-data");
    localStorage.removeItem("clientaccess-token");
    window.location.href = "/login";
    return false
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logoutTeamp();
            }
        }
        if (data.error) {
            if (data.code === 3) {

                logoutTeamp()
            }
        }
        return data;
    });
}

export { httpGet, httpPost, httpPatch, httpPostBet };
