import React, { Component } from "react";
import "./LiveBetHistory.css";
import Sidebar from "../../components/Sidebar/Sidebar";
import { httpPost } from "../../_helpers/http";
import moment from 'moment';
import Pagination from "../../components/Pagination/Pagination";
import Loader from "../../components/Loader/Loader";
import { FaFilter } from "react-icons/fa";


class LiveBetHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsTransction: {},
            errors: {},
            BetHistoryType: "",
            userBetHistory: {},
            endDate: moment().format('YYYY-MM-DD'),
            startDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
            currentDate: moment().format('YYYY-MM-DD'),
            currentPage: 1,
            itemsPerPage: 10,
            errorType: "",
            showAlert: false,
            resMessage: "",
            isFetch: false,
            activeTab: 1,
            isCompleted: false,
        }
    }
    componentDidMount() {
        this.getUserBetHistory();
    }

    inputChangeTransction = (e) => {
        e.preventDefault();
        let { name, value } = e.target;
        let fieldsTransction = this.state.fieldsTransction;
        let errors = this.state.errors;
        fieldsTransction[name] = value;
        errors[name] = "";
        this.setState({ fieldsTransction, errors });
    }

    handleTabClick = (tabIndex) => {
        this.setState({ activeTab: tabIndex });
    };

    handleSelectChange = (e) => {
        this.setState({
            isCompleted: e.target.value === "true" ? true : false,
        });
    };

    SubmitBetHistorysearch = () => {
        this.getUserBetHistory();
    }

    inputChangeStartDate = (e) => {
        this.setState({ startDate: e.target.value });
    }

    inputChangeEndDate = (e) => {
        this.setState({ endDate: e.target.value });
    }

    getUserBetHistory = async () => {
        let reqData = {
            // "marketId": "1.222996661", // required
            "casinoBet": this.state.fieldsTransction.gameType === "diamondCasino" ? false : true,  // optional
            "diamondBet": this.state.fieldsTransction.gameType === "Spribe" ||this.state.fieldsTransction.gameType === "Royal Gaming    " ? false : true,  // optional
            // "oddsBet": true,  // optional
            "isCompleted": this.state.fieldsTransction.ledgerType === "Open" ? false : true, // optional
            "fromDate": this.state.startDate,
            "toDate": this.state.endDate,
            // "status": this.state.fieldsTransction.ledgerType,
            // "gameType": this.state.fieldsTransction.gameType,
            // "selectionId": "23" // optional
            //  "downlineUserId":"ffff",  // optional
            //  "downlineUserType":"master" // optional
        }
        this.setState({ isFetch: true })
        let userBetHistory = await httpPost('sports/betsList', reqData);
        if (userBetHistory) {
            this.setState({ userBetHistory: userBetHistory.data });
        } else {
            this.setState({ showAlert: true, errorType: 1, resMessage: "Something went wrong" });
        }
        this.setState({ isFetch: false });
        setTimeout(() => this.setState({ showAlert: false }), 2000);
    }
    handlePageChange = (page) => {
        this.setState({ currentPage: page });
    };


    render() {
        let { userBetHistory, currentPage, itemsPerPage, isFetch } = this.state;
        let { casinoBetData, diamondBetData } = userBetHistory;
        let finalobject = [];



        diamondBetData && diamondBetData.forEach((casinoData) => {

            let pushObj = {
                eventName: casinoData.betFor,
                market: casinoData.casinoType,
                profitLoss: casinoData.profitLoss,
                createdAt: casinoData.createdAt,
                roundId: casinoData.roundId,
                resultDetails: casinoData.resultDetails,
                betType: casinoData.betType,
                amount: casinoData.amount,
                isDeclare: casinoData.isDeclare,
                ip: casinoData.ip,
                userInfo: casinoData.userInfo,

            }
            finalobject.push(pushObj)
        })

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const currentItems = Array.isArray(finalobject) ? finalobject.slice(startIndex, endIndex) : [];
        let user = JSON.parse(localStorage.getItem('spuser'));

        return (
            <div className="d-flex">
                {isFetch === true ?
                    <Loader />
                    : null}
                <Sidebar props={this.props} />
                <div className="w-full h-full lg:pl-6 pl-0 pt-2">
                    <div className="cricket-icon-color flex justify-between items-center">
                        <div className="flex justify-start items-center" >
                            <div className="w-full p-[6px] white-text font-normal text-base" >CLIENT CASINO BETS REPORT </div>
                            <select className="p-1 rounded-none">
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </div>
                        {/* <button className="back-btn text-[14px]" onClick={() => this.props.history.goBack()}>Back</button> */}
                    </div>
                    <div className="lg:flex hidden md:flex-row flex-col justify-start items-start md:space-x-5 space-x-0 md:space-y-0 space-y-2 py-1.5 px-2 bg-white w-full">
                        <input type="date" className="px-2 lg:py-1.5 py-2 lg:w-52 w-full text-[12px] bg-white border-[1px] border-[#007bff] focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[4px] cursor-pointer"
                            name="Date"
                            dateformat="yyyy-mm-dd"
                            id="datepicker" required
                            value={this.state.startDate} onChange={this.inputChangeStartDate}
                        />
                        <input type="date" className="px-2 lg:py-1.5 py-2 lg:w-52 w-full text-[12px] bg-white border-[1px] border-[#007bff]  focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[4px] cursor-pointer"
                            name="Date"
                            dateformat="yyyy-mm-dd"
                            id="datepicker" required
                            value={this.state.endDate} onChange={this.inputChangeEndDate}
                        />
                        <input type="search" className="px-2 lg:py-1.5 py-2 lg:w-28 w-full text-[12px] bg-white border-[1px] border-[#007bff]  focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[4px] cursor-pointer"
                            name="search"
                            placeholder="Search"
                            id="search"
                        />
                        <select className="md:w-44 w-full px-2 h-[35px] py-1.5 text-[12px] text-gray-600 border-[1px] border-[#007bff] rounded-[3px] bg-white cursor-pointer" aria-label="select" id="gameType" name="gameType" value={this.state.fieldsTransction && this.state.fieldsTransction["gameType"] ? this.state.fieldsTransction["gameType"] : ""} onChange={this.inputChangeTransction}>
                            <option value="ALL">ALL Game</option>
                            <option value="diamondCasino">Casino</option>
                            <option value="Spribe">Aviator</option>
                            <option value="Royal Gaming">Royal Gamming</option>
                        </select>
                        <select className="md:w-44 w-full px-2 h-[35px] py-1.5 text-[12px] text-gray-600 border-[1px] border-[#007bff] rounded-[3px] bg-white cursor-pointer" aria-label="select" id="ledgerType" name="ledgerType" value={this.state.fieldsTransction && this.state.fieldsTransction["ledgerType"] ? this.state.fieldsTransction["ledgerType"] : ""} onChange={this.inputChangeTransction} >
                            <option value="Open">Open</option>
                            <option value="Settlement">Settlement</option>
                        </select>
                        <button onClick={this.SubmitBetHistorysearch} className="bg-[#5cb85c] white-text px-4 text-[14px] py-1.5 rounded-[3px] flex justify-center items-center space-x-1"><FaFilter size={12} /><span>Filter</span></button>
                    </div>

                    <div className="lg:hidden flex md:flex-row flex-col justify-start items-start md:space-x-5 space-x-0 md:space-y-0 space-y-2 py-2 px-2 bgwhite w-full">
                        <div className="flex justify-between items-center space-x-2 w-full">
                            <input type="date" className="px-2 lg:py-1.5 py-2 lg:w-52 w-full text-[12px] bg-white border-[1px] border-[#007bff] focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[4px]"
                                name="Date"
                                dateformat="yyyy-mm-dd"
                                id="datepicker" required
                                value={this.state.startDate} onChange={this.inputChangeStartDate}
                            />
                            <input type="date" className="px-2 lg:py-1.5 py-2 lg:w-52 w-full text-[12px] bg-white border-[1px] border-[#007bff]  focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[4px]"
                                name="Date"
                                dateformat="yyyy-mm-dd"
                                id="datepicker" required
                                value={this.state.endDate} onChange={this.inputChangeEndDate}
                            />
                        </div>
                        <div className="flex justify-between items-center space-x-2 w-full">
                            <input type="search" className="px-2 lg:py-1.5 py-2 lg:w-28 w-full text-[12px] bg-white border-[1px] border-[#007bff]  focus:outline-none text-gray-600 placeholder:text-gray-500 rounded-[4px]"
                                name="search"
                                placeholder="Search"
                                id="search"
                            />
                            <select className="md:w-44 w-full px-2 h-[35px] py-1.5 text-[12px] text-gray-600 border-[1px] border-[#007bff] rounded-[3px] bg-white" aria-label="select" id="ledgerType" name="ledgerType" value={this.state.fieldsTransction && this.state.fieldsTransction["ledgerType"] ? this.state.fieldsTransction["ledgerType"] : ""} onChange={this.inputChangeTransction} >
                                <option value="Settlement">Settlement</option>
                                <option value="Open">Open</option>
                            </select>
                        </div>
                        <div className="flex justify-start items-center space-x-2 w-full">
                            <select className="md:w-44 w-[50%] px-2 h-[35px] py-1.5 text-[12px] text-gray-600 border-[1px] border-[#007bff] rounded-[3px] bg-white cursor-pointer" aria-label="select" id="gameType" name="gameType" value={this.state.fieldsTransction && this.state.fieldsTransction["gameType"] ? this.state.fieldsTransction["gameType"] : ""} onChange={this.inputChangeTransction}>
                                <option value="ALL">ALL Game</option>
                                <option value="diamondCasino">Casino</option>
                                <option value="Spribe">Aviator</option>
                                <option value="Royal Gaming">Royal Gamming</option>
                            </select>
                            <button onClick={this.SubmitBetHistorysearch} className="bg-[#5cb85c] white-text px-4 text-[14px] py-1.5 rounded-[3px] flex justify-center items-center space-x-1"><FaFilter size={12} /><span>Filter</span></button>
                        </div>
                    </div>

                    <div className='w-full table-responsive bgwhite px-2'>
                        <table className="w-full border-[1px] text-start table table-striped text-[12px]">
                            <thead className="rounded-t bg[#DCDCDC]">
                                <tr className='text-[#252525] bg-[#dcdcdc] font-normal text-[14px]'>
                                    <th className="whitespace-nowrap font-normal text-[16px] w-[3%]">S.No.</th>
                                    <th className="whitespace-nowrap font-normal text-[16px] w-[5%]">Client</th>
                                    <th className="whitespace-nowrap font-normal text-[16px] w-[16%]">Round Id</th>
                                    <th className="whitespace-nowrap font-normal text-[16px] w-[11%]">Casino Name</th>
                                    <th className="whitespace-nowrap font-normal text-[16px] w-[11%]">Player Name</th>
                                    <th className="whitespace-nowrap font-normal text-[16px] w-[5%]">Type</th>
                                    <th className="whitespace-nowrap font-normal text-[16px] w-[5%]">Stack</th>
                                    <th className="whitespace-nowrap font-normal text-[16px] w-[11%]">Date</th>
                                    <th className="whitespace-nowrap font-normal text-[16px] w-[5%]">P_L</th>
                                    <th className="whitespace-nowrap font-normal text-[16px] w-[5%]">Status</th>
                                    <th className="whitespace-nowrap font-normal text-[16px] w-[11%]">IP</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {currentItems && currentItems.length > 0 ?
                                    <>
                                        {currentItems && currentItems.length > 0 ? currentItems.map((element, index) => (
                                            <tr key={index} className="bg-transparent text-[#252525] text-start text-[12px]">
                                                <td  style={{border: '1px solid #c3bdbd'}} className=" w-[3%]  md:whitespace-normal text-[16px] whitespace-nowrap">{index + 1} </td>
                                                {/* <td  style={{border: '1px solid #c3bdbd'}} className=" w-[5%]  md:whitespace-normal text-[16px] whitespace-nowrap">{element.userInfo.clientName}</td> */}
                                                <td  style={{border: '1px solid #c3bdbd'}} className=" w-[5%]  md:whitespace-normal text-[16px] whitespace-nowrap">{element?.userInfo?.name}</td>
                                                <td  style={{border: '1px solid #c3bdbd'}} className=" w-[16%] md:whitespace-normal text-[16px] whitespace-nowrap ">{element?.roundId}</td>
                                                <td  style={{border: '1px solid #c3bdbd'}} className=" w-[5%]  md:whitespace-normal text-[16px] whitespace-nowrap">{element?.market}</td>
                                                <td  style={{border: '1px solid #c3bdbd'}} className=" w-[11%] md:whitespace-normal text-[16px] whitespace-nowrap ">{element?.eventName}</td>
                                                <td  style={{border: '1px solid #c3bdbd'}} className=" w-[5%]  md:whitespace-normal text-[16px] whitespace-nowrap">{element?.betType}</td>
                                                <td  style={{border: '1px solid #c3bdbd'}} className=" w-[5%]  md:whitespace-normal text-[16px] whitespace-nowrap">{element?.amount} </td>
                                                <td  style={{border: '1px solid #c3bdbd'}} className=" w-[11%] md:whitespace-normal text-[16px] whitespace-nowrap ">{moment(element?.createdAt).format('ddd MMM DD hh:mm:ss [IST] YYYY')}</td>
                                                <td  style={{border: '1px solid #c3bdbd'}} className={`w-[5%]  md:whitespace-normal text-[16px] whitespace-nowrap ${(element.profitLoss) >= 0 ? "text-[#3C763D]" : "text-red-500"}`}>{element?.profitLoss}</td>
                                                <td  style={{border: '1px solid #c3bdbd'}} className=" w-[5%]  md:whitespace-normal text-[16px] whitespace-nowrap">{element && element.isDeclare === 1 ? "OPEN" : "CLOSE"}</td>
                                                <td  style={{border: '1px solid #c3bdbd'}} className=" w-[11%] md:whitespace-normal text-[16px] whitespace-nowrap truncate">{element?.ip}</td>
                                            </tr>)) : null}
                                    </> : <tr className="bg-transparent text-[#252525] text-[12px] font-normal">
                                        <td className="whitespace-nowrap flex justify-start items-center w-full border-[1px] border-[#b9b9b9] col-span-6">No Record Found</td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={Math.ceil(finalobject && finalobject.length / itemsPerPage)}
                            maxVisibleButtons={1}
                            onPageChanged={this.handlePageChange}
                        />
                    </div>

                </div>
            </div >
        );
    }
}

export default LiveBetHistory;
