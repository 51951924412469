import React from 'react';
import { betChipsData } from '../../_config';
import { FaTimes } from 'react-icons/fa';



export default function BetPlaceMobileLoader(props) {
    const { updateStackOnclick, placeBet, handleClose, betSlipData, timerKey, handleCountdown, remainingTime, updateStake, minStake, maxStake } = props;
    let betChipsDatas = JSON.parse(localStorage.getItem('betChipsData'));
    const myArray = betChipsDatas && betChipsDatas.length > 0 ? Object.keys(betChipsDatas) : Object.keys(betChipsData);

    const handleCloseModal = () => {
        handleClose()
    }

    return (
        <div className='h-full md:h-screen m-auto z-50 w-full bg-black/30 pt-0 lg:hidden fixed  top-0 left-0 flex justify-center items-center'>

        {/* <div className={`${betSlipData.selectionId ? 'focus:absolute' : ''}`}> */}
        <div className={`${`bg-[#16191C] `}`}>
            <div className="w-full h-full flex justify-between page_bg p-2 items-center">
                <div className="text-[16px]  flex justify-start items-center space-x-1 ">
                    <h2 className="text-[#FDCD21] text-lg tracking-wide font-normal">
                        Place Bet
                    </h2>
                    <span className='text-[#ffff] px-2 font-medium text-[12px] pt-1 '>
                        Range : {minStake ? minStake : "100"} -
                        {maxStake ? maxStake : "10000"}
                    </span>
                </div>

                <div className='text-center pl-2' >
                    <FaTimes className='text-white cursor-pointer' size={20} />
                </div>
            </div>
            {/* <div className="state-name w-full">{betSlipData && betSlipData.name ? betSlipData.name : null}</div> */}
            <div className=''>
                <div className="p-2 ">

                    <div className="flex text-[#FDCD21] justify-start space-x-2 items-center">
                        <div className=' text-lg tracking-wide font-normal whitespace-nowrap'>
                            {betSlipData && betSlipData.nat ? betSlipData.nat : null}</div>
                        <div className='text-end w-full font-bold text-lg'>Odds - {betSlipData && betSlipData.rate ? betSlipData.rate : betSlipData.odds ? betSlipData.odds : null}</div>
                    </div>
                    <hr class="text-[#AAAFB5] my-2" />
                    <div className="choice-betting">
                        {/* <input type="text" className="odd-input" placeholder="0" value={betSlipData.odds} /> */}
                        <input type="text" className="px-[14px] border-[1px] border-white rounded-sm py-1.5 w-1/3 bg-[#23292E] white-text" placeholder="Amount" value={betSlipData.stake} />
                    </div>

                    <div className='row px-[10px] py-2'>
                        {myArray && myArray.length > 0 ? myArray?.map((element, index) => (
                            <div key={index} className="col-3 p-0.5 flex justify-center items-center">
                                <button className='bg-[#03B37F52] text-white w-full px-4 p-2 rounded-sm'  >{element}</button>
                            </div>
                        )) : null}
                    </div>
                    <div className='flex justify-between items-center xl:space-x-2 space-x-1'>
                            {/* <span className='bg-[#E61414] text-white text-xs w-full px-3 p-2 rounded-none text-center' onClick={() => updateStackOnclick("0")}>C</span>
                            <button className="bg-[#E61414] text-white text-xs w-full px-3 p-2 rounded-none" onClick={() => handleClose()}>Clear All</button> */}
                            <button className="bg-[#03B37F] text-white text-base w-full px-3 py-2 rounded-none flex items-center justify-center space-x-4" ><div>Submit</div>
                                <div className="xl:px-4 px-0 white-text h-2 flex justify-center items-center cursor-pointer border-[#d4d3e8] rounded-none font-extrabold text-sm" type="submit">
                                    <div className=" flex items-center justify-center absolute bg-transparent">
                                        <div className="flex items-center justify-center bg-transparent w-full">
                                            <div className="w-5 h-5 rounded-full animate-spin 
                        border-[5px] border-solid border-[#ffffff] border-t-transparent"></div>
                                        </div>
                                    </div>
                                </div>

                            </button>
                        </div>





                    {/* <div className='flex justify-center items-center'>
                        <button className="bg-[#128767] hover:bg-[#03B37F] hover:text-white text-gray-400 p-2 w-full px-5 text-lg" onClick={() => placeBet()}>Submit</button>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
        // </div>
    );
}

