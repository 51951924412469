import React from "react";
import { connect } from "react-redux";
import FlipCountdown from '@rumess/react-flip-countdown';
import io from 'socket.io-client';
import moment from "moment";
import PlaceBetMobileBmx from "../../casino_components/PlaceBetMobileBmx/PlaceBetMobileBmx";
import ResultModelBmx from "../../casino_components/ResultModelBmx/ResultModelBmx";
import GameCard from "../../casino_components/GameCard/GameCard";
import GameCard2 from "../../casino_components/GameCard2/GameCard2";
import RoundedTabBmx from "../../casino_components/RoundedTabBmx/RoundedTabBmx";
import axios from "axios";
import PageHeaderTheme from "../../casino_components/PageHeaderTheme/PageHeaderTheme";
import MyBetTabBmx from "../../casino_components/MyBetTabBmx/MyBetTabBmx";
import BetListTableBmx from "../../casino_components/BetListTableBmx/BetListTableBmx";
import BetListCasinoTableBmx from "../../casino_components/BetListCasinoTableBmx/BetListCasinoTableBmx";
import PlaceBetMobileBmxBet from "../../casino_components/PlaceBetMobileBmxBet/PlaceBetMobileBmxBet";
import Loader from "../../components/Loader/Loader";
import { httpPost, httpPostBet } from "../../_helpers/http";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from '../../casino_components/ErrorTost/ErrorTost';
import { userActions } from "../../_actions";
import "./Casino.css";
import { FaHome } from "react-icons/fa";
import { FaCircleInfo, FaGripLinesVertical } from 'react-icons/fa6';
import Sidebar from "../../components/Sidebar/Sidebar";
import RulesCasino from "../../components/RulesCasino/RulesCasino";
import BetPlaceDesktopCasino from "../../casino_components/BetPlaceDesktopCasino/BetPlaceDesktopCasino";
import Card from "../../casino_components/Card/Card";
import BetPlaceMobile from '../../components/BetPlaceMobile/BetPlaceMobile';
import BetPlaceDesktopCasinoLoader from "../../casino_components/BetPlaceDesktopCasinoLoader/BetPlaceDesktopCasinoLoader";
import BetPlaceMobileLoader from "../../components/BetPlaceMobileLoader/BetPlaceMobileLoader";
import ErrorTostCasino from "../../components/ErrorTostCasino/ErrorTostCasino";


class Lucky7A extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backBetModal: false,
      offset: 0,
      betSlipData: {},
      fieldsbet: {},
      errorsbet: {},
      count: 0,
      time: 7,
      casinoData: [],
      ResultModel: false,
      backBetModalMobile: false,
      showLoader: false,
      result: {},
      casinoDetails: {},
      fetchData: "",
      socketURL: "",
      tvUrl: "",
      cacheURL: "",
      betStatus: "",
      cashinoStatus: "",
      eventId: "",
      minStake: "",
      maxStake: "",
      name: "",
      shortName: "lucky7eu",
      betList: [],
      completeBetList: [],
      inCompleteBetList: [],
      betresponse: [],
      newCasinoModal: false,
      BetPlaceDesktop: false,
      betFor: null,
      betOpen: false,
      myBetOpen: false,
      setting: {},
      posArray: {},
      currentRoundId: 0,
      oddsDifference: 0,
      totalProfitLoss: 0,
      timerKey: 0,
      remainingTime: 10,
      RulesCasinoModal: false,
      isFatch: false,
      message: "",
      error: false,
      socketPerm: "",
    };
    this.socket = null;
    this.section1Ref = React.createRef();
    this.scrollTimeout = null;
    this.inputRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users && nextProps.users.betPlaceSucess) {
      return {
        ...nextProps,
        backBetModal: false,
        backBetModalMobile: false,
        betSlipData: {},
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }

  increaseCount = () => {
    const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
    this.setState({ count: parseFloat(increasedCount) });
  };

  decreaseCount = () => {
    const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
    this.setState({ count: parseFloat(decreasedCount) });
  };

  handleBackOpen = (data) => {
    this.betForSet(data.nat)
    if (this.scrollTimeout) {
      clearInterval(this.scrollTimeout);
    }
    this.setState({ backBetModal: true, backBetModalMobile: true, betSlipData: { ...data, stake: "0" }, count: data.rate, time: 7, });
    this.scrollTimeout = setInterval(() => {
      this.setState(prevState => ({ time: prevState.time - 1 }), () => {
        if (this.state.time <= 0) {
          clearInterval(this.scrollTimeout);
          this.setState({ backBetModal: false });
        }
      });
    }, 1000);
    this.resetTimer();

  };

  betForSet = (nat) => {
    let value = nat.split(' ')[0];
    if (value === "HIGH") {
      this.setState({ betFor: "lowHigh" })
    } else if (value === "LOW") {
      this.setState({ betFor: "lowHigh" })
    } else if (value === "Card") {
      this.setState({ betFor: "cards" })
    } else if (value === "Red") {
      this.setState({ betFor: "color" })
    } else if (value === "Black") {
      this.setState({ betFor: "color" })
    } else if (value === "Odd" || "Even") {
      this.setState({ betFor: "oddEven" })
    }
  }

  handleResultModel = (data) => {
    this.setState({ ResultModel: true, result: data });
  };

  componentDidMount() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    let requestData = {
      eventId: this.props.match.params.eventId,
    }
    this.getDidMountData(requestData)
  }

  getDidMountData = async (requestData) => {
    this.setState({ showLoader: true })
    let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
    if (!getCasinoDetails.error) {
      let casinoDetails = getCasinoDetails.data
      this.setState({
        casinoDetails: casinoDetails,
        fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
        socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
        socketPerm: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : "",
        tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
        cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
        betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
        cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
        eventId: casinoDetails && casinoDetails.eventId ? casinoDetails.eventId : "",
        minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
        maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
        name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
        shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "lucky7eu",
        setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
        oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
      })
      if (casinoDetails.eventId) {
        this.betList(casinoDetails.eventId)
      }

          
      let socketPerm = ""
      let socketUrl = ""
      let cacheUrl = ""
      if (casinoDetails.fetchData) {
        socketPerm = casinoDetails.fetchData
      }
      if (casinoDetails.socketURL) {
        socketUrl = casinoDetails.socketURL
      }
      if (casinoDetails.cacheURL) {
        cacheUrl = casinoDetails.cacheURL
      }

      if (socketPerm === "socket") {
        await this.connectSocket(socketUrl, socketPerm, casinoDetails.shortName)
      } else {
        await this.callCache(cacheUrl);
      }
      
    } else {

    }
    this.setState({ showLoader: false })
  }

  connectSocket = async (socketUrl, socketPerm, shortName) => {
    if (!socketUrl) {
      socketUrl = this.state.socketURL
    }
    if (!socketPerm) {
      socketPerm = this.state.socketPerm
    }
    if (!shortName) {
      shortName = this.state.shortName
    }
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      return;
    }

    if (socketPerm === "socket") {
      this.socket = io.connect(socketUrl, {
        transports: ["websocket"],
        reconnection: false,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 99,
      });

      this.socket.emit('JoinRoom', shortName);
      this.socket.on(shortName, data => {
        this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
        this.setState({ casinoData: data })
        this.updatePos()
      });
    }
  }

  callCache = async (cacheUrl) => {
    try {
      this.interval = setInterval(async () => this.getMarketCacheUrl(cacheUrl), 1000);
    } catch (error) {
      console.error('Error calling cache:', error);
    }
  }

  getMarketCacheUrl = async (cacheUrl) => {
    try {
      const response = await axios.get(cacheUrl);
      this.setState({ casinoData: response?.data?.data })
    } catch (error) {
      console.error('Error fetching cache data:', error);
    }
  }
  	
  
  

  toastClose = () => {
    this.setState({ isFatch: false });
};

  updatePos = async () => {
    const { betList, currentRoundId } = this.state;
    if (betList && betList.length > 0) {
      const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
      let pushPos = {};
      filteredBets.forEach((bet) => {
        const posArray = bet.posArray;
        Object.entries(posArray).forEach(([key, value]) => {
          pushPos[key] = (pushPos[key] || 0) + Number(value);
        });
      });

      this.setState({ posArray: pushPos });
    }
  }

  betList = async (eventId) => {
    let betReq = {
      "eventId": eventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    };

    let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
    let totalProfitLoss = 0
    let betList = getCasinoDetails?.data?.casinoBetData

    if (betList && betList.length > 0) {
      betList.map((bet, key) => {
        let posArray = bet.posArray
        let profitLoss = "Not Declare"
        let profitLossCount = 0
        if (bet.isDeclare && bet.posArray) {
          profitLoss = posArray[bet.result]
          profitLossCount = posArray[bet.result]
        }
        totalProfitLoss += Number(profitLossCount)
        betList[key].profitLoss = profitLoss
      })
    }
    if (getCasinoDetails) {
      this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
    }
  }

  componentWillUnmount() {
    clearInterval(this.cacheInterval);
    clearTimeout(this.timer);
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
    }
    this.cleanupWebSocket();
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  }

  cleanupWebSocket = () => {
    if (this.socket) {
      this.socket.close();
    }
  };

  handleVisibilityChange = () => {
    if (document.visibilityState === 'visible' && !this.state.isConnected) {
      this.connectSocket()
    } else if (document.visibilityState === 'hidden') {
      this.cleanupWebSocket();
    }
  };

  handleClose = () => {
    this.setState({ newCasinoModal: false, betOpen: false, stakeopen: false, backBetModal: false, backBetModalMobile: false, RulesCasinoModal: false, });
  };
  handleCloseResult = () => {
    this.setState({ ResultModel: false, });
  };


  updateStackOnclic = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  placeBet = async () => {
    const { casinoData } = this.state;
    this.setState({ LoadingBet: true })
    let { data } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let betObject = {
      "roundId": t1.mid,
      "sid": this.state.betSlipData.sid,
      "rate": (this.state.count - this.state.oddsDifference) + "",
      "amount": Number(this.state.betSlipData.stake),
      "casinoType": this.state.shortName ? this.state.shortName : "lucky7eu",
      "eventId": this.props.match.params.eventId,
      "betFor": this.state.betFor + "",
    }

    const result = await httpPostBet("casino/casinoBetPlace", betObject);
    if (result) {
      if (result.error === false) {

        this.setState(
          {
            betresponse: result.data
          },
        );
        this.setState({ isFatch: true, message: result.message, error: false })
        setTimeout(() => {
          this.setState({ isFatch: false })
      }, 3000);
        this.betList(this.props.match.params.eventId)
        this.props.dispatch(userActions.getUserBalance());
      }
      else {
        this.setState({ isFatch: true, message: result.message, error: true })
        setTimeout(() => {
            this.setState({ isFatch: false })
        }, 3000);
      }
    }
    this.handleClose();
    this.setState({ LoadingBet: false })
  };

  handleRulesCasinoModalOpen = () => {
    this.setState({ RulesCasinoModal: true });
  };

  updateStackOnclick = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStake = (event) => {
    let { value } = event.target;
    let { betSlipData } = this.state;
    if (value.startsWith('0') && value.length > 1) {
      value = value.slice(1);
    }
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
    });
  };
  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    if (value < 0 || isNaN(Number(value))) {
      value = 0;
    }

    if (value[0] === '0' && value.length > 1) {
      value = value.substring(1);
    }

    let { betSlipData } = this.state;
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
      [name]: Number(value),
    });
  };
  handleCountdown = () => {
    if (this.state.remainingTime > 0) {
      this.setState((prevState) => ({
        remainingTime: prevState.remainingTime - 1,
      }));
    } else {
      // When the timer reaches 0, close the modal
      this.setState({ betPlaceModal: false });
    }
  };
  resetTimer = () => {
    this.setState({
      remainingTime: 10,
      timerKey: this.state.timerKey + 1,
    });
  };
  handleResponseGame = () => {
    this.props.history.push('/app/casino')
  }


  render() {

    const { casinoData, name, shortName, ResultModel, time, count, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, oddsDifference, totalProfitLoss, minStake, maxStake, RulesCasinoModal ,isFatch, message, error } = this.state;
    let LOWCard = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[0] ? casinoData.data.t2[0] : {};
    let HIGHCard = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[1] ? casinoData.data.t2[1] : {};
    let Even = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[2] ? casinoData.data.t2[2] : {};
    let Odd = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[3] ? casinoData.data.t2[3] : {};
    let Red = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[4] ? casinoData.data.t2[4] : {};
    let Black = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[5] ? casinoData.data.t2[5] : {};
    let t1 = casinoData && casinoData.data && casinoData.data && casinoData.data.t1 && casinoData.data.t1[0] ? casinoData.data.t1[0] : {};
    let CardA = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[6] ? casinoData.data.t2[6] : {};
    let Card2 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[7] ? casinoData.data.t2[7] : {};
    let Card3 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[8] ? casinoData.data.t2[8] : {};
    let Card4 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[9] ? casinoData.data.t2[9] : {};
    let Card5 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[10] ? casinoData.data.t2[10] : {};
    let Card6 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[11] ? casinoData.data.t2[11] : {};
    let Card7 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[12] ? casinoData.data.t2[12] : {};
    let Card8 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[13] ? casinoData.data.t2[13] : {};
    let Card9 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[14] ? casinoData.data.t2[14] : {};
    let Card10 = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[15] ? casinoData.data.t2[15] : {};
    let CardJ = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[16] ? casinoData.data.t2[16] : {};
    let CardQ = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[17] ? casinoData.data.t2[17] : {};
    let CardK = casinoData && casinoData.data && casinoData.data && casinoData.data.t2 && casinoData.data.t2[18] ? casinoData.data.t2[18] : {};

    // console.log(casinoData.result,"datataa");


    return (
      <>
        {showLoader ?
          <Loader active={showLoader === true} />
          :
          <div className={`w-[100vw] relative page_bg text-sm aaaWrapper flex`}>
             {isFatch ?
              <ErrorTostCasino message={message} toastClose={this.toastClose} error={error} />
              : null}
            {RulesCasinoModal === true ?
              <RulesCasino
                handleClose={this.handleClose}
                casinorules="/rulesImage/lucky7-rules.webp"
              />
              : null}
            {ResultModel && ResultModel === true ? (
              <ResultModelBmx
                handleClose={this.handleCloseResult}
                name={name ? name : "Lucky7-A"}
                shortName={shortName ? shortName : "lucky7eu"}
                result={this.state.result}
              />
            ) : null}

            {LoadingBet === true ?
              <>
                <BetPlaceMobileLoader
                  betSlipData={this.state.betSlipData}
                  time={time}
                  count={count}
                />
              </> :
              <>
                {backBetModal && backBetModal === true ? (
                  <BetPlaceMobile
                    betSlipData={this.state.betSlipData}
                    updateStackOnclick={this.updateStackOnclick}
                    inputChange={this.inputChange}
                    decreaseCount={this.decreaseCount}
                    increaseCount={this.increaseCount}
                    placeBet={this.placeBet}
                    handleClose={this.handleClose}
                    count={this.state.count}
                    timerKey={this.state.timerKey}
                    remainingTime={this.state.remainingTime}
                    handleCountdown={this.handleCountdown}
                    updateStake={this.updateStake}
                  />
                ) : null}
              </>
            }
            <Sidebar props={this.props} />
            <div className="lg:flex w-full space-x-1.5 md:pt-2 overflow-x-hidden pb-2">
              <div className="lg:w-[70%] px-[6px] md:w-full mx-auto !hfull">

                <div>
                  <div className="bg-[#0B0614] flex justify-between w-full relative xl:h-[610px] md:h-[350px] h-[250px]">
                    <iframe src={tvUrl ? tvUrl : null} title=" " className='relative w-full  ' />
                    <div className="bg-black/70 flex flex-col justify-center items-center absolute top-0 left-0 lg:p-3 p-1.5">
                      <span className="text-[#E18C18] font-bold lg:text-lg text-sm">{name ? name : "Lucky 7"}</span>
                      <span className="text-white font-semibold lg:text-sm text-xs">
                        Round ID: {t1 && t1.mid ? t1.mid : null}
                      </span>
                    </div>

                    <div className="absolute top-[41%] left-0">
                      <div className="py-1 px-2 bg-black/50 rounded-r-md flex justify-between items-center space-x-2 h-14 lg:h-16">
                        <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="h-10 lg:h-14 w-8 lg:w-10" />
                      </div>
                    </div>

                    <div className="flex flex-col justify-end items-end absolute top-0 right-0 lg:p-3 p-1.5 space-y-1">
                      <div className="flex justify-center items-center space-x-2">
                        <span className="p-1.5 lg:text-2xl text-lg rounded-full border-[2px] border-white text-white cursor-pointer" onClick={() => this.handleResponseGame()}><FaHome /></span>
                        <span onClick={this.handleRulesCasinoModalOpen} className=" lg:p-1.5 p-1  text-lg lg:text-2xl rounded-full border-[2px] border-white text-white cursor-pointer"><FaCircleInfo /></span>
                      </div>
                    </div>
                    <div className="flex justify-center items-center absolute bottom-2 right-2">
                      <div className={`${t1 && t1.autotime === "0" ? "loader1" : "loader"}`}>
                      </div>
                      <span className="md:text-4xl text-2xl font-bold text-white absolute">{t1 && t1.autotime ? t1.autotime : null}</span>
                    </div>
                  </div>

                </div>
                <div className=" pt-2 space-y-4 grey_background">

                  <div className="py-1">
                    <div className=" odds-bet  w-full ">
                      <div className=" grid md:grid-cols-5 grid-cols-3 text-center md:px-10 px-2 w-full">
                        <div class="md:col-span-2 col-span-1 w-full ">
                          <GameCard
                            handleBackOpen={this.handleBackOpen}
                            Data={LOWCard}
                            Name={LOWCard.nat ? LOWCard.nat : "LOWCard"}
                            section1Ref={this.section1Ref}
                            posArray={posArray}
                            oddsDifference={oddsDifference}
                          />
                        </div>
                        <div class="bg-[#455A46] col-span-1 flex justify-center items-center w-full">
                          <div className=" flex justify-center items-center">
                            <img src="/assets/images/7.jpg" alt="aaaaaa" className="h-14 w-12" />
                          </div>
                        </div>
                        <div class="md:col-span-2 col-span-1 w-full">
                          <GameCard
                            handleBackOpen={this.handleBackOpen}
                            Data={HIGHCard}
                            Name={HIGHCard.nat ? HIGHCard.nat : "HIGHCard"}
                            section1Ref={this.section1Ref}
                            posArray={posArray}
                            oddsDifference={oddsDifference}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="md:flex block odds-bet gap-2 md:space-y-0 space-y-2">
                    <div className="w-full">
                      <div>
                        <div class="w-full grid grid-cols-4 lg:gap-6 gap-2  text-center md:px-10 px-2">
                          <GameCard
                            handleBackOpen={this.handleBackOpen}
                            Data={Even}
                            Name={Even.nat ? Even.nat : "Even"}
                            section1Ref={this.section1Ref}
                            posArray={posArray}
                            oddsDifference={oddsDifference}
                          />
                          <GameCard
                            handleBackOpen={this.handleBackOpen}
                            Data={Odd}
                            Name={Odd.nat ? Odd.nat : "Odd"}
                            section1Ref={this.section1Ref}
                            posArray={posArray}
                            oddsDifference={oddsDifference}
                          />
                          <GameCard2
                            handleBackOpen={this.handleBackOpen}
                            Data={Red}
                            select={"Red"}
                            section1Ref={this.section1Ref}
                            posArray={posArray}
                            oddsDifference={oddsDifference}
                          />
                          <GameCard2
                            handleBackOpen={this.handleBackOpen}
                            Data={Black}
                            section1Ref={this.section1Ref}
                            posArray={posArray}
                            oddsDifference={oddsDifference}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className=" w-full">
                      <div>
                        <div class="w-full grid grid-cols-2 lg:gap-6 gap-2  text-center md:px-10 px-2">
                          <GameCard2
                            handleBackOpen={this.handleBackOpen}
                            Data={Red}
                            select={"Red"}
                            section1Ref={this.section1Ref}
                            posArray={posArray}
                            oddsDifference={oddsDifference}
                          />
                          <GameCard2
                            handleBackOpen={this.handleBackOpen}
                            Data={Black}
                            section1Ref={this.section1Ref}
                            posArray={posArray}
                            oddsDifference={oddsDifference}
                          />

                        </div>
                      </div>

                    </div> */}
                  </div>

                  <div className="py-1 px-[10px] pb-lg-5 pb-0">
                    <div className="odds-bet space-y-2 mb-lg-5 mb-0">
                      <p className="flex justify-center items-center text-[#AAAFB5] text-[16px] py2 bg-[#3C444B] font-bold">{CardA?.rate ? CardA.rate - oddsDifference : 0}</p>
                      <div class="w-full row row-cols13 !flex-wrap mx-0 justify-center items-center pb-lg-5 pb-0">
                        <Card
                          handleBackOpen={this.handleBackOpen}
                          Data={CardA}
                          num={"1"}
                          section1Ref={this.section1Ref}
                          posArray={posArray}
                        />
                        <Card
                          handleBackOpen={this.handleBackOpen}
                          Data={Card2}
                          num={"2"}
                          section1Ref={this.section1Ref}
                          posArray={posArray}
                        />
                        <Card
                          handleBackOpen={this.handleBackOpen}
                          Data={Card3}
                          num={"3"}
                          section1Ref={this.section1Ref}
                          posArray={posArray}
                        />
                        <Card
                          handleBackOpen={this.handleBackOpen}
                          Data={Card4}
                          num={"4"}
                          section1Ref={this.section1Ref}
                          posArray={posArray}
                        />
                        <Card
                          handleBackOpen={this.handleBackOpen}
                          Data={Card5}
                          num={"5"}
                          section1Ref={this.section1Ref}
                          posArray={posArray}
                        />
                        <Card
                          handleBackOpen={this.handleBackOpen}
                          Data={Card6}
                          num={"6"}
                          section1Ref={this.section1Ref}
                          posArray={posArray}
                        />
                        <Card
                          handleBackOpen={this.handleBackOpen}
                          Data={Card7}
                          num={"7"}
                          section1Ref={this.section1Ref}
                          posArray={posArray}
                        />
                        <Card
                          handleBackOpen={this.handleBackOpen}
                          Data={Card8}
                          num={"8"}
                          section1Ref={this.section1Ref}
                          posArray={posArray}
                        />
                        <Card
                          handleBackOpen={this.handleBackOpen}
                          Data={Card9}
                          num={"9"}
                          section1Ref={this.section1Ref}
                          posArray={posArray}
                        />
                        <Card
                          handleBackOpen={this.handleBackOpen}
                          Data={Card10}
                          num={"10"}
                          section1Ref={this.section1Ref}
                          posArray={posArray}
                        />
                        <Card
                          handleBackOpen={this.handleBackOpen}
                          Data={CardJ}
                          num={"11"}
                          section1Ref={this.section1Ref}
                          posArray={posArray}
                        />
                        <Card
                          handleBackOpen={this.handleBackOpen}
                          Data={CardQ}
                          num={"12"}
                          section1Ref={this.section1Ref}
                          posArray={posArray}
                        />
                        <Card
                          handleBackOpen={this.handleBackOpen}
                          Data={CardK}
                          num={"13"}
                          section1Ref={this.section1Ref}
                          posArray={posArray}
                        />
                      </div>
                    </div>
                  </div>


                  <div className="md:hidden block py-2">
                    <div className="flex flex-col justify-center items-center space-y-2">
                      <div className="grid grid-cols-7 gap-2">
                        {casinoData && casinoData.result && casinoData.result.length > 0 ? casinoData.result.slice(0, 7).map((element, index) => (
                          <div onClick={() =>
                            this.handleResultModel(element)} className={` bg-[#000000] ${element && element.result && element.result === "0" ? "text-[#03B177]" : element && element.result && element.result === "1" ? "text-[#FC4242]" : element && element.result && element.result === "2" ? "text-[#FDCF13]" : "-"} w-[30px] h-[30px] cursor-pointer flex justify-center items-center rounded-[4px] py-[4px] px-[20px]`}>
                            <p className="font-semibold text-[16px]">{element && element.result && element.result === "0" ? "T" : element && element.result && element.result === "1" ? "L" : element && element.result && element.result === "2" ? "H" : "-"}</p>
                          </div>
                        )) : null}
                      </div>
                      <div className="grid grid-cols-3 gap-2">
                        {casinoData && casinoData.result && casinoData.result.length > 0 ? casinoData.result.slice(6, 9).map((element, index) => (
                          <div onClick={() =>
                            this.handleResultModel(element)} className={` bg-[#000000] ${element && element.result && element.result === "0" ? "bg-red-600" : element && element.result && element.result === "1" ? "text-[#FC4242]" : element && element.result && element.result === "2" ? "text-[#FDCF13]" : "-"} w-[30px] h-[30px] cursor-pointer flex justify-center items-center rounded-[4px] py-[4px] px-[20px]`}>
                            <p className="font-semibold text-[16px]">{element && element.result && element.result === "0" ? "T" : element && element.result && element.result === "1" ? "L" : element && element.result && element.result === "2" ? "H" : "-"}</p>
                          </div>
                        )) : null}
                      </div>
                    </div>
                  </div>

                </div>



              </div>

              <div className="lg:w-[30%] pr-[8px] w-full space-y-4">

                <div className="md:block hidden bg-black">
                  <RoundedTabBmx
                    PageTitle={name ? name : "Lucky 7A"}
                    ruleImage={"/rulesImage/tp-rules.webp"}
                    eventId={this.props.match.params.eventId} />
                  <div className="flex flex-wrap lg:justify-start justify-center p-1 gap-2 items-center">
                    {casinoData && casinoData.result && casinoData.result.length > 0 ? casinoData.result.map((element, index) => (
                      <div onClick={() =>
                        this.handleResultModel(element)} className={` bg-[#434343] ${element && element.result && element.result === "0" ? "bg-red-600" : element && element.result && element.result === "1" ? "text-[#FC4242]" : element && element.result && element.result === "2" ? "text-[#FDCF13]" : "-"} w-[39px] h-[30px] cursor-pointer flex justify-center items-center rounded-[4px] py-[4px] px-[20px]`}>
                        <p className="font-semibold text-[16px]">{element && element.result && element.result === "0" ? "T" : element && element.result && element.result === "1" ? "L" : element && element.result && element.result === "2" ? "H" : "-"}</p>
                      </div>
                    )) : null}
                  </div>
                </div>

                <div className="bg-[#3C444B] mt-3 md:block hidden">
                  <div className="bg-[#16191C] text-[#AAAFB5] text-[16px] px-2 py-[6px] flex justify-between items-center">
                    <span className="uppercase font-semibold tracking-wider">
                      Place Bet
                    </span>
                    <div className='font-medium text-[12px]'>
                      Range:{minStake ? minStake : "100"}-
                      {maxStake ? maxStake : "10000"}
                    </div>
                  </div>
                  <div className="px-3 text-[#AAAFB5] text-[16px] py-1.5 flex justify-between items-center ">
                    <span>(Bet for)</span>
                    <span>Odds</span>
                    <span>Stake</span>
                    <span>Profit</span>
                  </div>
                </div>

                {LoadingBet === true ?
                  <>
                    <BetPlaceDesktopCasinoLoader
                      betSlipData={this.state.betSlipData}
                      time={time}
                      count={count}
                    />
                  </> :
                  <>
                    {backBetModal && backBetModal === true ? (
                      <BetPlaceDesktopCasino
                        betSlipData={this.state.betSlipData}
                        updateStackOnclick={this.updateStackOnclick}
                        inputChange={this.inputChange}
                        decreaseCount={this.decreaseCount}
                        increaseCount={this.increaseCount}
                        placeBet={this.placeBet}
                        handleClose={this.handleClose}
                        count={this.state.count}
                        timerKey={this.state.timerKey}
                        remainingTime={this.state.remainingTime}
                        handleCountdown={this.handleCountdown}
                        updateStake={this.updateStake}
                      />
                    ) : null}
                  </>
                }

                <div className="bg-[#3C444B] mt-2">
                  <div className="bg-[#000000] text-[#AAAFB5] text-[16px] px-2.5 py-[6px] flex justify-between items-center">
                    <span className="uppercase font-semibold tracking-wider">
                      MY BETS
                    </span>
                  </div>
                  {/* <div className="px-4 text-[#AAAFB5] text-[16px] py-1 flex justify-between items-center ">
                    <span>Team</span>
                    <span>Mode</span>
                    <span>Rate</span>
                    <span>Amount</span>
                    <span>Result</span>
                    <span>Created</span>
                  </div> */}
                </div>

                <div className="">
                  {betList && betList.length > 0 ?
                    <div className="space-y-[1px] bg-gray-200 pb-1 rounded">
                      {/* <MyBetTabBmx totalProfitLoss={totalProfitLoss} /> */}
                      <BetListCasinoTableBmx betList={betList} />
                    </div>
                    : null}
                </div>
              </div>

            </div>
          </div>
          
        }

{/* <ErrorTostCasino/> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Lucky7A);
