import React, { useState } from 'react';
import RulesModelBmx from "../../casino_components/RulesModelBmx/RulesModelBmx";
import { useHistory } from 'react-router-dom';

export default function RoundedTabBmx(props) {
  const { PageTitle, ruleImage, eventId } = props;
  const [rulesModal, setRulesModal] = useState(false);
  const history = useHistory();

  const handleOpenRules = () => {
    setRulesModal(!rulesModal)
  };

  const onClickMenu = (url) => {
    history.push(url);
  };

  return (
    <div className="bg-[#16191C] text-white font-normal text-[16px] px-2.5 py-2 flex justify-between items-center">
      {rulesModal ?
        <RulesModelBmx ruleImage={ruleImage} PageTitle={PageTitle} setRulesModal={handleOpenRules} />
        : null}
      <span className="uppercase text-[#AAAFB5] font-semibold tracking-wide">
        Last Results
      </span>
      {/* <div className='flex justify-end items-center space-x-4'>
        <span onClick={() => handleOpenRules()}>Rules</span>
        <span onClick={() => onClickMenu(`/app/casinoresult/${eventId}`)} className="cursor-pointer">View All</span>
      </div> */}
    </div>
  );
}

