import React from 'react';

export default function BgSkyButtonTheme2(props) {
    const { ele, handleBackOpen, data, section1Ref, oddsDifference, posArray } = props;
    console.log(data, "datyaadstatydta");

    return (
        // <div onClick={() => handleBackOpen(data, section1Ref)} className="absolute top-0 even-background w-full h-9 flex justify-center items-center text-[14px] py-2 font-medium tracking-wide text-white uppercase rounded cursor-pointer">
        //     {ele}
        // </div>
        <div className={` ${data.nat === "LOW Card" ? 'border-[6px] rounded-none bg-transparent border-red-500' : data.nat === "HIGH Card" ? 'border-[6px] rounded-none bg-transparent border-[#03B37F]' : data.nat === "Dragon" ? 'border-[2px] rounded-none bg-[#3B3636] border-[#FC4242]' : data.nat === "Tiger" ? 'border-[2px] rounded-none bg-[#3B3636] border-[#FDCF13]' : data.nat === "Tie" ? 'border-[2px] rounded-none bg-[#3B3636] border-[#72BBEF]' : 'border-none bg-[#455A46] rounded-lg'} py-1 space-y-1`}>
            <div className="flex justify-center items-center text-[12px] text-[#AAAFB5]">
                <p className="uppercase text-[14px] md:text-[16px] px-2 text-[#AAAFB5] font-semibold">{data && data.rate ? (data.rate - oddsDifference) : 0}</p>
            </div>
            <div onClick={() => handleBackOpen(data, section1Ref)} className={` leading-normal w-full md:text-[16px] text-[14px] font-bold text-[#AAAFB5] uppercase cursor-pointer flex justify-center items-center $`}>
                {ele}
            </div>
            <div className={`${posArray[data.sid] < 0 ? "text-red-500" : "text-[#2CD62C]"} flex text-[16px] justify-center items-center font-bold`}>
                {posArray[data.sid] ?? 0}
            </div>
        </div>
    );
}